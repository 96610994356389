<template>
  <div
    class="cx-ligne-evenements-container"
    @contextmenu.prevent=""
  >
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-evenements-cartouche"
      @contextmenu.prevent=""
    >
      Evenements
    </div>
    <template v-for="(item,indexJour) in datas.dates">
      <div
        :key="indexJour"
        class="cx-ligne-evenements-case"
        :style="{width: 'calc((100% - '+largeurGauche+'px)/'+datas.dates.length+')'}"
        @contextmenu.prevent=""
        @click="f_edit_evenement(item)"
      >
        <v-tooltip
          bottom
          class="v-tooltip-css"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              v-if="item.detail_evenement"
              small
              class="ma-2"
              color="primary"
              style="cursor: pointer"
              v-on="on"
              @contextmenu.prevent=""
            >
              {{ item.detail_evenement.length }}
            </v-chip>
          </template>
          <div
            v-for="(detailItem,index) in item.detail_evenement"
            :key="index"
            :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
          >
            <ix-item-evenement :item="detailItem" />
          </div>
        </v-tooltip>
      </div>
    </template>
    <v-dialog
      v-if="selectedEvenement"
      v-model="showEdit"
      width="500px"
    >
      <div style="display: flex;flex-direction:column;width: 500px;height: 600px;background-color: white;padding: 10px">
        <fx-list
          show-search
          show-refresher
          show-add
          :data-provider="selectedEvenement.detail_evenement"
          :item-renderer="itemRendererEvenement"
          @add="f_ajouter"
          @refresh="getData"
          @action="$emit('update')"
        />
        <div style="display: flex;justify-content: flex-end;margin-top: 10px">
          <v-btn
            :small="true"
            color="red"
            style="margin-left: 10px"
            @click="showEdit = false"
          >
            Fermer
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import IxItemEvenement from './IxItemEvenement'
import IxItemEvenementListe from './IxItemEvenementListe'
export default {
    name: 'CxLigneEvenements',
    components: { IxItemEvenement },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return {
            showEdit: false,
            itemRendererEvenement: IxItemEvenementListe,
            selectedEvenement: null,
        }
    },
    methods: {
        getData () {
            this.$wikaz.request.get('api/edt/modules/planning-edt/planning/' + this.selectedEvenement.dadate + '/evenements').then((data) => {
                this.selectedEvenement = data
            })
        },
        f_edit_evenement (item) {
            this.selectedEvenement = item
            this.showEdit = true
        },
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/planning-edt/planning/' + this.selectedEvenement.dadate + '/evenements/create',
                this.f_result_ajouter,
            )
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post('api/edt/modules/planning-edt/planning/' + this.selectedEvenement.dadate + '/evenements', data).then(() => {
                this.$emit('update')
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-evenements-container {
        width: 100%;
        height: 35px;
        display: flex;
        box-sizing: border-box;
        //border-left: 1px solid black;
        //border-right: 1px solid black;
        background-color: #51b8d5;
    }

    .cx-ligne-evenements-cartouche {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-evenements-case {
        border-left: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .v-tooltip__content.menuable__content__active {
        opacity: 1 !important;
    }
</style>