<template>
  <div
    class="fx-calendrier-tranches-horaires-container"
    :style="{height: heightQuarter*4 + 'px'}"
  >
    <div
      v-for="(index) in (plage_fin - plage_debut)"
      :key="index"
      class="fx-calendrier-tranches-horaires-blocs"
      :style="{height: heightQuarter*4 + 'px'}"
    >
      <div style="width: 50%;box-sizing: border-box;font-size: 14px;padding-left: 4px">
        {{ ((plage_debut + index)-1).toString().padStart(2, '0') }}h
      </div>
      <div style="width: 50%;box-sizing: border-box;font-size: 10px;text-align: right;height: auto;display: flex;flex-direction: column">
        <div
          :style="{height: heightQuarter + 'px'}"
          style="box-sizing: border-box;vertical-align: bottom;border-bottom: 1px solid grey;padding-right: 4px"
        >
          00
        </div>
        <div
          :style="{height: heightQuarter + 'px'}"
          style="box-sizing: border-box;vertical-align: bottom;border-bottom: 1px solid grey;padding-right: 4px"
        >
          15
        </div>
        <div
          :style="{height: heightQuarter + 'px'}"
          style="box-sizing: border-box;vertical-align: bottom;border-bottom: 1px solid grey;padding-right: 4px"
        >
          30
        </div>
        <div
          :style="{height: heightQuarter + 'px'}"
          style="box-sizing: border-box;vertical-align: bottom;padding-right: 4px"
        >
          45
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
    .fx-calendrier-tranches-horaires-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-top: 1px;
    }

    .fx-calendrier-tranches-horaires-blocs {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        border-top: 1px solid grey;
        background-color: #0D6AAD;
        color: white;
    }

    .fx-calendrier-tranches-horaires-blocs:last-child {
        border-bottom: 1px solid grey
    }
</style>
<script>
export default {
    name: 'FxAgendaTranchesHoraires',
    props: {
        plage_debut: {
            type: Number,
            default: 1,
        },
        plage_fin: {
            type: Number,
            default: 24,
        },
        heightQuarter: {
            type: Number,
            default: 20,
        },
    },
}
</script>