<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        v-if="item.autorisation_type === 'CARD'"
        icon="credit_card"
        tooltip="Carte Bancaire"
      />
      <fx-icon
        v-else
        icon="account_balance"
        tooltip="SEPA"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.autorisation_slimpay_reference"
      />
      <v-list-item-subtitle>{{ item.autorisation_slimpay_id }}</v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.autorisation_date_debut">
        Depuis le : {{ $wikaz.outils.dates.getDate(item.autorisation_date_debut,'DD/MM/YYYY HH:mm') }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.autorisation_date_expiration">
        {{ item.autorisation_date_expiration }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon
        v-if="item.defaut === 1"
        color="success"
      >
        check
      </v-icon>
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemMoyenPaiement',
    extends: FxItemTemplate,
    methods: {
        f_ajouter () {

        },
    },
}
</script>