<template>
  <div style="display: flex;flex-direction: column;gap: 10px">
    <v-btn
      color="primary"
      @click="f_gerer_prestations"
    >
      Gestion des prestations
    </v-btn>
    <v-btn
      color="primary"
      @click="f_gerer_disponibilite"
    >
      Gestion des disponibilités
    </v-btn>
    <v-btn
      color="primary"
      @click="f_gerer_conge_indispo"
    >
      Gestion des congés & indisponibilités
    </v-btn>
    <div style="display: flex;align-items: center">
      <v-checkbox
        v-model="visibilite_do"
        :true-value="1"
        :false-value="0"
        @change="f_change_visibilite_do"
      />
      <div>Visibilité donneurs d'ordres</div>
    </div>
  </div>
</template>

<script>
import CxDisponibiliteGenerale
from '@/wikaz-konnect/commun/gestion-utilisateurs/disponibilites/CxDisponibiliteGenerale'
import CxCongesIndisponibilite
from '@/wikaz-konnect/commun/gestion-utilisateurs/conges-indisponibilites/CxCongesIndisponibilite'

export default {
    name: 'CxConfigurationProfilTechnicien',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { visibilite_do: 1 }
    },
    mounted () {
        this.getVisibiliteDo()
    },
    methods: {
        f_gerer_prestations () {
            this.$wikaz.popups.showTransfertList(
                'Affectations des prestations',
                this.urlRessourceEndPoint + '/affectation-competences',
                this.urlRessourceEndPoint + '/affectation-competences/0',
                () => {
                    this.f_refresh()
                },
                // () => {this.f_refresh()}
            )
        },
        f_gerer_disponibilite () {
            this.$wikaz.popups.show(
                CxDisponibiliteGenerale,
                { props: { urlEndPoint: this.urlRessourceEndPoint + '/disponibilites' } },
            )

            // :url-end-point="urlRessourceEndPoint + '/' + dataIn.id + '/disponibilites'"

        },
        f_gerer_conge_indispo () {
            this.$wikaz.popups.show(
                CxCongesIndisponibilite,
                { props: { urlEndPoint: this.urlRessourceEndPoint + '/indisponibilites' } },
            )
        },
        getVisibiliteDo () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/visibilite-do').then((data) => {
                this.visibilite_do = data.propriete_visibilite_do
            })
        },
        f_change_visibilite_do (etat) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/visibilite-do', { visibilite_do: etat }).then(() => {
                this.getVisibiliteDo()
            })
        },
    },
}
</script>

<style scoped>

</style>