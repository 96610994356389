<template>
  <div
    v-if="datas"
    :key="keyChange"
    class="cx-traitement-utilisateur-container"
  >
    <div
      v-if="index===0"
      class="cx-traitement-utilisateur-col1"
    >
      <fx-formulaire-generique
        :data-in="datas"
        :url-ressource="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/'+datas.id+'/edit'"
        @on-submit="updateData"
      />
      <div style="display: flex;justify-content: flex-end;padding: 5px">
        <v-btn
          small
          color="error"
          @click="resetMdp()"
        >
          Réinitialiser le mot de passe
        </v-btn>
      </div>
    </div>
    <div
      v-else-if="index===1"
      style="overflow: auto;padding: 10px"
      title="Profils"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-profils'"
        :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-profils/' + datas.id"
      />
    </div>
    <div
      v-else-if="index===2"
      style="overflow: auto;padding: 10px"
      title="Options"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-options'"
        :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-options/' + datas.id"
      />
    </div>
    <div
      v-else-if="index===3"
      style="overflow: auto;padding: 10px"
      title="Modules"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-modules'"
        :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-modules/' + datas.id"
      />
    </div>
    <div
      v-else-if="index===4"
      style="overflow: auto;padding: 10px"
      title="Sites"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-sites'"
        :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-sites/' + datas.id"
      />
    </div>
    <v-bottom-navigation
      v-model="index"
      grow
      hide-on-scroll
      @change="onChange"
    >
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>person</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>portrait</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>check_box</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>view_comfy</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>web</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<style scoped lang="scss">
    .cx-traitement-utilisateur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-traitement-utilisateur-col1 {
        width: 100%;
        box-sizing: border-box;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        overflow: auto;
    }

    .btn-menu {
        min-width: 20px;
        margin: 0px;
        padding: 0px;
    }
</style>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementUtilisateurPwa',
    extends: CxBase,
    data () {
        return {
            index: 0,
            keyChange: 0,
        }
    },
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Utilisateur'
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + data.id, data).then(this.f_result_updateData)
        },
        f_result_updateData () {
            this.$emit('on-refresh')
        },
        logAs () {
            this.$wikaz.request.get('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + this.datas.id + '/usurpation').then((data) => {
                this.$Notice[data.type]({
                    title: data.title,
                    desc: data.desc,
                    duration: 2.5,
                })
                if (data.type === 'success') {
                    window.Vue.$store.commit('UPDATE_TOKEN', data.token)
                    setTimeout(() => {
                        location.href = '/'
                    }, 400)
                }
            })
        },
        resetMdp () {
            this.$wikaz.request.put('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + this.datas.id + '/password')
        },
        f_retour () {
            this.f_precedent('vx-gestion-utilisateurs', 'Gestion des utilisateurs')
        },
        onChange () {
            this.keyChange ++
        },
    },
}
</script>