<template>
  <fx-list
    show-search
    show-refresher
    show-result
    style="height: 100%;overflow: auto"
    title="Utilisateurs"
    :item-renderer="itemRenderer"
    :url="urlRessourceEndPoint"
  />
</template>
<script>
import IxItemUtilisateur from '@/wikaz-core/ui/modules/gestion-des-packs/IxItemUtilisateur'

export default {
    name: 'CxListeUtilisateurs',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { itemRenderer: IxItemUtilisateur }
    },
}
</script>