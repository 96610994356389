<template>
  <div>
    <fx-titre titre="Configuration du formulaire" />
    <div
      v-for="(item,name,index) in value"
      :key="index"
      style="margin-left: 15px"
    >
      <fx-sous-titre :titre="name" />
      <!--<div style="margin-left: 15px">{{item}}</div>-->
      <div
        v-for="(propriete,namePropriete,indexPropriete) in value[name]"
        :key="indexPropriete"
        style="display: flex;margin-top: 5px;margin-left: 15px"
      >
        <span style="width: 200px">{{ namePropriete }}</span>
        <fx-text-input
          :value="item[namePropriete]"
          @input="value[name][namePropriete] = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxFormulaireEditeur',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>