<template>
  <div style="display: flex;justify-content: center">
    <v-icon style="width: 30%">
      info
    </v-icon>
    <div>{{ titre }}</div>
  </div>
</template>
<script>
export default{
    name: 'FxBlocInfo',
    props: {
        titre: {
            default: '',
            type: String,
        },
        activeColor: {
            default: '#000000',
            type: String,
        },
    },
}
</script>