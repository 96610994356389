<template>
  <div style="width:auto;display: flex">
    <fx-icon
      :size="38"
      icon="keyboard_arrow_left"
      @click="f_retrait_mois"
    />
    <div
      style="display:flex;width: 120px;text-align: center;border: 1px solid grey;border-radius: 5px;align-items: center;justify-content: center"
      @click="showEdit=true"
    >
      {{ f_formatter_mois(value) }}
    </div>
    <fx-icon
      :size="38"
      icon="keyboard_arrow_right"
      @click="f_ajout_mois"
    />
    <v-dialog
      v-model="showEdit"
      width="200px"
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in moisData"
          :key="index"
          @click="f_select_mois(index)"
        >
          <v-list-item-content style="margin-left: 10px">
            <v-list-item-title v-text="item" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: 'FxSelectionMois',
    props: {
        value: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            moisData: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            showEdit: false,
        }
    },
    methods: {
        f_retrait_mois () {
            if (this.value > 1) {
                this.$emit('input', this.value - 1)
            }
        },
        f_ajout_mois () {
            if (this.value < 12) {
                this.$emit('input', this.value + 1)
            }
        },
        f_select_mois (index) {
            this.$emit('input', index + 1)
            this.showEdit = false
        },
        f_formatter_mois (valeur) {
            return this.moisData[valeur - 1]
        },
    },
}
</script>

<style scoped>

</style>