<template>
  <v-checkbox
    v-model="selectedValue"
    style="padding: 0;margin-top: 0;margin-bottom: 0"
    hide-details
    :disabled="disabled"
    :true-value="trueValue"
    :false-value="falseValue"
    :label="label"
    v-on="listeners"
    @change="onChange"
  >
    <template v-slot:label>
      <div>
        En cochant cette case, vous confirmez avoir pris connaissance et accepté notre
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <a
              target="_blank"
              href="http://wikaz.fr/politique-de-confidentialite-gdt"
              @click.stop
              v-on="on"
            >politique de confidentialité</a>
          </template>
          Afficher dans une nouvelle fenêtre
        </v-tooltip>
        sur la vie privée et réglement RGPD.
      </div>
    </template>
  </v-checkbox>
</template>
<script>
export default{
    name: 'FxCheckRgpd',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        label: {
            default: null,
            type: String,
        },
        value: { default: 0 },
        size: { default: 'small' },
        width: { default: '100%' },
        trueValue: { default: 1 },
        falseValue: { default: 0 },
    },
    data () {
        return { selectedValue: this.value }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => self.$emit(item, event)
            })
            return { ...self.$listeners }
        },
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        onChange (ev) {
            this.$emit('input', ev)
            this.$emit('on-change', ev)
        },
    },
}
</script>