<template>
  <fx-traitement-template
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :composant-filtre-element="composantFiltreElement"
    :item-renderer="itemRenderer"
    :set-visible-ajout="true"
    :url-ressource-end-point="urlRessourceEndPoint"
    class="vx-gestion-crm"
    titre-liste="Liste des prospects"
    width="100%"
    width-list="350px"
  />
</template>
<script>
import IxItemGestionCrm from '@/wikaz-konnect/crm/gestion-prospects-crm/IxItemGestionCrm'
import CxFiltreGestionCrm from '@/wikaz-konnect/crm/gestion-prospects-crm/CxFiltreGestionCrm'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementCrmWeb from '@/wikaz-konnect/crm/gestion-prospects-crm/CxTraitementCrmWeb'
import CxTraitementCrmPwa from '@/wikaz-konnect/crm/gestion-prospects-crm/CxTraitementCrmPwa'

export default {
    name: 'VxGestionProspectsCrm',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-prospects-wk-crm/contacts',
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreGestionCrm),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementCrmWeb),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementCrmPwa),
            itemRenderer: IxItemGestionCrm,
        }
    },
}
</script>