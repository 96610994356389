<template>
  <div style="display: grid;grid-template-columns: 1fr;grid-template-rows: 25px 30px 1fr">
    <fx-titre titre="Gestion des pages" />
    <div style="height:30px;display: flex;justify-content: flex-end;padding-right: 10px">
      <fx-icon
        :size="24"
        icon="add"
        color="green"
        @click="f_ajouter_page"
      />
      <fx-icon
        :size="24"
        icon="refresh"
        color="blue"
        @click="$emit('refresh')"
      />
    </div>
    <div>
      <fx-list-simple
        :data-provider="value.pages"
        :item-renderer="itemRendererPages"
        @itemEmitting="$emit('onSelect',$event)"
        @actualiser="$emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
import IxItemPages from '../IxItemPages'

export default {
    name: 'CxPanelPages',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { itemRendererPages: IxItemPages }
    },
    methods: {
        f_ajouter_page () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/pages/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post('api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/pages', data).then(() => {
                this.$emit('refresh')
            })
        },
    },
}
</script>

<style scoped>

</style>