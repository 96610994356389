<template>
  <div
    v-if="urlRessourceEndPoint"
    class="cx-traitement-gestion-crm-container"
  >
    <div
      v-if="dataIn"
      class="cx-traitement-gestion-crm-col1"
    >
      <cx-fiche-prospect
        v-if="datas"
        :datas="datas"
      />
      <div v-else />
      <div style="display: flex;justify-content: center;align-items: center;margin-bottom: 10px">
        <v-btn
          v-if="(dataIn.is_proprietaire > 0 || dataIn.is_admin === 1) && dataIn.contact_user_proprietaire > 0"
          x-small
          color="error"
          @click="f_liberer"
        >
          Libérer
        </v-btn>
        <v-btn
          v-if="dataIn.is_admin === 1"
          style="margin-left: 10px"
          x-small
          color="error"
          @click="f_supprimer"
        >
          Supprimer
        </v-btn>
        <v-btn
          v-if="dataIn.is_proprietaire === 0 && dataIn.contact_user_proprietaire === 0"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="f_recuperer"
        >
          Récupérer le prospect
        </v-btn>
        <v-btn
          v-if="dataIn.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="f_modifier"
        >
          Modifier
        </v-btn>
      </div>
      <cx-interlocuteur-gdi
        :item-renderer-interlocuteur="itemRendererInterlocuteur"
        :url-ressource-end-point="urlRessourceEndPoint + dataIn.contact_id +'/'"
      />
    </div>
    <div class="cx-traitement-gestion-crm-col2">
      <fx-titre titre="Actions commerciales" />
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;width: 100%">
        <v-btn
          x-small
          color="success"
          @click="ajouterObservation"
        >
          Saisir une observation
        </v-btn>
        <v-btn
          v-if="dataIn.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="ajouterAction"
        >
          Action immédiate
        </v-btn>
        <v-btn
          v-if="dataIn.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="programmerAction"
        >
          Programmer une action
        </v-btn>
      </div>
      <fx-list
        ref="objListeActions"
        v-model="selectedAction"
        style="height: 100%;overflow: auto"
        show-search
        show-refresher
        :url="urlRessourceEndPoint + dataIn.contact_id + '/actions'"
        :item-renderer="itemRenderer"
        @itemEmitting="cacheKeyAction++"
        @refresh="selectedAction = null"
      />
      <cx-explorateur-fichier
        :extension-array="['pdf','jpg','png','jpeg']"
        show-import
        show-refresher
        :url-ressource-end-point="urlRessourceEndPoint + dataIn.contact_id + '/fichiers'"
      />
    </div>
  </div>
</template>
<script>
import CxFicheProspect from './CxFicheProspect'
import CxInterlocuteurGdi
from '@/wikaz-konnect/gdi/gestion-clients/interlocuteur/CxInterlocuteurGdi'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import IxGestionCrmAction from '@/wikaz-konnect/crm/gestion-prospects-crm/IxGestionCrmAction'
import IxGestionCrmInterlocuteur from '@/wikaz-konnect/crm/gestion-prospects-crm/IxGestionCrmInterlocuteur'

export default {
    name: 'CxTraitementCrmWeb',
    components: {
        CxInterlocuteurGdi,
        CxFicheProspect,
    },
    extends: CxBase,
    data () {
        return {
            urlRessourceEndPoint: null,
            itemRenderer: IxGestionCrmAction,
            itemRendererInterlocuteur: IxGestionCrmInterlocuteur,
            selectedAction: null,
            cacheKeyAction: 0,
            datas: null,
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '4':
            this.urlRessourceEndPoint = 'api/crm/modules/gestion-prospects-crm/contacts/'
            break
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-prospects-wk-crm/contacts/'
            break
        }
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + this.dataIn.contact_id).then((data) => {
                this.datas = data
            })
        },
        f_liberer () {
            this.$wikaz.request.put(this.urlRessourceEndPoint + this.dataIn.contact_id + '/liberer').then(() => {
                this.$emit('on-refresh')
            })
        },
        f_supprimer () {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Souhaitez vous réellement supprimer ce prospect', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + this.dataIn.contact_id).then(() => {
                    this.$emit('on-refresh')
                })
            })
        },
        f_recuperer () {
            this.$wikaz.request.put(this.urlRessourceEndPoint + this.dataIn.contact_id + '/recuperer').then(() => {
                this.$emit('on-refresh')
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.dataIn.contact_id + '/edit',
                (data) => {
                    this.$wikaz.request.put(this.urlRessourceEndPoint + this.dataIn.contact_id, data).then(() => {
                        this.getData()
                    })
                },
            )
        },
        ajouterInterlocuteur () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.dataIn.contact_id + '/interlocuteurs/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + this.dataIn.contact_id + '/interlocuteurs', data).then(() => {
                        this.$refs.objListeInterlocuteurs.refresh()
                    })
                },
            )
        },
        ajouterObservation () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.dataIn.contact_id + '/observations/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + this.dataIn.contact_id + '/observations', data).then(() => {
                        this.$refs.objListeActions.refresh()
                    })
                },
            )
        },
        f_refresh_action () {
            this.selectedAction = null
            this.$refs.objListeActions.refresh()
        },
        ajouterAction () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.dataIn.contact_id + '/actions/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + this.dataIn.contact_id + '/actions', data).then(() => {
                        this.f_refresh_action()
                    })
                },
            )
        },
        programmerAction () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.dataIn.contact_id + '/programmer-actions/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + this.dataIn.contact_id + '/programmer-actions', data).then(() => {
                        this.f_refresh_action()
                    })
                },
            )
        },
    },
}
</script>
<style lang="scss" scoped>

.cx-traitement-gestion-crm-container {
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    display: grid;
    grid-template-columns: 350px 1fr ;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    overflow: auto;
}

.cx-traitement-gestion-crm-col1 {
    //border: 1px solid green;
    box-sizing: border-box;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 2fr;
    overflow: auto;
}

.cx-traitement-gestion-crm-col2 {
    //border: 1px solid green;
    box-sizing: border-box;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 3fr 2fr;
    grid-row-gap: 10px;
    overflow: auto;
}

.cx-traitement-gestion-crm-col3 {
    // border: 1px solid green;
    box-sizing: border-box;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-row-gap: 10px;
    overflow: auto;
}
</style>