<template>
  <div>Planning SAP</div>
</template>

<script>
export default {
  name: 'VxPlanningsSap',
}
</script>

<style scoped>

</style>