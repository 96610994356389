import { render, staticRenderFns } from "./CxTraitementGestionClientsPwa.vue?vue&type=template&id=269e1e2c&scoped=true&"
import script from "./CxTraitementGestionClientsPwa.vue?vue&type=script&lang=js&"
export * from "./CxTraitementGestionClientsPwa.vue?vue&type=script&lang=js&"
import style0 from "./CxTraitementGestionClientsPwa.vue?vue&type=style&index=0&id=269e1e2c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "269e1e2c",
  null
  
)

export default component.exports