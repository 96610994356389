<template>
  <!--<div style="width: 150px;height: 35px;display: flex;align-items: center">
        <v-icon>{{item.event_icon}}</v-icon>
        <div style="margin-left: 10px">{{item.event_description}}</div>
    </div>-->
  <v-list-item
    dense
    style="overflow: visible;"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon color="primary">
        {{ item.event_icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-if="item.event_titre"
        v-html="item.event_titre"
      />
      <v-list-item-subtitle
        v-if="item.event_time"
        v-html="item.event_time"
      />
      <v-list-item-content
        v-if="item.event_description"
        v-html="item.event_description"
      />
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon
        v-if="item.event_etat === 1"
        color="grey"
      >
        hourglass_empty
      </v-icon>
      <v-icon
        v-if="item.event_etat === 2"
        color="green"
      >
        check_circle
      </v-icon>
      <v-icon
        v-if="item.event_etat === 3"
        color="red"
      >
        close
      </v-icon>
    </v-list-item-avatar>
    <v-menu
      v-if="item.event_etat === 1"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          v-on="on"
        />
      </template>
      <v-list ref="objLst">
        <v-list-item @click="f_terminer">
          <v-list-item-avatar>
            <v-icon color="green">
              check
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Terminer</v-list-item-title>
        </v-list-item>
        <v-list-item @click="f_annuler">
          <v-list-item-avatar>
            <v-icon color="red">
              close
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Annuler</v-list-item-title>
        </v-list-item>
        <v-list-item @click="f_supprimer">
          <v-list-item-avatar>
            <v-icon color="red">
              delete
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Supprimer</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>

<script>
import FxItemTemplate from '../../../../../wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemEvenementListe',
    extends: FxItemTemplate,
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_terminer () {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + this.item.event_date + '/evenements/' + this.item.event_id + '?etat=2', this.item).then(() => {
                this.$emit('action')
            })
        },
        f_annuler () {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + this.item.event_date + '/evenements/' + this.item.event_id + '?etat=3', this.item).then(() => {
                this.$emit('action')
            })
        },
        f_supprimer () {
            this.$wikaz.request.delete('api/edt/modules/planning-edt/planning/' + this.item.event_date + '/evenements/' + this.item.event_id).then(() => {
                this.$emit('action')
            })
        },
    },
}
</script>

<style scoped>

</style>