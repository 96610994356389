<template>
  <v-list-item
    v-if="item.type === 1"
    dense
    style="overflow: visible;"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon>today</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-subtitle-2"
        v-html="item.jour"
      />
      <v-list-item-title
        class="text-subtitle-1"
        v-html="f_format_date(item.dadate)"
      />
    </v-list-item-content>
    <v-list-item-avatar>
      <v-badge
        v-if="item.nb_inter>0"
        color="green"
        :content="item.nb_inter"
        inline
      />
    </v-list-item-avatar>
  </v-list-item>
  <v-list-item
    v-else
    dense
    style="overflow: visible;"
    @click="f_emit_selection"
  >
    <v-list-item-content>
      <v-list-item-title
        v-if="item.dadate === 0"
        class="text-subtitle-2"
        v-html="'Semaine en cours'"
      />
      <v-list-item-title
        v-else
        class="text-subtitle-1"
        v-html="'Semaine + ' + item.dadate"
      />
      <v-list-item-title
        class="text-subtitle-2"
        v-html="f_format_date(item.date_debut) + ' - ' + f_format_date(item.date_fin)"
      />
    </v-list-item-content>
    <v-list-item-avatar>
      <v-badge
        v-if="item.nb_inter>0"
        color="green"
        :content="item.nb_inter"
        inline
      />
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxItemDateCalendrier',
    extends: FxItemTemplate,
}
</script>