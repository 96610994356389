<template>
  <div>
    <div style="display: flex;align-items: center">
      <fx-checkbox
        v-model="filtering"
        @on-change="filterChanged()"
      />
      <fx-dropdown
        v-if="filtering"
        v-model="filterType"
        width="150px"
        :data-provider.sync="types"
        url="api/wikaz/modules/gestion-utilisateurs/comptes"
        @on-change="filterChanged()"
      />
      <div v-else>
        Filtrer par Comptes
      </div>
    </div>
    <div style="display: flex;align-items: center;justify-content: space-between">
      <div>Afficher Actif / Inactif</div>
      <fx-switch
        v-model="isActif"
        @on-change="filterChanged()"
      />
    </div>
  </div>
</template>
<script>

import FxFiltreTemplate from '@/wikaz-core/ui/composants/base/FxFiltreTemplate'

export default {
    name: 'CxFiltreUtilisateur',
    extends: FxFiltreTemplate,
    data () {
        return {
            isActif: 1,
            filtering: false,
            types: [],
            filterType: 16,
        }
    },
    methods: {
        getFiltreData () {
            let filtreType = - 1
            if (this.filtering) {
                filtreType = this.filterType
            }
            return '?isActif=' + this.isActif + '&compte=' + filtreType
        },
    },
}
</script>