<template>
  <div class="vx-configuration-docutheque-container">
    <fx-datagrid-crud
      titre="Gestion des types de fichiers"
      url-ressource-end-point="api/edt/modules/configuration-docutheque/types"
    />
    <div class="vx-configuration-docutheque-container-col2">
      <fx-datagrid-crud
        titre="Gestion des types de classement"
        url-ressource-end-point="api/edt/modules/configuration-docutheque/classements-types"
      />
      <fx-datagrid-crud
        titre="Gestion des zones de classement"
        url-ressource-end-point="api/edt/modules/configuration-docutheque/classements-zones"
      />
    </div>
  </div>
</template>

<script>
import FxDatagridCrud from '@/wikaz-core/ui/composants/datagrid/FxDatagridCrud'
export default {
    name: 'VxConfigurationDocutheque',
    components: { FxDatagridCrud },
    data () {
        return {
            urlRessourceEndPointType: 'api/edt/modules/configuration-docutheque/types',
            urlRessourceEndPointClassement: 'api/edt/modules/configuration-docutheque/types',
            urlRessourceEndPointClassementType: 'api/edt/modules/configuration-docutheque/types',
        }
    },
}
</script>

<style scoped lang="scss">
    .vx-configuration-docutheque-container {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .vx-configuration-docutheque-container-col2 {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        // padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>