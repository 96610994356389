<template>
  <div style="display: flex;">
    <div style="width: 140px">
      Position
    </div>
    <fx-icon
      v-if="value.ordre > 1"
      icon="keyboard_arrow_up"
      :size="22"
      @click="f_up"
    />
    <fx-icon
      v-if="value.ordre < value.maxordre"
      icon="keyboard_arrow_down"
      :size="26"
      @click="f_down"
    />
  </div>
</template>

<script>
export default {
    name: 'CxOrdreEditeur',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_up () {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id + '/up', { ordre: this.value.ordre }).then(() => {
                this.$emit('refresh')
            })
        },
        f_down () {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id + '/down', { ordre: this.value.ordre }).then(() => {
                this.$emit('refresh')
            })
        },
    },
}
</script>

<style scoped>

</style>