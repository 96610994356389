<template>
  <v-flex
    v-if="dataProvider.imageSrc"
    align-self-center
    xs12
    :class="(dataProvider.route ? 'cliquable' : '') + ' ' + (dataProvider.size ? dataProvider.size : 'md4')"
    @click="f_click()"
  >
    <v-img
      :src="$icsilab.rootApi + 'fichiers?file=' + dataProvider.imageSrc"
      :height="dataProvider.imageHeight ? dataProvider.imageHeight : '300'"
      contain
    />
  </v-flex>
  <v-flex
    v-else-if="dataProvider.map"
    align-self-center
    xs12
    :class="(dataProvider.route ? 'cliquable' : '') + ' ' + (dataProvider.size ? dataProvider.size : 'md4')"
  >
    <v-img
      :src="dataProvider.map"
      contain
    />
  </v-flex>
  <v-flex
    v-else
    class="fx-card"
    xs12
    :class="(dataProvider.route ? 'cliquable' : '') + ' ' + (dataProvider.size ? dataProvider.size : 'md4')"
    @click="f_click()"
  >
    <v-hover>
      <template v-slot="{ hover }">
        <v-card
          flat
          class="transparent"
          :elevation="dataProvider.elevation ? hover ? dataProvider.elevation.hover : dataProvider.elevation.initial : 0"
        >
          <v-card-text
            v-if="dataProvider.icon"
            class="text-center"
          >
            <v-icon
              x-large
              :color="dataProvider.iconColor ? dataProvider.iconColor : '#09203F'"
            >
              {{ dataProvider.icon }}
            </v-icon>
          </v-card-text>
          <v-card-text
            v-else-if="dataProvider.iconImage"
            class="text-center"
          >
            <div
              v-if="dataProvider.iconImage"
              style="display: flex;justify-content: center"
            >
              <v-img
                :src="$icsilab.rootApi + 'fichiers?file=' + dataProvider.iconImage"
                :height="dataProvider.iconImageHeight ? dataProvider.iconImageHeight : 60"
                contain
              />
            </div>
          </v-card-text>
          <v-card-title
            v-if="dataProvider.titre"
            class="headline"
            :style="{color: (dataProvider.titreColor ? dataProvider.titreColor : '#000000'),justifyContent: (dataProvider.titreAlign ? dataProvider.titreAlign : 'center')}"
            v-html="dataProvider.titre"
          />
          <v-card-subtitle
            v-if="dataProvider.sousTitre"
            class="title"
            :style="{color: (dataProvider.sousTitreColor ? dataProvider.sousTitreColor : '#000000'),textAlign: (dataProvider.sousTitreAlign ? dataProvider.sousTitreAlign : 'center')}"
            v-html="dataProvider.sousTitre"
          />
          <v-card-text
            v-if="dataProvider.content"
            class="body-1"
            :style="{color: (dataProvider.contentColor ? dataProvider.contentColor : '#000000'),textAlign: dataProvider.contentAlign}"
          >
            <span v-html="dataProvider.content" />
          </v-card-text>
        </v-card>
      </template>
    </v-hover>
  </v-flex>
</template>
<script>
export default {
    name: 'FxCard',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_click () {
            if (this.dataProvider.route && (this.$route.name !== this.dataProvider.route)) {
                this.$icsilab.goToPage(this.dataProvider.route)
            }
        },
    },
}
</script>
<style scoped>
    .cliquable:hover {
        cursor: pointer;
    }

</style>