<template>
  <!--<div class="fx-titre-style" :style="{backgroundColor: $vuetify.theme.currentTheme.titleBackGroundColor, color: $vuetify.theme.currentTheme.titleColor}">{{titre}}</div>-->
  <!--    <div class="fx-titre-style" :style="{backgroundColor: '#4a4fc6', color: '#ffffff'}">{{titre}}</div>-->
  <div class="fx-titre-style">
    {{ titre }}
  </div>
</template>
<style scoped lang="scss">
    .fx-titre-style {
        width: 100%;
        height: 25px;
        font-size: 16px;
        padding-left: 10px;
        font-weight: bold;
        // border-radius: 5px;
        // border-bottom-left-radius: 5px 5px;
        // border-top-right-radius: 50px 25px;
    }
</style>
<script>
export default{
    name: 'FxTitre',
    props: {
        titre: {
            default: null,
            type: String,
        },
    },
}
</script>