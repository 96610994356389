let urls = function () {
    this.getBaseUrl = function () {
        return ((process.env.NODE_ENV === 'development') ? '/baseURL' : process.env.VUE_APP_URL_API_PROD)
    }
    this.getUrlImageByUrl = function (url) {
        return this.getBaseUrl() + '/api/wikaz/images?url=' + url
    }
    this.getMenuImage = function (file) {
        return this.getBaseUrl() + '/api/wikaz/menu_image/' + file
    }
    this.openUrl = function (url) {
        let Urls = url.toString().split(';')
        for (let i = 0; i < Urls.length; i ++) {
            this.f_open_link(Urls[i])
        }
    }
    this.f_open_link = function (url, target = '_blank') {
        if (url.toString().substr(0, 4) === 'http') {
            window.open(url, target)
        } else {
            window.open('https://' + url, target)
        }
    }
}
export default urls