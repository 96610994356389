<template>
  <fx-sous-titre
    v-if="item.component === 'sous-titre'"
    :titre="item.label"
  />
  <fx-bloc-info
    v-else-if="item.component === 'bloc-info'"
    :titre="item.label"
  />
  <div
    v-else
    style="width:100%;display: flex;align-items: center;margin-bottom: 4px;margin-top: 4px;margin-left: 3px"
  >
    <fx-label
      v-if="item.label"
      :text="item.label"
      :width="labelWidth"
      :error="error ? true : false"
    />
    <component
      :is="'fx-'+item.component"
      ref="element"
      v-model="dataProvider[item.field]"
      :data-provider.sync="item.dataprovider"
      :placeholder="item.placeholder"
      :rules="rules"
      :small="small"
      :editable="item.editable"
      :url="item.url"
      @input="validate"
    />
    <fx-tooltip-icon-error :error="error" />
  </div>
</template>

<script>
export default {
    name: 'FxFormItem',
    props: {
        item: {
            type: Object,
            default: null,
        },
        dataProvider: {
            type: Object,
            default: null,
        },
        labelWidth: {
            type: String,
            default: 'auto',
        },
        rulesBrut: {
            type: Array,
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            rules: [],
            error: null,
        }
    },
    beforeMount () {
        if(this.rulesBrut) {
            let nbElem = this.rulesBrut.length
            for (let i = 0; i < nbElem; i ++) {
                // if (['text-input'].indexOf(this.item.component) > -1) {
                //     this.rules.push(this.rulesBrut[i])
                // } else {
                switch (this.rulesBrut[i].type) {
                case 'required':
                    // console.log(this.item.field,this.rulesBrut[i].type,this.rulesBrut[i].message)
                    this.rules.push(v => !! v || this.rulesBrut[i].message)
                    break
                }
                // }
            }
        }
    },
    methods: {
        validate (value) {
            this.$emit('input', value)
            if(this.$refs.element.validate) {
                let isValid = this.$refs.element.validate()
                if(this.$refs.element.getError) {
                    this.error = this.$refs.element.getError()
                }
                if(isValid) {
                    return isValid
                }
            }
            return false
        },
        getError () {
            return this.error
        },
    },
}
</script>

<style scoped>

</style>