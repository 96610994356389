<template>
  <div>Logement Pwa</div>
</template>

<script>
export default { name: 'CxTraitementGestionLogementPwa' }
</script>

<style scoped>

</style>