<template>
  <div
    v-if="datas"
    class="cx-traitement-utilisateur-container"
  >
    <div class="cx-traitement-utilisateur-col1">
      <cx-photo-profil :url-ressource="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/photo'" />
      <fx-formulaire-generique
        :data-in="datas"
        :url-ressource="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/'+datas.id+'/edit'"
        @on-submit="updateData"
      />
      <div style="display: flex;justify-content: flex-end;padding: 5px">
        <v-btn
          small
          color="error"
          @click="resetMdp()"
        >
          Réinitialiser le mot de passe
        </v-btn>
      </div>
    </div>
    <fx-tab-navigator>
      <div
        style="overflow: auto;padding: 10px"
        title="Profils"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-profils'"
          :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-profils/' + datas.id"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Options"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-options'"
          :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-options/' + datas.id"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Modules"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-modules'"
          :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-modules/' + datas.id"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Sites"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-sites'"
          :url-ressouce-update="'api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + datas.id + '/affectation-sites/' + datas.id"
        />
      </div>
    </fx-tab-navigator>
  </div>
</template>
<style>
    .cx-traitement-utilisateur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 450px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-traitement-utilisateur-col1 {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 300px 550px 45px;
        overflow: auto;
    }
</style>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementUtilisateur',
    extends: CxBase,
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + data.id, data).then(this.f_result_updateData)
        },
        f_result_updateData () {
            this.$emit('on-refresh')
        },
        logAs () {
            this.$wikaz.request.get('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + this.datas.id + '/usurpation').then((data) => {
                this.$Notice[data.type]({
                    title: data.title,
                    desc: data.desc,
                    duration: 2.5,
                })
                if (data.type === 'success') {
                    window.Vue.$store.commit('UPDATE_TOKEN', data.token)
                    setTimeout(() => {
                        location.href = '/'
                    }, 400)
                }
            })
        },
        resetMdp () {
            this.$wikaz.request.put('api/wikaz/modules/gestion-utilisateurs/utilisateurs/' + this.datas.id + '/password').then((data) => {
                this.$Notice[data.type]({
                    title: data.title,
                    desc: data.desc,
                    duration: 3.5,
                })
            })
        },
        f_retour () {
            this.f_precedent('vx-gestion-utilisateurs', 'Gestion des utilisateurs')
        },
    },
}
</script>