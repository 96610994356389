<template>
  <div class="vx-gestion-mode-de-paiement-container">
    <div class="cx-bloc1">
      <cx-contact-facturation :url-ressource-end-point="urlRessourceEndPoint + 'contact-facturation'" />
      <v-card />
    </div>
    <cx-historique-factures class="cx-bloc2" :url-ressource-end-point="urlRessourceEndPoint + 'factures'" />
  </div>
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxContactFacturation from '@/wikaz-core/ui/modules/factures/CxContactFacturation'
import CxHistoriqueFactures from '@/wikaz-core/ui/modules/factures/CxHistoriqueFactures'

export default {
    name: 'VxGestionFactures',
    components: {
        CxHistoriqueFactures,
        CxContactFacturation,
    },
    extends: VxBase,
    data () {
        return {
            datas_mode_paiement: null,
            datas_contact_facturation: null,
            datas_factures: null,
            urlRessourceEndPoint: 'api/wikaz/facturation/',
        }
    },
}
</script>
<style scoped lang="scss">
    .vx-gestion-mode-de-paiement-container {
        // border: 1px solid red;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-bloc1 {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-bloc2 {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
</style>