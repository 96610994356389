<template>
  <v-textarea
    ref="myInput"
    style="margin-top: 2px;margin-bottom: 2px;"
    outlined
    hide-details
    dense
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="!editable"
    :style="{width: width}"
    :value="value"
    :rules="rules"
    v-on="listeners"
  />
</template>
<script>
export default{
    name: 'FxTextArea',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => self.$emit(item, event)
            })
            return { ...self.$listeners }
        },
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
    },
}
</script>
<style lang="scss" scoped>
</style>