<template>
  <div style="width: 100%">
    <div v-if="label">
      {{ label }}
    </div>
    <div style="font-weight: bold">
      {{ getDateFormatted(item.dadate) }}
    </div>
    <div style="font-weight: bold">
      {{ (($wikaz.getInstance().outils.dates.getTodayBdd() === item.dadate) ? ' ( Aujourd\'hui )' : '') }}
    </div>

    <div
      v-if="item.poste_id > 0"
      style="margin-top: 5px;margin-bottom: 5px"
    >
      {{ item.poste_libelle }}
    </div>
    <div
      v-else-if="item.is_conge > 0"
      style="margin-top: 5px;margin-bottom: 5px"
    >
      Congé
    </div>
    <div
      v-else-if="item.is_tlc > 0"
      style="margin-top: 5px;margin-bottom: 5px"
    >
      Temps Libre Choisi
    </div>
    <div
      v-else-if="item.poste_id === 0"
      style="margin-top: 5px;margin-bottom: 5px"
    >
      Repos Hebdomadaire
    </div>

    <v-divider v-if="item.poste_id > 0" />
    <div
      v-if="item.pointage_heure_debut"
      style="display: flex"
    >
      <div style="width: 120px">
        Début journée:
      </div>
      <div :style="{color: (item.pointage_heure_debut != item.poste_heure_debut) ? 'red' : 'black'}">
        {{ item.pointage_heure_debut }}
      </div>
    </div>
    <div
      v-if="item.pointage_heure_debut_coupure"
      style="display: flex"
    >
      <div style="width: 120px">
        Début coupure:
      </div>
      <div
        :style="{color: (item.pointage_heure_debut_coupure != item.poste_coupure_debut) ? 'red' : 'black'}"
        style="margin-left: 20px"
      >
        {{ item.pointage_heure_debut_coupure }}
      </div>
    </div>
    <div
      v-if="item.pointage_heure_fin_coupure"
      style="display: flex"
    >
      <div style="width: 120px">
        Fin coupure:
      </div>
      <div
        :style="{color: (item.pointage_heure_fin_coupure != item.poste_coupure_fin) ? 'red' : 'black'}"
        style="margin-left: 20px"
      >
        {{ item.pointage_heure_fin_coupure }}
      </div>
    </div>
    <div
      v-if="item.pointage_heure_fin"
      style="display: flex"
    >
      <div style="width: 120px">
        Fin journée:
      </div>
      <div :style="{color: (item.pointage_heure_fin != item.poste_heure_fin) ? 'red' : 'black'}">
        {{ item.pointage_heure_fin }}
      </div>
    </div>
    <v-divider v-if="item.is_maladie > 0" />
    <div
      v-if="item.is_maladie > 0"
      style="display: flex;align-items: center;justify-content:center;margin-top: 10px;margin-bottom: 10px"
    >
      <v-icon color="red">
        sick
      </v-icon>
      <div style="margin-left: 10px;">
        Maladie
      </div>
    </div>
    <v-divider v-if="item.is_astreinte > 0" />
    <div
      v-if="item.is_astreinte > 0"
      style="display: flex;align-items: center;justify-content:center;margin-top: 10px;margin-bottom: 10px"
    >
      <v-icon color="red">
        warning
      </v-icon>
      <div style="margin-left: 10px;">
        Astreinte
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxItemJournee',
    props: {
        item: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        showInterventions: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getDateFormatted (valeur) {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, 'dddd D MMMM YYYY')
        },
    },
}
</script>

<style scoped>

</style>