<template>
  <div
    class="vx-maintenance-gdt-container"
    :style="{width: isMobile ? '100%' : '450px'}"
  >
    <div style="font-weight: bold;font-size: large">
      Sélectionner un utilisateur
    </div>
    <div style="display: flex;align-items: center">
      <v-text-field
        v-model="search"
        dense
        append-icon="search"
        label="Filtre"
        hide-details
        outlined
      />
      <v-btn
        style="margin-left: 10px"
        color="primary"
        @click="getData"
      >
        Actualiser
      </v-btn>
    </div>
    <v-list
      v-if="data"
      style="height: 100%;overflow: auto"
    >
      <div
        v-for="(itemCompte, indexCompte) in filteredList"
        :key="indexCompte"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              style="font-weight: bold"
              v-text="itemCompte.compte_raison_sociale"
            />
            <v-list-item-subtitle
              style="font-weight: bold"
              v-text="'compte id : ' + itemCompte.compte_id"
            />
            <v-list-item-subtitle
              style="font-weight: bold"
              v-text="'utilisateurs : ' + itemCompte.nb_users"
            />
          </v-list-item-content>
          <v-list-item-action>
            <fx-icon
              color="error"
              icon="delete"
              tooltip="supprimer le compte"
              @click="suppression(itemCompte.compte_id)"
            />
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-for="(item, index) in itemCompte.utilisateurs"
          :key="index"
        >
          <v-list-item-avatar>
            <v-icon
              v-if="itemCompte.compte_proprietaire === item.id"
              color="green"
            >
              account_circle
            </v-icon>
            <v-icon v-else>
              person
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.label" />
            <v-list-item-subtitle v-text="item.email" />
            <v-list-item-subtitle v-text="'Utilisateur id : ' + item.id" />
          </v-list-item-content>
          <v-list-item-action>
            <fx-icon
              :size="28"
              color="primary"
              icon="login"
              tooltip="usurpation"
              @click="usurpation(item)"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider />
      </div>
    </v-list>
    <cx-waiting
      v-else
      texte="Chargement des techniciens"
    />
  </div>
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'

export default {
    name: 'VxMaintenance',
    extends: VxBase,
    data () {
        return {
            data: null,
            search: '',
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    computed: {
        filteredList () {
            return this.$wikaz.outils.filters.filterBy(this.search.toLowerCase(), this.data)
        },
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get('api/wikaz/structure/maintenance').then((data) => {
                this.data = data
            })
        },
        usurpation (item) {
            this.$wikaz.request.get('api/wikaz/structure/maintenance/' + item.id).then((data) => {
                this.$store.commit('UPDATE_TOKEN', data.token)
                if (this.isMobile) {
                    this.$store.commit('SET_VIEW_PWA', null)
                    this.$root.$emit('refreshMenu')
                } else {
                    this.remove_tab()
                }
            })
        },
        suppression (compte_id) {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer ce compte', () => {
                this.$wikaz.outils.notification.showConfirmation('Suppression', 'Je sais c\'est lourd, mais êtes vous vraiment certain de vouloir supprimer ce compte.', () => {
                    this.$wikaz.request.delete('api/wikaz/structure/maintenance/' + compte_id).then(() => {
                        this.getData()
                    })
                })
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-maintenance-gdt-container {
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>