<template>
  <div style="display: flex;flex-direction: column; font-size: 13px">
    <fx-titre titre="Fiche prospect" />
    <div style="padding-left: 10px;padding-right: 10px;padding-top: 7px;display:flex;justify-content:space-between;font-weight: bold;font-size: 16px">
      <div>{{ datas.contact_nom }}</div>
      <div>{{ datas.etat_libelle }}</div>
    </div>
    <div
      v-if="datas.secteur_libelle"
      style="padding-left: 10px;padding-top: 7px;"
    >
      <b>Secteur :</b> {{ datas.secteur_libelle }}
    </div>
    <div
      v-if="datas.field16"
      style="padding-left: 10px;padding-top: 7px;"
    >
      <b>Siret :</b> {{ datas.field16 }}
    </div>
    <div
      v-if="datas.field1 || datas.field12 || datas.field13 || datas.field14"
      style="display:flex;padding-left: 10px;padding-top: 7px;"
    >
      <div style="font-weight: bold">
        Adresse :
      </div>
      <div style="margin-left: 3px">
        <div>{{ datas.field1 }}</div>
        <div>{{ datas.field12 }}</div>
        <div>{{ datas.field13 }} {{ datas.field14 }}</div>
        <div>{{ datas.field15 }}</div>
      </div>
    </div>
    <div
      v-if="datas.field4"
      style="display:flex;align-items: center;padding-left: 10px;padding-top: 7px"
    >
      <div><b>Téléphone :</b></div>
      <div style="margin-left: 3px;margin-right: 3px">
        {{ datas.field4 }}
      </div>
      <fx-icon
        icon="phone"
        @click="$wikaz.api.navigateur.tel(datas.field4)"
      />
    </div>
    <div
      v-if="datas.field17"
      style="display:flex;align-items: center;padding-left: 10px;padding-top: 7px"
    >
      <div><b>Email :</b></div>
      <div style="margin-left: 3px;margin-right: 3px">
        {{ datas.field17 }}
      </div>
      <fx-icon
        icon="mail"
        @click="$wikaz.api.navigateur.mailto(datas.field17)"
      />
    </div>
    <template v-if="datas.field7">
      <template v-for="(item,index) in datas.field7.toString().split(';')">
        <div
          :key="index"
          style="display:flex;padding-left: 10px;padding-top: 7px"
        >
          <div><b>Site internet :</b></div>
          <div>
            <a
              style="margin-left: 3px;"
              :href="item"
              target="_blank"
            >{{ item }}</a>
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="datas.field6"
      style="display: flex;padding-left: 10px;padding-top: 7px"
    >
      <div><b>Observation :</b></div>
      <div style="white-space: pre-line;margin-left: 3px">
        {{ datas.field6 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxFicheProspect',
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>