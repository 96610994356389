<template>
  <v-card
    :style="{height: this.height,width: this.width}"
    style="padding: 10px"
  >
    <cx-explorateur-fichier
      v-if="urlRessourceEndPoint"
      :extension-array="extensionArray"
      show-refresher
      show-import
      :titre="titre"
      :width="width"
      :height="height"
      :url-ressource-end-point="urlRessourceEndPoint"
    />
  </v-card>
</template>

<script>

export default {
    name: 'CxExplorateurFichiersSite',
    data () {
        return {
            width: '600px',
            height: '500px',
            urlRessourceEndPoint: null,
            titre: '',
            extensionArray: [],
        }
    },
}
</script>

<style scoped>

</style>