<template>
  <div style="display:flex;align-items:center;justify-content:center;height: auto;width: 100%;">
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="subject"
      color="#878787"
      tooltip="Afficher les observations du client"
      @click="f_afficher_observation()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="create"
      color="#E8B229"
      tooltip="Modifier la fiche client"
      @click="f_editer_client()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="print"
      color="#878787"
      tooltip="Imprimer la fiche client"
      @click="f_imprimer_fiche()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="delete"
      color="error"
      tooltip="Supprimer la fiche client"
      @click="f_delete_fiche()"
    />
  </div>
</template>
<script>
import CxObservations from '../observations/CxObservations'

export default {
    name: 'CxFicheClientOutils',
    props: {
        clientId: {
            required: true,
            type: Number,
        },
        urlRessourceEndPoint: {
            required: true,
            type: String,
        },
    },
    data () {
        return { datas: null }
    },
    methods: {
        f_editer_client () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.clientId + '/edit',
                this.f_result_formx)
        },
        f_result_formx (data) {
            this.$wikaz.request.put(data.urlUpdate, data).then(() => {
                this.$emit('actualiser')
            })
        },
        f_imprimer_fiche () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + this.clientId + '/impression')
        },
        f_afficher_observation () {
            this.$wikaz.popups.show(
                CxObservations,
                {
                    datas: {
                        urlEndPoint: this.urlRessourceEndPoint + this.clientId + '/observations',
                        titre: 'Observations client',
                        width: '500px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_result_popup)
        },
        f_result_popup () {

        },
        f_result_formPost (data) {
            this.$wikaz.request.post(data.urlUpdate, data).then(() => {
            })
        },
        f_delete_fiche () {
            this.$wikaz.outils.notification.showConfirmation('Suppression client', 'Etes-vous certain de vouloir supprimer la fiche ?', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + this.clientId).then(() => {
                    this.$emit('actualiser')
                })
            })
        },
    },
}
</script>