<template>
  <v-list-item
    dense
    style="overflow: visible;border-bottom: 1px solid grey"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon color="primary">
        today
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="item.intervention_description" />
      <v-list-item-subtitle
        v-if="item.intervention_heure_debut"
        v-html="'Heure de début : ' + item.intervention_heure_debut"
      />
      <v-list-item-subtitle
        v-if="item.intervention_heure_fin"
        v-html="'Heure de fin : ' + item.intervention_heure_fin"
      />
    </v-list-item-content>
    <fx-icon
      style="margin-left: 10px"
      icon="create"
      color="primary"
      @click="f_edit"
    />
    <fx-icon
      style="margin-left: 10px"
      icon="delete"
      color="error"
      @click="f_delete"
    />
  </v-list-item>
</template>

<script>
import FxItemTemplate from '../../../../../wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemInterventions',
    extends: FxItemTemplate,
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { isMobile: this.$store.getters.isMobile() }
    },
    methods: {
        f_delete () {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer cette intervention ?', () => {
                let baseUrl = 'api/edt/modules/planning-edt/planning/' + this.item.intervention_astreinte_id + '/interventions'
                if(this.isMobile) {
                    baseUrl = 'api/edt/modules/gestion-astreintes-pwa-gdt/astreintes/' + this.item.intervention_astreinte_id + '/interventions'
                }
                this.$wikaz.request.delete(baseUrl + '/' + this.item.intervention_id).then(() => {
                    this.f_emit_actualiser()
                })
            })
        },
        f_edit () {
            let baseUrl = 'api/edt/modules/planning-edt/planning/' + this.item.intervention_astreinte_id + '/interventions'
            if(this.isMobile) {
                baseUrl = 'api/edt/modules/gestion-astreintes-pwa-gdt/astreintes/' + this.item.intervention_astreinte_id + '/interventions'
            }
            this.$wikaz.getInstance().popups.showFormulaire(
                baseUrl + '/' + this.item.intervention_id + '/edit',
                this.f_result_edit,
            )
        },
        f_result_edit (data) {
            let baseUrl = 'api/edt/modules/planning-edt/planning/' + this.item.intervention_astreinte_id + '/interventions'
            if(this.isMobile) {
                baseUrl = 'api/edt/modules/gestion-astreintes-pwa-gdt/astreintes/' + this.item.intervention_astreinte_id + '/interventions'
            }
            this.$wikaz.request.put(baseUrl + '/' + this.item.intervention_id, data).then(() => {
                this.f_emit_actualiser()
            })
        },
    },
}
</script>

<style scoped>

</style>