<template>
  <div
    v-if="datas"
    style="display: flex;flex-direction: column;gap: 5px"
  >
    <div style="display: flex;justify-content: space-between">
      <fx-titre titre="Fiche Logement" />
      <fx-icon
        color="primary"
        icon="create"
        tooltip="Modifier la fiche"
      />
    </div>
    <fx-champ
      label="Id"
      :text="datas.logement_id"
    />
    <fx-champ
      label="Numéro"
      :text="datas.logement_numero"
    />
    <fx-champ
      label="Libellé"
      :text="datas.logement_libelle"
    />
    <fx-champ
      label="Etage"
      :text="datas.logement_etage"
    />
    <fx-champ
      label="Type"
      :text="datas.logements_type_libelle"
    />
  </div>
  <div
    v-else
    style="display: flex;flex-direction: column;gap: 5px"
  >
    <div style="display: flex;justify-content: space-between">
      <fx-titre titre="Fiche Logement" />
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxFicheLogement',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint, data).then(()=> {
                    this.getData()
                })
            })
        },
    },
}
</script>

<style scoped>

</style>