<template>
  <v-tooltip
    v-if="error"
    bottom
    color="red"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon color="red">
          error
        </v-icon>
      </v-btn>
    </template>
    <span>{{ error }}</span>
  </v-tooltip>
</template>
<script>
export default {
    name: 'FxTooltipIconError',
    props: {
        error: {
            type: String,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>