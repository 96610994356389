import Vue from 'vue'

import FxCard from './FxCard'
import FxSection from './FxSection'
import FxSpace from './FxSpace'
import FxDivider from './FxDivider'
import FxFormulaire from './FxFormulaire'
import FxBandeauImage from './FxBandeauImage'
import FxBandeauCarousel from './FxBandeauCarousel'
import FxAnomalieComposant from './FxAnomalieComposant'
import FxBandeauLogo from './FxBandeauLogo'
import FxRecherche from './FxRecherche'
import FxSectionCercle from './FxSectionCercle'
import FxFiles from './FxFiles'
import FxPublicationsFB from './FxPublicationsFB'

const Imports = []

Imports.push(
    {
        name: 'fx-recherche',
        component: FxRecherche,
    },
    {
        name: 'fx-publications-fb',
        component: FxPublicationsFB,
    },
    {
        name: 'fx-files',
        component: FxFiles,
    },
    {
        name: 'fx-section-cercle',
        component: FxSectionCercle,
    },
    {
        name: 'fx-bandeau-logo',
        component: FxBandeauLogo,
    },
    {
        name: 'fx-bandeau-image',
        component: FxBandeauImage,
    },
    {
        name: 'fx-bandeau-carousel',
        component: FxBandeauCarousel,
    },
    {
        name: 'fx-card',
        component: FxCard,
    },
    {
        name: 'fx-section',
        component: FxSection,
    },
    {
        name: 'fx-space',
        component: FxSpace,
    },
    {
        name: 'fx-divider',
        component: FxDivider,
    },
    {
        name: 'fx-formulaire',
        component: FxFormulaire,
    },
    {
        name: 'fx-anomalie-composant',
        component: FxAnomalieComposant,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports