<template>
  <div
    v-if="datas"
    class="cx-traitement-gestion-client-pwa-container"
  >
    <cx-fiche-client :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id" />
    <cx-fiche-client-outils
      :client-id="datas.client_id"
      url-ressource-end-point="api/entreprises/modules/gestion-clients-entreprises/clients/"
      @actualiser="f_actualiser"
    />
    <cx-liste-adresses
      :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/adresses'"
      style="width: 100%;height: 100%;overflow: auto"
      @on-submit="f_actualiser_historique"
    />
  </div>
</template>
<script>

import CxFicheClient from '@/wikaz-konnect/gdi/gestion-clients/fiche-client/CxFicheClient'
import CxFicheClientOutils from '@/wikaz-konnect/gdi/gestion-clients/fiche-client-outils/CxFicheClientOutils'
import CxListeAdresses from '@/wikaz-konnect/gdi/gestion-clients/liste-adresses/CxListeAdresses'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementGestionClientsPwa',
    components: {
        CxListeAdresses,
        CxFicheClientOutils,
        CxFicheClient,
    },
    extends: CxBase,
    data () {
        return {
            index: 0,
            selectedCibleType: null,
            selectedCibleId: null,
        }
    },
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Client ' + this.datas.client_id
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
        this.chargerData()
    },
    methods: {
        chargerData () {
            if (this.datas.inter !== '') {
                this.f_charger_intervention(this.datas.inter)
            }
        },
        f_selection_adresse  (event) {
            this.$refs.objListeIntervention.setFiltre(event)
        },
        f_select_intervention (event) {
            this.selectedCibleType = event.type
            if (event.type === 1) {
                this.selectedCibleId = event.intervention_id
                this.f_charger_intervention(event.intervention_id)
            } else {
                this.selectedCibleId = event.demande_id
                this.f_charger_demande(event.demande_id)
            }

        },
        f_actualiser_historique () {
            this.$refs.objListeIntervention.getListe()
        },
        f_charger_intervention (intervention) {
            if (intervention && this.$refs.objFicheInter) {
                this.$refs.objFicheInter.setUrl('api/entreprises/modules/gestion-clients-entreprises/clients/' + this.datas.client + '/interventions/' + intervention)
            }
        },
        f_charger_demande (demande) {
            if (demande && this.$refs.objFicheDemande) {
                this.$refs.objFicheDemande.setUrl('api/entreprises/modules/gestion-clients-entreprises/clients/' + this.datas.client + '/demandes/' + demande)
            }
        },
        f_actualiser () {
            this.$emit('on-refresh')
        },
        f_retour () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-gestion-clients-entreprises',
                module_libelle: 'Clients',
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-traitement-gestion-client-pwa-container {
        // border: 1px solid red;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: 200px 40px 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-gestion-client-pwa-col1 {
        //border: 1px solid green;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto 2fr;
        overflow: auto;
    }

    .cx-traitement-gestion-client-pwa-col2 {
        //border: 1px solid green;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-gestion-client-pwa-col3 {
        // border: 1px solid green;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>