<template>
  <v-card style="padding: 10px">
    <fx-titre titre="Liste des compétences" />
    <v-list
      subheader
      flat
      style="background-color: transparent"
    >
      <template v-for="(item,index) in datas">
        <v-list-item :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ item.metier_libelle }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              v-model="item.affected"
              @change="f_update(item)"
            />
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
    name: 'CxAffectationCompetances',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.chargerData()
    },
    methods: {
        chargerData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_update (item) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.metier_id, item)
        },
    },
}
</script>

<style scoped>

</style>