<template>
  <div
    v-resize="getData"
    class="vx-statistiques-edt-container-principal"
  >
    <div
      ref="tableau"
      class="vx-statistiques-edt-container-header"
    >
      <div style="text-align: center;font-weight: bold;font-size: 40px">
        <div style="text-align: center;font-weight: bold;font-size: 40px">
          CONGES ANNUELS
        </div>
        <div style="text-align: center;font-weight: bold;font-size: 18px">
          {{ $wikaz.getInstance().outils.dates.getDate() }}
        </div>
      </div>
      <fx-selection-annee
        v-model="annee"
        @input="getData"
      />
      <fx-icon
        :size="36"
        icon="refresh"
        color="green"
        @click="getData"
      />
      <fx-icon
        :size="36"
        icon="print"
        color="primary"
        @click="openPdf"
      />
    </div>
    <div v-if="datas">
      <div class="cx-ligne-jours-label-container">
        <div
          :style="{width: largeurGauche + 'px'}"
          class="cx-ligne-jours-label-cartouche"
        />
        <template v-for="(item,index) in datas.mois">
          <div
            :key="index"
            class="cx-ligne-jours-label-case"
            :style="{width: ((largeurElement*item.nb_day)-2)+'px'}"
          >
            <div v-html="$wikaz.outils.dates.getMoisLettre(item.mois)" />
          </div>
        </template>
      </div>
      <div
        v-for="(item,index) in datas.agents"
        :key="index"
        style="display: flex;border-bottom: 1px solid black"
      >
        <div
          class="cx-ligne-agents-cartouche"
          :style="{width: largeurGauche + 'px'}"
        >
          <v-avatar
            style="margin-left: 5px;margin-right: 5px"
            size="48"
          >
            <v-tooltip
              right
              class="v-tooltip-css"
              :open-delay="300"
            >
              <template v-slot:activator="{ on }">
                <v-img
                  :src="'data:image/jpeg;base64,' + $wikaz.getInstance().getPhotoUtilisateur(item.wcu_utilisateur_id)"
                  v-on="on"
                />
              </template>
              <cx-mini-fiche-technicien :item="item" />
            </v-tooltip>
          </v-avatar>
          <div style="width: 100%">
            {{ item.label }}
          </div>
        </div>
        <div style="width: calc(100% - 180px);position: relative;border-right: 1px solid black">
          <div
            v-for="(elem,index2) in item.stats"
            :key="index2"
            style="height: 100%;background-color: brown;position: absolute;box-sizing: border-box;"
            :style="{minWidth: largeurElement + 'px', width: largeurElement + 'px',left: (largeurElement * (elem.doy-1)) + 'px'}"
          />
        </div>
      </div>

      <!--            <div class="cx-statistique-container-agents" style="overflow: auto;box-sizing: border-box">
                <cx-ligne-agents v-for="(item,index) in datas.agents" :key="index" :item="item" :datas="datas" :largeur-gauche="largeurGauche" ></cx-ligne-agents>
            </div>-->
      <cx-ligne-footer />
    </div>
    <cx-waiting v-else />
  </div>
</template>

<script>
import CxLigneFooter from '@/wikaz-core/ui/composants/statistiques/statistiques-elements/footer/CxLigneFooter'
import CxMiniFicheTechnicien from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxMiniFicheTechnicien'
export default {
    name: 'VxCongesGdt',
    components: {
        CxMiniFicheTechnicien,
        CxLigneFooter,
    },
    data () {
        return {
            datas: null,
            largeurGauche: 180,
            largeurElement: 4,
            annee: (new Date()).getFullYear(),
        }
    },
    mounted () {
        // this.getData()
    },
    methods: {
        getData () {
            this.setWidths()
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/conges-gdt/conges?annee=' + this.annee).then((data) => {
                this.datas = data
                this.setWidths()
            })
        },
        setWidths () {
            let largeurElement = 0
            if(this.$refs.tableau) {
                largeurElement = (this.$refs.tableau.clientWidth - this.largeurGauche) / 365
            }
            this.largeurElement = largeurElement
        },
        openPdf () {
            this.$wikaz.request.get('api/edt/modules/conges-gdt/conges/' + this.annee + '/afficher')
        },
    },
}
</script>
<style scoped>
    .vx-statistiques-edt-container-principal {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 120px 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .vx-statistiques-edt-container-header {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr auto auto auto ;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        align-items: center;
        overflow: auto;
    }

    .cx-ligne-agents-cartouche {
        display: flex;
        align-items: center;
        background-color: #0d6aad;
        color: white;
        border-right: 1px solid black;
    }

    .cx-ligne-jours-label-container {
        width: 100%;
        height: 55px;
        display: flex;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-jours-label-cartouche {
        background-color: #0d6aad;
        border-top-left-radius: 10px;
        border-bottom: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .cx-ligne-jours-label-case {
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        text-align: center;
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
</style>