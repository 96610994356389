<template>
  <div class="cx-ligne-jours-label-container">
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-jours-label-cartouche"
      v-html="label"
    />
    <template v-for="(item,index) in datas">
      <div
        :key="index"
        class="cx-ligne-jours-label-case"
        :style="{width: 'calc((100% - '+(largeurGauche)+'px)/'+datas.length+')'}"
      >
        <div v-html="item[labelField]" />
        <fx-icon
          v-if="item[tooltipField]"
          style="margin-left: 5px"
          icon="info"
          :tooltip="item[tooltipField]"
        />
      </div>
    </template>
  </div>
</template>
<script>
export default {
    name: 'CxLigneHeader',
    props: {
        datas: {
            type: Array,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
        labelField: {
            type: String,
            default: 'label',
        },
        label: {
            type: String,
            default: '',
        },
        tooltipField: {
            type: String,
            default: 'description',
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-jours-label-container {
        width: 100%;
        height: 55px;
        display: flex;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-jours-label-cartouche {
        background-color: #0d6aad;
        border-top-left-radius: 10px;
        border-bottom: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .cx-ligne-jours-label-case {
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        text-align: center;
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
</style>