import Vue from 'vue'
import dates from '@/wikaz-core/outils/dates'
import json from '@/wikaz-core/outils/json'
import clipboard from '@/wikaz-core/outils/clipboard'
import notification from '@/wikaz-core/outils/notification'
import random from '@/wikaz-core/outils/random'
import divers from '@/wikaz-core/outils/divers'
import Google from '@/wikaz-core/api/Google'
import Request from '@/wikaz-core/request/Request'
import urls from '@/wikaz-core/outils/urls'
import Auth from '@/wikaz-core/authentification/auth'
import importation from '@/wikaz-core/outils/importation'
import filters from '@/wikaz-core/outils/filters'
import popups from '@/wikaz-core/popups/popups'
import VxProfil from '@/wikaz-core/ui/modules/profil/VxProfil'
import Navigateur from '@/wikaz-core/api/Navigateur'
import VueApexCharts from 'vue-apexcharts'
import timer from '@/wikaz-core/outils/timer'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('vx-profil', VxProfil)
require('@/wikaz-core/ui/composants')
require('@/wikaz-core/css')

class WikazApp {
    constructor () {
        if(! WikazApp.instance) {
            WikazApp.instance = this
            WikazApp.instance.app.sessionId = WikazApp.instance.outils.random.string(32)
            WikazApp.instance.app.version = require('../../package.json').version
            window.addEventListener('load', this.forbidenHistory)
            window.addEventListener('popstate', this.forbidenHistory)
            window.addEventListener('resize', this.f_resize)
            this.app.titre = process.env.VUE_APP_TITRE
            document.title = process.env.VUE_APP_TITRE
            this.app.version = require('../../package.json').version
            this.app.client_secret = process.env.VUE_APP_CLIENT_SECRET
            this.app.client_id = process.env.VUE_APP_CLIENT_ID
            Vue.prototype.$auth = this.auth
        }
        return WikazApp.instance
    }
    getInstance () {
        return WikazApp.instance
    }
    initialisation (vueInstance) {
        window.Vue = vueInstance
        this.f_resize()
        this.loadPhotosUsers()
        if (window.Vue.$store.getters.getAuthState()) {
            this.api.google_api = new Google()
        }
    }
    app = {
        sessionId: null,
        titre: null,
        version: null,
        client_secret: null,
        client_id: null,
    }
    api = {
        google_api: null,
        navigateur: new Navigateur(),
    }
    theme = {
        favicon: null,
        logo: null,
        colors: { backgroundlogin: '#A6ED8E' },
    }
    auth = new Auth()
    request = new Request()
    outils = {
        dates: new dates(),
        json: new json(),
        clipboard: new clipboard(),
        notification: new notification(),
        random: new random(),
        divers: new divers(),
        urls: new urls(),
        importation: new importation(),
        filters: new filters(),
        timer: new timer(),
    }
    popups = new popups()
    photosUtilisateur = null
    forbidenHistory () {
        window.history.forward()
        window.history.pushState({}, '')
    }
    f_resize () {
        if (window.matchMedia('(max-width: 767px) and (orientation:portrait)').matches) {
            window.Vue.$store.commit('SET_IS_MOBILE', true)
        } else if (window.matchMedia('(max-height: 567px) and (orientation:landscape)').matches) {
            window.Vue.$store.commit('SET_IS_MOBILE', true)
        } else {
            window.Vue.$store.commit('SET_IS_MOBILE', false)
        }
    }
    loadPhotosUsers () {
        if (window.Vue.$store.getters.getAuthState()) {
            this.request.get('api/wikaz/structure/photos').then((data) => {
                this.photosUtilisateur = data
            })
        }
    }
    getPhotoUtilisateur (utilisateurId) {
        return this.photosUtilisateur[utilisateurId]
    }
}
const instance = new WikazApp()
// Object.freeze(instance);
export default instance