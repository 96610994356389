<template>
  <div class="fx-calendar-container">
    <div class="fx-calendar-titre">
      <v-icon
        x-large
        color="blue"
        @click="removeYear()"
      >
        keyboard_arrow_left
      </v-icon>
      <h4>{{ currentYear }}</h4>
      <v-icon
        x-large
        color="blue"
        @click="addYear()"
      >
        keyboard_arrow_right
      </v-icon>
    </div>
    <div :class="isMobile ? 'fx-calendar-content-pwa' : 'fx-calendar-content-desktop'">
      <template v-for="n in 12">
        <component
          :is="itemRenderer"
          :key="n"
          :data-provider="{mois: n,annee: currentYear,etat: etatAnneeMois(n),mois_libelle: currentMonth(n)}"
        />
      </template>
    </div>
  </div>
</template>
<script>
export default{
    name: 'FxCalendrier',
    props: {
        itemRenderer: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            myArray: [],
            ready: false,
            year: this.$wikaz.outils.dates.getCurrentDate('YYYY'),
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    computed: {
        currentYear () {
            return this.$wikaz.outils.dates.dayjs().year(this.year).format('YYYY')
        },
    },
    methods: {
        removeYear () {
            this.year = Number(this.year) - 1
        },
        addYear () {
            this.year = Number(this.year) + 1
        },
        currentMonth (n) {
            return this.$wikaz.outils.dates.dayjs().month(n - 1).format('MMMM')
        },
        etatAnneeMois (mois) {
            let anneemoisencours = this.$wikaz.outils.dates.getCurrentDate('YYYY-MM')
            let anneemoistest = this.currentYear + '-' + mois.toString().padStart(2, '0')

            if (anneemoistest > anneemoisencours) {
                return 1
            }
            if (anneemoistest < anneemoisencours) {
                return - 1
            }
            return 0

        },
        getJPM (mois, type) {
            this.$emit('btnJPM', {
                annee: this.year,
                mois: mois.toString(),
                type: type,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
    .fx-calendar-container{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 100%;
        grid-column-gap: 5px;
        padding-left: 5px;
        h4{
            text-align: center;
            font-weight: bold;
            font-size: 2.8rem;
            color: #2d8cf0;
            text-shadow: 1px 1px 1px rgba(13, 106, 173, 0.36);
        }
    }

    .fx-calendar-titre {
        display: flex;
        width: 99%;
        box-sizing: border-box;
        justify-content: space-between;
    }

    .fx-calendar-content-desktop{
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 5px;
    }

    .fx-calendar-content-pwa{
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-column-gap: 5px;
    }

</style>