<template>
  <v-card
    class="cx-rechercher-compte-wikaz"
    width="100%"
    height="600px"
    style="padding: 10px"
  >
    <fx-list
      :title="sens === 1 ? 'Rechercher un donneur d\'ordre' : 'Rechercher un prestataire'"
      :item-renderer="itemRenderer"
      :data-provider.sync="datas"
      show-search
      show-result
      show-refresher
      :url="urlRessourceEndPoint + '/create'"
      @itemEmitting="selectedItem=$event"
      @inviter="f_inviter"
    />
    <div style="display: flex;justify-content: flex-end;gap: 10px">
      <v-btn
        v-if="sens === 1"
        color="success"
        @click="showDialog=true"
      >
        La société n'est pas dans la liste
      </v-btn>
      <v-btn
        v-if="sens === 2"
        color="success"
        @click="showDialog=true"
      >
        Envoyer une invitation d'inscription
      </v-btn>
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
    <v-dialog
      v-if="sens === 1"
      v-model="showDialog"
      width="500px"
    >
      <v-card style="padding: 10px">
        <v-card-title>Ajouter une référence</v-card-title>
        <v-card-text>
          Siren
          <fx-siren-input
            ref="obj_siren"
            v-model="siren"
          />
        </v-card-text>
        <v-card-text style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="success"
            @click="f_ajouter_reference"
          >
            Ajouter
          </v-btn>
          <v-btn
            color="error"
            @click="showDialog=false"
          >
            Annuler
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="sens === 2"
      v-model="showDialog"
      width="500px"
    >
      <v-card style="padding: 10px">
        <v-card-title>Envoyer une invitation</v-card-title>
        <v-card-text>
          Email
          <fx-text-input v-model="adresse_email" />
        </v-card-text>
        <v-card-text style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="success"
            @click="f_send_mail_invitation"
          >
            Envoyer
          </v-btn>
          <v-btn
            color="error"
            @click="showDialog=false"
          >
            Annuler
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import IxItemCompte from '@/wikaz-konnect/gdi/gestion-recepteurs/IxItemCompte'

export default {
    name: 'CxRechercheCompteWikaz',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        sens: {
            type: Number,
            default: 1,
        },
    },
    data () {
        return {
            datas: null,
            itemRenderer: IxItemCompte,
            selectedItem: null,
            showDialog: false,
            adresse_email: '',
            ruleEmail: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
            siren: null,
        }
    },
    methods: {
        f_inviter (item) {
            if(item) {
                this.$wikaz.request.post(this.urlRessourceEndPoint, { cible: item.id }).then(() => {
                    this.$emit('submit')
                })
            } else {
                this.$wikaz.outils.notification.showError('Veuillez sélectionner un destinataire')
            }
        },
        f_send_mail_invitation () {
            if (this.ruleEmail.test(this.adresse_email)) {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/0/email', { email: this.adresse_email }).then(() => {
                    this.$emit('submit')
                })
            } else {
                this.$wikaz.outils.notification.showError('Veuillez saisir un email valide.')
            }
        },
        f_ajouter_reference () {
            if (this.$refs.obj_siren.validate()) {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/0/reference', { siren: this.siren }).then(() => {
                    this.$emit('submit')
                })
            } else {
                this.$wikaz.outils.notification.showError(this.$refs.obj_siren.getError())
            }
        },
    },
}
</script>

<style scoped>
    .cx-rechercher-compte-wikaz {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 5px;
        overflow: auto;
    }
</style>