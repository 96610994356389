<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <v-icon
        :size="30"
        :color="getColor(item)"
      >
        insert_drive_file
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold;white-space: pre-line;"
        v-html="item.document_titre"
      />
      <v-list-item-subtitle
        class="caption"
        style="font-weight: bold"
        v-html="item.documents_type_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        style="font-weight: bold"
        v-html="item.documents_type_code + ' - ' + item.document_chrono + ' - Version : ' + item.document_version"
      />
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxDocuments',
    extends: FxItemTemplate,
    methods: {
        getColor (item) {
            if (item.document_version === 0) {
                return 'warning'
            }
            if (item.expire > 0) {
                return 'error'
            }
            return 'primary'

        },
    },
}
</script>