<template>
  <div style="max-height: 400px;overflow: auto">
    <vue-editor
      :value="value"
      v-on="listeners"
    />
  </div>
</template>
<script>
import { VueEditor } from 'vue2-editor'
export default{
    name: 'FxEditeurHtml',
    components: { VueEditor },
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 'text',
            type: String,
        },
        value: { default: null },
        size: { default: 'small' },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        clearable: {
            default: false,
            type: Boolean,
        },
        labelLeft: { default: '' },
        labelRight: { default: '' },
        tooltip: { default: null },
        tooltipPosition: { default: 'bottom-start' },
        labelColor: { default: '#000' },
        rows: { default: 4 },
    },
    data () {
        return { content: '<h1>test</h1></br>blabla' }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => self.$emit(item, event)
            })
            return { ...self.$listeners }
        },
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return ''
        },
    },
}
</script>