<template>
  <div class="vx-gestion-factures-administration-container">
    <div
      style="display: flex;justify-content: space-between;align-items: center;gap: 5px"
      :style="{flexDirection: (isMobile ? 'column' : 'row')}"
    >
      <div v-if="!isMobile">
        Gestion des factures
      </div>
      <fx-selection-mois
        v-model="mois"
        @input="f_refresh"
      />
      <fx-selection-annee
        v-model="annee"
        @input="f_refresh"
      />
      <div style="display: flex;align-items: center;justify-content: space-between">
        <div>Afficher les factures annulées</div>
        <fx-switch
          v-model="showAnnule"
          style="margin-left: 10px"
          :true-value="1"
          :false-value="0"
          @input="f_refresh"
        />
      </div>
    </div>
    <fx-datagrid-vuetify
      :key="lstKey"
      ref="obj_liste"
      :url-ressource-end-point="urlRessourceEndPoint + filtre"
      :dense="isMobile"
      @afficher="afficher"
      @afficher_bdc="afficher_bdc"
      @editer="editer"
      @emettre="emettre"
      @acquitter="acquitter"
      @anomalie="anomalie"
      @dupliquer="dupliquer"
      @ajouter="ajouter"
      @finaliser="finaliser"
      @supprimer="supprimer"
    />
  </div>
</template>
<script>
import CxEditionFacture from '@/wikaz-konnect/adm/gestion-factures/CxEditionFacture'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'

export default {
    name: 'VxGestionFacturesAdministration',
    extends: VxBase,
    data () {
        return {
            lstKey: 0,
            mois: parseInt((new Date()).getMonth()) + 1,
            annee: (new Date()).getFullYear(),
            urlRessourceEndPoint: 'api/wikaz/modules/gestion-factures-administration/factures',
            filtre: '?annee=' + (new Date()).getFullYear() + '&mois=' + (parseInt((new Date()).getMonth()) + 1) + '&annule=0',
            showAnnule: 0,
        }
    },
    methods: {
        f_refresh () {
            this.filtre = '?annee=' + this.annee + '&mois=' + this.mois + '&annule=' + this.showAnnule
            this.lstKey ++
        },
        afficher (item) {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/' + item.facture_id + '/afficher')
        },
        afficher_bdc (item) {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/' + item.facture_id + '/bon_commande')
        },
        ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                    this.f_refresh()
                })
            })
        },
        editer (item) {
            this.$wikaz.getInstance().popups.show(
                CxEditionFacture,
                { props: { urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.facture_id } },
                this.f_refresh,
            )
        },
        emettre (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/emettre').then(() => {
                this.f_refresh()
            })
        },
        acquitter (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/acquitter').then(() => {
                this.f_refresh()
            })
        },
        anomalie (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/anomalie').then(() => {
                this.f_refresh()
            })
        },
        finaliser (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/finaliser').then(() => {
                this.f_refresh()
            })
        },
        supprimer (item) {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer la facture', () => {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/supprimer').then(() => {
                    this.f_refresh()
                })
            })
        },
        dupliquer (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/' + item.facture_id + '/dupliquer/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.facture_id + '/dupliquer', data).then(() => {
                    this.f_refresh()
                })
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-gestion-factures-administration-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>