<template>
  <v-flex xs12>
    <v-container grid-list-xl>
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          v-if="data"
          class="md4"
        >
          <v-card>
            <v-card-text>
              <cx-photo-profil
                :url-ressource="urlEndPoint"
                field="avatar"
              />
              <v-text-field
                v-model="data.email"
                readonly
                label="Email"
              />
              <v-text-field
                v-model="data.nom"
                label="Nom"
              />
              <v-text-field
                v-model="data.prenom"
                label="Prénom"
              />
              <v-text-field
                v-model="data.user_adresse"
                label="Adresse"
              />
              <div style="display: flex">
                <v-text-field
                  v-model="data.user_cp"
                  label="Code Postal"
                />
                <v-text-field
                  v-model="data.user_ville"
                  label="Ville"
                />
              </div>

              <v-text-field
                v-model="data.user_telephone1"
                label="Téléphone 1"
              />
              <v-text-field
                v-model="data.user_telephone2"
                label="Téléphone 2"
              />
              <div style="display: flex;justify-content: flex-end">
                <v-btn
                  color="success"
                  small
                  @click="modifierNomPrenom"
                >
                  Valider
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex class="md4">
          <v-card>
            <v-card-text>
              <fx-titre titre="Modifier le mot de passe" />
              <v-text-field
                v-model="mdp.ancien_mot_de_passe"
                autocomplete="new-password"
                type="password"
                label="Ancien mot de passe"
              />
              <v-text-field
                v-model="mdp.nouveau_mot_de_passe"
                autocomplete="new-password"
                type="password"
                :rules="mdpRules"
                label="Nouveau mot de passe"
              />
              <v-text-field
                v-model="mdp.nouveau_mot_de_passe_2"
                type="password"
                autocomplete="new-password"
                :rules="mdpRules"
                label="Nouveau mot de passe 2"
              />
              <div style="display: flex;justify-content: flex-end">
                <v-btn
                  color="success"
                  small
                  @click="modifierPassword"
                >
                  Valider
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex class="md4">
          <v-card>
            <v-card-text>
              <fx-titre titre="Paramètres" />
              <v-switch
                v-model="$vuetify.theme.dark"
                label="Thème sombre"
                @change="f_change_theme"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>
<script>

export default {
    name: 'VxProfil',
    data () {
        return {
            data: null,
            urlEndPoint: 'api/wikaz/structure/profil',
            showAvatarPicker: true,
            loading: true,
            mdp: {
                ancien_mot_de_passe: '',
                nouveau_mot_de_passe: '',
                nouveau_mot_de_passe_2: '',
            },
            mdpRules: [
                v => !! v || 'Mot de passe requis',
                v => v.length >= 8 || 'Minimun 8 characters',
                v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(v) || 'Le mot de passe doit contenir au moins une majuscules, un chiffres , une minuscules et un caractère spécial',
            ],
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            if (this.$store.state.auth.isLoggedIn !== false) {
                this.$wikaz.request.get(this.urlEndPoint).then((data) => {
                    this.data = data
                })
            }
        },
        modifierPassword () {
            if (this.nouveau_mot_de_passe === this.nouveau_mot_de_passe_2) {

                this.$wikaz.request.put(this.urlEndPoint + '/0', this.mdp).then((data) => {
                    if (data.code === 'success') {
                        this.mdp.ancien_mot_de_passe = ''
                        this.mdp.nouveau_mot_de_passe = ''
                        this.mdp.nouveau_mot_de_passe_2 = ''
                    }
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Les deux mots de passes saisis ne correspondent pas.')
            }
        },
        f_change_theme (valeur) {
            this.$wikaz.request.put('api/wikaz/structure/theme', { etat: valeur })
        },
        modifierNomPrenom () {
            this.$wikaz.request.put('api/wikaz/structure/user_name', this.data).then(() => {
                this.$wikaz.outils.notification.showInfo('La mise à jour à bien été appliquée.')
            })

        },
    },
}
</script>