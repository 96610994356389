<template>
  <l-map
    v-if="showMap"
    ref="map"
    :zoom="zoomDefault"
    :center="center"
    :bounds="bounds"
    :options="mapOptions"
    class="mymap"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
  >
    <l-tile-layer
      :url="autoColor ? ( $vuetify.theme.dark ? urlSombre : urlClair ) : url"
      :attribution="attribution"
    />
    <slot />
    <template v-if="markers">
      <fx-map-marker
        v-for="(marker,index) in markers"
        :key="index"
        :latitude="marker.lat"
        :longitude="marker.lng"
        :popup="marker.popup"
        :tooltip="marker.tooltip"
        :icon="require('leaflet/dist/images/marker-icon.png')"
      />
    </template>
  </l-map>
</template>
<script>
import L from 'leaflet'
import { latLng, latLngBounds } from 'leaflet'
import { LMap, LTileLayer } from 'vue2-leaflet'
import FxMapMarker from './FxMapMarker'
import 'leaflet/dist/leaflet.css'

export default {
    name: 'FxMap',
    components: {
        FxMapMarker,
        LMap,
        LTileLayer,
    },
    props: {
        zoom: {
            type: Number,
            default: 6,
        },
        latitudeCenter: {
            type: Number,
            default: 46.789339,
        },
        longitudeCenter: {
            type: Number,
            default: 2.657497,
        },
        markers: {
            type: Array,
            default: null,
        },
        autoColor: {
            type: Boolean,
            default: false,
        },
        boundAuto: {
            type: Boolean,
            default: false,
        },
        centerAuto: {
            type: Boolean,
            default: false,
        },
        showRoute: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            zoomDefault: this.zoom,
            center: latLng(this.latitudeCenter, this.longitudeCenter),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            urlClair: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
            urlSombre: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
            attribution: '',
            currentZoom: 11.5,
            currentCenter: latLng(43.276275, 5.504516),
            showParagraph: false,
            mapOptions: {},
            showMap: true,
            icon: L.icon({
                iconUrl: '/img/icons/apple-touch-icon.png',
                iconSize: [32, 37],
                iconAnchor: [16, 37],
            }),
            bounds: null,
        }
    },
    mounted () {
        if (this.markers) {
            this.setCenter(this.markers)
        }
        this.initTypeMap()
    },
    methods: {
        zoomUpdate (zoom) {
            this.currentZoom = zoom
        },
        centerUpdate (center) {
            this.currentCenter = center
        },
        initTypeMap () {
            if (this.autoColor) {
                if(this.$vuetify.theme.dark) {
                    this.url = this.urlSombre
                } else {
                    this.url = this.urlClair
                }
            }
        },
        setCenter (markers) {
            let minLat = markers[0].lat
            let maxLat = markers[0].lat
            let minLng = markers[0].lng
            let maxLng = markers[0].lng
            let LatCenter = 0
            let LngCenter = 0
            for (let i = 0; i < markers.length; i ++) {
                if (markers[i].lat < minLat) {
                    minLat = markers[i].lat
                }
                if (markers[i].lat > maxLat) {
                    maxLat = markers[i].lat
                }
                if (markers[i].lng < minLng) {
                    minLng = markers[i].lng
                }
                if (markers[i].lng > maxLng) {
                    maxLng = markers[i].lng
                }
            }
            LatCenter = maxLat - ((maxLat - minLat) / 2)
            LngCenter = maxLng - ((maxLng - minLng) / 2)
            if(this.boundAuto) {
                this.bounds = latLngBounds([
                    [minLat, minLng],
                    [maxLat, maxLng],
                ])
            }
            if(this.centerAuto) {
                this.center = {
                    lat: LatCenter,
                    lng: LngCenter,
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
    .mymap {
        width: 600px;
        height: 600px;
    }
</style>