<template>
  <div style="display: flex;align-items: center">
    <fx-icon
      :icon="value"
      :tooltip="value"
    />
    <v-btn
      color="primary"
      small
      style="margin-left: 10px"
      @click="openModal = true"
    >
      Sélectionner une icone
    </v-btn>
    <v-dialog
      v-model="openModal"
      persistent
      eager
      :width="isMobile ? 'auto' : '500px'"
    >
      <v-card style="padding: 10px">
        <v-card-title>
          Liste des icones matérial design
        </v-card-title>
        <v-text-field
          v-model="searchField"
          size="small"
          placeholder="Filtre"
          style="width:100%"
          @input="search"
        />
        <div style="height: 500px;display: flex;flex-flow: row wrap;overflow: auto;border: 1px solid grey">
          <template v-for="(item,name,index) in datas">
            <fx-icon
              :key="index"
              :color="(name === value) ? 'green' : 'grey'"
              :size="37"
              :icon="name"
              @click="onChange(name)"
            />
          </template>
        </div>
        <div style="display: flex;justify-content: flex-end;margin-top: 10px">
          <v-btn
            color="primary"
            small
            style="margin-left: 10px"
            @click="openModal = false"
          >
            Fermer
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import IxItemIcon from './IxItemIcon'
import MaterialIcons from './MaterialIcons-Regular.json'
export default{
    name: 'FxIconInput',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        size: { default: 'small' },
        width: { default: '100%' },
        labelField: { default: 'label' },
        idField: { default: 'id' },
        multiple: {
            default: false,
            type: Boolean,
        },
        selectedIndex: {
            default: null,
            type: [String, Number],
        },
    },
    data () {
        return {
            dataProvider: [],
            dataProviderFiltered: [],
            loading: false,
            openModal: false,
            itemRendererIcon: IxItemIcon,
            lstMaterialIcon: MaterialIcons,
            searchField: '',
            isMobile: window.Vue.$store.getters.isMobile(),
            datas: null,
        }
    },
    mounted () {
        this.search()
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        search () {
            if (this.searchField.length > 1) {
                let tmpData = {}
                for (const [key, value] of Object.entries(this.lstMaterialIcon)) {
                    if (key.toString().toUpperCase().search(this.searchField.toUpperCase()) > - 1) {
                        tmpData[key] = value
                    }
                }
                this.datas = tmpData
            } else {
                this.datas = this.lstMaterialIcon
            }
        },
        onChange (ev) {
            if (ev) {
                this.$emit('input', ev)
                this.$emit('on-change', ev)
            }
        },
    },
}
</script>