<template>
  <!--    <v-card-subtitle>{{titre}}</v-card-subtitle>-->
  <div class="fx-titre-style">
    {{ titre }}
  </div>
</template>
<style scoped lang="scss">
.fx-titre-style {
    width: 100%;
    height: 25px;
    font-size: 13px;
    padding-left: 10px;
    font-weight: bold;
    // border-radius: 5px;
    // border-bottom-left-radius: 5px 5px;
    // border-top-right-radius: 50px 25px;
}
</style>
<script>
export default{
    name: 'FxSousTitre',
    props: {
        titre: {
            default: '',
            type: String,
        },
        activeColor: {
            default: '#000000',
            type: String,
        },
    },
}
</script>