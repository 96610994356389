<template>
  <v-card style="width: 600px;padding-left: 10px;padding-right: 10px">
    <v-card-title>Configurations Propriétés du compte</v-card-title>
    <div style="font-weight: bold">
      Visibilité planning agents
    </div>
    <v-card-text style="padding: 0">
      <div style="display: flex;align-items: center;height: 50px">
        <div>Nombre de jours roulant affichés ( 30 par défaut ) :</div>
        <v-text-field
          v-if="datas"
          v-model="datas.propriete_planning_roulant"
          outlined
          hide-details
          dense
          style="margin-left: 10px;width: 170px"
        />
      </div>
    </v-card-text>
    <v-spacer style="height: 10px" />
    <v-card-text style="padding: 0">
      <div style="display: flex;align-items: center;height: 30px">
        <div>Limiter la visibilité du planning roulant à une date spécifique :</div>
        <fx-date-field-check
          v-if="datas"
          v-model="datas.propriete_limite_planning"
          style="margin-left: 10px;width: 170px"
        />
      </div>
    </v-card-text>
    <div style="font-weight: bold">
      Gestion des pauses
    </div>
    <v-card-text style="padding: 0">
      <div style="display: flex;align-items: center;gap: 10px">
        <div>Retirer une pause de</div>
        <fx-text-input
          v-if="datas"
          v-model="datas.propriete_duree_pause_minute"
          width="150px"
          outlined
          hide-details
          dense
        />
        <div>Minutes</div>
      </div>
    </v-card-text>
    <v-spacer style="height: 10px" />
    <v-card-text style="padding: 0">
      <div style="display: flex;align-items: center;gap: 10px">
        <div>Toutes les</div>
        <fx-text-input
          v-if="datas"
          v-model="datas.propriete_duree_retrait_pause"
          width="150px"
          outlined
          hide-details
          dense
        />
        <div> heures consécutives.</div>
      </div>
    </v-card-text>
    <v-spacer style="height: 10px" />
    <v-card-actions style="display: flex;justify-content: flex-end">
      <v-btn
        color="success"
        small
        style="margin-left: 10px"
        @click="update"
      >
        Valider
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'CxConfProprietesCompte',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        update () {
            this.$wikaz.request.post(this.urlRessourceEndPoint, this.datas).then(() => {
                this.getData()
            })
        },
    },
}
</script>

<style scoped>

</style>