<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-checkbox v-model="item.selected" />
    </v-list-item-avatar>
    <v-list-item-avatar>
      <v-icon
        v-if="item.etat_utilisateur === 1"
        color="success"
      >
        person
      </v-icon>
      <v-icon
        v-else
        color="error"
      >
        person
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ item.label }}</v-list-item-title>
      <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxUtilisateursAffectation',
    extends: FxItemTemplate,
}
</script>