<template>
  <div
    v-if="datas"
    class="cx-statistique-container"
  >
    <cx-ligne-header
      :datas="datas.stats"
      :largeur-gauche="largeurGauche"
      label-field="stats_libelle"
      tooltip-field="stats_description"
    />
    <div
      class="cx-statistique-container-agents"
      style="overflow: auto;box-sizing: border-box"
    >
      <cx-ligne-agents
        v-for="(item,index) in datas.agents"
        :key="index"
        :item="item"
        :datas="datas"
        :largeur-gauche="largeurGauche"
      />
    </div>
    <cx-ligne-footer />
  </div>
  <cx-waiting v-else />
</template>
<script>
import CxLigneAgents from './statistiques-elements/agents/CxLigneAgents'
import CxLigneFooter from './statistiques-elements/footer/CxLigneFooter'
import CxLigneHeader from './statistiques-elements/header/CxLigneHeader'
export default {
    name: 'CxStatistiques',
    components: {
        CxLigneHeader,
        CxLigneFooter,
        CxLigneAgents,
    },
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { largeurGauche: 180 }
    },
    methods: {
        f_update () {
            this.$emit('update')
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-statistique-container {
        overflow: auto;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        border: 1px solid black;
        border-radius: 10px;
    }

    .cx-statistique-container-agents {
        // border-left: 1px solid black;
    }
    /*.cx-statistique-container-agents::-webkit-scrollbar {
        width: 0 !important;
    }*/
</style>