<template>
  <v-list-item
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon size="30">
        today
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="date(item.rdv_date) + ' ' + item.rdv_heure"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Rdv n° : ' + item.rdv_id"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Agent : ' + item.agent_nom"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Prestation : ' + item.prestation_libelle"
      />
    </v-list-item-content>
    <v-menu
      v-if="this.$attrs.selected"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item v-if="this.$attrs.selected">
          <v-list-item-avatar>
            <v-icon color="primary">
              print
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Imprimer la fiche</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxListeInterventions',
    extends: FxItemTemplate,
    methods: {
        date (date) {
            return date ? this.$wikaz.outils.dates.getDate(date, 'DD/MM/YYYY') : ''
        },
    },
}
</script>