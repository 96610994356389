<template>
  <div class="cx-editeur-page-container">
    <div class="cx-editeur-page-col1">
      <div style="display: flex;justify-content: space-between;padding-right: 10px">
        <div style="font-weight: bold">
          Preview Page
        </div>
        <div v-if="dataProvider">
          Edition de la page : {{ dataProvider.label }}
        </div>
        <div style="display: flex;gap: 10px">
          <fx-checkbox
            v-model="showContours"
            style="width: 100px"
            label="Contours"
          />
          <fx-icon
            :size="22"
            icon="refresh"
            @click="chargerPage"
          />
          <fx-icon
            :size="22"
            icon="add"
            color="green"
            style="margin-left: 10px"
            @click="f_ajouter_composant"
          />
        </div>
      </div>
      <div
        v-if="pageId > -1"
        class="cx-editeur-page-preview"
      >
        <fx-header
          v-if="$icsilab.getHeader()"
          :fixed="false"
        />
        <cx-preview
          v-if="dataProvider"
          :data-provider="dataProvider"
          :show-contours="showContours"
          @selectedItem="f_select"
        />
        <cx-waiting v-else-if="showWaiting" />
        <div v-else />
        <fx-footer
          v-if="$icsilab.getFooter()"
          :fixed="false"
        />
      </div>
    </div>
    <cx-panel-proprietes
      v-if="pageId > -1 && selectedItem"
      :key="cacheKey"
      v-model="selectedItem"
      @refresh="chargerPage"
    />
  </div>
</template>
<script>
import CxPreview from './CxPreview'
import FxFooter from './elements-site/commun/FxFooter'
import FxHeader from './elements-site/commun/FxHeader'
import CxPanelProprietes from './CxPanelProprietes'
export default {
    name: 'CxEditeurPage',
    components: {
        CxPanelProprietes,
        FxHeader,
        FxFooter,
        CxPreview,
    },
    props: {
        pageId: {
            type: Number,
            default: - 1,
        },
    },
    data () {
        return {
            dataProvider: null,
            selectedItem: null,
            showWaiting: false,
            cacheKey: 0,
            showContours: false,
        }
    },
    mounted () {
        this.chargerPage()
    },
    methods: {
        chargerPage () {
            this.dataProvider = null
            this.selectedItem = null
            if (this.pageId > 0) {
                this.showWaiting = true
                this.$wikaz.request.get('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.pageId).then((data) => {
                    this.dataProvider = data
                    this.showWaiting = false
                })
            }
        },
        f_select (item) {
            this.selectedItem = item
            this.cacheKey ++
        },
        f_ajouter_composant () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.pageId + '/composants/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post('api/wikaz/modules/editeur-de-site/sites/' + this.siteId + '/pages/' + this.pageId + '/composants', data).then(() => {
                this.chargerPage()
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-editeur-page-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 400px;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-editeur-page-col1 {
        // border: 1px solid grey;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        // padding: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 3px;
        overflow: auto;
        // border: 1px solid grey;
    }

    .cx-editeur-page-preview {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        // grid-row-gap: 10px;
        overflow: auto;
        border: 1px solid #393939;
    }
</style>