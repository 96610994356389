<template>
  <!--    <v-combobox
        ref="myInput"
        v-model="model"
        :items="items"
        :filter="filterObject"
        :loading="isLoading"
        :search-input.sync="search"
        hide-details
        @input="$emit('input',$event)"
        outlined
        dense
    ></v-combobox>-->
  <div style="display: flex;align-items: center;gap: 5px;width: 100%">
    <fx-text-input
      v-model="adresseComplete"
      :editable="false"
    />
    <fx-icon
      :size="30"
      icon="search"
      @click="showDialog=true"
    />
    <v-dialog
      v-model="showDialog"
      width="600px"
    >
      <v-card style="padding: 10px;gap: 10px">
        <v-card-title>Formulaire Adresse</v-card-title>
        <fx-titre titre="Assistant" />
        <v-card-text style="display: flex;align-items: center;gap: 10px">
          <fx-label
            width="90px"
            text="Recherche"
          />
          <fx-adresse-input-object
            v-model="adresseComplete"
            @input="f_test"
          />
        </v-card-text>
        <fx-titre titre="Champs" />
        <v-card-text style="display: flex;align-items: center;gap: 10px">
          <fx-label
            width="90px"
            text="Adresse"
          />
          <fx-text-input v-model="adresse.rue" />
        </v-card-text>
        <v-card-text style="display: flex;align-items: center;gap: 10px">
          <fx-label
            width="90px"
            text="CP"
          />
          <fx-text-input v-model="adresse.cp" />
          <fx-label
            width="50px"
            text="Ville"
          />
          <fx-text-input v-model="adresse.ville" />
        </v-card-text>
        <div style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="error"
            @click="f_change"
          >
            Fermer
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FxAdresseInputObject from '@/wikaz-core/ui/composants/inputs/FxAdresseInputObject'
export default{
    name: 'FxAdresseInputFull',
    components: { FxAdresseInputObject },
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        editable: {
            default: true,
            type: Boolean,
        },
    },
    data () {
        return {
            descriptionLimit: 60,
            items: [],
            isLoading: false,
            model: null,
            search: null,
            showDialog: false,
            adresseComplete: '',
            adresse: {
                rue: '',
                cp: '',
                ville: '',
            },
        }
    },
    /*computed: {
        adresseComplete: function () {
            let adresse_complete = (this.adresse.rue + ', ' + this.adresse.cp + ' ' + this.adresse.ville).toString().trim();

            return (adresse_complete != ',') ? adresse_complete : ''
        }
    },*/
    /*watch: {
        search (val) {
            if (val && val.length >= 4 && val.length%3 === 0) {
                this.isLoading = true
                this.$wikaz.request.get('api/wikaz/structure/adresse/' + val.replace(',',''))
                    .then((data) => {
                        this.items = data.map((item) => {
                            return item.label
                        })
                        this.isLoading = false
                    })
            }
        }
    },*/
    mounted () {
        this.model = this.value
        if (this.value) {
            this.adresseComplete = this.value.rue + ', ' + this.value.cp + ' ' + this.value.ville
            this.adresse.rue = this.value.rue
            this.adresse.cp = this.value.cp
            this.adresse.ville = this.value.ville
        }
    },
    methods: {
        validate () {
            return true // this.$refs.myInput.validate()
        },
        getError () {
            return '' // this.$refs.myInput.errorBucket[0]
        },
        filterObject () {
            // item, queryText, itemText
            // console.log(item, queryText, itemText)
            return true
        },
        f_test (item) {
            this.adresse.rue = item.properties.name
            this.adresse.cp = item.properties.postcode
            this.adresse.ville = item.properties.city
        },
        f_change () {
            this.adresseComplete = this.adresse.rue + ', ' + this.adresse.cp + ' ' + this.adresse.ville
            this.$emit('input', JSON.stringify(this.adresse))
            this.showDialog = false

        },

    },
}
</script>
<style scoped>
</style>