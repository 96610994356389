<template>
  <v-card
    v-if="latitudeCible && longitudeCible"
    class="cx-carte-cible-container-main"
    :style="{width: width,height: height}"
  >
    <div class="cx-carte-cible-container">
      <div class="cx-carte-cible-col1">
        <fx-map
          :zoom="5"
          :markers="[{lat: parseFloat(latitudeCible), lng: parseFloat(longitudeCible)}]"
          style="width: 100%;height: 100%"
        />
        <fx-map
          center-auto
          :zoom="8"
          :markers="[{lat: parseFloat(latitudeCible), lng: parseFloat(longitudeCible)}]"
          style="width: 100%;height: 100%"
        />
      </div>
      <div class="cx-carte-cible-col2">
        <fx-map
          bound-auto
          :markers="[{lat: parseFloat(latitudeCible) , lng: parseFloat(longitudeCible)}]"
          style="width: 100%;height: 100%"
        />
      </div>
    </div>
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
  <v-card
    v-else
    class="cx-carte-cible-container"
    :style="{width: width,height: height}"
  >
    Cette adresse n'est pas géodécodée
  </v-card>
</template>
<style scoped lang="scss">
    .cx-carte-cible-container-main {
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        overflow: auto;
    }

    .cx-carte-cible-container {
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-carte-cible-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        overflow: auto;
    }

    .cx-carte-cible-col2 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-carte-cible-mobile {
        // border: 1px solid red;
    }
</style>
<script>
import FxMap from '@/wikaz-core/ui/composants/maps/FxMap'
export default {
    name: 'CxCarteCible',
    components: { FxMap },
    //components: {FxMap},
    data () {
        return {
            title: '',
            latitudeCible: null,
            longitudeCible: null,
            width: '100%',
            height: '800px',
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        init () {
            // this.initMap('carteCible', 5, true)
            // this.initMap('carteCible1', 9, true)
            // this.initMap('carteCible2', 16, false)
        },
        initMap (idMap, zoom, disable) {
            let interval = setInterval(() => {
                let elem = document.getElementById(idMap)
                if (elem !== null && this.latitudeCible && this.longitudeCible) {
                    clearInterval(interval)
                    let map = new window.google.maps.Map(elem, {
                        center: {
                            lat: this.latitudeCible,
                            lng: this.longitudeCible,
                        },
                        zoom: zoom,
                        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        disableDefaultUI: disable,
                        disableDoubleClickZoom: disable,
                        gestureHandling: (disable) ? 'none' : 'auto',
                    })
                    this.makeMarker(map)
                }
            })
        },
        makeMarker (map) {
            let infoMarker = new window.google.maps.Marker({
                position: {
                    lat: this.latitudeCible,
                    lng: this.longitudeCible,
                },
                map: map,
                draggable: false,
            })
            return infoMarker
        },
        close () {
            this.$emit('close')
        },
    },
}
</script>