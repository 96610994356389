<template>
  <div style="display:flex;align-items:center;justify-content:center;height: auto;width: 100%;">
    <!--    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="subject"
      color="#878787"
      tooltip="Afficher les observations du client"
      @click="f_afficher_observation()"
    />-->
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="create"
      color="#E8B229"
      tooltip="Modifier la fiche client"
      @click="f_editer_client()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="edit_calendar"
      color="#E8B229"
      tooltip="Prendre rdv"
      @click="f_prise_rdv_client()"
    />
  </div>
</template>
<script>

export default {
    name: 'CxFicheClientOutils',
    props: {
        clientId: {
            required: true,
            type: Number,
        },
        urlRessourceEndPoint: {
            required: true,
            type: String,
        },
    },
    data () {
        return { datas: null }
    },
    methods: {
        f_editer_client () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.clientId + '/edit',
                this.f_result_formx)
        },
        f_result_formx (data) {
            this.$wikaz.request.put(data.urlUpdate, data).then(() => {
                this.$emit('actualiser')
            })
        },
        f_prise_rdv_client () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.clientId + '/rdv/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + this.clientId + '/rdv', data).then(() => {
                        this.$emit('actualiser')
                    })
                })
        },
    },
}
</script>