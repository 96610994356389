<template>
  <div class="fx-tab-navigator-container">
    <div style="display: flex;gap: 10px">
      <v-btn
        v-for="(item,index) in $slots.default"
        :key="index"
        :color="(index === selectedIndex) ? 'primary' : ''"
        @click="show(index)"
      >
        {{ item.data.attrs.title }}
      </v-btn>
    </div>
    <div style="display: grid;grid-template-columns: 1fr;grid-template-rows: 1fr;overflow: auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
    name: 'FxTabNavigator',
    data () {
        return { selectedIndex: 0 }
    },
    mounted () {
        this.show(0)
    },
    methods: {
        show (index) {
            this.selectedIndex = index
            let element = null
            for (let i = 0; i < this.$slots.default.length; i ++) {
                if(i === index) {
                    this.$slots.default[i].elm.hidden = false
                } else {
                    this.$slots.default[i].elm.hidden = true
                    element = this.$slots.default[i].elm
                }
            }
            this.$emit('change', index, element)
        },
    },
}
</script>

<style scoped>
    .fx-tab-navigator-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
    }
</style>