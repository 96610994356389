<template>
  <div>
    <fx-list
      style="overflow: auto"
      title="Moyens de paiements"
      :item-renderer="itemRenderer"
      show-refresher
      show-result
      show-search
      show-add
      :url="urlRessourceEndPoint"
      :data-provider.sync="datas"
      @add="f_show_dialog"
    />
    <v-dialog
      v-model="showDialog"
      width="600"
    >
      <v-card style="padding: 10px;display: flex;flex-direction: column;gap: 10px">
        <v-card-title>Envoyer un email d'ajout de mode de paiement</v-card-title>
        <fx-text-input v-model="dataIn.proprietaire_email" />
        <fx-text-input v-model="dataIn.proprietaire_nom" />
        <fx-text-input v-model="dataIn.compte_adresse" />
        <fx-text-input v-model="dataIn.compte_cp" />
        <fx-text-input v-model="dataIn.compte_ville" />
        <div style="display: flex;justify-content: center;gap: 10px">
          <v-btn
            color="primary"
            @click="f_ajouter('SEPA')"
          >
            SEPA
          </v-btn>
          <v-btn
            color="primary"
            @click="f_ajouter('CARD')"
          >
            CARD
          </v-btn>
        </div>
        <div style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="error"
            @click="showDialog=false"
          >
            Fermer
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import IxItemMoyenPaiement from '@/wikaz-core/ui/modules/gestion-des-packs/moyen-paiement-compte/IxItemMoyenPaiement'

export default {
    name: 'CxMoyenPaiementCompte',
    extends: CxBase,
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            itemRenderer: IxItemMoyenPaiement,
            showDialog: false,
            destinataire: this.email,
        }
    },
    methods: {
        f_show_dialog () {
            this.showDialog = true
        },
        f_ajouter (type) {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/create?type=' + type).then(() => {
                // this.url_payment = data.url
                this.showDialog = false
            })
        },
    },
}
</script>

<style scoped>

</style>