<template>
  <div
    class="cx-ligne-astreintes-container"
    @contextmenu.prevent=""
  >
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-astreintes-cartouche"
      @contextmenu.prevent=""
    >
      Astreintes / Nuits
    </div>
    <template v-for="(item,indexJour) in datas.dates">
      <div
        :key="indexJour"
        class="cx-ligne-astreintes-case"
        :style="{width: 'calc((100% - '+largeurGauche+'px)/'+datas.dates.length+')'}"
        @contextmenu.prevent=""
      >
        <fx-icon
          v-if="item.etat_nuit === 1"
          color="orange"
          icon="nights_stay"
          tooltip="Nuit"
          @click="f_edit(item)"
        />
        <fx-icon
          v-if="item.etat_nuit === 0"
          :color="(item.interventions > 0) ? 'red' : 'dimgrey'"
          icon="call"
          :tooltip="'Astreinte' + ' ( ' + item.interventions + ' intervention(s) )'"
          @click="f_edit(item)"
        />
      </div>
    </template>
    <v-dialog
      v-if="selectedItem"
      v-model="showEdit"
      width="500px"
    >
      <div
        style="display: flex;flex-direction:column;justify-content: center;align-items:center;width: 100%;height: auto;padding: 10px"
        :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
      >
        <template v-for="(item,index) in dataAstreinte">
          <div
            :key="index"
            style="width: 100%"
          >
            {{ item.utilisateur }}
            <cx-interventions
              style="height: 250px"
              :url-ressource-end-point="'api/edt/modules/planning-edt/planning/' + item.astreinte_id + '/interventions'"
            />
          </div>
        </template>
        <v-switch
          v-model="selectedEtat"
          class="ma-2"
          label="Nuit"
        />
        <div style="display: flex;justify-content: flex-end;margin-top: 10px">
          <v-btn
            :small="true"
            color="success"
            style="margin-left: 10px"
            @click="f_valider"
          >
            Valider
          </v-btn>
          <v-btn
            :small="true"
            color="error"
            style="margin-left: 10px"
            @click="showEdit = false"
          >
            Fermer
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import CxInterventions from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxInterventions'
export default {
    name: 'CxLigneAstreintesNuits',
    components: { CxInterventions },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return {
            dataAstreinte: null,
            showEdit: false,
            selectedItem: null,
            selectedEtat: false,
        }
    },
    methods: {
        f_edit (item) {
            this.selectedItem = item
            this.selectedEtat = (item.etat_nuit === 1)
            this.f_charger_astreintes()
        },
        f_charger_astreintes () {
            this.$wikaz.request.get('api/edt/modules/planning-edt/planning/' + this.selectedItem.dadate + '/astreintes').then((data) => {
                this.dataAstreinte = data
                this.showEdit = true
            })
        },
        f_valider () {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + this.selectedItem.dadate + '/nuits/' + ((this.selectedEtat) ? '1' : '0')).then(() => {
                this.selectedItem.etat_nuit = ((this.selectedEtat) ? 1 : 0)
                this.showEdit = false
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-astreintes-container {
        width: 100%;
        height: 35px;
        display: flex;
        box-sizing: border-box;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        //border-left: 1px solid black;
        //border-right: 1px solid black;
        //border-bottom: 1px solid black;
        border-top: 1px solid black;
        background-color: #51b8d5;
    }

    .cx-ligne-astreintes-cartouche {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0d6aad;
        border-bottom-left-radius: 10px;
        color: white;
        cursor: default;
    }

    .cx-ligne-astreintes-case {
        border-left: 1px solid black;
        // border-top: 1px solid black;
        // border-top: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
    }
</style>