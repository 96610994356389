<template>
  <v-card>
    <fx-sous-titre titre="Compte rendu d'intervention" />
    <div>Sélection finalité</div>
    <fx-dropdown
      v-model="params.cloture"
      pre-holder="Finalités"
      :data-provider.sync="dataClotures"
      :url="'api/entreprises/modules/mon-activite/planning/' + $store.getters.getViewPwa().config.datas.intervention_id + '/facturation'"
      @input="f_update"
    />
    <div>Enregistrer le CRI</div>
    <div style="display: flex;flex-direction: column;align-items: center">
      <cx-photo @change="upload" />
      <div>
        Etat du scan :
        <v-icon
          v-if="params.scan"
          color="success"
        >
          check
        </v-icon>
        <v-icon
          v-else
          color="error"
        >
          close
        </v-icon>
      </div>
    </div>

    <!--        <v-stepper v-model="e1">
                    <v-stepper-header>
                        <v-stepper-step @click="e1 = 1" :complete="e1 > 1" step="1">Name of step 1</v-stepper-step>
        &lt;!&ndash;                <v-divider></v-divider>
                        <v-stepper-step @click="e1 = 2" :complete="e1 > 2" step="2">Name of step 2</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step @click="e1 = 3" :complete="e1 > 3" step="3">Name of step 3</v-stepper-step>&ndash;&gt;
                        <v-divider></v-divider>
                        <v-stepper-step @click="e1 = 2" :complete="e1 > 2" step="2">Name of step 2</v-stepper-step>
        &lt;!&ndash;                <v-divider></v-divider>
                        <v-stepper-step @click="e1 = 3" :complete="e1 > 3" step="3">Name of step 3</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step @click="e1 = 4" :complete="e1 > 4" step="4">Name of step 4</v-stepper-step>&ndash;&gt;
                        <v-divider v-if="params.cloture === 2"></v-divider>
                        <v-stepper-step v-if="params.cloture === 2" @click="e1 = 3" :complete="e1 > 3" step="5">Name of step 5</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1" style="padding: 5px">
                            Sélectionnez une finalité
                            <fx-dropdown @input="f_update;(params.cloture ? e1++ : null)" pre-holder="Finalités" v-model="params.cloture" :data-provider.sync="dataClotures" :url="'api/entreprises/modules/mon-activite/planning/' + $store.getters.getViewPwa().config.datas.intervention_id + '/facturation'"></fx-dropdown>
                            <cx-commande-etape hide-precedent @suivant="(params.cloture ? e1++ : null)"/>
                        </v-stepper-content>
        &lt;!&ndash;                <v-stepper-content step="2" style="padding: 5px">
                            <cx-facturation-fourniture style="margin-bottom: 10px" @input="f_update" v-model="params.fournitures"></cx-facturation-fourniture>
                            <cx-commande-etape @precedent="e1&#45;&#45;" @suivant="e1++"/>
                        </v-stepper-content>
                        <v-stepper-content step="3" style="padding: 5px">
                            <cx-facturation-main-oeuvre style="margin-bottom: 10px" @input="f_update" v-model="params.prestations" :cloture-id="params.cloture"></cx-facturation-main-oeuvre>
                            <cx-commande-etape @precedent="e1&#45;&#45;" @suivant="e1++"/>
                        </v-stepper-content>&ndash;&gt;
                        <v-stepper-content step="2" style="padding: 5px">
                            <div>Scanner le CRI</div>
                        </v-stepper-content>
        &lt;!&ndash;                <v-stepper-content step="3" style="padding: 5px">
                            <cx-signature @input="f_update" v-model="params.signature_client" titre="Signature Client" :url-request-end-point="'api/entreprises/modules/mon-activite/planning/' + $store.getters.getViewPwa().config.datas.intervention_id + '/signature?type=client'" ></cx-signature>
                            <cx-commande-etape @precedent="e1&#45;&#45;" @suivant="(params.signature_client ? e1++ : null)"/>
                        </v-stepper-content>
                        <v-stepper-content step="4" style="padding: 5px">
                            <cx-signature @input="f_update"  v-model="params.signature_prestataire" titre="Signature Prestataire" :url-request-end-point="'api/entreprises/modules/mon-activite/planning/' + $store.getters.getViewPwa().config.datas.intervention_id + '/signature?type=prestataire'" ></cx-signature>
                            <cx-commande-etape @precedent="e1&#45;&#45;" :hide-suivant="params.cloture != 2" @suivant="e1++"/>
                        </v-stepper-content>&ndash;&gt;
                        <v-stepper-content step="3" style="padding: 5px"  v-if="params.cloture === 2">
                            <cx-recherche-disponibilite style="height: 450px" v-model="params.planification" :url-end-point="'api/entreprises/modules/mon-activite/planning/' + $store.getters.getViewPwa().config.datas.intervention_id + '/disponibilite'"></cx-recherche-disponibilite>
                            <cx-commande-etape @precedent="e1&#45;&#45;" hide-suivant/>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>-->
  </v-card>
</template>
<script>
import CxPhoto from '@/wikaz-konnect/gdi/mon-activite/pages/intervention/photo/CxPhoto'
export default {
    name: 'CxEtapesCloture',
    components: { CxPhoto },
    data () {
        return {
            params: {
                cloture: null,
                fournitures: [],
                prestations: [],
                signature_client: false,
                signature_prestataire: false,
                planification: null,
                scan: false,
            },
            cloture: null,
            dataTva: [20, 10],
            dataRglt: ['CB', 'CH', 'ES'],
            dataClotures: null,
            e1: 1,
        }
    },
    methods: {
        f_update () {
            this.$emit('input', this.params)
        },
        upload (event) {
            this.$wikaz.request.request('POST', 'api/entreprises/modules/mon-activite/planning/' + this.$store.getters.getViewPwa().config.datas.intervention_id + '/scancri', null, event).then((response) => {
                if (response) {
                    this.$wikaz.outils.notification.showSuccess('Le document a bien été enregistré.')
                    this.$emit('uploaded', this.exportFile)
                    this.params.scan = true
                }
            }).catch((err) => {
                this.$wikaz.outils.notification.showWarning('Une erreur est survenue, merci de reessayer.' + err.message)
                this.file = null
                this.params.scan = false
            })
            this.f_update()
        },
    },
}
</script>

<style scoped>

</style>