<template>
  <v-card style="display: flex; flex-direction: column;padding: 10px;gap: 10px">
    <fx-titre titre="Disponibilité Générale" />
    <div style="display: flex;flex-direction: column;justify-content: space-between;padding-left: 15px;row-gap: 5px">
      <template v-for="(item,index) in dataJours">
        <div :key="index">
          <div
            :key="index"
            style="display: flex; justify-content: space-between;gap: 10px"
          >
            <fx-checkbox
              v-model="item.etat"
              :label="item.label"
              @input="f_change($event,item)"
            />
            <v-btn
              v-if="item.etat === 1"
              color="success"
              small
              @click="f_open_dialog(index)"
            >
              Configuration Plages
            </v-btn>
          </div>
        </div>
      </template>
    </div>
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
    <v-dialog
      v-model="dialog"
      width="600"
    >
      <div style="width: 600px;height: 400px;background-color: white;padding: 10px">
        <fx-list
          v-if="selectedJour"
          style="height: 100%"
          show-add
          show-refresher
          show-search
          title="Liste des plages horaires"
          :item-renderer="itemRendererPlage"
          :data-provider="selectedJour.plages"
          @refresh="chargerDatas"
          @add="f_ajout"
        />
      </div>
    </v-dialog>
  </v-card>
</template>
<script>
import IxPlages from './IxPlages'
import VxCarteTournees from '@/wikaz-konnect/gdi/plannings/VxCarteTournees'

export default {
    name: 'CxDisponibiliteGenerale',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            dataJours: null,
            dialog: false,
            dialog2: false,
            selectedJour: null,
            selectedIndex: null,
            itemRendererPlage: IxPlages,
            dataPlanning: null,
            composantCarte: VxCarteTournees,
        }
    },
    mounted () {
        this.chargerDatas()
    },
    methods: {
        chargerDatas () {
            this.$wikaz.request.get(this.urlEndPoint).then((data) => {
                this.dataJours = data
                if (this.selectedJour) {
                    this.selectedJour = this.dataJours[this.selectedIndex]
                }
            })
        },
        f_open_dialog (index) {
            this.selectedIndex = index
            this.selectedJour = this.dataJours[index]
            this.dialog = true
        },
        f_show_planning () {
            this.dataPlanning = {
                config: {
                    plage_debut: 8,
                    plage_fin: 18,
                },
                plannings: [
                    {
                        verrou: (this.dataJours[0].etat === 1) ? 0 : 1,
                        label: 'Lundi',
                        rdvs: this.f_get_plages_agenda(1),
                    },
                    {
                        verrou: (this.dataJours[1].etat === 1) ? 0 : 1,
                        label: 'Mardi',
                        rdvs: this.f_get_plages_agenda(2),
                    },
                    {
                        verrou: (this.dataJours[2].etat === 1) ? 0 : 1,
                        label: 'Mercredi',
                        rdvs: this.f_get_plages_agenda(3),
                    },
                    {
                        verrou: (this.dataJours[3].etat === 1) ? 0 : 1,
                        label: 'Jeudi',
                        rdvs: this.f_get_plages_agenda(4),
                    },
                    {
                        verrou: (this.dataJours[4].etat === 1) ? 0 : 1,
                        label: 'Vendredi',
                        rdvs: this.f_get_plages_agenda(5),
                    },
                    {
                        verrou: (this.dataJours[5].etat === 1) ? 0 : 1,
                        label: 'Samedi',
                        rdvs: this.f_get_plages_agenda(6),
                    },
                    {
                        verrou: (this.dataJours[6].etat === 1) ? 0 : 1,
                        label: 'Dimanche',
                        rdvs: this.f_get_plages_agenda(7),
                    },
                ],
            }
            this.dialog2 = true
        },
        f_get_plages_agenda (jour) {
            if (this.dataJours[jour - 1].etat === 1) {
                let tmpData = this.dataJours[jour - 1].plages

                let dataPlage = []

                for (let i = 0; i < tmpData.length; i ++) {
                    dataPlage.push({
                        th_libelle: tmpData[i].debut_libelle,
                        duree: tmpData[i].duree,
                        couleur: '#7ebc7e',
                        nom_client: '',
                    })
                }

                return dataPlage
            }
            return []

        },
        f_change (etat, item) {
            this.$wikaz.request.put(this.urlEndPoint + '/' + item.id, { etat: etat }).then(() => {
                this.chargerDatas()
            })
        },
        f_ajout () {
            this.$wikaz.popups.showFormulaire(this.urlEndPoint + '/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            data.jour = this.selectedJour.id
            this.$wikaz.request.post(this.urlEndPoint, data).then(() => {
                this.chargerDatas()
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .chkcss {
        margin: 0;
        padding: 0;
    }

    .chkcss /dee/ .v-input__control /dee/ .v-inpout__slot {
        margin: 0;
        padding: 0;
    }

    .chkcss /dee/ .v-input__control /dee/ .v-inpout__slot /dee/ .v-input--selection-controls__input {
        margin: 0;
        padding: 0;
    }
</style>