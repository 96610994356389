<template>
  <v-list-item
    dense
    style="overflow: visible;height: 50px"
    :style="{marginLeft: ( item.module_parent_id > 0 ) ? '20px' : '0'}"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <fx-checkbox v-model="item.selected" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="item.label" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxListTransfert',
    extends: FxItemTemplate,
}
</script>

<style scoped>

</style>