import Vue from 'vue'
import FxAdresseInput from '@/wikaz-core/ui/composants/inputs/FxAdresseInput'
import FxCheckbox from '@/wikaz-core/ui/composants/inputs/FxCheckbox.vue'
import FxColorPicker from '@/wikaz-core/ui/composants/inputs/FxColorPicker.vue'
import FxSwitch from '@/wikaz-core/ui/composants/inputs/FxSwitch.vue'
import FxTelephone from '@/wikaz-core/ui/composants/inputs/FxTelephone.vue'
import FxTextInput from '@/wikaz-core/ui/composants/inputs/FxTextInput.vue'
import FxDropdown from '@/wikaz-core/ui/composants/inputs/FxDropdown'
import FxTextArea from '@/wikaz-core/ui/composants/inputs/FxTextArea'
import FxImageInput from '@/wikaz-core/ui/composants/inputs/FxImageInput'
import FxUrl from '@/wikaz-core/ui/composants/inputs/FxUrl'
import FxEmail from '@/wikaz-core/ui/composants/inputs/FxEmail'
import FxAdresseInputFull from '@/wikaz-core/ui/composants/inputs/FxAdresseInputFull'
import FxSirenInput from '@/wikaz-core/ui/composants/inputs/FxSirenInput'
import FxPasswordInput from '@/wikaz-core/ui/composants/inputs/FxPasswordInput'
import FxCheckRgpd from '@/wikaz-core/ui/composants/inputs/FxCheckRgpd'
import FxFilterTag from '@/wikaz-core/ui/composants/inputs/FxFilterTag'

Vue.component('fx-adresse-input', FxAdresseInput)
Vue.component('fx-adresse-input-full', FxAdresseInputFull)
Vue.component('fx-checkbox', FxCheckbox)
Vue.component('fx-color-picker', FxColorPicker)
Vue.component('fx-dropdown', FxDropdown)
Vue.component('fx-switch', FxSwitch)
Vue.component('fx-telephone', FxTelephone)
Vue.component('fx-text-area', FxTextArea)
Vue.component('fx-text-input', FxTextInput)
Vue.component('fx-image-input', FxImageInput)
Vue.component('fx-url', FxUrl)
Vue.component('fx-email', FxEmail)
Vue.component('fx-siren-input', FxSirenInput)
Vue.component('fx-password-input', FxPasswordInput)
Vue.component('fx-check-rgpd', FxCheckRgpd)
Vue.component('fx-filter-tag', FxFilterTag)