<template>
  <v-list-item
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <fx-icon
      style="width: 60px"
      :size="30"
      icon="today"
      :badge="item.nbinter+item.nbdemande"
      tooltip="Nombre d'interventions"
    />
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.titre"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="item.adresse"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="item.cpville"
      />
    </v-list-item-content>
    <v-menu
      v-if="this.$attrs.selected"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item
          v-if="this.$attrs.selected && item.url && item.url_client && item.show_create_demande === 1"
          @click="f_creation_demande()"
        >
          <v-list-item-avatar>
            <v-icon color="green">
              add
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Créer une demande</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected && item.url && item.url_client && item.show_create_intervention === 1"
          @click="f_creation_intervention"
        >
          <v-list-item-avatar>
            <v-icon color="green">
              add
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Créer une intervention</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected && (item.nbinter + item.nbdemande ) > 0 && isMobile"
          @click="f_afficher_historique"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              reorder
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Liste des interventions</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected"
          @click="f_edit"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              create
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Modifier l'adresse</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected"
          @click="f_afficher_carte"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              map
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Afficher la carte</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected && item.adresse_telephone1"
          @click="f_telephone(item.adresse_telephone1)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.adresse_telephone1 }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected && item.adresse_telephone2"
          @click="f_telephone(item.adresse_telephone2)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.adresse_telephone2 }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import CxCarteCible from './CxCarteCible'
import CxCreationIntervention from './creation-intervention/CxCreationIntervention'
import CxCreationDemande from './creation-demande/CxCreationDemande'
import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxListeAdresses',
    extends: FxItemTemplate,
    data () {
        return { isMobile: window.Vue.$store.getters.isMobile() }
    },
    methods: {
        f_creation_intervention () {
            if (this.$store.getters.isMobile()) {
                let dataParams = this.item
                dataParams.client_id = this.item.numero_client
                this.$store.commit('SET_VIEW_PWA', {
                    component: 'cx-creation-intervention',
                    module_libelle: 'Nouvelle intervention',
                    config: {
                        iconLeft: 'arrow_back',
                        datas: dataParams,
                    },
                })
            } else {
                this.$wikaz.popups.show(
                    CxCreationIntervention,
                    { datas: { dataIn: this.item } },
                    this.f_emit_actualiser,
                )
            }
        },
        f_edit () {
            this.$wikaz.popups.showFormulaire(this.item.url_edit + '/edit', this.f_result_popup)
        },
        f_result_popup (data) {
            this.$wikaz.request.put(this.item.url_edit, data).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_afficher_carte () {
            if (this.$store.getters.isMobile()) {
                window.location = 'geo:' + this.item.adresse_latitude + ',' + this.item.adresse_longitude
            } else {
                this.$wikaz.popups.show(
                    CxCarteCible,
                    {
                        datas: {
                            width: '1200px',
                            latitudeCible: this.item.adresse_latitude,
                            longitudeCible: this.item.adresse_longitude,
                        },
                    },
                )
            }
        },
        f_creation_devis () {
            this.$wikaz.popups.showFormulaire(this.item.url_creation_devis + '/create', this.f_resultat_creation_devis)
        },
        f_resultat_creation_devis (data) {
            this.$wikaz.request.post(this.item.url_creation_devis, data).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_telephone (valeur) {
            window.location = 'tel:' + valeur
        },
        f_afficher_historique () {
            let dataParams = this.item
            dataParams.client_id = this.item.numero_client
            this.$store.commit('SET_VIEW_PWA', {
                component: 'cx-page-interventions',
                module_libelle: 'Liste des interventions',
                config: {
                    iconLeft: 'arrow_back',
                    datas: dataParams,
                },
            })
        },
        f_creation_demande () {
            this.$wikaz.popups.show(
                CxCreationDemande,
                {
                    datas: {
                    // urlRessourceEndPoint: this.item.url_add_demande, // this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/propager',
                        urlRessourceEndPoint: this.item.url_edit, // this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/propager',
                        titre: 'Propager la demande',
                        width: '600px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_emit_actualiser)
        },
    },
}
</script>