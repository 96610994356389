<template>
  <div
    class="fx-tree-file-container"
    :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
  >
    <div
      v-for="(folder, index) in myDatas.directories"
      :key="index"
      style="width:100%;"
    >
      <div
        class="file-line"
        @click="showFolder(index)"
      >
        <div style="display: flex;align-items: center">
          <div style="display: flex; justify-content: center;width: 60px">
            <fx-icon
              v-show="shouldShow(index)"
              :size="30"
              icon="folder_open"
            />
            <fx-icon
              v-show="!shouldShow(index)"
              :size="30"
              icon="folder"
            />
          </div>
          <div style="text-overflow:ellipsis;word-wrap: break-word;margin-left: 15px">
            {{ folder.nom }}
          </div>
        </div>
      </div>
      <div
        v-show="shouldShow(index)"
        class="multi-parent"
      >
        <fx-tree-file
          :datas="folder"
          @open="openFile"
          @show="showFile"
          @delete="deleteFile"
        />
      </div>
    </div>
    <div
      v-for="file in myDatas.files"
      :key="file.filename"
      class="file-line"
    >
      <div style="display: flex; justify-content: center;width: 60px">
        <v-img
          v-if="['jpg','png','jpeg'].includes(file.ext)"
          contain
          height="50px"
          :src="$wikaz.outils.urls.getUrlImageByUrl(file.dir + '/' + file.filename)"
        />
        <fx-icon
          v-else-if="file.ext === 'pdf'"
          :size="30"
          icon="picture_as_pdf"
        />
        <fx-icon
          v-else
          :size="30"
          icon="insert_drive_file"
        />
      </div>
      <div
        style="word-wrap: break-word;margin-left: 15px"
        v-html="getTroncateFileName(file.filename)"
      />
      <span style="display: flex;justify-content: flex-end;width: 100px">
        <fx-icon
          :size="25"
          icon="remove_red_eye"
          tooltip="Afficher"
          @click="showFile(file)"
        />
        <!--                <fx-icon style="margin-left: 10px" :size="25" icon="cloud_download" tooltip="Télécharger" @click="openFile(file)"/>-->
        <fx-icon
          style="margin-left: 10px"
          :size="25"
          icon="delete"
          color="red"
          tooltip="Supprimer"
          @click="deleteFile(file)"
        />
      </span>
    </div>
  </div>
</template>
<script>
//v-if="['jpg','png','jpeg','webp','pdf','docx','xlsx'].includes(file.ext)"
export default{
    name: 'FxTreeFile',
    props: { datas: null },
    data () {
        return {
            myDatas: {
                directories: [],
                files: [],
            },
            showable: [],
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    watch: {
        datas: function (newVal) {
            this.myDatas = newVal
        },
    },
    created () {
        this.myDatas = this.datas
    },
    methods: {
        getTroncateFileName (filename) {
            if (this.isMobile) {
                return filename.length > 20 ? filename.substr(0, 17) + '...' : filename
            }
            return filename

        },
        showFolder (i) {
            if (this.showable.indexOf(i) > - 1) {
                this.showable.splice(this.showable.indexOf(i), 1)
            } else {
                this.showable.push(i)
            }
        },
        shouldShow (i) {
            return this.showable.indexOf(i) > - 1
        },
        openFile (file) {
            this.$emit('open', file)
        },
        showFile (file) {
            this.$emit('show', file)
        },
        deleteFile (file) {
            this.$emit('delete', file)
        },
    },
}
</script>
<style lang="scss" scoped>
    .fx-tree-file-container {
        border-radius: 5px;
        // border: 1px solid #dddddd;
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .multi-parent {
        margin-left: 16px;
        width: -moz-calc(100% - 16px);
        width: -webkit-calc(100% - 16px);
        width: calc(100% - 16px);
        padding-left: 8px;
        // border-left: 1px solid #d7ebf6;
        text-align: left;
    }
    .file-line{
        padding:4px 8px;
        min-height: 56px;
        height: auto;
        width: 100%;
        text-align: left;
        // display:flex;
        // justify-content: space-between;
        align-items: center;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        span i {
            // color: #0d5aa7;
            font-size: 1rem;
        }
        img{
            width:18px;
            margin-right:12px;
        }
        &:hover{
            // background-color: #ebf2f9;
            cursor: pointer;
            >span{
                // color: #0d5aa7;
            }
        }
        p{
            display:flex;
            align-items: center;
            margin: 0;
            font-size: .8rem;
        }
    }
</style>