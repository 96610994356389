<template>
  <div
    v-if="urlRessourceEndPoint"
    class="cx-filtre-gestion-crm-container"
  >
    <div class="cx-filtre-gestion-crm-ligne">
      <fx-label
        text="Origine"
        width="80px"
      />
      <fx-dropdown
        v-model="selectedAffichage"
        :data-provider.sync="dataAffichage"
        :url="urlRessourceEndPoint + 'origine'"
        @on-change="filterChanged"
      />
    </div>
  </div>
</template>
<script>

import FxFiltreTemplate from '@/wikaz-core/ui/composants/base/FxFiltreTemplate'

export default {
    name: 'CxFiltreClients',
    extends: FxFiltreTemplate,
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-clients-entreprises/filtres/',
            dataAffichage: null,
            selectedAffichage: 0,
        }
    },
    methods: {
        getFiltreData () {
            return '?origine=' + this.selectedAffichage
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-filtre-gestion-crm-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
        overflow: auto;
    }

    .cx-filtre-gestion-crm-ligne {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-template-rows: 1fr;
    }
</style>