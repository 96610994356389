<template>
  <v-card>
    <v-tabs
      v-model="tab"
      centered
      fixed-tabs
      icons-and-text
      @change="getData"
    >
      <v-tabs-slider />
      <v-tab>
        Demandes
        <v-icon>add_task</v-icon>
      </v-tab>
      <v-tab>
        Congés
        <v-icon>date_range</v-icon>
      </v-tab>
      <v-tab>
        TLC
        <v-icon>date_range</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      fixed-tabs
    >
      <v-tab-item>
        <v-card flat>
          <div style="display: flex;justify-content: flex-end;padding: 10px">
            <v-btn
              :small="true"
              color="success"
              @click="f_ajouter"
            >
              Nouvelle demande
            </v-btn>
          </div>
          <v-list disabled>
            <v-list-item
              v-for="(item, i) in datas"
              :key="i"
              style="border: 1px solid grey;margin: 3px;border-radius: 11px"
            >
              <v-list-item-icon>
                <v-icon color="primary">
                  date_range
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="'du ' + $wikaz.getInstance().outils.dates.getDate(item.demande_conge_date_debut,'DD/MM/YYYY') + ' au ' + $wikaz.getInstance().outils.dates.getDate(item.demande_conge_date_fin,'DD/MM/YYYY')" />
                <v-list-item-subtitle v-if="item.demande_conge_etat === 0">
                  Demande de {{ item.demande_type === 2 ? 'TLC' : 'Congés' }} en attente de traitement
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.demande_conge_etat === 1">
                  Demande de {{ item.demande_type === 2 ? 'TLC' : 'Congés' }} accepté
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.demande_conge_etat === 2">
                  Demande de {{ item.demande_type === 2 ? 'TLC' : 'Congés' }} refusé
                </v-list-item-subtitle>
                <v-list-item-content
                  v-if="item.demande_motif_demande"
                  style="font-style: italic"
                  v-html="'<b>Motif demande :</b>' + item.demande_motif_demande"
                />
                <v-list-item-content
                  v-if="item.demande_motif_reponse"
                  style="font-style: italic"
                  v-html="'<b>Motif réponse :</b>' + item.demande_motif_reponse"
                />
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-if="item.demande_conge_etat === 0">
                  hourglass_empty
                </v-icon>
                <v-icon
                  v-if="item.demande_conge_etat === 1"
                  color="green"
                >
                  check
                </v-icon>
                <v-icon
                  v-if="item.demande_conge_etat === 2"
                  color="red"
                >
                  close
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-list disabled>
            <v-list-item
              v-for="(item, i) in datas"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>today</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$wikaz.getInstance().outils.dates.getDate(item.conge_date)" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-list disabled>
            <v-list-item
              v-for="(item, i) in datas"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon>today</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="$wikaz.getInstance().outils.dates.getDate(item.tlc_date)" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>

export default {
    name: 'VxGestionCongesEdt',
    data () {
        return {
            tab: 0,
            datas: null,
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/gestion-conges-edt/conges?type=' + this.tab).then((data) => {
                this.datas = data
            })
        },
        f_change (tabIndex) {
            this.tab = tabIndex
            this.getData()
        },
        getDateFormatted (valeur) {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, 'dddd Do MMMM YYYY')
        },
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/gestion-conges-edt/conges/create',
                this.f_resultat_ajouter_demande,
            )
        },
        f_resultat_ajouter_demande (data) {
            this.$wikaz.request.post('api/edt/modules/gestion-conges-edt/conges', data).then(() => {
                this.getData()
            })
        },
    },
}
</script>
<style scoped>
</style>