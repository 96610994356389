<template>
  <v-carousel
    v-if="dataProvider"
    cycle
    :height="dataProvider.height"
    :interval="(dataProvider.interval ? dataProvider.interval : 6 ) * 1000"
    :show-arrows="dataProvider.imageSrc2 ? true : false"
    :hide-delimiters="dataProvider.imageSrc2 ? false : true"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <template v-for="index in 10">
      <v-carousel-item
        v-if="dataProvider['imageSrc' + index]"
        :key="index"
        :src="$icsilab.rootApi + 'fichiers?file=' + dataProvider['imageSrc' + index]"
      >
        <div
          v-if="dataProvider.baseline"
          :style="{color: dataProvider.baselineColor ? dataProvider.baselineColor : 'white'}"
          class="display-2 titre-accroche"
          v-html="dataProvider.baseline"
        />
        <div
          v-if="dataProvider.texte && !(['xs','sm','md'].indexOf($screen.breakpoint) > -1)"
          class="texte-accroche-conteneur"
        >
          <v-card
            elevation="12"
            :style="{color: dataProvider.texteColor ? dataProvider.texteColor : 'white'}"
            class="subtitle-1 texte-accroche"
            v-html="dataProvider.texte"
          />
        </div>
      </v-carousel-item>
    </template>
  </v-carousel>
</template>
<style scoped>
    .titre-accroche {
        display:flex;
        height:40%;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: right;
        padding-right: 50px;
        padding-top: 45px;
        font-weight: bold;
    }
    .texte-accroche-conteneur {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 50%;
        width: 100%;
        padding-right: 50px;
        padding-top: 45px;
    }

    .texte-accroche {
        top: 0;
        left: 0;
        height: auto;
        width: auto;
        text-align: right;
        padding: 10px;
        background-color: #f2f2f2
    }

</style>
<script>
export default {
    name: 'FxBandeauCarousel',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_click () {
        },
    },
}
</script>