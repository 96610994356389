<template>
  <v-footer
    :fixed="fixed"
    :color="$icsilab.getFooter().BackgroundColor ? $icsilab.getFooter().BackgroundColor : 'white'"
    padless
  >
    <v-flex xs12>
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center
        >
          <div
            class="md3"
            :style="{color: $icsilab.getFooter().color ? $icsilab.getFooter().color : 'black'}"
          >
            © 2020 ICSILAB
          </div>
          <div
            class="md1"
            :style="{color: $icsilab.getFooter().color ? $icsilab.getFooter().color : 'black'}"
            v-html="'&nbsp;|&nbsp;'"
          />
          <div
            class="md4"
            :style="{color: $icsilab.getFooter().color ? $icsilab.getFooter().color : 'black'}"
          >
            Tous droits réservés
          </div>
          <div
            class="md1"
            :style="{color: $icsilab.getFooter().color ? $icsilab.getFooter().color : 'black'}"
            v-html="'&nbsp;|&nbsp;'"
          />
          <div
            class="md3"
            style="padding-right: 10px;cursor: pointer"
            :style="{color: $icsilab.getFooter().color ? $icsilab.getFooter().color : 'black'}"
            @click="goToPage"
          >
            Mentions légales
          </div>
        </v-layout>
      </v-container>
    </v-flex>
  </v-footer>
</template>
<script>
export default {
    name: 'FxFooter',
    props: {
        fixed: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        goToPage () {
            this.$icsilab.goToPage('mentions-legales')
        },
    },
}
</script>