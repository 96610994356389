<template>
  <div
    class="cx-liste-intervention-container"
    :style="'width:'+width+';height:'+height"
  >
    <fx-list
      ref="liste"
      style="overflow: auto;height: 100%"
      :item-renderer="item_renderer"
      :show-search="true"
      :title="titre"
      show-result
      height="100%"
      :pre-search="preSearch"
      :show-refresher="true"
      :show-loading="loading"
      :show-add="showAdd"
      :data-provider="datas"
      @refresh="getListe()"
      @itemEmitting="selectedItem($event)"
      @add="f_ajouter"
    />
  </div>
</template>
<style scoped>
    .cx-liste-intervention-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

     .v-input >>> .v-messages {
        min-height: 0;
    }
</style>
<script>
import IxListeInterventions from './IxListeInterventions'

export default{
    name: 'CxListeInterventions',
    props: {
        urlEndPoint: {
            required: true,
            type: String,
        },
        width: {
            type: String,
            default: '250px',
        },
        height: {
            type: String,
            default: '100%',
        },
        preSearch: {
            type: String,
            default: '',
        },
        titre: { default: 'Liste des interventions' },
        limit: { default: true },
        showAdd: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            datas: [],
            historique: 1,
            item_renderer: IxListeInterventions,
            loading: false,
            adresseId: 0,
        }
    },
    mounted () {
        this.getListe()
    },
    methods: {
        getListe () {
            this.datas = []
            this.loading = true
            this.$wikaz.request.get(this.urlEndPoint + '?adresse_id=' + this.adresseId).then((data) => {
                this.datas = data
            }).finally(() => {
                this.loading = false
            })
        },
        selectedItem (ev) {
            this.$emit('selectedItem', ev)
        },
        setFiltre (valeur) {
            if (this.$refs.liste) {
                // this.$refs.liste.searchField = valeur;
                // this.$refs.liste.search()
                this.adresseId = valeur
                this.getListe()
            }
        },
        f_ajouter () {
            this.$emit('add')
        },
    },
}
</script>