<template>
  <div class="cx-recherche-disponibilite-container">
    <fx-sous-titre titre="Disponibilités" />
    <div
      v-if="isMobile"
      style="display: flex;flex-direction:column"
    >
      <div style="display: flex">
        <fx-date-field-check
          v-model="date_rdv"
          label=""
          @input="chargerData"
        />
        <!--                <cx-waiting style="width: 20px" v-if="showBusy" :size="24"></cx-waiting>-->
      </div>
      <fx-periode-semaine-bloc
        ref="obj_periode"
        url-ressource-end-point="api/entreprises/modules/gestion-clients-entreprises/agenda"
        x-small
        @selection="chargerData"
      />
    </div>
    <div
      v-else
      style="display: flex; align-items: center"
    >
      <fx-date-field-check
        v-model="date_rdv"
        style="width: 220px"
        label=""
        @input="chargerData"
      />
      <fx-periode-semaine-bloc
        ref="obj_periode"
        url-ressource-end-point="api/entreprises/modules/gestion-clients-entreprises/agenda"
        x-small
        @selection="chargerData"
      />
      <!--            <cx-waiting style="width: 20px" v-if="showBusy" :size="24"></cx-waiting>-->
    </div>
    <fx-list
      ref="objlst"
      style="overflow: auto"
      :show-loading="showBusy"
      show-search
      show-result
      show-refresher
      :item-renderer="itemDisponibilite"
      :data-provider.sync="dataDispo"
      @refresh="chargerData"
      @itemEmitting="f_change_liste"
    />
    <div
      v-if="displayObservations"
      style="display: flex;gap: 10px;margin-top: 10px"
    >
      <div style="width: 50%">
        <fx-sous-titre titre="Observation Client" />
        <fx-text-area
          v-model="observationClient"
          type="textarea"
          @change="f_saisie_observation"
          @on-blur="f_saisie_observation"
        />
      </div>
      <div style="width: 50%">
        <fx-sous-titre titre="Observation Intervention" />
        <fx-text-area
          v-model="observation"
          type="textarea"
          @change="f_saisie_observation"
          @on-blur="f_saisie_observation"
        />
      </div>
    </div>
  </div>
</template>
<script>

import IxDisponibilite from './IxDisponibilite'

export default {
    name: 'CxRechercheDisponibilite',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
        height: {
            type: String,
            default: '100%',
        },
        width: {
            type: String,
            default: '100%',
        },
        displayObservations: { default: true },
    },
    data () {
        return {
            url: this.urlEndPoint,
            infos_suite: '',
            itemDisponibilite: IxDisponibilite,
            observation: '',
            observationClient: '',
            date_rdv: null,
            selectedItem: null,
            dataDispo: [],
            showBusy: false,
            durees: [],
            cancelRequest: null,
            isMobile: window.Vue.$store.getters.isMobile(),
            infos_planification: null,
        }
    },
    methods: {
        setUrlEndPoint (url) {
            this.url = url
            this.chargerData()
        },
        chargerData () {
            this.$emit('input', '')
            if (this.url) {
                this.infos_suite = ''
                this.dataDispo = []
                this.showBusy = true
                let urlParams = ''
                let caractere = '?'
                if (this.url.includes('?')) {
                    caractere = '&'
                }
                if (this.date_rdv) {
                    urlParams = caractere + 'date=' + this.date_rdv
                } else {
                    urlParams = caractere + 'date=' + this.$refs.obj_periode.getSelectedValue()
                }

                if (this.cancelRequest !== null) {
                    this.cancelRequest.abort()
                }
                this.cancelRequest = new AbortController()

                this.$wikaz.request.get(this.url + urlParams, null, null, this.cancelRequest).then((data) => {
                    this.dataDispo = data
                    this.showBusy = false
                })
            }
        },
        f_change_liste (item) {
            this.infos_planification = item
            this.f_update_data()
        },
        f_saisie_observation () {
            this.f_update_data()
        },
        f_update_data () {
            if (this.infos_planification) {
                let data = {
                    observation: this.observation,
                    observation_client: this.observationClient,
                    infos_planification: this.infos_planification,
                }
                this.selectedItem = data
                this.infos_suite = JSON.stringify(data)
                this.$emit('input', data)
            } else {
                this.$emit('input', '')
            }
        },
    },
}
</script>
<style scoped>
    .cx-recherche-disponibilite-container {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 30px auto 1fr auto;
        grid-column-gap: 5px;
        overflow: auto;
    }
</style>