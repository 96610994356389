<template>
  <div
    class="alterStyleRow"
    style="display: flex;padding: 5px;align-items: center;justify-content: space-between"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon
        :size="30"
        class="avatarStyle"
        color="white"
      >
        backup
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="'Date mise à jour : ' + $wikaz.outils.dates.getDate(item.historique_date_upload,'DD/MM/YYYY HH:mm')"
      />
      <v-list-item-subtitle
        v-if="item.historique_date_expire"
        class="caption"
        style="font-weight: bold"
        v-html="'Date expiration : ' + $wikaz.outils.dates.getDate(item.historique_date_expire,'DD/MM/YYYY')"
      />
      <v-list-item-subtitle
        v-else
        class="caption"
        style="font-weight: bold"
        v-html="'Pas de date d\'expiration'"
      />
    </v-list-item-content>
  </div>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxHistorique',
    extends: FxItemTemplate,
    methods: {
        f_set_date_fin () {
            /*this.$wikaz.popups.showFormulaire('api/edt/modules/gestion-residents/residents/' + this.item.contact_resident_id + '/contacts/' + this.item.contact_id + '/edit',() => {
                    this.$refs.obj_liste.refresh()
                })*/
        },
        get_type (item) {
            if(item.contact_detail) {
                return item.contacts_type_libelle + ' ( ' + item.contact_detail + ' )'
            }
            return item.contacts_type_libelle

        },
    },
}
</script>
<style scoped lang="scss">

    .alterStyleRow {
        background-color: #BBBBBB;
    }

    .alterStyleRow:first-child {
        background-color: #4CAF50;
        color: white;
    }

    /*.alterStyleRow:nth-child(even) {
        background-color: #f5f3f3
    }
    .alterStyleRow:nth-child(odd) {
        background-color: #ffffff
    }*/

</style>