<template>
  <div style="background-color: white">
    <v-list-item two-line>
      <v-list-item-avatar
        tile
        size="40"
      >
        <v-icon color="primary">
          map
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle v-text="datas.adresse_adresse1" />
        <v-list-item-subtitle v-text="datas.adresse_cp + ' ' + datas.adresse_ville" />
      </v-list-item-content>
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <fx-icon
            :size="30"
            color="primary"
            icon="menu"
            tooltip="Menu des actions"
            v-on="on"
          />
        </template>
        <v-list>
          <v-list-item @click="f_gps('//?q=' + datas.adresse_adresse1+ ', '+datas.adresse_cp + ' ' + datas.adresse_ville)">
            <v-list-item-avatar>
              <v-icon color="primary">
                map
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ouvrir le GPS</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </div>
</template>

<script>
export default {
    name: 'CxInterventionInfosAdresse',
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_gps (valeur) {
            window.location = 'geo:' + valeur
        },
    },
}
</script>

<style scoped>

</style>