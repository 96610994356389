<template>
  <v-card style="padding: 10px">
    <fx-datagrid-vuetify titre="Factures" :url-ressource-end-point="urlRessourceEndPoint" @ouvrir_facture="afficher_pdf" />
  </v-card>
</template>
<script>
export default {
    name: 'CxHistoriqueFactures',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    methods: {
        afficher_pdf (item) {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/' + item.facture_id)
        },
    },
}
</script>