import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: function (resolve) {
                require(['../ui/modules/login/VxLogin.vue'], resolve)
            },
            props: true,
        },
        {
            path: '/recovery',
            name: 'recovery',
            props: true,
            component: function (resolve) {
                require(['../ui/modules/recovery/VxRecovery.vue'], resolve)
            },
        },
        {
            path: '/inscription',
            name: 'inscription',
            props: true,
            component: function (resolve) {
                require(['../ui/modules/inscription/VxInscription.vue'], resolve)
            },
        },
        {
            path: '/inscription/:slug',
            name: 'inscription-slug',
            props: true,
            component: function (resolve) {
                require(['../ui/modules/inscription/VxInscription.vue'], resolve)
            },
        },
        {
            path: '/invitation/:id',
            name: 'invitation',
            props: true,
            component: function (resolve) {
                require(['../ui/modules/invitation/VxInvitation.vue'], resolve)
            },
        },
        {
            path: '',
            name: 'home',
            component: function (resolve) {
                require(['../ui/modules/home/VxSwitch.vue'], resolve)
            },
            beforeEnter: guardRoute,
        },
        {
            path: '*',
            redirect: '/login',
        },
    ],
})

function guardRoute (to, from, next) {
    const auth = router.app.$options.store.state.auth
    if (! auth.isLoggedIn) {
        next({ name: 'login' })
    } else {
        next()
    }
}

export default router