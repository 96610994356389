<template>
  <v-card
    v-if="dataIn"
    class="cx-creation_intervention-container"
    :style="{width: width}"
  >
    <div class="cx-creation_intervention-col1">
      <cx-fiche-client :url-end-point="dataIn.url_client" />
      <fx-titre titre="Adresse d'intervention" />
      <div>
        <div style="margin-left: 40px">
          {{ dataIn.titre }}
        </div>
        <div style="margin-left: 40px">
          {{ dataIn.adresse }}
        </div>
        <div style="margin-left: 40px">
          {{ dataIn.adresse_cp }} {{ dataIn.adresse_ville }}
        </div>
      </div>
      <fx-titre titre="Adresse de facturation" />
      <div style="padding-left: 40px;padding-right: 10px">
        <fx-dropdown
          v-model="adresse_facturation_id"
          width="100%"
          :url="dataIn.url + '/liste-adresses-facturation'"
          :data-provider.sync="adresses_facturation"
        />
      </div>
      <fx-titre titre="Liste des prestations disponible" />
      <div style="padding-left: 40px;padding-right: 10px">
        <fx-list
          v-model="selectedItemCodeAppel"
          style="height: 100%;overflow: auto"
          show-refresher
          :item-renderer="itemRendererCodeAppel"
          show-search
          show-result
          :url="dataIn.url + '/creation-intervention'"
          :data-provider.sync="dataCodeAppel"
          @itemEmitting="f_charger_disponibilite"
        />
      </div>
    </div>
    <div class="cx-creation_intervention-col2">
      <fx-titre titre="Créer une nouvelle intervention" />
      <div style="display: flex;align-items: center;width: 350px">
        <fx-label
          width="140px"
          text="Durée Intervention:"
        />
        <fx-dropdown
          v-model="duree"
          width="200px"
          :url="dataIn.url + '/durees'"
          :data-provider.sync="durees"
          @on-change="f_charger_disponibilite"
        />
      </div>
      <cx-recherche-disponibilite
        ref="objDisponibilite"
        v-model="dataPlanification"
      />
      <div style="display: flex;flex-direction: row;justify-content: flex-end; padding: 10px;gap: 10px">
        <v-btn
          :loading="waitingValid"
          color="success"
          small
          @click="f_valider"
        >
          Valider
        </v-btn>
        <v-btn
          color="error"
          small
          @click="$emit('close')"
        >
          Fermer
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>

import IxCreationInterventionPrestataires from './IxCreationIntervention'
import CxRechercheDisponibilite
from '@/wikaz-konnect/gdi/gestion-clients/liste-adresses/creation-intervention/disponibilite/CxRechercheDisponibilite'
import CxFicheClient from '@/wikaz-konnect/gdi/gestion-clients/fiche-client/CxFicheClient'

export default {
    name: 'CxCreationIntervention',
    components: {
        CxFicheClient,
        CxRechercheDisponibilite,
    },
    data () {
        return {
            dataIn: null,
            dataCodeAppel: [],
            selectedItemCodeAppel: null,
            itemRendererCodeAppel: IxCreationInterventionPrestataires,
            dataPlanification: null,
            width: '1200px',
            duree: '0.00',
            durees: [],
            adresse_facturation_id: 0,
            adresse_intervention_id: 0,
            adresses_facturation: [],
            adresses_intervention: [],
            waitingValid: false,
        }
    },
    methods: {
        f_charger_disponibilite () {
            if(this.selectedItemCodeAppel) {
                this.$refs.objDisponibilite.setUrlEndPoint(this.dataIn.url + '/creation-intervention/' + this.selectedItemCodeAppel.prestation_id + '?adresse=' + this.dataIn.id + '&duree=' + this.duree)
            }
        },
        f_valider () {
            this.waitingValid = true
            if (this.dataPlanification) {
                let dataParams = {
                    date_rdv: this.dataPlanification.infos_planification.presence_date,
                    tranche_horaire_rdv: this.dataPlanification.infos_planification.th_id,
                    prestation_id: this.selectedItemCodeAppel.prestation_id,
                    duree: this.duree,
                    observation_inter: this.dataPlanification.observation,
                    observation_client: this.dataPlanification.observation_client,
                    adresse_facturation: (this.adresse_facturation_id > 0) ? this.adresse_facturation_id : this.dataIn.id,
                    adresse_intervention: this.dataIn.id,
                    cible_id: this.dataPlanification.infos_planification.utilisateur_id,
                }
                this.$wikaz.request.post(this.dataIn.url + '/creation-intervention', dataParams).then((data) => {
                    if (data.code === 'success') {
                        this.$emit('on-submit')
                    }
                    this.waitingValid = false
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Veuillez vérifier votre sélection')
                this.waitingValid = false
            }
        },
    },
}
</script>
<style scoped>
    .cx-creation_intervention-container {
        height: 800px;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 380px 1fr;
        grid-template-rows: 100%;
        overflow: auto;
    }
    .cx-creation_intervention-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
    .cx-creation_intervention-col2 {
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr auto;
        overflow: auto;
    }
</style>