<template>
  <div
    v-if="siteId"
    class="cx-editeur-site-container"
  >
    <cx-infos-site
      :key="siteId"
      :site-id="siteId"
      @selectedPage="f_select_page"
    />
    <cx-editeur-page
      :key="(_uid.toString() + siteId.toString() + selectedPageId.toString())"
      :page-id="selectedPageId"
    />
  </div>
</template>

<script>
import CxInfosSite from './CxInfosSite'
import CxEditeurPage from '@/wikaz-konnect/icsilab/editeur-de-site/CxEditeurPage'
export default {
    name: 'CxEditeurSite',
    components: {
        CxEditeurPage,
        CxInfosSite,
    },
    props: {
        siteId: {
            type: Number,
            default: null,
        },
    },
    data () {
        return { selectedPageId: - 1 }
    },
    methods: {
        f_select_page (pageId) {
            this.selectedPageId = pageId
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-editeur-site-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 350px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
        ::-webkit-scrollbar {
            width: 0 !important;
        }
    }
</style>