<template>
  <div style="padding: 5px">
    <template v-for="(item,index) in datas">
      <v-card
        :key="index"
        style="display:flex;margin-bottom: 5px;padding: 10px"
        :style="{backgroundColor: getColorBg(item)}"
      >
        <cx-item-journee
          :item="item"
          :show-interventions="true"
        />
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <fx-icon
              :size="30"
              color="primary"
              icon="menu"
              tooltip="Menu des actions"
              v-on="on"
            />
          </template>
          <v-list>
            <v-list-item @click="f_afficher_tous_les_planning(item)">
              <v-list-item-avatar>
                <v-icon color="primary">
                  event_note
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Voir tous les plannings</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>
    </template>
  </div>
</template>
<script>
import CxItemJournee from '@/wikaz-konnect/gdt/mon-planning/CxItemJournee'
export default {
    name: 'VxMonPlanningEdt',
    components: { CxItemJournee },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/mon-planning-edt/planning').then((data) => {
                this.datas = data
            })
        },
        getDateFormatted (valeur) {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, 'dddd Do MMMM YYYY')
        },
        f_afficher_tous_les_planning (item) {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'cx-all-planning-journee',
                module_libelle: 'Planning Equipe',
                config: {
                    iconLeft: 'arrow_back',
                    datas: item,
                },
            })
        },
        getColorBg (item) {
            if (item.is_tlc > 0) {
                return 'grey'
            } else if (item.is_conge > 0) {
                return 'brown'
            }
            return item.poste_color ? item.poste_color : 'lightgrey'

        },
    },
}
</script>

<style scoped>

</style>