<template>
  <div
    class="cx-ligne-agents-container"
    @contextmenu.prevent=""
  >
    <div
      class="cx-ligne-agents-cartouche"
      :style="{width: largeurGauche + 'px'}"
      @contextmenu.prevent=""
    >
      <v-avatar
        style="margin-left: 5px;margin-right: 5px"
        size="48"
      >
        <v-tooltip
          right
          class="v-tooltip-css"
          :open-delay="300"
        >
          <template v-slot:activator="{ on }">
            <v-img
              :src="'data:image/jpeg;base64,' + $wikaz.getInstance().getPhotoUtilisateur(item.wcu_utilisateur_id)"
              v-on="on"
            />
          </template>
          <cx-mini-fiche-technicien :item="item" />
        </v-tooltip>
      </v-avatar>
      <div style="height:50%;width: 100%;display: flex;align-items: center">
        {{ item.label }}
      </div>
      <div style="height:50%;width: 100%;display: flex;align-items: center;justify-content: flex-end">
        <cx-menu-agent
          :item="item"
          :datas="datas"
          @refresh="f_update"
        />
      </div>
    </div>
    <template v-for="(element,indexJour) in item.calendrier">
      <v-menu
        :key="indexJour"
        bottom
        origin="center center"
        :offset-x="true"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <div
            v-if="element.poste > 0"
            class="cx-ligne-agents-case"
            :class="(element.pointage_maladie === 1 ? 'isMaladie' : '') + ' ' + (element.pointage_astreinte === 1 ? 'isAstreinte' : '')"
            :style="{width: 'calc((100% - '+largeurGauche+'px)/'+ item.calendrier.length+')',backgroundColor: element.color}"
            v-on="on"
            @contextmenu.prevent="on.click"
          >
            <div style="font-size: 10px">
              {{ element.poste_libelle.toString().substr(0,10) }}
            </div>
            <div
              v-if="element.heure_debut"
              :style="{color: element.debut_different ? 'red' : 'black',fontWeight: element.debut_different ? 'bold' : 'normal'}"
            >
              {{ element.heure_debut }}
            </div>
            <div
              v-if="element.heure_fin"
              :style="{color: element.fin_different ? 'red' : 'black',fontWeight: element.fin_different ? 'bold' : 'normal'}"
            >
              {{ element.heure_fin }}
            </div>
          </div>
          <div
            v-else-if="element.poste < 0"
            class="cx-ligne-agents-case"
            :class="(element.pointage_maladie === 1 ? 'isMaladie' : '') + ' ' + (element.pointage_astreinte === 1 ? 'isAstreinte' : '')"
            :style="{width: 'calc((100% - '+largeurGauche+'px)/'+ item.calendrier.length+')',backgroundColor: 'brown'}"
            style="color: white"
            v-on="on"
            @contextmenu.prevent="on.click"
          >
            Congé
          </div>
          <div
            v-else-if="element.pointage_tlc > 0"
            class="cx-ligne-agents-case"
            :class="(element.pointage_maladie === 1 ? 'isMaladie' : '') + ' ' + (element.pointage_astreinte === 1 ? 'isAstreinte' : '')"
            :style="{width: 'calc((100% - '+largeurGauche+'px)/'+ item.calendrier.length+')',backgroundColor: 'grey'}"
            v-on="on"
            @contextmenu.prevent="on.click"
          >
            TLC
          </div>
          <div
            v-else
            class="cx-ligne-agents-case"
            :class="(element.pointage_maladie === 1 ? 'isMaladie' : '') + ' ' + (element.pointage_astreinte === 1 ? 'isAstreinte' : '')"
            :style="{width: 'calc((100% - '+largeurGauche+'px)/'+ item.calendrier.length+')',backgroundColor: '#F0F0F0'}"
            style="color: #C8C8C8;"
            v-on="on"
            @contextmenu.prevent="on.click"
          >
            Repos
            Hebdo
          </div>
        </template>
        <v-list ref="objLst">
          <v-list-item
            v-if="element.poste > 0"
            @click="f_edit_evenement(element)"
          >
            <v-list-item-avatar>
              <v-icon color="primary">
                create
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Editer un poste</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.poste > 0"
            @click="f_reinitialiser(element)"
          >
            <v-list-item-avatar>
              <v-icon color="red">
                delete
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Supprimer le poste</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.poste === 0"
            @click="f_edit_evenement(element)"
          >
            <v-list-item-avatar>
              <v-icon color="green">
                add
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ajouter un poste</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.poste === 0"
            @click="f_ajouter_conge(element)"
          >
            <v-list-item-avatar>
              <v-icon color="green">
                add
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Ajouter un congé</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.poste < 0"
            @click="f_modifier_conge(element)"
          >
            <v-list-item-avatar>
              <v-icon color="primary">
                create
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Modifier le congé</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.poste < 0"
            @click="f_annuler_conge(element)"
          >
            <v-list-item-avatar>
              <v-icon color="red">
                delete
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Annuler le congé</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_maladie > 0 && element.poste >= 0"
            @click="f_retirer_maladie(element)"
          >
            <v-list-item-avatar>
              <v-icon color="green">
                sentiment_satisfied_alt
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Retirer la maladie</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_maladie === 0 && element.poste >= 0"
            @click="f_ajouter_maladie(element)"
          >
            <v-list-item-avatar>
              <v-icon color="red">
                sick
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Mettre en maladie</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_astreinte > 0 && element.poste >= 0"
            @click="f_retirer_astreinte(element)"
          >
            <v-list-item-avatar>
              <v-icon color="red">
                ring_volume
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Retirer l'astreinte</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_astreinte === 0 && element.poste >= 0"
            @click="f_ajouter_astreinte(element)"
          >
            <v-list-item-avatar>
              <v-icon color="green">
                ring_volume
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Mettre en astreinte</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_tlc > 0 && element.poste === 0"
            @click="f_retirer_tlc(element)"
          >
            <v-list-item-avatar>
              <v-icon color="red">
                access_time
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Retirer le temps libre choisi</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="element.pointage_tlc === 0 && element.poste === 0"
            @click="f_ajouter_tlc(element)"
          >
            <v-list-item-avatar>
              <v-icon color="green">
                access_time
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Mettre en temps libre choisi</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-dialog
      v-if="selectedEvenement"
      v-model="showEdit"
      width="500px"
    >
      <cx-edit-pointage
        :key="indexKey"
        v-model="showEdit"
        :datas="selectedEvenement"
        :data-postes="dataPostes"
        @update="f_update"
      />
    </v-dialog>
  </div>
</template>
<script>
import CxEditPointage from './CxEditPointage'
import CxMenuAgent from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxMenuAgent'
import CxMiniFicheTechnicien from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxMiniFicheTechnicien'
export default {
    name: 'CxLigneAgents',
    components: {
        CxMiniFicheTechnicien,
        CxMenuAgent,
        CxEditPointage,
    },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        dataPostes: { default: null },
        item: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return {
            showEdit: false,
            selectedEvenement: null,
            indexKey: 123456789,
        }
    },
    methods: {
        f_edit_evenement (item) {
            this.indexKey ++
            this.selectedEvenement = item
            this.showEdit = true
        },
        f_update () {
            this.$emit('update')
        },
        f_annuler_conge (item) {
            const param = item.dadate + '|' + item.utilisateur_id
            this.$wikaz.request.delete('api/edt/modules/planning-edt/conges/' + param).then(() => {
                this.f_update()
            })
        },
        f_ajouter_conge (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/planning-edt/conges/create?date=' + item.dadate + '&utilisateur=' + item.utilisateur_id,
                this.f_resultat_ajouter_conge,
            )
        },
        f_resultat_ajouter_conge (data) {
            this.$wikaz.request.post('api/edt/modules/planning-edt/conges', data).then(() => {
                this.f_update()
            })
        },
        f_modifier_conge (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/planning-edt/conges/0/edit?date=' + item.dadate + '&utilisateur=' + item.utilisateur_id,
                this.f_resultat_modifier_conge,
            )
        },
        f_resultat_modifier_conge (data) {
            this.$wikaz.request.put('api/edt/modules/planning-edt/conges/0', data).then(() => {
                this.f_update()
            })
        },
        f_reinitialiser (item) {
            this.$wikaz.request.delete('api/edt/modules/planning-edt/planning/' + item.dadate + '/pointages/' + item.pointage_id).then(() => {
                this.f_update()
            })
        },
        f_ajouter_maladie (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/planning-edt/planning/' + item.dadate + '/maladies/' + item.utilisateur_id + '/edit',
                this.f_resultat_ajouter_maladie,
            )
        },
        f_resultat_ajouter_maladie (item) {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + item.date_debut + '/maladies/' + item.utilisateur, item).then(() => {
                this.f_update()
            })
        },
        f_retirer_maladie (item) {
            this.$wikaz.request.delete('api/edt/modules/planning-edt/planning/' + item.dadate + '/maladies/' + item.utilisateur_id).then(() => {
                this.f_update()
            })
        },
        f_ajouter_astreinte (item) {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + item.dadate + '/astreintes/' + item.utilisateur_id).then(() => {
                this.f_update()
            })
        },
        f_retirer_astreinte (item) {
            this.$wikaz.request.delete('api/edt/modules/planning-edt/planning/' + item.dadate + '/astreintes/' + item.utilisateur_id).then(() => {
                this.f_update()
            })
        },
        f_ajouter_tlc (item) {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + item.dadate + '/tlc/' + item.utilisateur_id).then(() => {
                this.f_update()
            })
        },
        f_retirer_tlc (item) {
            this.$wikaz.request.delete('api/edt/modules/planning-edt/planning/' + item.dadate + '/tlc/' + item.utilisateur_id).then(() => {
                this.f_update()
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-agents-container {
        width: 100%;
        height: 60px;
        display: flex;
        box-sizing: border-box;
        // border-top: 1px solid black;
        //border-left: 1px solid black;
        //border-right: 1px solid black;
        border-bottom: 1px solid black;
        background-color: #ffffff;
    }

    .cx-ligne-agents-cartouche {
        display: flex;
        align-items: center;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-agents-case {
        cursor: pointer;
        border-left: 1px solid black;
        // border-top: 1px solid black;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.isMaladie {
            border: 4px solid red;
            // text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        }

        &.isAstreinte {
            border: 4px solid #2726E9 !important;
        }

        &.isTlc {
            border: 4px solid #4d7ae0 !important;
        }
    }

    .fx-maladie {
        position: absolute;
        z-index: 1 !important;
    }
</style>