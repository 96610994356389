<template>
  <fx-datagrid-vuetify
    ref="objDatagrid"
    :url-ressource-end-point="urlRessourceEndPoint"
    @ajouter="f_ajouter"
    @edition="f_editer"
    @suppression="f_suppression"
    @affectation_utilisateur="f_affectation_utilisateur"
  />
</template>
<script>

import CxAffectationUtilisateursPrestations
from '@/wikaz-konnect/gdt/parametrages/gestion-prestations/CxAffectationUtilisateursPrestations.vue'

export default {
    name: 'VxGestionPrestations',
    data () {
        return { urlRessourceEndPoint: 'api/edt/modules/gestion-prestations/prestations' }
    },
    methods: {
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                this.f_result_ajouter,
            )
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_editer (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.prestation_id + '/edit',
                this.f_result_editer,
            )
        },
        f_result_editer (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + data.prestation_id, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_suppression (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.prestation_id).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_affectation_utilisateur (item) {
            this.$wikaz.popups.show(
                CxAffectationUtilisateursPrestations,
                {
                    datas:
                    {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.prestation_id + '/affectations-utilisateurs',
                        isEditable: true,
                        isPopup: true,
                    },
                },
                null,
                () => {
                    this.$refs.objDatagrid.chargerData()
                },
            )
        },
    },
}
</script>
<style scoped>
</style>