import { render, staticRenderFns } from "./FxFiles.vue?vue&type=template&id=2ea7ca3a&scoped=true&"
import script from "./FxFiles.vue?vue&type=script&lang=js&"
export * from "./FxFiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ea7ca3a",
  null
  
)

export default component.exports