<template>
  <div class="cx-fiche-client-container">
    <fx-titre titre="Fiche Client" />
    <div
      v-if="datas"
      class="cx-formulaire-container"
    >
      <div class="ligne-formulaire">
        <fx-champ
          label="Client"
          :text="datas.client_id.toString()"
        />
        <fx-champ
          label="Créé le"
          :text="$wikaz.outils.dates.getDate(datas.client_date_creation)"
        />
      </div>
      <fx-champ
        label="Nom"
        :text="(datas.client_civilite ? datas.client_civilite + ' ' : '') + datas.client_nom + ' ' + (datas.client_prenom ? datas.client_prenom : '')"
      />
      <fx-champ
        label="Tel."
        :text="datas.client_telephone"
      >
        <fx-icon
          v-if="datas.client_telephone"
          color="primary"
          icon="phone"
          tooltip="Appeler"
          @click="$wikaz.api.navigateur.tel(datas.client_telephone)"
        />
      </fx-champ>
      <fx-champ
        label="Adresse"
        :text="datas.client_adresse"
      />
      <fx-champ
        label="Complément"
        :text="datas.client_adresse_complement"
      />
    </div>
    <cx-waiting
      v-else
      width="100%"
      height="100%"
    />
  </div>
</template>
<script>
export default {
    name: 'CxFicheClient',
    props: {
        urlEndPoint: {
            required: true,
            type: String,
        },
    },
    data () {
        return {
            url: this.urlEndPoint,
            datas: null,
        }
    },
    mounted () {
        this.getFiche()
    },
    methods: {
        getFiche () {
            if (this.url) {
                this.datas = null
                this.$wikaz.request.get(this.url).then((data) => {
                    this.datas = data
                })
            }
        },
        date (date) {
            return this.$wikaz.outils.dates.getDate(date, 'DD/MM/YYYY')
        },
        f_appeler (valeur) {
            window.location = 'tel:' + valeur
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-fiche-client-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
        font-size: 12px;
        // background-color: #EEEEEE;
    }

    .cx-formulaire-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 6px;
    }

    .ligne-formulaire {
        display: flex;
        align-items: center;
    }
</style>