import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr'

const opts = {
    theme: {
        dark: false,
        themes: {
            dark: {
                background: '#424242',
                titleBackGroundColor: '#616161',
                titleColor: '#FFFFFF',
                backgroundComposant: '#1e1e1e',
            },
            light: {
                background: '#EEEEEE',
                titleBackGroundColor: '#BDBDBD',
                titleColor: '#000000',
                backgroundComposant: '#FFFFFF',
            },
        },
    },
    icons: { iconfont: 'md' },
    lang: {
        locales: { fr },
        current: 'fr',
    },
}
export default new Vuetify(opts)