<template>
  <fx-list
    title="Projet d'accompagnement"
    show-search
    show-result
    show-refresher
    show-add
  />
</template>

<script>
export default { name: 'CxProjetsAccompagnements' }
</script>

<style scoped>

</style>