<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-content>
      <v-list-item-title v-html="item.compte_raison_sociale" />
      <v-list-item-subtitle v-html="'N° de compte : ' + item.compte_id" />
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxCreationDemande',
    extends: FxItemTemplate,
}
</script>