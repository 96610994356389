<template>
  <div
    v-if="dataIn"
    class="cx-traitement-client-container"
  >
    <div class="cx-traitement-client-col1">
      <cx-fiche-client :url-end-point="'api/edt/modules/gestion-clients-sap/clients/' + dataIn.client_id" />
      <cx-fiche-client-outils
        url-ressource-end-point="api/edt/modules/gestion-clients-sap/clients/"
        :client-id="dataIn.client_id"
        @actualiser="f_actualiser"
      />
    </div>
    <div class="cx-traitement-client-col2">
      <fx-list
        :url="'api/edt/modules/gestion-clients-sap/clients/' + dataIn.client_id + '/rdv'"
        :item-renderer="itemRendererRdv"
      />
    </div>
    <div class="cx-traitement-client-col3" />
  </div>
</template>

<script>

import CxFicheClient from '@/wikaz-konnect/gdt/gestion-clients-sap/CxFicheClient.vue'
import CxFicheClientOutils from '@/wikaz-konnect/gdt/gestion-clients-sap/CxFicheClientOutils.vue'
import IxRdv from '@/wikaz-konnect/gdt/gestion-clients-sap/IxRdv.vue'

export default {
    name: 'CxTraitementGestionClients',
    components: {
        CxFicheClientOutils,
        CxFicheClient,
    },
    props: {
        dataIn: {
            type: Object,
            default: null,
        },
    },
  data() {
    return {
      itemRendererRdv: IxRdv,
    }
  },
    methods: {
        f_actualiser () {
            this.$emit('on-refresh')
        },
    },
}
</script>

<style scoped lang="scss">
.cx-traitement-client-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 380px 380px 380px;
  grid-template-rows: 100%;
  grid-column-gap: 5px;
  padding-left: 5px;
  overflow: auto;
}

.cx-traitement-client-col1 {
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 240px auto 1fr;
  overflow: auto;
}

.cx-traitement-client-col2 {
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: auto;
}

.cx-traitement-client-col3 {
  box-sizing: border-box;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 52px 4fr;
  overflow: auto;
}
</style>