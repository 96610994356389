import Vue from 'vue'
let importation = function () {
    this.element = function (name, component) {
        Vue.component(name, component)
    },
    this.elementByComponent = function (component) {
        Vue.component(component.name, component)
        return component.name
    }
}
export default importation