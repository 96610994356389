<template>
  <v-list-item
    dense
    style="overflow: visible;height: 50px"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon v-if="item.etat_utilisateur === 1">
        person
      </v-icon>
      <v-icon v-else>
        remove_circle
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ item.nom }}&nbsp;{{ item.prenom }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxUtilisateur',
    extends: FxItemTemplate,
    props: ['item', 'route'],
    mounted () {
    },
    methods: {
        emitClick () {
            this.$emit('itemEmitting', this.item.id)
        },
    },
}

</script>