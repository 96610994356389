<template>
  <v-list-item
    v-if="item.type === 1"
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon size="30">
        today
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="date(item.intervention_date_planifie) + ' ' + item.th_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Intervention n° : ' + item.intervention_id"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Technicien : ' + item.technicien"
      />
      <v-list-item-subtitle
        v-if="item.intervention_reference > 0"
        class="caption"
        v-html="'Suite : ' + item.intervention_reference"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Prestation : ' + item.prestation_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Finalisation : ' + (item.libelle_cloture ? item.libelle_cloture : 'En attente')"
      />
    </v-list-item-content>
    <!--        <v-list-item-avatar>
            <v-icon v-if="item.intervention_aquisition_demande_id === 0" :color="getColor(item.intervention_cloture)">home</v-icon>
            <v-icon v-else :color="getColor(item.intervention_cloture)">device_hub</v-icon>
        </v-list-item-avatar>-->
    <v-menu
      v-if="this.$attrs.selected"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item v-if="this.$attrs.selected">
          <v-list-item-avatar>
            <v-icon color="primary">
              print
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Imprimer la fiche</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
  <v-list-item
    v-else
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon>reorder</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="date(item.demande_date)"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Demande n° : ' + item.demande_id.toString().padStart(7,'0')"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Origine : ' + item.demande_origine"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Prestation : ' + item.prestation"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Etat : ' + item.etat_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="'Finalisation : ' + (item.libelle_cloture ? item.libelle_cloture : 'En attente')"
      />
    </v-list-item-content>
    <!--<v-list-item-avatar>
            <v-icon v-if="item.intervention_aquisition_demande_id === 0" :color="getColor(item.intervention_cloture)">home</v-icon>
            <v-icon v-else :color="getColor(item.intervention_cloture)">device_hub</v-icon>
        </v-list-item-avatar>-->
    <v-menu
      v-if="this.$attrs.selected && (item.client_telephone !== null)"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item @click="f_dupliquer(item.demande_id)">
          <v-list-item-avatar>
            <v-icon color="primary">
              content_copy
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Dupliquer la demande</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="this.$attrs.selected && item.client_telephone"
          @click="f_telephone(item.adresse_telephone1)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.client_telephone }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxListeInterventions',
    extends: FxItemTemplate,
    methods: {
        date (date) {
            return date ? this.$wikaz.outils.dates.getDate(date, 'DD/MM/YYYY') : ''
        },
        afficherCRI () {
            let config = {
                responseType: 'arraybuffer',
                headers: { 'Content-Type': 'application/json' },
            }
            this.$wikaz.request.get('api/wikaz/interventions/' + this.item.ininter + '/cri', config).then((response) => {
                let blob = new Blob([response], { type: 'application/pdf' })
                let url = window.URL.createObjectURL(blob)
                window.open(url)
            })
        },
        getColor (valeur) {
            if (valeur) {
                return 'green'
            }
            return 'grey'
        },
        f_dupliquer (demandeId) {
            this.$wikaz.popups.showFormulaire(
                'api/entreprises/modules/gestion-clients-entreprises/clients/' + this.item.demande_client_id + '/demandes/' + demandeId + '/dupliquer/create',
                (data) => {
                    this.$wikaz.request.post('api/entreprises/modules/gestion-clients-entreprises/clients/' + this.item.demande_client_id + '/demandes/' + demandeId + '/dupliquer', data).then(() => {
                        this.f_emit_actualiser()
                    })
                })
        },
    },
}
</script>