<template>
  <div class="cx-filtre-gestion-crm-container">
    <div>
      <fx-checkbox
        v-model="selectedFiltreActif"
        label="Filtrer les résidents actifs"
        @on-change="filterChanged"
      />
    </div>
  </div>
</template>
<script>

import FxFiltreTemplate from '@/wikaz-core/ui/composants/base/FxFiltreTemplate'

export default {
    name: 'CxFiltreResidents',
    extends: FxFiltreTemplate,
    data () {
        return { selectedFiltreActif: 0 }
    },
    methods: {
        getFiltreData () {
            return '?actifs=' + this.selectedFiltreActif
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-filtre-gestion-crm-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
        overflow: auto;
    }

    .cx-filtre-gestion-crm-ligne {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-template-rows: 1fr;
    }
</style>