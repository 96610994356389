<template>
  <div
    v-if="dataProvider"
    :style="{width: width,height: height}"
    style="display:flex;flex-direction: column;background-color: white;border-radius: 15px;padding: 10px;gap: 10px"
  >
    <div style="display: flex;height: 390px">
      <cx-fiche-client
        style="width: 50%"
        :url-end-point="dataProvider.url_fiche_client"
      />
      <cx-fiche-inter
        style="width: 50%;height: 100%"
        :url-end-point="dataProvider.url_fiche_inter"
      />
    </div>
    <div style="display: flex; justify-content: flex-end;gap: 10px">
      <v-btn
        color="success"
        small
        @click="f_reporter()"
      >
        Reporter
      </v-btn>
      <v-btn
        color="error"
        small
        @click="f_annuler()"
      >
        Annuler
      </v-btn>
      <v-btn
        color="warning"
        small
        @click="f_fermer()"
      >
        Fermer
      </v-btn>
    </div>
  </div>
  <cx-waiting v-else />
</template>

<script>

import CxFicheClient from '@/wikaz-konnect/gdi/gestion-clients/fiche-client/CxFicheClient'
import CxFicheInter from '@/wikaz-konnect/gdi/gestion-clients/fiche-inter/CxFicheInter'
import CxPopupReporterIntervention
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupReporterIntervention'
export default {
    name: 'CxDetailRdv',
    components: {
        CxFicheInter,
        CxFicheClient,
    },
    data () {
        return {
            dataProvider: null,
            urlDetail: null,
            width: '800px',
            height: '450px',
        }
    },
    methods: {
        f_fermer () {
            this.$emit('close')
        },
        f_reporter () {
            this.$wikaz.popups.show(
                CxPopupReporterIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.dataProvider.url_report,
                        titre: 'Reporter l\'interventions',
                        width: '900px',
                        height: '800px',
                    },
                },
                this.f_result_reporter)
        },
        f_result_reporter () {
            this.$emit('on-submit')
        },
        f_annuler () {
            this.$wikaz.outils.notification.showConfirmation(
                'Annulation de l\'intervention',
                'Etes-vous certain de vouloir annuler l\'intervention ?',
                () => {
                    this.$wikaz.request.post(this.dataProvider.url_annulation).then(this.f_result_annuler)
                }, '350px',
            )
        },
        f_result_annuler () {
            this.$emit('on-submit')
        },
    },
}
</script>

<style scoped>

</style>