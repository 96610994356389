<template>
  <div class="vx-gestion-interventions-prestataires-pwa-container">
    <template v-for="(item,name,index) in datas">
      <v-list
        :key="index"
        two-line
        subheader
        color="#EEEEEE"
      >
        <div style="padding: 10px;background-color: #0d5aa7;color: white;height: 30px;display: flex;align-items: center">
          {{ $wikaz.outils.dates.getDate(name,'dddd D MMMM') }} {{ ($wikaz.outils.dates.getTodayBdd() === name) ? ' ( Aujourd\'hui )' : '' }}
        </div>
        <template v-for="itemIntervention in item">
          <v-card
            :key="itemIntervention.intervention_id"
            style="margin: 10px"
          >
            <v-list-item three-line>
              <v-list-item-avatar
                tile
                size="80"
              >
                <v-icon
                  v-if="itemIntervention.intervention_aquisition_demande_id === 0"
                  :color="itemIntervention.etapes_en_cours === 3 ? 'green' : itemIntervention.etapes_en_cours === 0 ? 'orange' : 'blue'"
                >
                  home
                </v-icon>
                <v-icon
                  v-else
                  :color="itemIntervention.etapes_en_cours === 3 ? 'green' : itemIntervention.etapes_en_cours === 0 ? 'orange' : 'blue'"
                >
                  device_hub
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="itemIntervention.client_civilite + ' ' + itemIntervention.client_nom + ' ' + itemIntervention.client_prenom" />
                <v-list-item-subtitle v-text="itemIntervention.prestation_libelle" />
                <v-list-item-subtitle v-text="'Horaire : ' + itemIntervention.th_libelle" />
                <v-list-item-subtitle v-text="itemIntervention.adresse_cp + ' ' + itemIntervention.adresse_ville" />
              </v-list-item-content>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <fx-icon
                    :size="30"
                    color="primary"
                    icon="menu"
                    tooltip="Menu des actions"
                    v-on="on"
                  />
                </template>
                <v-list>
                  <v-list-item @click="f_open(itemIntervention)">
                    <v-list-item-avatar>
                      <v-icon color="green">
                        launch
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Ouvrir</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="itemIntervention.client_telephone"
                    @click="$wikaz.api.navigateur.tel(itemIntervention.client_telephone)"
                  >
                    <v-list-item-avatar>
                      <v-icon color="primary">
                        phone
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Appeler : {{ itemIntervention.client_telephone }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="itemIntervention.client_email"
                    @click="$wikaz.api.navigateur.mailto(itemIntervention.client_email)"
                  >
                    <v-list-item-avatar>
                      <v-icon color="primary">
                        mail
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Ecrire : {{ itemIntervention.client_email }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$wikaz.api.navigateur.geo('//?q=' + itemIntervention.adresse_adresse1+ ', '+itemIntervention.adresse_cp + ' ' + itemIntervention.adresse_ville)">
                    <v-list-item-avatar>
                      <v-icon color="primary">
                        map
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>Ouvrir le GPS</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item>
          </v-card>
        </template>
      </v-list>
    </template>
  </div>
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementActivite from '@/wikaz-konnect/gdi/mon-activite/CxTraitementActivite'

export default {
    name: 'VxMonActivite',
    extends: VxBase,
    data () {
        return {
            datas: null,
            indexCalendrier: 0,
            busy: false,
        }
    },
    mounted () {
        this.chargerDatas()
    },
    methods: {
        chargerDatas () {
            this.busy = true
            this.$wikaz.request.get('api/entreprises/modules/mon-activite/planning').then((data) => {
                this.datas = data
                this.busy = false
            })
        },
        f_open (item) {
            this.$store.commit('SET_VIEW_PWA', {
                component: this.$wikaz.outils.importation.elementByComponent(CxTraitementActivite),
                config: { datas: item },
            })
        },
        f_retour () {
            // this.$store.commit('tabPrecedente')
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-gestion-interventions-prestataires-pwa-container {
        width: 100%;
        height: 100%;
        padding: 5px;
    }
</style>