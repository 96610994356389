<template>
  <div class="cx-traitement-utilisateur-container">
    <div style="display: flex; flex-direction: column;row-gap: 10px">
      <fx-formulaire-generique
        style="height: 565px"
        :url-ressource="urlRessourceEndPoint + '/' + dataIn.id + '/edit'"
        @on-submit="f_update_utilisateur"
      />
      <v-btn
        color="primary"
        @click="f_gerer_droits"
      >
        Gestion des droits
      </v-btn>
    </div>
    <template v-for="(item,index) in dataIn.profils">
      <div
        v-if="item.affect_profil_profil_id === 60"
        :key="index"
        class="cx-traitement-utilisateur-container-col2"
      >
        <fx-formulaire-generique
          :url-ressource="urlRessourceEndPoint + '/' + dataIn.id + '/proprietes/0/edit'"
          @on-submit="f_update_proprietes"
        />
        <cx-affectation-competances
          :url-ressource-end-point="urlRessourceEndPoint + '/' + dataIn.id + '/metiers'"
          @on-refresh="f_refresh"
        />
      </div>
      <div
        v-if="item.affect_profil_profil_id === 30"
        :key="index"
        class="cx-traitement-utilisateur-container-col2"
      />
      <cx-configuration-profil-technicien
        v-if="item.affect_profil_profil_id === 31"
        :key="index"
        :url-ressource-end-point="urlRessourceEndPoint + '/' + dataIn.id"
      />
    </template>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxAffectationCompetances
from '@/wikaz-konnect/commun/gestion-utilisateurs/CxAffectationCompetances'
import CxConfigurationProfilTechnicien
from '@/wikaz-konnect/commun/gestion-utilisateurs/CxConfigurationProfilTechnicien'
export default {
    name: 'CxTraitementUtilisateurs',
    components: {
        CxConfigurationProfilTechnicien,
        CxAffectationCompetances,
    },
    extends: CxBase,
    props: {
        dataIn: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            dataProfil: null,
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-utilisateurs-wikaz/utilisateurs',
        }
    },
    methods: {
        f_update_utilisateur (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + this.dataIn.id, data).then(() => {
                this.$emit('on-refresh')
            })
        },
        f_refresh () {
            this.$emit('on-refresh')
        },
        f_supprimer_lien () {
            this.$wikaz.outils.notification.showConfirmation(
                'Suppression utilisateur',
                'Etes-vous certain de vouloir supprimer cet utilisateur ?',
                () => {
                    this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + this.dataIn.id).then(() => {
                        this.$emit('on-refresh')
                    })
                },
            )
        },
        f_gerer_droits () {
            this.$wikaz.popups.showTransfertList(
                'Affectations droits',
                this.urlRessourceEndPoint + '/' + this.dataIn.id + '/affectation-droits',
                this.urlRessourceEndPoint + '/' + this.dataIn.id + '/affectation-droits/0',
                () => {
                    this.f_refresh()
                },
                () => {
                    this.f_refresh()
                },
            )
        },
        f_update_proprietes (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + this.dataIn.id + '/proprietes/0', data).then(() => {
                this.$emit('on-refresh')
            })
        },
    },
}
</script>
<style scoped>
    .cx-traitement-utilisateur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 450px 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-utilisateur-container-col2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 5px;
    }
</style>