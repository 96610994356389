<template>
  <div
    class="alterStyleRow"
    style="display: flex;padding: 5px;align-items: center;justify-content: space-between"
    @click="f_emit_selection"
  >
    <v-chip
      style="width: 50px;margin-right: 10px;display: flex;justify-content: center"
      :color="item.en_cours === 1 ? 'green' : 'red'"
    >
      {{ item.logement_numero }}
    </v-chip>
    <div style="width: 300px;display: flex;flex-direction: column;gap: 3px">
      <div>{{ item.logement_libelle }}{{ item.logement_libelle ? ' - ' : ' ' }}{{ item.logements_type_libelle }} - {{ item.libelle_etage }}</div>
      <v-divider />
      <div style="display: flex;gap: 5px;align-items: flex-start">
        <fx-icon
          color="primary"
          icon="meeting_room"
          tooltip="Arrivée dans le logement"
        >
          login
        </fx-icon>
        <fx-label
          style="margin-top: 3px"
          width="80px"
          :text="$wikaz.outils.dates.getDate(item.affect_date_debut)"
        />
        <div style="font-size: 12px;margin-top: 3px">
          {{ item.affect_motif_debut }}
        </div>
      </div>
      <div
        v-if="item.affect_date_fin"
        style="display: flex;gap: 5px;align-items: flex-start"
      >
        <fx-icon
          color="primary"
          icon="no_meeting_room"
          tooltip="Départ du logement"
        >
          logout
        </fx-icon>
        <fx-label
          style="margin-top: 3px"
          width="80px"
          :text="$wikaz.outils.dates.getDate(item.affect_date_fin)"
        />
        <div style="font-size: 12px;margin-top: 3px">
          {{ item.affect_motif_fin }}
        </div>
      </div>
      <!--                <div v-else style="display: flex;gap: 5px;align-items: flex-start">
                    <fx-icon color="primary" icon="no_meeting_room" tooltip="Départ du le logement">logout</fx-icon>
                    <fx-label style="margin-top: 3px" width="80px" text="Occupé"></fx-label>
                </div>-->
    </div>
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          v-if="item.en_cours === 1 && !item.affect_date_fin"
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
        <div
          v-else
          style="width: 30px"
        />
      </template>
      <v-list>
        <v-list-item @click="f_set_date_fin">
          <v-list-item-avatar>
            <v-icon color="primary">
              logout
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Renseigner une date de fin</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
<!--    <v-list-item @click="f_emit_selection">
        <v-chip style="width: 50px;margin-right: 10px;display: flex;justify-content: center" :color="item.en_cours === 1 ? 'green' : 'red'">{{item.logement_numero}}</v-chip>
        <v-list-item-content>
            <v-list-item-title v-if="item.logement_libelle" class="caption" style="font-weight: bold" v-html="item.logement_libelle"></v-list-item-title>
            <v-list-item-subtitle class="caption" style="font-weight: bold" v-html="item.logements_type_libelle"></v-list-item-subtitle>
            <div style="display: flex;width: 220px">
                <fx-icon :size="25" icon="today" tooltip="Période "></fx-icon>
                <v-list-item-title class="caption" style="font-weight: bold" v-html="$wikaz.outils.dates.getDate(item.affect_date_debut)"></v-list-item-title>
                <v-list-item-title class="caption" style="font-weight: bold" v-html="item.affect_date_fin ? $wikaz.outils.dates.getDate(item.affect_date_fin) : ''"></v-list-item-title>
            </div>
        </v-list-item-content>
        <v-list-item-content>
            <div style="display: flex">
                <fx-icon :size="20" icon="stairs" tooltip="Etage"></fx-icon>
                <v-list-item-title class="caption" style="font-weight: bold;font-size: 9px" v-html="item.libelle_etage"></v-list-item-title>
            </div>
        </v-list-item-content>
        <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on }">
                <fx-icon v-if="item.en_cours === 1" v-on="on" :size="30" color="primary" icon="menu" tooltip="Menu des actions"></fx-icon>
                <div v-else style="width: 30px"></div>
            </template>
            <v-list>
                <v-list-item @click="f_set_date_fin">
                    <v-list-item-avatar><v-icon color="primary">logout</v-icon></v-list-item-avatar>
                    <v-list-item-title>Renseigner une date de fin</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-list-item>-->
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxAppartement',
    extends: FxItemTemplate,
    methods: {
        f_set_date_fin () {
            this.f_action_custom('arret', this.item)
        },
        get_type (item) {
            if(item.contact_detail) {
                return item.contacts_type_libelle + ' ( ' + item.contact_detail + ' )'
            }
            return item.contacts_type_libelle

        },
    },
}
</script>
<style scoped lang="scss">

    .alterStyleRow:nth-child(even) {
        background-color: #f5f3f3
    }
    .alterStyleRow:nth-child(odd) {
        background-color: #ffffff
    }

</style>