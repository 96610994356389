import { render, staticRenderFns } from "./CxTraitementGestionLogementPwa.vue?vue&type=template&id=afc1ef56&scoped=true&"
import script from "./CxTraitementGestionLogementPwa.vue?vue&type=script&lang=js&"
export * from "./CxTraitementGestionLogementPwa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afc1ef56",
  null
  
)

export default component.exports