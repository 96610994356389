<template>
  <v-card style="padding: 10px">
    <v-card-subtitle class="title">
      Contact facturation
    </v-card-subtitle>
    <v-card-text style="display: flex;align-items: center">
      Les nouvelles factures seront envoyées à cet email. Vous pouvez renseigner plusieurs emails séparés par des points virgules.
    </v-card-text>
    <v-card-text style="display: flex;align-items: center">
      <v-text-field
        v-model="email"
        prepend-icon="email"
        dense
        hide-details
        outlined
      />
    </v-card-text>
    <v-card-actions style="display: flex;justify-content: flex-end">
      <v-btn
        color="success"
        small
        @click="f_update(email)"
      >
        Mettre à jour l'email
      </v-btn>
      <v-btn
        color="error"
        small
        @click="f_update('')"
      >
        Effacer l'email
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'CxContactFacturation',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { email: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.email = data.compte_fact_email
            })
        },
        f_update (email) {
            this.email = email
            this.$wikaz.request.post(this.urlRessourceEndPoint, { email: email }).then(() => {
                this.$wikaz.outils.notification.showInfo('Adresse email mise à jour')
            })
        },
    },
}
</script>

<style scoped>

</style>