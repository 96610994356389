<template>
  <div style="padding: 5px">
    <template v-for="(item,index) in datas">
      <v-card
        :key="index"
        style="display:flex;flex-direction: column;margin-bottom: 10px;padding: 10px"
        :style="{backgroundColor: getBackgroudColor(item.bde_etat)}"
      >
        <v-card-title>
          <v-icon
            v-if="item.bde_etat === 0"
            color="grey"
          >
            hourglass_empty
          </v-icon>
          <v-icon
            v-if="item.bde_etat === 1"
            color="grey"
          >
            hourglass_empty
          </v-icon>
          <v-icon
            v-if="item.bde_etat === 2"
            color="green"
          >
            check
          </v-icon>
          <v-icon
            v-if="item.bde_etat === 9"
            color="green"
          >
            check
          </v-icon>
          {{ getDateFormatted(item.pointage_date_origine) }}
        </v-card-title>
        <v-card-subtitle v-if="item.bde_direction === 1">
          Vous avez fait une proposition d'échange avec {{ item.utilisateur_cible }}
        </v-card-subtitle>
        <v-card-subtitle v-else>
          {{ item.utilisateur_origine }} vous propose un echange
        </v-card-subtitle>
        <div style="width: 100%">
          <div style="display: flex">
            <div style="width: 50%;display: flex;flex-direction:column;justify-content: center;align-items: center">
              <div
                v-if="item.bde_direction === 1"
                style="font-weight: bold"
              >
                Vous
              </div>
              <div
                v-else
                style="font-weight: bold"
              >
                {{ item.utilisateur_origine }}
              </div>
              <div>{{ item.pointage_heure_debut_origine ? item.pointage_heure_debut_origine.toString().substr(0,5) : '' }}</div>
              <div>{{ item.pointage_heure_fin_origine ? item.pointage_heure_fin_origine.toString().substr(0,5) : '' }}</div>
              <v-icon color="green">
                check
              </v-icon>
            </div>
            <div style="width: 50%;display: flex;flex-direction:column;justify-content: center;align-items: center">
              <div
                v-if="item.bde_direction === 2"
                style="font-weight: bold"
              >
                Vous
              </div>
              <div
                v-else
                style="font-weight: bold"
              >
                {{ item.utilisateur_cible }}
              </div>
              <div>{{ item.pointage_heure_debut_cible ? item.pointage_heure_debut_cible.toString().substr(0,5) : 'Repos' }}</div>
              <div>{{ item.pointage_heure_fin_cible ? item.pointage_heure_fin_cible.toString().substr(0,5) : 'Hebdo' }}</div>
              <v-icon
                v-if="item.bde_etat === 0"
                color="grey"
              >
                hourglass_empty
              </v-icon>
              <v-icon
                v-if="item.bde_etat === 1"
                color="green"
              >
                check
              </v-icon>
              <v-icon
                v-if="item.bde_etat === 2"
                color="green"
              >
                check
              </v-icon>
              <v-icon
                v-if="item.bde_etat === 8"
                color="red"
              >
                close
              </v-icon>
              <v-icon
                v-if="item.bde_etat === 9"
                color="green"
              >
                check
              </v-icon>
            </div>
          </div>
        </div>
        <v-card-subtitle v-if="item.bde_etat === 0">
          Proposition en attente de réponse de l'agent
        </v-card-subtitle>
        <v-card-subtitle v-if="item.bde_etat === 1">
          Proposition en attente de réponse du responsable
        </v-card-subtitle>
        <v-card-subtitle v-if="item.bde_etat === 2">
          Proposition accepté par le responsable
        </v-card-subtitle>
        <v-card-subtitle v-if="item.bde_etat === 8">
          Proposition refusé par l'agent
        </v-card-subtitle>
        <v-card-subtitle v-if="item.bde_etat === 9">
          Porposition refusé par le responsable
        </v-card-subtitle>
        <div style="margin-left: 15px;margin-right: 15px">
          <v-list-item-content
            v-if="item.bde_motif_agent_demande"
            style="color: black;font-style: italic"
            v-html="'<b>Motif demande :</b>' + item.bde_motif_agent_demande"
          />
          <v-list-item-content
            v-if="item.bde_motif_agent_reponse"
            style="color: black;font-style: italic"
            v-html="'<b>Motif réponse agent :</b>' + item.bde_motif_agent_reponse"
          />
          <v-list-item-content
            v-if="item.bde_motif_responsable_reponse"
            style="color: black;font-style: italic"
            v-html="'<b>Motif réponse responsable :</b>' + item.bde_motif_responsable_reponse"
          />
        </div>
        <v-textarea
          v-if="item.bde_direction === 2 && item.bde_etat === 0"
          v-model="motif"
          label="Motif"
          outlined
        />
        <v-card-actions
          v-if="item.bde_direction === 1 && item.bde_etat === 0"
          style="display: flex;justify-content: flex-end"
        >
          <v-btn
            :small="true"
            color="error"
            @click="f_supprimer(item)"
          >
            Annuler la proposition
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-if="item.bde_direction === 2 && item.bde_etat === 0"
          style="display: flex;justify-content: flex-end"
        >
          <v-btn
            :small="true"
            color="success"
            @click="f_accepter(item)"
          >
            Accepter l'échange
          </v-btn>
          <v-btn
            :small="true"
            color="error"
            @click="f_refuser(item)"
          >
            Refuser l'échange
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
    name: 'VxBourseDesEchangesEdt',
    data () {
        return {
            datas: null,
            motif: null,
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/bourse-des-echanges-edt/echanges').then((data) => {
                this.datas = data
            })
        },
        getDateFormatted (valeur) {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, 'dddd D MMMM YYYY')
        },
        getBackgroudColor (etat) {
            switch (etat) {
            case 0:
                return 'lightgrey'
            case 1:
                return 'lightgreen'
            case 2:
                return 'lightgreen'
            case 8:
            case 9:
                return 'indianred'
            default:
                return 'lightgrey'
            }
        },
        f_accepter (item) {
            this.datas = null
            this.$wikaz.request.put('api/edt/modules/bourse-des-echanges-edt/echanges/' + item.bde_id + '?action=1', { motif: this.motif }).then(() => {
                this.motif = null
                this.getData()
            })
        },
        f_refuser (item) {
            this.datas = null
            this.$wikaz.request.put('api/edt/modules/bourse-des-echanges-edt/echanges/' + item.bde_id + '?action=8', { motif: this.motif }).then(() => {
                this.motif = null
                this.getData()
            })
        },
        f_supprimer (item) {
            this.datas = null
            this.$wikaz.request.put('api/edt/modules/bourse-des-echanges-edt/echanges/' + item.bde_id + '?action=7').then(() => {
                this.getData()
            })
        },
    },
}
</script>

<style scoped>

</style>