<template>
  <cx-waiting v-if="busy" />
  <div
    v-else-if="!busy && datas"
    class="cx-page-intervention-container"
  >
    <!--<cx-intervention-progression :datas="datas"/>-->
    <v-card v-if="datas.etapes_en_cours != 2">
      <cx-intervention-infos-generale :datas="datas" />
      <cx-intervention-infos-adresse :datas="datas" />
      <cx-intervention-infos-prestation :datas="datas" />
    </v-card>
    <template v-else>
      <v-btn
        color="info"
        @click="dialogInfos = true"
      >
        <v-icon style="margin-right: 10px">
          info
        </v-icon>Afficher les infos
      </v-btn>
      <v-dialog v-model="dialogInfos">
        <fx-titre titre="Informations client" />
        <cx-intervention-infos-generale :datas="datas" />
        <cx-intervention-infos-adresse :datas="datas" />
        <cx-intervention-infos-prestation :datas="datas" />
      </v-dialog>
    </template>
    <cx-etapes-cloture
      v-if="datas.etapes_en_cours === 2"
      v-model="paramsCloture"
    />
    <cx-intervention-actions
      v-if="datas.etapes_en_cours < 3"
      :datas="datas"
      @retour="f_retour_etape"
      @annulation="f_annulation"
      @action="f_action"
    />
    <cx-intervention-terminee
      v-if="datas.etapes_en_cours === 3"
      :datas="datas"
    />
  </div>
</template>

<script>
import CxInterventionInfosGenerale from './pages/intervention/CxInterventionInfosGenerale'
import CxInterventionInfosAdresse from './pages/intervention/CxInterventionInfosAdresse'
import CxInterventionInfosPrestation from './pages/intervention/CxInterventionInfosPrestation'
import CxInterventionActions from './pages/intervention/CxInterventionActions'
import CxEtapesCloture from './pages/intervention/CxEtapesCloture'
import CxInterventionTerminee from './pages/intervention/CxInterventionTerminee'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
export default {
    name: 'CxTraitementActivite',
    components: {
        CxInterventionTerminee,
        CxEtapesCloture,
        CxInterventionActions,
        CxInterventionInfosPrestation,
        CxInterventionInfosAdresse,
        CxInterventionInfosGenerale,
    },
    extends: CxBase,
    data () {
        return {
            datas: null,
            busy: false,
            dialogInfos: false,
            paramsCloture: {
                cloture: null,
                fournitures: [],
                prestations: [],
                signature_client: false,
                signature_prestataire: false,
                planification: null,
                scan: null,
            },
        }
    },
    mounted () {
        this.chargerData()
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Intervention ' + this.datas.intervention_id
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        chargerData () {
            this.busy = true
            this.$wikaz.request.get('api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/intervention').then((data) => {
                this.datas = data
                this.busy = false
            })
        },
        f_action () {
            let valid = true
            let message = []

            if (this.datas.etapes_en_cours === 2) {
                if (this.paramsCloture.cloture === null) {
                    valid = false
                    message.push('- Veuillez sélectionner une cloture')
                }
                if (! this.paramsCloture.scan) {
                    valid = false
                    message.push('- Veuillez scanner le CRI')
                }
                /* else {
                        if ( [1].includes(this.paramsCloture.cloture) && this.paramsCloture.prestations.length === 0) {
                            valid = false;
                            message.push('- Veuillez renseigner au moins une ligne de prestation')
                        }
                        if ( this.paramsCloture.cloture === 2 && (this.paramsCloture.planification === null || this.paramsCloture.planification === "")) {
                            valid = false;
                            message.push('- Veuillez séléctionner un créneau pour la nouvelle intervention')
                        }
                    }*/
                /*if (!this.paramsCloture.signature_client) {
                        valid = false;
                        message.push('- Veuillez faire signer le CRI par le client')
                    }*/
                /*if (!this.paramsCloture.signature_prestataire) {
                        valid = false;
                        message.push('- Veuillez signer le CRI')
                    }*/
            }

            if (valid) {
                this.paramsCloture.fournitures = JSON.stringify(this.paramsCloture.fournitures)
                this.paramsCloture.prestations = JSON.stringify(this.paramsCloture.prestations)
                this.paramsCloture.planification = JSON.stringify(this.paramsCloture.planification)

                this.busy = true
                this.$wikaz.request.post('api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/intervention', this.paramsCloture).then((data) => {
                    this.datas = data
                    this.busy = false
                })

            } else {
                this.$wikaz.outils.notification.showError(message.join('\n'))
            }
        },
        f_retour_etape () {
            this.$wikaz.request.delete('api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/intervention/0', {}).then((data) => {
                this.datas = data
                this.busy = false
            })
        },
        f_retour () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-mon-activite',
                module_libelle: 'Mon activité',
            })
        },
        f_annulation () {
            this.$wikaz.request.delete('api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/intervention/0', {}).then((data) => {
                this.datas = data
                this.busy = false
            })
        },
        f_appeler (valeur) {
            window.location = 'tel:' + valeur
        },
        f_gps (valeur) {
            window.location = 'geo:' + valeur
        },
        f_mail (valeur) {
            window.location = 'mailto:' + valeur
        },
    },
}
</script>
<style scoped>
    .cx-page-intervention-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 5px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-row-gap: 5px;
        padding-left: 5px;
    }
</style>