import Vue from 'vue'
import VxSuiviRecurrence from '@/wikaz-konnect/pdc/suivi-recurrence/VxSuiviRecurrence'

const Imports = []

Imports.push(
    {
        name: 'vx-suivi-recurrence',
        component: VxSuiviRecurrence,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports