<template>
  <v-card>
    <v-card-actions v-if="datas.intervention_date_planifie === $wikaz.outils.dates.getTodayBdd()">
      <div
        v-if="datas.etapes_en_cours === 0 && datas.inter_en_cours === null"
        style="width:100%;display:flex;justify-content: flex-end"
      >
        <!--                <v-btn color="red" @click="f_annulation"><v-icon style="margin-right: 10px">close</v-icon>ANNULER</v-btn>-->
        <v-btn
          color="green"
          @click="f_action"
        >
          <v-icon style="margin-right: 10px">
            near_me
          </v-icon>Je me mets en route
        </v-btn>
      </div>
      <div
        v-if="datas.etapes_en_cours === 0 && datas.inter_en_cours !== null"
        style="width:100%;display:flex;justify-content: space-between"
      >
        Une autre intervention est en cours
      </div>
      <div
        v-if="datas.etapes_en_cours === 1"
        style="width:100%;display:flex;justify-content: space-between"
      >
        <v-btn
          color="red"
          @click="f_retour"
        >
          <v-icon style="margin-right: 10px">
            navigate_before
          </v-icon>Retour
        </v-btn>
        <v-btn
          color="green"
          @click="f_action"
        >
          <v-icon style="margin-right: 10px">
            home
          </v-icon>Je suis arrivé
        </v-btn>
      </div>
      <div
        v-if="datas.etapes_en_cours === 2"
        style="width:100%;display:flex;justify-content: space-between"
      >
        <v-btn
          color="red"
          @click="f_retour"
        >
          <v-icon style="margin-right: 10px">
            navigate_before
          </v-icon>Retour
        </v-btn>
        <v-btn
          color="green"
          @click="f_action"
        >
          <v-icon style="margin-right: 10px">
            done
          </v-icon>J'ai terminé
        </v-btn>
      </div>
      <div
        v-if="datas.etapes_en_cours === 3"
        style="width:100%;display:flex;justify-content: space-between"
      >
        <!--<v-btn color="red" @click="f_annulation"><v-icon style="margin-right: 10px">navigate_before</v-icon>Annuler</v-btn>-->
      </div>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
    name: 'CxInterventionActions',
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_annulation () {
            this.$wikaz.outils.notification.showConfirmation('Annulation de l\'intervention', 'Etes-vous certain de vouloir annuler l\'intervention ?', () => {
                this.$emit('retour')
            }, '100%')
        },
        f_retour () {
            this.$emit('retour')
        },
        f_action () {
            this.$emit('action')
        },
    },
}
</script>
<style scoped>
</style>