<template>
  <div style="display: flex;justify-content: flex-end;padding: 10px">
    <v-btn
      v-if="showValidate"
      color="success"
      small
      :disabled="disabledValidate"
      @click="$emit('submit')"
    >
      {{ textValidate }}
    </v-btn>
    <v-btn
      v-if="showCancel"
      style="margin-left: 10px"
      color="error"
      small
      @click="$emit('close')"
    >
      {{ textCancel }}
    </v-btn>
  </div>
</template>
<script>
export default{
    name: 'FxFormFooter',
    props: {
        showValidate: { default: true },
        showCancel: { default: true },
        textValidate: {
            default: 'Valider',
            type: String,
        },
        textCancel: {
            default: 'Annuler',
            type: String,
        },
        disabledValidate: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style scoped>
    .ivu-btn-success[disabled] {
        background: #19BE6B;
        opacity: 0.7;
    }
</style>