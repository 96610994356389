<template>
  <v-list class="fx-list-simple-container">
    <template>
      <slot name="loader" />
      <cx-waiting v-if="loading" />
      <v-list-item-group v-else>
        <draggable
          v-model="dataProvider"
          :disabled="!isDraggable"
          :group="{ name:draggableGroupName, pull:'clone', put: false}"
          style="width:100%"
          @change="updateChange($event)"
          @end="dragging = false"
          @input="updateVal($event)"
          @start="dragging = true"
        >
          <template v-for="(item, index) in dataProvider">
            <component
              :is="itemRenderer"
              v-if="itemRenderer"
              :key="index"
              ref="comp"
              :class="[isSelected(item),isEven(index),{ dragging : dragging }, isDisabled()]"
              :item="item"
              :index="index"
              :last-index="dataProvider ? ((dataProvider.length)-1) : -1"
              :selected="isSelected(item)['active']"
              :url="url"
              v-on="listeners"
              @actualiser="actualiser()"
            />
            <v-list-item
              v-else
              :key="index"
              :class="[isSelected(item),isEven(index),{ dragging : dragging }, isDisabled()]"
              @click="itemEmitting(item, item)"
              v-on="listeners"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item[labelField] || 'undefined'" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </draggable>
      </v-list-item-group>
    </template>
  </v-list>
</template>
<style scoped lang="scss">
    .fx-list-simple-container {
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        border: 1px solid #dddddd;
    }

    /*.fx-list-simple-container::-webkit-scrollbar {
        width: 0 !important;
    }*/

    .active {
        // background-color: #DDDDDD;
        background-color: rgba(30, 99, 232, 0.6);
        color: white;
    }
</style>
<script>
import draggable from 'vuedraggable'

export default{
    name: 'FxListSimple',
    components: { draggable },
    props: {
        selected: null,
        dataProvider: {
            default () {
                return []
            },
        },
        labelField: { default: 'label' },
        clean: {
            default: false,
            type: Boolean,
        },
        isDraggable: {
            default: false,
            type: Boolean,
        },
        itemRenderer: { default: null },
        highlight: { default: true },
        height: {
            type: String,
            default: 'inherit',
        },
        width: {
            type: String,
            default: 'inherit',
        },
        url: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        draggableGroupName: {
            default: 'element',
            type: String,
        },
    },
    data () {
        return {
            // ready: false,
            selectedItem: null,
            dragging: false,
        }
    },
    computed: {
        listeners () {
            Object.keys(this.$listeners).forEach((item) => {

                this.$listeners[item] = (event) => {
                    if (item === 'itemEmitting') {
                        this.selectedItem = event
                        this.$emit('input', event)
                    }
                    this.$emit(item, event)
                }
            })
            return { ...this.$listeners }
        },
    },
    watch: {
        selected: function () {
            // this.$emit('itemEmitting', newVal)
            this.selectedItem = this.selected
        },
    },
    mounted () {
        // this.ready = true
    },
    methods: {
        selectItem (item) {
            this.$emit('update:selected', item)
        },
        itemEmitting: function (e, item) {
            if (e) {
                this.selectItem(item)
                this.$emit('itemEmitting', e)
            }
        },
        actualiser () {
            this.$emit('actualiser')
        },
        event (event) {
            this.$emit('custom-event', event)
        },
        dblClick: function (e, item) {
            this.selectItem(item)
            this.$emit('on-dblclick', e)
        },
        isSelected: function (item) {
            return { active: item === this.selectedItem && this.highlight === true }
        },
        isEven: function (n) {
            return {
                even: (n % 2) === 0 && ! this.clean,
                odd: (n % 2) !== 0 && ! this.clean,
            }
        },
        updateVal (ev) {
            this.$emit('updateDatas', ev)
        },
        updateChange (ev) {
            this.$emit('updateChange', ev)
        },
        isDisabled () {
            return { disabled: this.disabled }
        },
        /*select (index) {
                setTimeout(() => {
                    if (this.$refs['comp'] && this.$refs['comp'].length > 0) {
                        if (this.$refs['comp'][index]) {
                            this.$refs['comp'][index].emitClick()
                        } else {
                            this.$emit('update:selected', null)
                        }
                    } else {
                        this.$emit('update:selected', null)
                    }
                }, 50)
            }*/
    },
}
</script>