<template>
  <l-marker
    v-if="latitude && longitude"
    :lat-lng="position"
    :icon="icon ? iconObj : null"
    @click="onClick"
  >
    <l-popup v-if="popup">
      <div @click="innerClick">
        {{ popup.titre }}
        <p v-if="popup.paragraphe">
          {{ popup.paragraphe }}
        </p>
      </div>
    </l-popup>
    <l-tooltip
      v-if="tooltip"
      :options="{ interactive: true }"
    >
      <div @click="innerClick">
        {{ tooltip.titre }}
        <p v-if="tooltip.paragraphe">
          {{ tooltip.paragraphe }}
        </p>
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import L, { latLng } from 'leaflet'
import { LMarker, LPopup, LTooltip } from 'vue2-leaflet'

export default {
    name: 'FxMapMarker',
    components: {
        LMarker,
        LPopup,
        LTooltip,
    },
    props: {
        latitude: {
            type: Number,
            default: null,
        },
        longitude: {
            type: Number,
            default: null,
        },
        popup: {
            type: Object,
            default: null,
        },
        tooltip: {
            type: Object,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            position: latLng(this.latitude, this.longitude),
            iconObj: L.icon({
                iconUrl: this.icon,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
            }),
        }
    },
    methods: {
        innerClick () {
        },
        onClick (e) {
            this.$emit('click', e)
        },
    },
}
</script>

<style scoped>

</style>