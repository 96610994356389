import Vue from 'vue'
import FxDateField from '@/wikaz-core/ui/composants/dates/FxDateField'
import FxDateFieldCheck from '@/wikaz-core/ui/composants/dates/FxDateFieldCheck'
import FxDateHeureField from '@/wikaz-core/ui/composants/dates/FxDateHeureField'
import FxHeureField from '@/wikaz-core/ui/composants/dates/FxHeureField'
import FxHeureFieldCheck from '@/wikaz-core/ui/composants/dates/FxHeureFieldCheck'
import FxSelectionAnnee from '@/wikaz-core/ui/composants/dates/FxSelectionAnnee'
import FxSelectionMois from '@/wikaz-core/ui/composants/dates/FxSelectionMois'
import FxPeriodeDate from '@/wikaz-core/ui/composants/dates/FxPeriodeDate'
import FxPeriodeSemaineBloc from '@/wikaz-core/ui/composants/dates/FxPeriodeSemaineBloc'
Vue.component('fx-date-field', FxDateField)
Vue.component('fx-datefield', FxDateField)
Vue.component('fx-date-field-check', FxDateFieldCheck)
Vue.component('fx-datefield-check', FxDateFieldCheck)
Vue.component('fx-date-heure-field', FxDateHeureField)
Vue.component('fx-dateheurefield', FxDateHeureField)
Vue.component('fx-heurefield', FxHeureField)
Vue.component('fx-heure-field', FxHeureField)
Vue.component('fx-heure-field-check', FxHeureFieldCheck)
Vue.component('fx-heurefield-check', FxHeureFieldCheck)
Vue.component('fx-periode-date', FxPeriodeDate)
Vue.component('fx-selection-annee', FxSelectionAnnee)
Vue.component('fx-selection-mois', FxSelectionMois)
Vue.component('fx-periode-semaine-bloc', FxPeriodeSemaineBloc)