<template>
  <div style="display: flex;justify-content: center;align-items: center">
    <span>{{ composant }}</span>
    <v-icon color="red">
      warning
    </v-icon>
    <span>Attention vous devez renseigner au moins la propriété <b>{{ propriete }}</b></span>
  </div>
</template>
<script>
export default {
    name: 'FxAnomalieComposant',
    props: {
        composant: {
            type: String,
            default: null,
        },
        propriete: {
            type: String,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>