let divers = function () {
    this.compareVersions = function (v1, comparator, v2) {
        'use strict'
        comparator = comparator === '=' ? '==' : comparator
        if(['==', '===', '<', '<=', '>', '>=', '!=', '!=='].indexOf(comparator) === - 1) {
            throw new Error('Invalid comparator. ' + comparator)
        }
        let v1parts = v1.split('.'), v2parts = v2.split('.')
        let maxLen = Math.max(v1parts.length, v2parts.length)
        let part1, part2
        let cmp = 0
        for(let i = 0; i < maxLen && ! cmp; i ++) {
            part1 = parseInt(v1parts[i], 10) || 0
            part2 = parseInt(v2parts[i], 10) || 0
            if(part1 < part2) {
                cmp = 1
            }
            if(part1 > part2) {
                cmp = - 1
            }
        }
        return eval('0' + comparator + cmp)
    }
}
export default divers