<template>
  <div
    style="display: flex;align-items: center"
    :disabled="editable===false"
  >
    <fx-checkbox
      v-model="checked"
      :value="checked"
      :label="label"
      :true-value="true"
      :false-value="false"
      @on-change="updateValeur"
    />
    <v-menu
      ref="menu"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="time"
          style="margin: 0;padding: 0;height: 32px"
          prepend-icon="access_time"
          :disabled="checked===false"
          readonly
          v-on="on"
        />
      </template>
      <!--<v-date-picker v-model="date" no-title @input="menu1 = false" v-on="listeners" first-day-of-week="1" locale="fr-fr"></v-date-picker>-->
      <v-time-picker
        v-if="menu1"
        v-model="time"
        full-width
        no-title
        format="24hr"
        @click:minute="$refs.menu.save(time)"
        v-on="listeners"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
    name: 'FxHeureFieldCheck',
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        editable: {
            default: false,
            type: Boolean,
        },
        width: { default: '120px' },
        isTimeNullDefault: {
            default: true,
            type: Boolean,
        },
    },
    data () {
        return {
            time: (this.value !== '' && this.value !== null) ? this.value : (this.isTimeNullDefault ? null : this.$wikaz.getInstance().outils.dates.getTime()),
            menu1: false,
            checked: (this.value !== '' && this.value !== null),
        }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => {
                    if (item === 'input') {
                        this.updateValeur()
                    } else {
                        self.$emit(item, event)
                    }
                }
            })
            return { ...self.$listeners }
        },
        style () {
            return { width: this.width }
        },
    },
    mounted () {
        this.updateValeur()
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        updateValeur () {
            let valRetour
            if (this.checked) {
                valRetour = this.time
            } else {
                valRetour = null
            }
            this.$emit('input', valRetour)
        },
    },
}
</script>