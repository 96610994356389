<template>
  <v-list-item
    v-if="isMobile"
    style="border-bottom: 1px solid grey"
    @click="f_emit_selection"
  >
    <div style="width: 120px">
      <v-list-item-title
        style="font-size: 12px"
        v-text="f_format_date(item.presence_date,'DD/MM/YYYY')"
      />
      <v-list-item-subtitle
        style="font-size: 12px"
        v-text="item.th_libelle"
      />
    </div>
    <div style="width: 100%">
      <v-list-item-title
        style="font-size: 12px;text-align: center"
        v-text="item.utilisateur_nom"
      />
    </div>
    <div style="width: 100px;display: flex;flex-direction: column;align-items: center">
      <fx-icon
        tooltip="Distance ajouté à la tournée"
        icon="add_location"
      />
      <v-list-item-title
        style="font-size: 11px"
        v-text="(item.distance_ajout > 0 ? item.distance_ajout : 0) + ' Kms'"
      />
    </div>
  </v-list-item>
  <v-list-item
    v-else
    @click="f_emit_selection"
  >
    <div style="width:80px;display: flex;align-items: center;justify-content: center; margin-right: 10px;font-weight: bold">
      <span>{{ item.jour_libelle }}</span>
    </div>
    <div style="width:100px;display: flex;align-items: center;justify-content: center">
      <span>{{ f_format_date(item.presence_date,'DD/MM/YYYY') }}</span>
    </div>
    <div style="width:100px;display: flex;align-items: center;justify-content: center">
      <span>{{ item.th_libelle }}</span>
    </div>
    <div style="display: flex;align-items: center;justify-content: center;padding-left: 5px;padding-right: 5px;font-weight: bold">
      <span>{{ item.utilisateur_nom }}</span>
    </div>
    <div style="width:120px;display: flex;align-items: center">
      <fx-icon
        tooltip="Distance ajouté à la tournée"
        icon="add_location"
      />
      <span>{{ item.distance_ajout > 0 ? item.distance_ajout : 0 }} Kms</span>
    </div>
    <!--<div style="width:100px;display: flex;align-items: center">
            <fx-icon tooltip="Distance totale tournée avant ajout" icon="map"></fx-icon>
            <span>{{item.distance_tournee}} Kms</span>
        </div>-->
    <div style="width:70px;display: flex;align-items: center">
      <fx-icon
        tooltip="Nombre d'intervention de la tournée"
        icon="calendar_today"
        :badge="item.nb_interventions === 0 ? '0' : item.nb_interventions"
      />
    </div>
    <div style="width:70px;display: flex;align-items: center">
      <fx-icon
        tooltip="Durée totale d'intervention hors trajets"
        icon="alarm"
      />
      <span>{{ item.duree_inter }}</span>
    </div>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxDisponibilite',
    extends: FxItemTemplate,
    data () {
        return { isMobile: window.Vue.$store.getters.isMobile() }
    },
    methods: {
        f_get_icon_tcref (valeur) {
            if (valeur === 1) {
                return 'grade'
            }
            return ''
        },
    },
}
</script>