<template>
  <div
    class="cx-ligne-evenements-container"
    @contextmenu.prevent=""
  >
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-evenements-cartouche"
      @contextmenu.prevent=""
    >
      Anomalies
    </div>
    <template v-for="(item,indexJour) in datas.dates">
      <div
        :key="indexJour"
        class="cx-ligne-evenements-case"
        :style="{width: 'calc((100% - '+largeurGauche+'px)/'+datas.dates.length+')'}"
        @contextmenu.prevent=""
      >
        <v-tooltip
          bottom
          class="v-tooltip-css"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              v-if="item.detail_anomalie"
              small
              class="ma-2"
              color="red"
              text-color="white"
              v-on="on"
              @contextmenu.prevent=""
            >
              {{ item.detail_anomalie.length }}
            </v-chip>
          </template>
          <div
            v-for="(detailItem,index) in item.detail_anomalie"
            :key="index"
            :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
          >
            <ix-item-anomalie :item="detailItem" />
          </div>
        </v-tooltip>
      </div>
    </template>
  </div>
</template>
<script>
import IxItemAnomalie from '@/wikaz-konnect/gdt/planning/planning-elements/anomalies/IxItemAnomalie'
export default {
    name: 'CxLigneAnomalies',
    components: { IxItemAnomalie },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-evenements-container {
        width: 100%;
        height: 35px;
        display: flex;
        box-sizing: border-box;
        background-color: #51b8d5;
    }

    .cx-ligne-evenements-cartouche {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid black;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-evenements-case {
        border-left: 1px solid black;
        border-top: 1px solid black;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .v-tooltip__content.menuable__content__active {
        opacity: 1 !important;
    }
</style>