<template>
  <v-list-item @click="onClick()">
    <v-tooltip
      :disabled="!mini"
      right
    >
      <template v-slot:activator="{ on }">
        <v-list-item-icon v-on="on">
          <!--<v-icon :color="color" style="margin-right: 5px">{{icon}}</v-icon>-->
          <v-img
            v-if="urlSrc"
            contain
            :src="urlSrc"
          />
          <fx-icon
            v-else
            :color="color"
            :size="24"
            style="margin-right: 5px"
            :icon="icon"
            :badge="badge"
          />
        </v-list-item-icon>
      </template>
      <span>{{ libelle }}</span>
    </v-tooltip>
    <v-list-item-content>
      <v-list-item-title>{{ libelle }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'FxItemRendererMenu',
    props: {
        mini: {
            type: Boolean,
            default: false,
        },
        libelle: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: null,
        },
        urlSrc: {
            type: String,
            default: null,
        },
        badge: { default: null },
    },
    methods: {
        onClick () {
            this.$emit('click')
        },
    },
}
</script>

<style scoped>

</style>