<template>
  <div
    v-if="datas"
    class="cx-planning-container"
    @contextmenu.prevent=""
  >
    <cx-ligne-semaines-label
      :datas="datas"
      :largeur-gauche="largeurGauche"
    />
    <cx-ligne-jours-label
      :datas="datas"
      :largeur-gauche="largeurGauche"
    />
    <cx-ligne-anomalies
      :datas="datas"
      :largeur-gauche="largeurGauche"
      @update="f_update"
    />
    <cx-ligne-evenements
      :datas="datas"
      :largeur-gauche="largeurGauche"
      @update="f_update"
    />
    <div
      style="overflow: auto"
      class="cx-planning-container-agents"
    >
      <cx-ligne-agents
        v-for="(item,index) in datas.agents"
        :key="index"
        :item="item"
        :datas="datas"
        :data-postes="dataPostes"
        :largeur-gauche="largeurGauche"
        @update="f_update"
      />
    </div>
    <cx-ligne-astreintes-nuits
      :datas="datas"
      :largeur-gauche="largeurGauche"
      @update="f_update"
    />
  </div>
  <cx-waiting v-else />
</template>
<script>
import CxLigneEvenements from './planning-elements/evenements/CxLigneEvenements'
import CxLigneAstreintesNuits from './planning-elements/astreintes/CxLigneAstreintesNuits'
import CxLigneJoursLabel from './planning-elements/jours/CxLigneJoursLabel'
import CxLigneAgents from './planning-elements/agents/CxLigneAgents'
import CxLigneAnomalies from './planning-elements/anomalies/CxLigneAnomalies'
import CxLigneSemainesLabel from '@/wikaz-konnect/gdt/planning/planning-elements/semaines/CxLigneSemainesLabel'
export default {
    name: 'CxPlanning',
    components: {
        CxLigneSemainesLabel,
        CxLigneAnomalies,
        CxLigneAgents,
        CxLigneJoursLabel,
        CxLigneAstreintesNuits,
        CxLigneEvenements,
    },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        dataPostes: { default: null },
    },
    data () {
        return { largeurGauche: 200 }
    },
    methods: {
        f_update () {
            this.$emit('update')
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-planning-container{
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        // padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto 1fr auto;
        // grid-row-gap: 10px;
        overflow: auto;
        background-color: white;
        border: 1px solid black;
        border-radius: 11px;
    }

    /*.cx-planning-container-agents::-webkit-scrollbar {
        width: 0 !important;
    }*/

</style>