import Vue from 'vue'
import VxGestionEtatsProspects from '@/wikaz-konnect/crm/parametrages/gestion-etats/VxGestionEtatsProspects'
import VxGestionSecteursProspects from '@/wikaz-konnect/crm/parametrages/gestion-secteurs/VxGestionSecteursProspects'
import VxGestionTypesActions from '@/wikaz-konnect/crm/parametrages/gestion-types/VxGestionTypesActions'
import VxStatistiqueGrc from '@/wikaz-konnect/crm/statistique-grc/VxStatistiqueGrc'
import VxTableauDeBordGrc from '@/wikaz-konnect/crm/tableau-de-bord-grc/VxTableauDeBordGrc'
import VxGestionProspectsCrm from '@/wikaz-konnect/crm/gestion-prospects-crm/VxGestionProspectsCrm'

const Imports = []

Imports.push(
    {
        name: 'vx-gestion-etats-prospects-wk-crm',
        component: VxGestionEtatsProspects,
    },
    {
        name: 'vx-gestion-secteurs-prospects-wk-crm',
        component: VxGestionSecteursProspects,
    },
    {
        name: 'vx-gestion-types-actions-wk-crm',
        component: VxGestionTypesActions,
    },

    {
        name: 'vx-statistique-wk-crm',
        component: VxStatistiqueGrc,
    },
    {
        name: 'vx-tableau-de-bord-wk-crm',
        component: VxTableauDeBordGrc,
    },
    {
        name: 'vx-gestion-prospects-wk-crm',
        component: VxGestionProspectsCrm,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports