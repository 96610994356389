<template>
  <v-app-bar
    :fixed="fixed"
    dense
    flat
    style="border-bottom: 1px solid rgba(44,44,44,0.18) !important;background-color: white"
    :height="$icsilab.getHeader().height"
  >
    <template v-if="$icsilab.getHeader().imageSrc">
      <div
        v-if="$icsilab.getHeader().imageOffsetX || $icsilab.getHeader().imageOffsetY"
        class="offset-image"
        :style="{borderBottomLeftRadius: $icsilab.getHeader().borderBottomLeftRadius ? $icsilab.getHeader().borderBottomLeftRadius : '0 0',
                 borderBottomRightRadius: $icsilab.getHeader().borderBottomRightRadius ? $icsilab.getHeader().borderBottomRightRadius : '0 0',
                 height: $icsilab.getHeader().imageHeight ? $icsilab.getHeader().imageHeight + 'px' : $icsilab.getHeader().height + 'px',
                 top: ( $icsilab.getHeader().imageOffsetX ? $icsilab.getHeader().imageOffsetX : 0) + 'px',
                 left: + ( $icsilab.getHeader().imageOffsetY ? $icsilab.getHeader().imageOffsetY : 0) + 'px'}"
      >
        <img
          style="margin-right: 10px;padding: 10px;cursor: pointer"
          :src="$icsilab.rootApi + 'fichiers?file=' + $icsilab.getHeader().imageSrc"
          alt=""
          :height="$icsilab.getHeader().imageHeight ? $icsilab.getHeader().imageHeight + 'px' : '80%'"
          @click="f_accueil"
        >
      </div>
      <img
        v-else
        style="margin-right: 10px;cursor: pointer"
        :src="$icsilab.rootApi + 'fichiers?file=' + $icsilab.getHeader().imageSrc"
        alt=""
        :height="$icsilab.getHeader().imageHeight ? $icsilab.getHeader().imageHeight + 'px' : '80%'"
        @click="f_accueil"
      >
    </template>
    <v-toolbar-title
      style="cursor: pointer"
      @click="f_accueil"
      v-text="$icsilab.getHeader().titre"
    />
    <v-toolbar-title
      style="cursor: pointer;margin-left: 5px"
      @click="f_accueil"
      v-text="!(['xs','sm','md'].indexOf($screen.breakpoint) > -1) ? $icsilab.getHeader().baseline : ''"
    />
    <v-spacer />
    <template v-if="['lg','xl'].indexOf($screen.breakpoint) > -1">
      <div
        v-for="(item,index) in $icsilab.getPages()"
        :key="index"
        depressed
        style="margin-right: 10px;margin-left: 10px;cursor: pointer"
        @click="f_route(item)"
      >
        <template v-if="item.is_menu === 1">
          <v-icon
            v-if="item.icon"
            :color="($route.name === item.route) ? ( $icsilab.getHeader().colorMenuActive ? $icsilab.getHeader().colorMenuActive : '#F54845' ) : ( $icsilab.getHeader().colorMenu ? $icsilab.getHeader().colorMenu : '#09203F' )"
            left
          >
            {{ item.icon }}
          </v-icon>
          <v-img
            v-if="item.image_src"
            :src="$icsilab.rootApi + 'fichiers?file=' + item.image_src"
            contain
          />
          <span
            :style="{color: ($route.name === item.route) ? ( $icsilab.getHeader().colorMenuActive ? $icsilab.getHeader().colorMenuActive : '#F54845' ) : ( $icsilab.getHeader().colorMenu ? $icsilab.getHeader().colorMenu : '#09203F' )}"
            style="font-weight: bold"
          >{{ item.label }}</span>
        </template>
      </div>
      <!--<v-text-field style="width: 150px" dense solo label="Rechercher ..." prepend-inner-icon="search"></v-text-field>-->
    </template>
    <v-app-bar-nav-icon
      v-else
      @click="f_menu_click"
    />
  </v-app-bar>
</template>
<style scoped>
    .active {
        color: #F54845
    }

    .offset-image {
        background-color:white;
        position:fixed;
        border-bottom: 1px solid rgba(44,44,44,0.18);
    }
</style>
<script>
export default {
    name: 'FxHeader',
    props: {
        fixed: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        f_menu_click () {
            this.$emit('menuClick')
        },
        f_accueil () {
            this.$icsilab.goToPage('home')
        },
        f_route (item) {
            this.$icsilab.goToPage(item.route)
        },
    },
}
</script>