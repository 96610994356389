<template>
  <div class="fx-url-container">
    <v-text-field
      ref="myInput"
      class="fx-url-text-field"
      outlined
      hide-details
      dense
      :disabled="disabled"
      :readonly="!editable"
      type="text"
      :rules="rules"
      :value="value"
      @input="onChange"
    />
    <fx-icon
      v-if="value && value.length > 3"
      style="margin-left: 10px"
      icon="visibility"
      @click="$wikaz.outils.urls.openUrl(value)"
    />
  </div>
</template>
<script>
export default{
    name: 'FxUrl',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        editable: {
            default: true,
            type: Boolean,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        onChange (event) {
            this.$emit('input', event)
        },
    },
}
</script>
<style lang="scss" scoped>
    .fx-url-container {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .fx-url-text-field {
        width: 100%;
        margin-top: 2px;
        margin-bottom: 2px;
    }
</style>