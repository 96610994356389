export const STORAGE_KEY = process.env.VUE_APP_TITRE.toString().toLowerCase().replace(' ', '-')
// + '-' + require('../../../package.json').version;

let initialState = {}

if (localStorage.getItem(STORAGE_KEY)) {
    initialState = JSON.parse(localStorage.getItem(STORAGE_KEY))
} else {
    initialState = {
        isMobile: false,
        theme: false,
        auth: {
            isLoggedIn: false,
            accessToken: null,
            refreshToken: null,
            expires_in: null,
            expires_date_heure: null,
        },
        user: {
            id: null,
            name: '',
            email: '',
            options: [],
            login: '',
            props: [],
            modules: [],
            epingles: [],
        },
        menu: [],
        sidebar: false,
        mobileView: null,
        tabs: [],
        selectedTab: null,
        selectedTabIndex: null,
    }
}

export const state = initialState