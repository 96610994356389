<template>
  <div>
    <v-btn
      color="primary"
      @click="openInputFile"
    >
      <v-icon left>
        camera
      </v-icon>Scanner le CRI
    </v-btn>
    <input
      ref="contrat"
      type="file"
      hidden
      @change="fileUpload"
    >
  </div>
</template>
<script>
export default {
    name: 'CxPhoto',
    props: {
        label: { default: '' },
        disabled: { default: false },
    },
    data () {
        return { fileName: '' }
    },
    methods: {
        openInputFile () {
            this.$refs.contrat.click()
        },
        fileUpload (event) {
            this.fileName = event.target.files[0].name
            this.$emit('change', event.target.files[0])
        },
    },
}
</script>
<style scoped>
</style>