<template>
  <div class="vx-configuration-logements-container">
    <fx-datagrid-crud
      titre="Gestion des types de logements"
      url-ressource-end-point="api/edt/modules/configuration-logements/types"
    />
  </div>
</template>
<script>
export default { name: 'VxConfigurationLogements' }
</script>
<style scoped>
    .vx-configuration-logements-container {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>