<template>
  <div
    class="cx-ligne-jours-label-container"
    @contextmenu.prevent=""
  >
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-jours-label-cartouche"
      @contextmenu.prevent=""
    >
      Jours
    </div>
    <template v-for="(item,index) in datas.dates">
      <div
        :key="index"
        class="cx-ligne-jours-label-case"
        :style="{width: 'calc((100% - '+largeurGauche+'px)/'+datas.dates.length+')'}"
        @click="f_open_detail(item.dadate)"
        @contextmenu.prevent=""
      >
        <div
          class="cx-ligne-jours-label-case-element"
          :style="{backgroundColor: ( item.dadate === $wikaz.getInstance().outils.dates.getTodayBdd() ) ? '#65E5BE' : ( item.dimanche === 1 ) ? 'grey' : ( item.feries === 1 ) ? 'lightgrey' : '#0d6aad'}"
        >
          <div style="cursor: pointer;">
            {{ item.jour_lettre }}
          </div>
          <div style="cursor: pointer;">
            {{ item.jour }}
          </div>
        </div>
      </div>
    </template>
    <v-dialog
      v-if="selectedDate"
      v-model="showDetail"
      width="80%"
    >
      <cx-detail-journee
        :url-ressource-end-point="'api/edt/modules/planning-edt/planning/' + selectedDate + '/detail'"
        @close="showDetail = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import CxDetailJournee from './CxDetailJournee'
export default {
    name: 'CxLigneJoursLabel',
    components: { CxDetailJournee },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return {
            showDetail: false,
            selectedDate: null,
        }
    },
    methods: {
        f_open_detail (date) {
            this.selectedDate = date
            this.showDetail = true
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-jours-label-container {
        width: 100%;
        height: 43px;
        display: flex;
        box-sizing: border-box;
        //border-top-left-radius: 10px;
        //border-top-right-radius: 10px;

        border-top: 1px solid black ;

        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-jours-label-cartouche {
        background-color: #0d6aad;
        border-top-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cx-ligne-jours-label-case {
        border-left: 1px solid black;
        // border-top: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        &:last-child {
            border-top-right-radius: 10px;
        }
    }

    .cx-ligne-jours-label-case-element {
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.isToday {
            color: #313131;
            background-color: #b7f8b7 !important;
        }
    }

</style>