<template>
  <div class="cx-traitement-gestion-resident-container">
    <div class="cx-traitement-gestion-resident-col1">
      <cx-fiche-logement :url-ressource-end-point="'api/edt/modules/gestion-logements/logements/' + datas.logement_id" />
      <cx-liste-residents :url-ressource-end-point="'api/edt/modules/gestion-logements/logements/' + datas.logement_id + '/residents'" />
      <fx-list
        title="Historique Résidents"
        show-refresher
        show-search
        show-result
      />
    </div>
    <div class="cx-traitement-gestion-resident-col2">
      <fx-list
        title="Equipements"
        show-add
        show-refresher
        show-search
        show-result
      />
    </div>
    <div class="cx-traitement-gestion-resident-col3">
      <fx-list
        title="Historique Evenements"
        show-add
        show-refresher
        show-search
        show-result
      />
    </div>
  </div>
</template>
<script>

import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxFicheLogement from '@/wikaz-konnect/gdt/gestion-logements/elements/fiche/CxFicheLogement'
import CxListeResidents from '@/wikaz-konnect/gdt/gestion-logements/elements/residents/CxListeResidents'

export default {
    name: 'CxTraitementGestionLogement',
    components: {
        CxListeResidents,
        CxFicheLogement,
    },
    extends: CxBase,
}
</script>
<style scoped lang="scss">
    .cx-traitement-gestion-resident-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 2fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col2 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col3 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

</style>