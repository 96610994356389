import Vue from 'vue'

export const UPDATE_AUTH = (state, auth) => {
    state.auth = auth
}

export const UPDATE_USER = (state, user) => {
    state.user = user
}

export const UPDATE_MENU = (state, menu) => {
    state.menu = menu
}

export const UPDATE_TOKEN = (state, token) => {
    state.auth.accessToken = token
}

export const SET_THEME_SOMBRE = (state, valeur) => {
    window.Vue.$vuetify.theme.dark = valeur
}

export const ADD_EPINGLE = (state, module_id) => {
    window.Vue.$wikaz.request.post('api/wikaz/structure/epingles', { module_id: module_id }).then(() => {
        state.user.epingles.push(module_id)
    })
}

export const REMOVE_EPINGLE = (state, module_id) => {
    window.Vue.$wikaz.request.delete('api/wikaz/structure/epingles/' + module_id).then(() => {
        state.user.epingles.splice(state.user.epingles.indexOf(module_id), 1)
    })
}

export const SET_IS_MOBILE = (state, val) => {
    state.isMobile = val
}
export const SET_ETAT_SIDEBAR = (state, val) => {
    state.sidebar = val
}
export const SET_VIEW_PWA = (state, item) => {
    state.mobileView = item
    state.sidebar = false
}

export const CLEAR_ALL_DATA = (state) => {
    state.auth = {
        isLoggedIn: false,
        accessToken: null,
        refreshToken: null,
        expires_in: null,
        expires_date_heure: null,
    }
    state.user = {
        id: null,
        name: '',
        email: '',
        options: [],
        login: '',
        props: [],
        modules: [],
        epingles: [],
    }
    state.menu = []
    state.mobileView = null
    state.sidebar = false
    state.tabs = []
    state.selectedTab = null
    state.selectedTabIndex = null
}

export const SET_TABS = (state, tabs) => {
    state.tabs = tabs
}

export const SET_SELECTED_TAB = (state, uid) => {
    state.selectedTab = uid
}

export const ADD_TAB = (state, tab) => {
    let comp
    let Mycomp
    let myclass

    switch (parseInt(tab.action)) {
    case 1: // Module
        if (! tab.route) {
            window.Vue.$wikaz.outils.notification.showWarning('Merci de renseigner un nom de module')
            /*window.Vue.$Notice['warning']({
                    title: 'Opération impossible',
                    desc: 'Merci de renseigner un nom de module',
                    duration: 4.5
                });*/
            return false
        }
        comp = Vue.component(tab.route)
        if (! comp) {
            window.Vue.$wikaz.outils.notification.showWarning('Le module doit être importé à la racine de votre projet')
            /*window.Vue.$Notice['warning']({
                    title: 'Opération impossible',
                    desc: 'Le module doit être importé à la racine de votre projet',
                    duration: 4.5
                });*/
            return false
        }
        Mycomp = Vue.extend(comp)
        myclass = new Mycomp()
        if (tab.params) {
            myclass.params = tab
        }
        myclass._props = { moduleInfos: tab }
        state.tabs.push({
            item: tab,
            instance: myclass,
        })
        if (tab.module_id) {
            window.Vue.$wikaz.request.put('api/wikaz/structure/menu/' + tab.module_id, {})
        }
        SET_SELECTED_TAB(state, myclass._uid)
        break
    case 3: // Url
        if (tab.route.indexOf('http') === 0 || tab.route.indexOf('//') === 0) {
            window.open(tab.route)
        } else {
            let config = {
                responseType: 'arraybuffer',
                headers: { 'Content-Type': 'application/json' },
            }
            window.Vue.$wikaz.request.get(tab.module, config).then((response) => {
                let blob = new Blob([response], { type: 'application/pdf' })
                let url = window.URL.createObjectURL(blob)
                window.open(url)
            })
        }
        // return false
        break
    default:
    }
    state.selectedTabIndex = (state.tabs.length - 1)
}

export const REMOVE_TAB = (state, uid) => {
    SET_TABS(state, state.tabs.filter((mappedTab) => {
        return mappedTab.instance._uid !== uid
    }))
    if (state.selectedTab === uid) {
        SET_SELECTED_TAB(state, (state.tabs.length > 0) ? state.tabs[state.tabs.length - 1].instance._uid : null)
    }
}