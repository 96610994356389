<template>
  <div style="padding: 5px">
    <template v-for="(item,index) in datas">
      <v-card
        :key="index"
        style="display:flex;margin-bottom: 5px;padding: 10px"
        :style="{backgroundColor: item.poste_color ? item.poste_color : 'lightgrey'}"
      >
        <cx-item-journee
          :item="item"
          :label="item.label"
        />
        <v-menu
          v-if="item.action === 1"
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <fx-icon
              :size="30"
              color="primary"
              icon="menu"
              tooltip="Menu des actions"
              v-on="on"
            />
          </template>
          <v-list>
            <v-list-item @click="f_proposition(item)">
              <v-list-item-avatar>
                <v-icon color="green">
                  event_note
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Proposer un échange</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>
    </template>
  </div>
</template>

<script>
import CxItemJournee from '@/wikaz-konnect/gdt/mon-planning/CxItemJournee'

export default {
    name: 'CxAllPlanningJournee',
    components: { CxItemJournee },
    data () {
        return { datas: null }
    },
    mounted () {
        this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/mon-planning-edt/planning/' + this.$store.getters.getViewPwa().config.datas.dadate + '/echanges').then((data) => {
                this.datas = data
            })
        },
        getDateFormatted (valeur) {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, 'dddd Do MMMM YYYY')
        },
        f_proposition (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                'api/edt/modules/mon-planning-edt/planning/' + this.$store.getters.getViewPwa().config.datas.dadate + '/echanges/create?utilisateur_id=' + item.utilisateur_id,
                this.f_send_proposition,
            )
        },
        f_send_proposition (item) {
            this.$wikaz.request.post('api/edt/modules/mon-planning-edt/planning/' + this.$store.getters.getViewPwa().config.datas.dadate + '/echanges', item)
        },
        f_retour () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-mon-planning-edt',
                module_libelle: 'Mon Planning',
            })
        },
    },
}
</script>
<style scoped>

</style>