<template>
  <div class="vx-statistiques-edt-container-principal">
    <div class="vx-statistiques-edt-container-header">
      <div style="height: 100%">
        <v-img
          v-if="logo"
          height="110px"
          contain
          :src="'data:image/jpeg;base64,' + logo"
        />
      </div>
      <div style="text-align: center;font-weight: bold;font-size: 40px">
        <div style="text-align: center;font-weight: bold;font-size: 40px">
          SYNTHESE ANNUELLE - HEURES
        </div>
        <div style="text-align: center;font-weight: bold;font-size: 18px">
          {{ $wikaz.getInstance().outils.dates.getDate() }}
        </div>
      </div>
      <fx-selection-annee
        v-model="annee"
        @input="getData"
      />
      <fx-icon
        :size="36"
        icon="refresh"
        color="green"
        @click="getData"
      />
    </div>
    <cx-statistiques
      :datas="datas"
      @update="getData"
    />
  </div>
</template>

<script>
export default {
    name: 'VxStatistiquePlanificationEdt',
    data () {
        return {
            mois: (new Date()).getMonth() + 1,
            annee: (new Date()).getFullYear(),
            datas: null,
            logo: null,
        }
    },
    mounted () {
        this.getLogo()
        this.getData()
    },
    methods: {
        getLogo () {
            this.$wikaz.request.get('api/edt/modules/statistique-planification-edt/logo').then((data) => {
                this.logo = data
            })
        },
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/statistique-planification-edt/statistiques?annee=' + this.annee).then((data) => {
                this.datas = data
            })
        },
    },
}
</script>

<style scoped>
    .vx-statistiques-edt-container-principal {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 120px 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .vx-statistiques-edt-container-header {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 200px 1fr auto 200px;
        grid-template-rows: 1fr;
        align-items: center;
        overflow: auto;
    }
</style>