<template>
  <div
    class="fx-bandeau-container"
    :style="{height: dataProvider.height,
             backgroundColor: dataProvider.colorStart ? dataProvider.colorStart : '#09203F',
             backgroundImage: 'linear-gradient(to top,'+ getColorStart() +' 0%,'+ getColorEnd() +' 100%)'
    }"
  >
    <img
      :src="$icsilab.rootApi + 'fichiers?file=' + dataProvider.imageSrc"
      :height="dataProvider.imageHeight"
    >
    <h1
      class="white--text mb-2 display-2 text-center"
      style="margin-top: 30px"
    >
      {{ dataProvider.titre }}
    </h1>
    <div class="white--text headline subheading mb-4 text-center">
      {{ dataProvider.sousTitre }}
    </div>
    <v-btn
      v-if="dataProvider.boutton"
      class="mt-12"
      color="blue lighten-2"
      large
      href="/pre-made-themes"
      @click.prevent="f_click"
    >
      {{ dataProvider.boutton.label }}
    </v-btn>
  </div>
</template>
<style scoped>
    .fx-bandeau-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
</style>
<script>
export default {
    name: 'FxBandeauLogo',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_click () {
            // backgroundImage: 'linear-gradient(to top,' + dataProvider.colorStart ? dataProvider.colorStart : '#09203F' + ' 0%,'+ dataProvider.colorEnd ? dataProvider.colorEnd : '#537895' +' 100%)'
        },
        getColorStart () {
            return this.dataProvider.colorStart ? this.dataProvider.colorStart : '#09203F'
        },
        getColorEnd () {
            return this.dataProvider.colorEnd ? this.dataProvider.colorEnd : '#537895'
        },
    },
}
</script>