<template>
  <div class="vx-configuration-container">
    <cx-conf-proprietes-compte url-ressource-end-point="api/edt/modules/configurations-gdt/proprietes_compte" />
    <cx-conf-anomalie-min-poste
      style="height: 520px"
      url-ressource-end-point="api/edt/modules/configurations-gdt/anomalie_type_postes"
    />
  </div>
</template>
<script>
import CxConfAnomalieMinPoste from '@/wikaz-konnect/gdt/parametrages/configurations/CxConfAnomalieMinPoste'
import CxConfProprietesCompte from '@/wikaz-konnect/gdt/parametrages/configurations/CxConfProprietesCompte'
export default {
    name: 'VxConfigurationsGdt',
    components: {
        CxConfProprietesCompte,
        CxConfAnomalieMinPoste,
    },
}
</script>

<style scoped lang="scss">
    .vx-configuration-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        // display: grid;
        // grid-template-columns: 1fr;
        // grid-template-rows: auto auto auto;
        // grid-row-gap: 10px;
        display: flex;
        gap: 10px;
        overflow: auto;
    }
</style>