<template>
  <div class="cx-ligne-astreintes-container" />
</template>
<script>
export default { name: 'CxLigneFooter' }
</script>
<style scoped lang="scss">
    .cx-ligne-astreintes-container {
        width: 100%;
        height: 35px;
        display: flex;
        box-sizing: border-box;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #0d6aad;
    }
</style>