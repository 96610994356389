<template>
  <v-combobox
    ref="myInput"
    v-model="model"
    :items="items"
    :filter="filterObject"
    :loading="isLoading"
    :search-input.sync="search"
    hide-details
    outlined
    dense
    @input="$emit('input',$event)"
  />
</template>
<script>
export default{
    name: 'FxAdresseInput',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        editable: {
            default: true,
            type: Boolean,
        },
    },
    data: () => ({
        descriptionLimit: 60,
        items: [],
        isLoading: false,
        model: null,
        search: null,
    }),
    watch: {
        search (val) {
            if (val && val.length >= 4 && val.length % 3 === 0) {
                this.isLoading = true
                this.$wikaz.request.get('api/wikaz/structure/adresse/' + val.replace(',', ''))
                    .then((data) => {
                        this.items = data.map((item) => {
                            return item.label
                        })
                        // this.items = data
                        this.isLoading = false
                    })
            }
        },
    },
    mounted () {
        this.model = this.value
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        filterObject () {
            return true
        },
    },
}
</script>
<style scoped>
</style>