<template>
  <div
    v-if="datas"
    class="fx-agenda-container"
  >
    <div style="background-color: #0D6AAD;border-top-left-radius: 10px;border-top-right-radius: 10px;" />
    <div class="fx-agenda-container-header">
      <div style="background-color: #0D6AAD;display: flex;align-items: center;justify-content: center">
        <fx-icon
          icon="settings"
          tooltip="Configuration"
          @click="showConfig = true"
        />
        <v-dialog
          v-model="showConfig"
          width="300px"
        >
          <v-card
            width="100%"
            height="200px"
            style="padding: 10px"
          >
            <v-card-title>Configuration</v-card-title>
            <v-slider
              v-model="heightQuarter"
              label="Zoom vertical"
              style="margin-top: 40px"
              color="success"
              thumb-color="primary"
              track-color="warning"
              min="12"
              max="28"
            />
          </v-card>
        </v-dialog>
      </div>
      <div style="display: flex;flex-direction: row;overflow: auto;background-color: #0D6AAD">
        <div
          v-for="(item,index) in datas.plannings"
          :key="index"
          class="fx-agenda-element-header"
          :style="{width: getCalculWidth()}"
          :class="getDayClassCss(item)"
        >
          <div style="width: 100%">
            {{ item.label }}
          </div>
          <!--<div><v-img contain height="20px" :src="'data:image/jpeg;base64,'+ item.photo"></v-img></div>-->
          <fx-icon
            icon="portrait"
            :size="20"
            color="white"
            tooltip="voir la photo"
            @click="f_afficher_photo(item.wcu_utilisateur_id)"
          />
          <fx-icon
            v-if="(getDayClassCss(item) === 'activeDay' || getDayClassCss(item) === 'futurDay') && datas.plannings.length > 0"
            style="margin-left: 5px"
            icon="map"
            :size="20"
            color="white"
            tooltip="afficher la tournée"
            @click="showTournee(item.date,item.wcu_utilisateur_id)"
          />
        </div>
      </div>
    </div>
    <div
      ref="obj_calendar"
      v-resize="f_resize"
      class="fx-agenda-container-calendrier"
    >
      <fx-agenda-tranches-horaires
        :height-quarter="heightQuarter"
        :plage_debut="datas.config.plage_debut"
        :plage_fin="datas.config.plage_fin"
      />
      <div
        class="agenda-calendrier"
        :style="{height: getCalculHeight() + 'px'}"
      >
        <div
          v-for="(itemP,index) in datas.plannings"
          :key="index"
          class="agenda-element-calendrier"
          :style="{width: getCalculWidth()}"
          :class="getDayClassCss(itemP)"
        >
          <template v-for="(rdv,index2) in itemP.rdvs">
            <fx-agenda-rdv
              :key="index2"
              :item-renderer-cellule="itemRendererCellule"
              :height-quarter="heightQuarter"
              :data-provider="rdv"
              :plage_debut="datas.config.plage_debut"
              :plage_fin="datas.config.plage_fin"
              @refresh="refresh"
            />
          </template>
        </div>
      </div>
    </div>
    <div style="background-color: #0D6AAD;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px" />
    <v-dialog
      v-model="showPhoto"
      width="300px"
    >
      <div>
        <v-img
          contain
          width="100%"
          :src="'data:image/jpeg;base64,'+ photo"
        />
      </div>
    </v-dialog>
  </div>
  <cx-waiting v-else />
</template>
<script>
import FxAgendaTranchesHoraires from '@/wikaz-core/ui/composants/agenda/FxAgendaTranchesHoraires'
import FxAgendaRdv from '@/wikaz-core/ui/composants/agenda/FxAgendaRdv'

export default {
    name: 'FxAgenda',
    components: {
        FxAgendaRdv,
        FxAgendaTranchesHoraires,
    },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        showToday: {
            type: Boolean,
            default: true,
        },
        itemRendererCellule: { default: null },
        composantCarte: { default: null },
    },
    data () {
        return {
            heightQuarter: 20,
            photo: null,
            showPhoto: false,
            showConfig: false,
        }
    },
    methods: {
        f_resize () {
            let planningDuree = this.datas.config.plage_fin - this.datas.config.plage_debut
            let heightHeure = this.$refs.obj_calendar.clientHeight / planningDuree
            this.heightQuarter = Math.floor(heightHeure) / 4
        },
        getDayClassCss (item) {
            if (item.date === this.$wikaz.outils.dates.getTodayBdd() && item.verrou !== 1) {
                if (this.showToday) {
                    return 'activeDay'
                }
            } else {
                if (item.verrou === 1) {
                    return 'indisponibleDay'
                } else if (item.verrou === 2) {
                    return 'anterieurDay'
                } else if (item.verrou === 3) {
                    return 'verouilleDay'
                }
            }
            return 'futurDay'
        },
        getCalculHeight () {
            return (((this.datas.config.plage_fin - this.datas.config.plage_debut) * 4) * this.heightQuarter) + 1
        },
        getCalculWidth () {
            return 'calc(100%/' + this.datas.plannings.length + ')'
        },
        refresh () {
            this.$emit('refresh')
        },
        showTournee (date, user) {
            if(this.composantCarte) {
                this.$wikaz.popups.show(
                    this.composantCarte,
                    { datas: { urlRessourceEndPoint: 'api/entreprises/modules/plannings-entreprises/tournees?date=' + date + '&user=' + user } },
                )
            }
        },
        f_afficher_photo (user_id) {
            this.photo = this.$wikaz.getPhotoUtilisateur(user_id)
            this.showPhoto = true
        },
    },
}
</script>
<style lang="scss" scoped>

    $activeDay: #75db4c;
    $futurDay: #2480d5;
    $indisponibleDay: #cccccc;
    $inactiveDay: #6a6767;
    $anterieurDay: #c1d9b8;

    .fx-agenda-container {
        height: 100%;
        display: grid;
        // padding: 5px;
        grid-template-columns: 1fr;
        grid-template-rows: 10px 30px 1fr 15px auto;
        overflow: auto;
    }

    .fx-agenda-container-header {
        display: grid;
        // border: 1px solid grey;
        grid-template-columns: 50px 1fr;
        grid-template-rows: 30px;

    }
    .fx-agenda-container-calendrier {
        height: 100%;
        display: grid;
        // border: 1px solid grey;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        grid-template-columns: 50px 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
        background-color: #0D6AAD;
    }

    .fx-agenda-element-header {
        // width: calc(100%/7);
        max-width: 260px;
        min-width: 30px;
        text-align: center;
        align-items:center;
        display:flex;
        justify-content:center;
        overflow: auto;
        // border: 1px solid grey;
        border-bottom: 1px solid grey;
        border-left: 1px solid grey;

        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-sizing: border-box;
        background-color: #4590ec;
        color: white;
        align-items: center;
        padding-right: 5px;

        &.activeDay {
            background-color: $activeDay;
            color: black;
        }
        &.indisponibleDay {
            background-color: $indisponibleDay;
            color: black;
        }
        &.verouilleDay {
            background-color: $inactiveDay;
            color: white;
        }
        &.anterieurDay {
            background-color: $anterieurDay;
            color: black;
        }
        &.futurDay {
            background-color: $futurDay;
            color: white;
        }
    }

    .agenda-calendrier {
        display: flex;
        flex-direction: row;
        overflow: auto;
    }

    .agenda-element-calendrier {
        // border: 1px solid grey;
        border-right: 1px solid grey;
        min-width: 30px;
        max-width: 260px;
        position: relative;
        &.activeDay {
            background-color: $activeDay;
        }
        &.verouilleDay {
            background-color: $inactiveDay;
        }
        &.anterieurDay {
            background-color: $anterieurDay;
        }
        &.indisponibleDay {
            background-color: $indisponibleDay;
        }
        &.futurDay {
            background-color: #8daaf3;
        }
    }

</style>