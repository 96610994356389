<template>
  <div v-if="tooltip && !isMobile">
    <v-tooltip
      ref="tooltip"
      :bottom="tooltipBottom"
      :right="tooltipRight"
      :left="tooltipLeft"
      :top="tooltipTop"
      :open-delay="300"
    >
      <template v-slot:activator="{ on }">
        <v-badge
          v-if="badge"
          overlap
          bordered
          color="green"
          :content="badge"
        >
          <v-icon
            :size="size"
            :color="color"
            v-on="on"
            @click="$emit('click',$event)"
          >
            {{ icon }}
          </v-icon>
        </v-badge>
        <v-icon
          v-else
          :size="size"
          :color="color"
          v-on="on"
          @click="$emit('click',$event)"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span
        style="white-space: pre-line;"
        v-html="tooltip"
      />
    </v-tooltip>
  </div>
  <div v-else>
    <v-badge
      v-if="badge"
      overlap
      bordered
      color="green"
      :content="badge"
    >
      <v-icon
        :size="size"
        :color="color"
        @click="$emit('click',$event)"
      >
        {{ icon }}
      </v-icon>
    </v-badge>
    <v-icon
      v-else
      :size="size"
      :color="color"
      @click="$emit('click',$event)"
    >
      {{ icon }}
    </v-icon>
  </div>
</template>
<script>
export default {
    name: 'FxIcon',
    props: {
        icon: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        tooltipPosition: {
            type: String,
            default: 'right',
        },
        badge: { default: null },
        size: {
            type: Number,
            default: 22,
        },
        color: {
            type: String,
            default: 'blue',
        },
    },
    data () {
        return {
            tooltipTop: (this.tooltipPosition === 'top'),
            tooltipRight: (this.tooltipPosition === 'right'),
            tooltipBottom: (this.tooltipPosition === 'bottom'),
            tooltipLeft: (this.tooltipPosition === 'left'),
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
}
</script>