<template>
  <div
    v-if="datas"
    class="cx-detail-container"
  >
    <div class="zn-principale">
      <div class="zn-evenement-container">
        <cx-ligne-header label="Evenements" />
        <div style="border-left: 1px solid black;border-right: 1px solid black;overflow-y:auto">
          <ix-item-evenement
            v-for="(item,index) in datas.evenements"
            :key="index"
            :item="item"
          />
        </div>
        <cx-ligne-footer />
      </div>
      <div class="zn-planning-container">
        <cx-ligne-header-detail
          :datas="datas.heures"
          :label="$wikaz.getInstance().outils.dates.getDate(datas.date,'dddd DD MMM YYYY')"
          :largeur-gauche="largeurGauche"
          label-field="jour"
        />
        <!--<cx-ligne-anomalies :datas="datas" :largeur-gauche="largeurGauche"></cx-ligne-anomalies>-->
        <div style="overflow: auto">
          <cx-ligne-planning
            v-for="(item,index) in datas.agents"
            :key="index"
            :item="item"
            :datas="datas"
            :largeur-gauche="largeurGauche"
          />
        </div>
        <cx-ligne-footer />
      </div>
    </div>
    <div style="height: 30px;display: flex;justify-content: space-between;margin-top: 10px">
      <v-btn
        :small="true"
        color="primary"
        style="margin-left: 10px"
        @click="f_imprimer_planning"
      >
        Imprimer
      </v-btn>
      <v-btn
        :small="true"
        color="red"
        style="margin-left: 10px"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </div>
  <cx-waiting v-else />
</template>
<script>

import CxLignePlanning from './CxLignePlanning'
import IxItemEvenement from '@/wikaz-konnect/gdt/planning/planning-elements/evenements/IxItemEvenement'
import CxLigneHeader from '@/wikaz-core/ui/composants/statistiques/statistiques-elements/header/CxLigneHeader'
import CxLigneFooter from '@/wikaz-core/ui/composants/statistiques/statistiques-elements/footer/CxLigneFooter'
import CxLigneHeaderDetail from '@/wikaz-konnect/gdt/planning/planning-elements/jours/CxLigneHeaderDetail'
export default {
    name: 'CxDetailJournee',
    components: {
        CxLigneHeaderDetail,
        CxLigneFooter,
        CxLigneHeader,
        IxItemEvenement,
        CxLignePlanning,
    },
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return { datas: null }
    },
    watch: {
        urlRessourceEndPoint: function () {
            this.getDatas()
        },
    },
    mounted () {
        this.getDatas()
    },
    methods: {
        getDatas () {
            this.datas = null
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_imprimer_planning () {
            this.$wikaz.request.get('api/edt/modules/planning-edt/planning/' + this.datas.date)
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-detail-container {
        font-size: 13px;
        width: 100%;
        height: 700px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 40px;
        overflow: auto;
        background-color: white;
        padding: 10px;
    }

    .zn-principale {
        height: 640px;
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
    }

    .zn-evenement-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
    }

    .zn-planning-container {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        border: 1px solid black;
        border-radius: 10px;
        overflow: auto;
    }
</style>