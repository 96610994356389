<template>
  <cx-explorateur-fichier
    :extension-array="['pdf','jpg','png','jpeg']"
    titre="Documents Divers"
    show-import
    show-refresher
    :url-ressource-end-point="urlRessourceEndPoint"
  />
</template>

<script>
export default {
    name: 'CxDocumentsResidentsDivers',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>