<template>
  <div style="display: flex;flex-direction: column;gap: 5px;overflow: auto">
    <div style="display: flex">
      <fx-titre titre="Fiche" />
      <fx-icon
        icon="create"
        color="primary"
        tooltip="Modifier la fiche"
        @click="f_modifier"
      />
    </div>
    <fx-champ
      v-if="datas"
      label-width="120px"
      label="Nom / Prénom"
      :text="datas.resident_nom + ' ' + datas.resident_prenom"
    />
    <fx-champ
      v-if="datas"
      label-width="120px"
      label="Num. sécu. sociale"
      :text="datas.resident_securite_sociale"
    />
    <fx-champ
      v-if="datas"
      label-width="120px"
      label="Date de naissance"
      :text="datas.resident_date_naissance ? $wikaz.outils.dates.getDate(datas.resident_date_naissance) : ''"
    />
  </div>
</template>

<script>
export default {
    name: 'CxFicheResident',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint, data).then(()=> {
                    this.getData()
                })
            })
        },
    },
}
</script>

<style scoped>

</style>