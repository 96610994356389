<template>
  <div class="fx-list-container">
    <div>
      <fx-titre
        v-if="title"
        :titre="title"
      />
      <!--<v-card-title v-if="title">{{title}}</v-card-title>-->
    </div>
    <div>
      <slot name="list-filtre" />
    </div>
    <div
      v-if="showSearch || showResult || showRefresher || showAdd"
      class="fx-list-container-filtre"
      style="width:100%;display: flex;justify-content: space-between;align-items: center"
    >
      <v-text-field
        v-if="showSearch"
        v-model="searchField"
        hide-details
        dense
        outlined
        placeholder="Filtre"
        style="width:100%;"
      />
      <span
        v-if="showResult && myDatas"
        style="width:35px;padding:0 4px;text-align: center"
      ><small>{{ myDatas.length }}<span v-if="total">/{{ total }}</span></small></span>
      <div
        class="buttons"
        style="display: flex;flex-direction: row"
      >
        <div
          v-if="showMove"
          style="margin:0 12px;display:flex;"
        >
          <fx-icon
            :disabled="hasSelection || isLast"
            icon="keyboard_arrow_down"
            color="#319ace"
            @click="move('down')"
          />
          <fx-icon
            :disabled="hasSelection || isFirst"
            icon="keyboard_arrow_down"
            color="#319ace"
            @click="move('up')"
          />
        </div>
        <v-btn
          v-if="showRefresher"
          icon
          @click="refresh()"
        >
          <v-icon
            color="#319ace"
            dense
          >
            refresh
          </v-icon>
        </v-btn>
        <fx-export-button
          v-if="showExport && exportData.length > 0"
          :tooltip="tooltipExport"
          :filename="filenameExport"
          :uri-ressource="exportRessource"
          :type="exportType"
          :json="JSON.stringify(exportData)"
        />
        <v-btn
          v-if="showAdd"
          icon
          @click="add()"
        >
          <v-icon
            color="#42c831"
            dense
          >
            add
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showEdit"
          icon
          @click="edit()"
        >
          <v-icon
            color="#319ace"
            dense
          >
            create
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showDelete"
          icon
          @click="deleteSelection()"
        >
          <v-icon
            color="#ff2200"
            dense
          >
            close
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showWarning"
          icon
          @click="warning()"
        >
          <v-icon
            color="#f7b217"
            dense
          >
            warning
          </v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-else
      style="height: 10px"
    />
    <fx-list-simple
      ref="list"
      :is-draggable="isDraggable"
      :selected.sync="selected"
      :data-provider.sync="myDatas"
      :label-field="labelField"
      :item-renderer="itemRenderer"
      :highlight="highlight"
      :clean="clean"
      :url="url"
      :disabled="disabled"
      :loading="loading"
      :draggable-group-name="draggableGroupName"
      v-on="listeners"
      @on-dblclick="dblClick($event)"
      @updateDatas="updateDatas($event)"
      @updateChange="updateChange($event)"
      @actualiser="refresh()"
    />
    <!--<slot name="footer" v-if='$slots.footer'></slot>-->
  </div>
</template>
<script>
export default {
    name: 'FxList',
    props: {
        idField: { default: null },
        labelField: { default: 'label' },
        dataProvider: {
            default () {
                return []
            },
        },
        clean: {
            default: false,
            type: Boolean,
        },
        showSearch: {
            default: false,
            type: Boolean,
        },
        isDraggable: {
            default: false,
            type: Boolean,
        },
        draggableGroupName: {
            default: 'element',
            type: String,
        },
        showMove: {
            default: false,
            type: Boolean,
        },
        showDelete: {
            default: false,
            type: Boolean,
        },
        showEdit: {
            default: false,
            type: Boolean,
        },
        itemRenderer: { default: null },
        showAdd: {
            default: false,
            type: Boolean,
        },
        showWarning: {
            default: false,
            type: Boolean,
        },
        showNext: {
            default: false,
            type: Boolean,
        },
        title: {
            default: null,
            type: String,
        },
        total: {
            default: 0,
            type: Number,
        },
        showResult: {
            default: false,
            type: Boolean,
        },
        showRefresher: {
            default: false,
            type: Boolean,
        },
        highlight: {
            default: true,
            type: Boolean,
        },
        height: {
            default: '100%',
            type: String,
        },
        width: {
            default: '100%',
            type: String,
        },
        url: {
            default: null,
            type: String,
        },
        item: { default: null },
        preSearch: {
            type: String,
            default: '',
        },
        showExport: {
            type: Boolean,
            default: false,
        },
        tooltipExport: {
            type: String,
            default: '',
        },
        filenameExport: {
            type: String,
            default: 'export',
        },
        exportRessource: {
            type: String,
            default: 'exporter',
        },
        exportType: {
            type: String,
            default: 'csv',
        },
        preSelectedAttribute: { default: 'id' },
        preSelectedValue: { default: null },
        disabled: {
            default: false,
            type: Boolean,
        },
        overflow: { default: 'visible' },
        showLoading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            myItemRenderer: '',
            searchField: this.preSearch,
            loading: false,
            querying: false,
            myDatas: [],
            selected: null,
            exportData: [],
            cancelRequest: null,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    computed: {
        hasSelection () {
            return this.selected === null
        },
        isLast () {
            return this.myDatas.indexOf(this.selected) === (this.myDatas.length - 1)
        },
        isFirst () {
            return this.myDatas.indexOf(this.selected) === 0
        },
        listeners () {
            Object.keys(this.$listeners).forEach((item) => {

                this.$listeners[item] = (event) => this.$emit(item, event)
            })
            return { ...this.$listeners }
        },
    },
    watch: {
        dataProvider: function (newVal) {
            // this.loading = true
            this.search()
            if (newVal) {
                // this.loading = false
            }
            if (this.showExport) {
                this.buildExportData()
            }
        },
        searchField: function () {
            this.search()
        },
        url: function () {
            this.getDataProvider()
        },
        showLoading: function (newVal) {
            this.loading = newVal
        },
    },
    mounted () {
        this.search()
        this.getDataProvider(true)
    },
    methods: {
        handleScroll: function () {
            this.$emit('requestNext')
        },
        search () {
            if (this.searchField.length > 2 && this.dataProvider.length > 0) {
                this.myDatas = this.$wikaz.outils.filters.filterList(this.searchField, this.dataProvider)
            } else {
                this.myDatas = this.dataProvider
            }
            this.preSelectItem(false)
        },
        add: function () {
            this.$emit('add', this.selected)
        },
        edit: function () {
            this.$emit('edit', this.selected)
        },
        warning: function () {
            this.$emit('warning')
        },
        refresh: function () {
            this.myDatas = []
            this.selected = null
            this.getDataProvider()
            this.$emit('refresh')
        },
        event (event) {
            this.$emit(event.label, event.object)
        },
        itemEmitting: function (e) {
            if (this.idField != null) {
                this.$emit('itemEmitting', e[this.idField])
            } else {
                this.$emit('itemEmitting', e)
            }
        },
        dblClick: function (e) {
            this.$emit('on-dblclick', e)
        },
        updateDatas (ev) {
            this.$emit('updateDatas', ev)
        },
        updateChange (ev) {
            this.value = this.selected
            this.$emit('updateChange', ev)
        },
        move (dir) {
            this.$emit('updateOrder', {
                dir: dir,
                item: this.selected,
            })
        },
        deleteSelection () {
            this.$emit('deleteItem', this.selected)
        },
        getSelectedItem () {
            return this.selected
        },
        select (index) {
            setTimeout(() => {
                if (this.$refs['list']) {
                    this.$refs['list'].select(index)
                }
            }, 50)
        },
        selectById (prop, id) {
            setTimeout(() => {
                this.myDatas.map((item) => {
                    if (item[prop] === id) {
                        this.selected = item
                    }
                })
            }, 80)
        },
        getDataProvider (preselect = false) {
            if (this.url) {
                this.loading = true

                if (this.cancelRequest !== null) {
                    this.cancelRequest.abort()
                }
                this.cancelRequest = new AbortController()
                this.$wikaz.request.request('GET', this.url, null, null, this.cancelRequest).then((data) => {
                    this.myDatas = data
                    this.loading = false
                    this.$emit('update:dataProvider', data)
                    this.preSelectItem(preselect)
                })
            } else {
                this.preSelectItem(preselect)
            }
        },
        preSelectItem () {
            if (this.myDatas) {
                // if (preselect && this.preSelectedValue) {
                if (this.preSelectedValue) {
                    this.selectById(this.preSelectedAttribute, this.preSelectedValue)
                    this.itemEmitting(this.selected)
                }
            }
        },
        buildExportData () {
            this.exportData = []
            this.dataProvider.forEach((item) => {
                let temp = {}
                Object.keys(item).forEach((key) => {
                    if (typeof item[key] !== 'object') {
                        // Exclusion des objets et tableaux
                        if (typeof item[key] !== 'string' || ! this.isJson(item[key])) {
                            // suppression des sauts de lignes, point-virgules et autres caractères problèmatiques
                            temp[key] = String(item[key]).replace(/(?:\r\n|\r|\n)/g, '').replace(';', '.')
                        }
                    }
                })
                this.exportData.push(temp)
            })
        },
        isJson (value) {
            try {
                JSON.parse(value)
            } catch (e) {
                return false
            }
            return true
        },
    },
}
</script>
<style scoped lang="scss">
    .fx-list-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 1fr;
        row-gap: 3px;
    }

    .fx-list-container-filtre {

    }
</style>