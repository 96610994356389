<template>
  <div
    class="cx-ligne-jours-label-container"
    @contextmenu.prevent=""
  >
    <div
      :style="{width: largeurGauche + 'px'}"
      class="cx-ligne-jours-label-cartouche"
      @contextmenu.prevent=""
    >
      Semaines
    </div>
    <template v-for="(item,index) in datas.dates">
      <div
        :key="index"
        class="cx-ligne-jours-label-case"
        :style="{width: 'calc((100% - '+largeurGauche+'px)/'+datas.dates.length+')'}"
        @click="f_open_detail(item.dadate)"
        @contextmenu.prevent=""
      >
        <div
          class="cx-ligne-jours-label-case-element"
          :style="{backgroundColor: ( item.dadate === $wikaz.getInstance().outils.dates.getTodayBdd() ) ? '#65E5BE' : ( item.dimanche === 1 ) ? 'grey' : ( item.feries === 1 ) ? 'lightgrey' : '#0d6aad'}"
        >
          <div style="cursor: pointer">
            {{ item.semaine }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
    name: 'CxLigneSemainesLabel',
    props: {
        datas: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-jours-label-container {
        width: 100%;
        height: 20px;
        display: flex;
        box-sizing: border-box;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        // border-top: 1px solid black ;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-jours-label-cartouche {
        background-color: #0d6aad;
        border-top-left-radius: 10px;
        text-align: center;
    }

    .cx-ligne-jours-label-case {
        border-left: 1px solid black;
        // border-top: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        &:last-child {
            border-top-right-radius: 10px;
        }
    }

    .cx-ligne-jours-label-case-element {
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.isToday {
            color: #313131;
            background-color: #b7f8b7 !important;
        }
    }

</style>