<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-traitement-element="composantTraitementElement"
    :composant-filtre-element="composantFiltreElement"
    titre-liste="Mes utilisateurs"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>
<script>
import IxTraitement from './IxUtilisateurs.vue'
import CxTraitement from './CxTraitementUtilisateurs'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxFiltreUtilisateurs from '@/wikaz-konnect/commun/gestion-utilisateurs/CxFiltreUtilisateurs'
export default {
    name: 'VxGestionUtilisateursWikaz',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-utilisateurs-wikaz/utilisateurs',
            composantTraitementElement: this.$wikaz.outils.importation.elementByComponent(CxTraitement),
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreUtilisateurs),
            itemRenderer: IxTraitement,
        }
    },
}
</script>