<template>
  <div
    width="100%"
    height="300px"
    style="display: flex;flex-direction: column;align-items: center"
    gap="10px"
  >
    <fx-titre :titre="titre" />
    <div
      class="avatar"
      style="width: 150px;height: 160px; justify-content: center"
    >
      <span v-if="avatar !== null">
        <img
          :src="'data:image/jpeg;base64,'+avatar"
          class="img-circle"
          alt="Avatar"
          width="140px"
          height="160px"
          style="object-fit: contain"
        >
      </span>
    </div>
    <v-file-input
      style="width: 100%"
      label="Sélectionnez un fichier"
      dense
      prepend-icon="photo_camera"
      @change="onFileChange"
    />
  </div>
</template>
<script>
export default {
    name: 'CxPhotoProfil',
    props: {
        titre: {
            type: String,
            default: 'Photo utilisateur',
        },
        urlRessource: {
            type: String,
            default: null,
        },
        field: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            avatar: null,
            image: '',
        }
    },
    mounted () {
        this.getProfile()
    },
    methods: {
        getProfile () {
            this.$wikaz.request.get(this.urlRessource).then((data) => {
                if (data) {
                    if (this.field) {
                        this.avatar = data[this.field]
                    } else {
                        this.avatar = data
                    }

                }
            })
        },
        onFileChange (e) {
            this.file = e
            this.createImage(this.file)
            this.upload()

        },
        createImage (file) {
            let reader = new FileReader()
            reader.onload = (e) => {
                this.image = e.target.result
            }
            reader.readAsDataURL(file)
        },
        upload () {
            // var data = {avatar: this.file};
            this.$wikaz.request.post(this.urlRessource, this.file).then(() => {
                this.getProfile()
            })
        },
    },
}
</script>
<style scoped>
    #file {  display: none}

    .file_label {
        display: block;
        font-size: 20px;
        font-family: Arial;
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: #e67e22;
        color: #FFF;
        text-align: center;
        border: 1px solid #CCC;
        border-radius: 5px;
        transition: all 0.3s ease-in-out
    }

    .file_label:hover {
        background-color: #f39c12;
        color: #ffffff;
    }
</style>