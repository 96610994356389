<template>
  <div
    class="text-center"
    style="width: 100%;display: flex;align-items: center;justify-content: center"
  >
    <v-progress-circular
      :size="size"
      :width="7"
      color="purple"
      indeterminate
    />
  </div>
</template>
<script>
export default {
    name: 'CxWaiting',
    props: {
        texte: {
            type: String,
            default: null,
        },
        size: {
            type: Number,
            default: 70,
        },
    },
}
</script>