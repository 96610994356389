<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-traitement-element="composantTraitement"
    titre-liste="Clients"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import IxGestionClients from '@/wikaz-konnect/gdt/gestion-clients-sap/IxGestionClients.vue'
import CxTraitementGestionClients from '@/wikaz-konnect/gdt/gestion-clients-sap/CxTraitementGestionClients.vue'

export default {
    name: 'VxGestionClientsSap',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/edt/modules/gestion-clients-sap/clients',
            composantTraitement: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionClients),
            itemRenderer: IxGestionClients,
        }
    },
}
</script>