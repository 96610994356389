<template>
  <div
    class="vx-maintenance-gdt-container"
    :style="{width: isMobile ? '100%' : '450px'}"
  >
    <div style="font-weight: bold;font-size: large">
      Liste des demandes
    </div>
    <div style="display: flex;align-items: center">
      <v-text-field
        v-model="search"
        dense
        append-icon="search"
        label="Filtre"
        hide-details
        outlined
      />
      <v-btn
        style="margin-left: 10px"
        color="primary"
        @click="getData"
      >
        Actualiser
      </v-btn>
    </div>
    <v-list
      v-if="data"
      style="overflow: auto"
    >
      <v-list-item
        v-for="(item, index) in filteredList"
        :key="index"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.compte_raison_sociale" />
          <v-list-item-subtitle v-text="item.utilisateur +' ( '+item.email+ ' )'" />
          <v-divider />
          <v-list-item-subtitle
            style="white-space: pre-line;font-weight: bold;
                                                 margin-top: 5px;margin-bottom: 5px"
            v-text="item.support_objet"
          />
          <v-list-item-subtitle
            style="white-space: pre-line;"
            v-text="item.support_description"
          />
          <div style="display: flex;justify-content: flex-end">
            <v-btn
              color="success"
              @click="terminer(item)"
            >
              Terminé
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <cx-waiting
      v-else
      texte="Chargement des techniciens"
    />
  </div>
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'

export default {
    name: 'VxSupport',
    extends: VxBase,
    data () {
        return {
            data: null,
            search: '',
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    computed: {
        filteredList () {
            return this.$wikaz.outils.filters.filterBy(this.search.toLowerCase(), this.data)
        },
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.data = null
            this.$wikaz.request.get('api/wikaz/structure/supports').then((data) => {
                this.data = data
            })
        },
        terminer (item) {
            this.$wikaz.request.put('api/wikaz/structure/supports/' + item.support_id).then(() => {
                this.getData()
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-maintenance-gdt-container {
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>