<template>
  <div
    class="cx-edit-pointage-container-principal"
    :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
  >
    <div v-if="datas">
      <div style="font-size: 18px;font-weight: bold;height: 30px;display: flex;align-items: center;justify-content: center">
        {{ $wikaz.getInstance().outils.dates.getDate(datas.dadate,'DD/MM/YYYY') }}
      </div>
      <div>
        <fx-titre titre="Planification" />
        <div style="display: flex;align-items: center;margin-top: 10px">
          <div style="width: 150px">
            Poste
          </div>
          <fx-dropdown
            v-model="selectedPosteId"
            pre-holder="Sélectionner un poste"
            :data-provider.sync="dataPostes"
            id-field="poste_id"
            label-field="poste_libelle"
            @input="f_selection_poste_change"
          />
        </div>
        <div v-if="dataPostes && selectedPosteId > 0">
          <div style="display: flex;align-items: center">
            <div style="width: 150px">
              Heure début
            </div>
            <fx-heurefield v-model="selectedHeureDebut" />
          </div>
          <div style="display: flex;align-items: center;margin-left: 35px">
            <div style="width: 150px">
              Coupure début
            </div>
            <fx-heurefield v-model="selectedHeureDebutCoupure" />
          </div>
          <div style="display: flex;align-items: center;margin-left: 35px">
            <div style="width: 150px">
              Coupure fin
            </div>
            <fx-heurefield v-model="selectedHeureFinCoupure" />
          </div>
          <div style="display: flex;align-items: center">
            <div style="width: 150px">
              Heure fin
            </div>
            <fx-heurefield v-model="selectedHeureFin" />
          </div>
          <div style="display: flex;align-items: center">
            <div style="width: 150px">
              Heure manuelle
            </div>
            <div style="width: 150px">
              <fx-number-input v-model="heureManuelle" />
            </div>
          </div>
          <div style="display: flex;align-items: center">
            <div style="width: 150px">
              Heure supplémentaire
            </div>
            <fx-checkbox
              v-model="heureSupplementaire"
              :true-value="1"
              :false-value="0"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <fx-checkbox
        v-model="recurrence.etat"
        label="Réccurence"
        style="width: 200px"
      />
    </div>
    <div
      v-if="recurrence.etat"
      style="display: flex;flex-wrap: wrap;margin-left: 40px"
    >
      <fx-checkbox
        v-model="recurrence.lundi"
        :true-value="2"
        :false-value="0"
        label="Lundi"
      />
      <fx-checkbox
        v-model="recurrence.mardi"
        :true-value="3"
        :false-value="0"
        label="Mardi"
      />
      <fx-checkbox
        v-model="recurrence.mercredi"
        :true-value="4"
        :false-value="0"
        label="Mercredi"
      />
      <fx-checkbox
        v-model="recurrence.jeudi"
        :true-value="5"
        :false-value="0"
        label="Jeudi"
      />
      <fx-checkbox
        v-model="recurrence.vendredi"
        :true-value="6"
        :false-value="0"
        label="Vendredi"
      />
      <fx-checkbox
        v-model="recurrence.samedi"
        :true-value="7"
        :false-value="0"
        label="Samedi"
      />
      <fx-checkbox
        v-model="recurrence.dimanche"
        :true-value="1"
        :false-value="0"
        label="Dimanche"
      />
    </div>
    <div
      v-if="datas"
      style="display: flex;justify-content: flex-end;margin-top: 10px"
    >
      <v-btn
        :small="true"
        :loading="loadingPointage"
        color="success"
        style="margin-left: 10px"
        @click="f_valider"
      >
        Valider
      </v-btn>
      <v-btn
        :small="true"
        color="error"
        style="margin-left: 10px"
        @click="$emit('input',false)"
      >
        Fermer
      </v-btn>
    </div>
  </div>
</template>

<script>
import FxNumberInput from '@/wikaz-core/ui/composants/inputs/FxNumberInput'
export default {
    name: 'CxEditPointage',
    components: { FxNumberInput },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        dataPostes: {},
    },
    data () {
        return {
            loadingPoste: false,
            loadingPointage: false,
            selectedPosteId: this.datas.poste,
            selectedHeureDebut: (this.datas && this.datas.heure_debut) ? this.datas.heure_debut : null,
            selectedHeureFin: (this.datas && this.datas.heure_fin) ? this.datas.heure_fin : null,
            selectedHeureDebutCoupure: (this.datas && this.datas.pointage_heure_debut_coupure) ? this.datas.pointage_heure_debut_coupure : null,
            selectedHeureFinCoupure: (this.datas && this.datas.pointage_heure_fin_coupure) ? this.datas.pointage_heure_fin_coupure : null,
            heureManuelle: ((this.datas && this.datas.pointage_heures_manuel) || (this.datas.pointage_heures_manuel === 0)) ? this.datas.pointage_heures_manuel : null,
            heureSupplementaire: (this.datas && this.datas.pointage_heures_supplementaire) ? this.datas.pointage_heures_supplementaire : 0,
            recurrence: {
                etat: false,
                lundi: false,
                mardi: false,
                mercredi: false,
                jeudi: false,
                vendredi: false,
                samedi: false,
                dimanche: false,
            },
        }
    },
    methods: {
        f_valider () {
            this.loadingPoste = true
            let arrayDayRecurrence = []
            if(this.recurrence.etat) {
                if (this.recurrence.lundi) {
                    arrayDayRecurrence.push(this.recurrence.lundi)
                }
                if (this.recurrence.mardi) {
                    arrayDayRecurrence.push(this.recurrence.mardi)
                }
                if (this.recurrence.mercredi) {
                    arrayDayRecurrence.push(this.recurrence.mercredi)
                }
                if (this.recurrence.jeudi) {
                    arrayDayRecurrence.push(this.recurrence.jeudi)
                }
                if (this.recurrence.vendredi) {
                    arrayDayRecurrence.push(this.recurrence.vendredi)
                }
                if (this.recurrence.samedi) {
                    arrayDayRecurrence.push(this.recurrence.samedi)
                }
                if (this.recurrence.dimanche) {
                    arrayDayRecurrence.push(this.recurrence.dimanche)
                }
            }

            let dataParams = {
                pointage_date: this.datas.dadate,
                pointage_utilisateur_id: this.datas.utilisateur_id,
                pointage_id: this.datas.pointage_id,
                pointage_poste_id: this.selectedPosteId,
                pointage_heure_debut: this.selectedHeureDebut,
                pointage_heure_fin: this.selectedHeureFin,
                pointage_heure_debut_coupure: this.selectedHeureDebutCoupure,
                pointage_heure_fin_coupure: this.selectedHeureFinCoupure,
                pointage_heures_manuel: this.heureManuelle,
                pointage_heures_supplementaire: this.heureSupplementaire,
                recurrence: JSON.stringify({
                    etat: this.recurrence.etat,
                    jours: arrayDayRecurrence.join(','),
                }),
            }
            this.$wikaz.request.post('api/edt/modules/planning-edt/planning/' + this.datas.dadate + '/pointages', dataParams).then(() => {
                this.$emit('update')
                this.loadingPoste = false
            })
        },
        f_selection_poste_change (id) {
            let item = this.getDataItem(id)
            this.selectedHeureDebut = item.poste_heure_debut ? item.poste_heure_debut : null
            this.selectedHeureFin = item.poste_heure_fin ? item.poste_heure_fin : null
            this.selectedHeureDebutCoupure = item.poste_coupure_debut ? item.poste_coupure_debut : null
            this.selectedHeureFinCoupure = item.poste_coupure_fin ? item.poste_coupure_fin : null
        },
        getDataItem (id) {
            for (let i = 0; i < this.dataPostes.length; i ++) {
                if (this.dataPostes[i].poste_id === id) {
                    return this.dataPostes[i]
                }
            }
        },
        f_set_astreinte (etat_astreinte) {
            this.$wikaz.request.put('api/edt/modules/planning-edt/planning/' + this.datas.pointage_id + '/astreintes/' + etat_astreinte)
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-edit-pointage-container-principal {
        font-size: 13px;
        width: 500px;
        height: auto;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>