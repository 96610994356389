<template>
  <v-card
    class="cx-archive-container"
    :style="{width: isMobile ? 'auto' : width,height: isMobile ? '400px' : width, padding: isPopup ? '5px' : '0'}"
  >
    <cx-explorateur-fichier
      v-if="urlRessourceEndPoint"
      :show-import="showImport"
      :extension-array="extensionArray"
      show-refresher
      :titre="titre"
      :width="width"
      :height="height"
      :url-ressource-end-point="urlRessourceEndPoint"
      @close="close"
    />
    <div
      v-if="isPopup"
      style="display: flex;justify-content: flex-end;height: 25px;margin-top: 5px"
    >
      <v-btn
        color="error"
        small
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
    <div v-else />
  </v-card>
</template>

<script>

export default {
    name: 'CxPopupArchivesIntervention',
    data () {
        return {
            width: 'auto',
            height: '500px',
            urlRessourceEndPoint: null,
            titre: '',
            extensionArray: [],
            isPopup: false,
            showImport: true,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
    },
}
</script>

<style scoped>
    .cx-archive-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
</style>