/*export const isModuleAllowed = state => (id = null) => {
    return state.user.modules ? state.user.modules.indexOf(id) > -1 : false
};*/

/*export const getUserInfos = state => () => {
    return state.user
};*/

export const getMenu = state => () => {
    return state.menu
}
export const isMobile = state => () => {
    return state.isMobile
}

export const getViewPwa = state => () => {
    return state.mobileView
}

/*export const getTokenExpireState = state => () => {
    let dateHeureExpire = state.auth.expires_date_heure;
    let dateHeure = new Date();
    return (dateHeure < dateHeureExpire)
};*/

export const getAuthState = state => () => {
    return state.auth.isLoggedIn
}

/*export const getToken = state => () => {
    return state.auth.accessToken
};*/

/*export const userHasOption = state => (id = null) => {
    return state.user.options ? state.user.options.indexOf(id) > -1 : false
};*/

export const userHasEpingle = state => (id = null) => {
    return state.user.epingles ? state.user.epingles.indexOf(id) > - 1 : false
}

export const getTabs = state => () => {
    return state.tabs
}

export const getSelectedTab = state => () => {
    return state.selectedTab
}

/*export const getSelectedTabIndex = state => () => {
    return state.selectedTabIndex
};*/