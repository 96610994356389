<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :composant-filtre-element="composantFiltreElemen"
    titre-liste="Logements"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>

<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementGestionLogement from '@/wikaz-konnect/gdt/gestion-logements/CxTraitementGestionLogement'
import CxTraitementGestionLogementPwa from '@/wikaz-konnect/gdt/gestion-logements/CxTraitementGestionLogementPwa'
import IxLogement from '@/wikaz-konnect/gdt/gestion-logements/IxLogement'
import CxFiltreLogements from '@/wikaz-konnect/gdt/gestion-logements/CxFiltreLogements'

export default {
    name: 'VxGestionLogements',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/edt/modules/gestion-logements/logements',
            composantFiltreElemen: this.$wikaz.outils.importation.elementByComponent(CxFiltreLogements),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionLogement),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionLogementPwa),
            itemRenderer: IxLogement,
        }
    },
}
</script>
<style scoped>
</style>