<template>
  <div class="vx-statistique-facturation-container">
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="primary"
        @click="getData"
      >
        Actualiser
      </v-btn>
    </div>
    <fx-chart
      v-if="datas"
      :data-provider="datas"
    />
  </div>
</template>

<script>
import FxChart from '@/wikaz-core/ui/composants/charts/FxChart'
export default {
    name: 'VxStatistiqueFacturation',
    components: { FxChart },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get('api/wikaz/modules/statistique-facturation-adm/statistiques').then((data) => {
                this.datas = data
            })
        },
    },
}
</script>
<style scoped>
    .vx-statistique-facturation-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>