<template>
  <div
    v-if="datas"
    :key="keyChange"
    class="cx-traitement-profil-container"
  >
    <fx-formulaire-generique
      v-if="index===0"
      :data-in="datas"
      :url-ressource="'api/wikaz/modules/gestion-profils/profils/'+datas.profil_id+'/edit'"
      @on-submit="updateData"
    />
    <div
      v-else-if="index===1"
      style="overflow: auto;padding: 10px"
      title="Modules"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-profils/profils/' + datas.profil_id + '/affectation-modules'"
        :url-ressouce-update="'api/wikaz/modules/gestion-profils/profils/' + datas.profil_id + '/affectation-modules/' + datas.profil_id"
        :item-renderer="itemRendererModuleAffectation"
      />
    </div>
    <div
      v-else-if="index===2"
      style="overflow: auto;padding: 10px"
      title="Utilisateurs"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-profils/profils/' + datas.profil_id + '/affectation-utilisateurs'"
        :url-ressouce-update="'api/wikaz/modules/gestion-profils/profils/' + datas.profil_id + '/affectation-utilisateurs/' + datas.profil_id"
        :item-renderer="itemRendererUtilisateurAffectation"
      />
    </div>
    <v-bottom-navigation
      v-model="index"
      grow
      hide-on-scroll
      @change="onChange"
    >
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>article</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>view_comfy</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        icon
        class="btn-menu"
      >
        <v-icon>person</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import IxModulesAffectation from '@/wikaz-konnect/adm/gestion-profils/IxModulesAffectation'
import IxUtilisateursAffectation from '@/wikaz-konnect/adm/gestion-profils/IxUtilisateursAffectation'

export default {
    name: 'CxTraitementProfilPwa',
    extends: CxBase,
    data () {
        return {
            index: 0,
            keyChange: 0,
            itemRendererModuleAffectation: IxModulesAffectation,
            itemRendererUtilisateurAffectation: IxUtilisateursAffectation,
        }
    },
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Profil'
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-profils/profils/' + data.profil_id, data).then(() => {
                this.$emit('on-refresh')
            })
        },
        f_retour () {
            this.f_precedent('vx-gestion-profils', 'Gestion des profils')
        },
        onChange () {
            this.keyChange ++
        },
    },
}
</script>
<style scoped>
    .cx-traitement-profil-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-profil-col2 {
        height: 100%;
        box-sizing: border-box;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
    }

    /*.v-window >>> .v-window__container {
        overflow: auto;
    }*/
</style>