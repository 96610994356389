<template>
  <div class="cx-selection-type-planning">
    <div style="width: 200px">
      <v-select
        v-model="selectedType"
        style="width: 100%;height: 42px"
        :items="typePlanning"
        solo
        dense
        item-value="type"
        item-text="label"
        label="Type de Planning"
        @change="f_change_type"
      />
    </div>
    <div style="width: 250px;margin-left: 10px">
      <fx-dropdown
        v-if="dataTechniciens && selectedType === 2"
        v-model="selectedTechnicienId"
        selected-index="0"
        :data-provider="dataTechniciens"
        @on-change="f_change_type"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxSelectionTypePlanning',
    data () {
        return {
            typePlanning: [
                {
                    type: 1,
                    label: 'Journalier',
                },
                {
                    type: 2,
                    label: 'Hebdomadaire',
                },
            ],
            selectedType: 1,
            dataTechniciens: null,
            selectedTechnicienId: null,
        }
    },
    mounted () {
        this.$emit('input', {
            type: 1,
            cible: 0,
        })
        this.chargerTechniciens()
    },
    methods: {
        chargerTechniciens () {
            this.$wikaz.request.get('api/entreprises/modules/plannings-entreprises/techniciens').then((data) => {
                this.dataTechniciens = data
                this.selectedTechnicienId = data[0].id
            })
        },
        f_change_type () {
            this.$emit('input', {
                type: this.selectedType,
                cible: this.selectedTechnicienId,
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-selection-type-planning {
        width: 100%;
        height: 100%;
        display: flex;
    }
</style>