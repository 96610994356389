var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cx-ligne-agents-container"},[_c('div',{staticClass:"cx-ligne-agents-cartouche",style:({width: _vm.largeurGauche + 'px'})},[_c('v-avatar',{staticStyle:{"margin-left":"5px","margin-right":"5px"},attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":'data:image/jpeg;base64,' + _vm.$wikaz.getInstance().getPhotoUtilisateur(_vm.item.wcu_utilisateur_id)}})],1),_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.item.label)+" ")])],1),_c('div',{staticStyle:{"width":"30px","border-right":"1px solid black","color":"black","display":"flex","align-items":"center","justify-content":"center","background-color":"#e0eef9"}},[(_vm.item.is_maladie)?_c('fx-icon',{attrs:{"icon":"done","color":"red","tooltip":"Maladie"}}):_vm._e()],1),_c('div',{staticStyle:{"width":"30px","border-right":"1px solid black","color":"black","display":"flex","align-items":"center","justify-content":"center","background-color":"#e0eef9"}},[(_vm.item.is_astreinte)?_c('fx-icon',{attrs:{"icon":"done","color":"#4d7ae0","tooltip":"Astreinte"}}):_vm._e()],1),(_vm.item.is_conge === 1)?_c('div',{staticClass:"cx-ligne-agents-case",style:({ width: 'calc((100% - '+(_vm.largeurGauche+60)+'px)',
              backgroundColor: 'brown'
    })},[_vm._v(" Congé ")]):_vm._e(),(_vm.item.is_tlc === 1)?_c('div',{staticClass:"cx-ligne-agents-case",style:({ width: 'calc((100% - '+(_vm.largeurGauche+60)+'px)',
              backgroundColor: 'grey'
    })},[_vm._v(" TLC ")]):_vm._e(),_vm._l((_vm.item.detail),function(element,indexJour){return [(indexJour === 0)?_c('div',{key:indexJour,staticClass:"cx-ligne-agents-case",style:({ marginLeft: 'calc((100% - '+(_vm.largeurGauche+60)+'px)/24*'+ element.debut+')',
                width: 'calc((100% - '+(_vm.largeurGauche+60)+'px)/24*'+ element.duree+')',
                backgroundColor: element.poste_color
      })},[_vm._v(" "+_vm._s(element.poste_libelle)+" "+_vm._s(( element.pointage_astreinte === 1 ? ' + Astreinte' : ''))+" "+_vm._s(( element.pointage_maladie === 1 ? ' ( Maladie )' : ''))+" "),_c('br'),_vm._v(" ( "+_vm._s(parseInt(element.debut))+"h - "+_vm._s(parseInt(element.fin))+"h ) ")]):_c('div',{key:indexJour,staticClass:"cx-ligne-agents-case",style:({ marginLeft: 'calc((100% - '+(_vm.largeurGauche+60)+'px)/24*'+ (element.debut-(_vm.item.detail[indexJour-1].duree)) +')',
                width: 'calc((100% - '+(_vm.largeurGauche+60)+'px)/24*'+ element.duree+')',
                backgroundColor: element.poste_color
      })},[_vm._v(" "+_vm._s(element.poste_libelle)+" "+_vm._s(( element.pointage_astreinte === 1 ? ' + Astreinte' : ''))+" "+_vm._s(( element.pointage_maladie === 1 ? ' ( Maladie )' : ''))+" "),_c('br'),_vm._v(" ( "+_vm._s(parseInt(element.debut))+"h - "+_vm._s(parseInt(element.fin))+"h ) ")])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }