<template>
  <div
    v-if="datas"
    style="height: 400px;padding: 10px;display: flex;gap: 10px"
  >
    <fx-list-transfert
      titre="Affectations Clients"
      :url-ressouce-index="'api/entreprises/modules/gestion-interlocuteurs/interlocuteurs/' + datas.interlocuteur_id + '/affectation-clients'"
    />
    <fx-list-transfert
      titre="Affectations Adresses"
      :url-ressouce-index="'api/entreprises/modules/gestion-interlocuteurs/interlocuteurs/' + datas.interlocuteur_id + '/affectation-adresses'"
    />
  </div>
  <cx-waiting v-else />
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxItemDetailInterlocuteur',
    extends: CxBase,
}
</script>

<style scoped>

</style>