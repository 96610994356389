<template>
  <v-list-item
    dense
    style="overflow: visible;height: 30px"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon color="green">
        {{ item.icon }}
      </v-icon>
      <v-img
        v-if="item.image_src"
        :src="$icsilab.rootApi + 'fichiers?file=' + item.image_src"
        contain
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <v-tooltip
          v-if="item.label && item.label.toString().length > 20"
          top
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{ item.label.toString().substr(0,20) }}
            </div>
          </template>
          <span>{{ item.label }}</span>
        </v-tooltip>
        <div v-else>
          {{ item.label }}
        </div>
      </v-list-item-title>
    </v-list-item-content>
    <div style="width: 30px;justify-content: center">
      <fx-icon
        v-if="index > 1"
        icon="keyboard_arrow_up"
        :size="26"
        @click="f_up"
      />
    </div>
    <div style="width: 30px;justify-content: center">
      <fx-icon
        v-if="index > 0 && index < lastIndex"
        icon="keyboard_arrow_down"
        :size="26"
        @click="f_down"
      />
    </div>
    <div style="width: 30px;justify-content: center">
      <fx-icon
        icon="create"
        :size="22"
        color="blue"
        tooltip="Editer la page"
        @click="f_editer_page"
      />
    </div>
    <div style="width: 30px;justify-content: center">
      <fx-icon
        v-if="index > 0"
        icon="delete"
        :size="22"
        color="red"
        tooltip="Supprimer la page"
        @click="f_delete"
      />
      <fx-icon
        v-else
        icon="lock"
        :size="22"
        color="grey"
        tooltip="Cette page ne peux pas être supprimée ( page home )"
      />
    </div>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemPages',
    extends: FxItemTemplate,
    methods: {
        f_editer_page () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.item.pages_site_id + '/pages/' + this.item.pages_id + '/edit', this.f_result_update)
        },
        f_result_update (data) {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.item.pages_site_id + '/pages/' + this.item.pages_id, data).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_up () {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.item.pages_site_id + '/pages/' + this.item.pages_id + '/up', this.item).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_down () {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.item.pages_site_id + '/pages/' + this.item.pages_id + '/down', this.item).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_delete () {
            this.$wikaz.notification.showConfirmation('Suppression de la page', 'Souhaitez vous réellement supprimer la page : ' + this.item.label, () => {
                this.$wikaz.request.delete('api/wikaz/modules/editeur-de-site/sites/' + this.item.pages_site_id + '/pages/' + this.item.pages_id).then(() => {
                    this.f_emit_actualiser()
                })
            }, '400px', '180px')
        },
    },
}
</script>
<style scoped>
</style>