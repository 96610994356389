<template>
  <div class="cx-traitement-gestion-resident-container">
    <div class="cx-traitement-gestion-resident-col1">
      <cx-fiche-document
        ref="objet_fiche"
        :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id"
      />
      <cx-zone-affichage :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id + '/affichage'" />
    </div>
    <div class="cx-traitement-gestion-resident-col2">
      <cx-historique-version
        :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id + '/fichier'"
        @refresh="f_refresh"
      />
    </div>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxFicheDocument from '@/wikaz-konnect/gdt/systeme-documentaire/elements/fiche/CxFicheDocument'
import CxZoneAffichage from '@/wikaz-konnect/gdt/systeme-documentaire/elements/affichage/CxZoneAffichage'
import CxHistoriqueVersion
from '@/wikaz-konnect/gdt/systeme-documentaire/elements/historique-version/CxHistoriqueVersion'

export default {
    name: 'CxTraitementSystemeDocumentaire',
    components: {
        CxHistoriqueVersion,
        CxZoneAffichage,
        CxFicheDocument,
    },
    extends: CxBase,
    methods: {
        f_refresh () {
            this.$refs.objet_fiche.getData()
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-traitement-gestion-resident-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col2 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col3 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

</style>