<template>
  <div
    v-if="items.length > 0"
    class="cx-information-tournees-container"
  >
    <fx-sous-titre
      v-if="title.length > 0"
      :titre="(computedTotalDistance) ? (title + ' ' + computedTotalDistance) : title"
      bkgd="#ddd"
      font-size="28px"
      active-color="#000"
    />
    <div
      v-for="(item, index) in items"
      :key="index"
      class="vignette"
      @click="zoom( item.lat, item.lng)"
    >
      <div class="img-pin">
        <img
          style="width:25px;height:50%;"
          :src="item.icon"
        >
      </div>
      <div
        style="width:100%"
        v-html="item.content"
      />
    </div>
  </div>
</template>
<script>
export default {
    name: 'CxInformationTournees',
    props: {
        title: { default: '' },
        items: { default: [] },
        computedTotalDistance: { default: null },
        color: { default: '#e4eeee' },
    },
    methods: {
        zoom (lat, lng) {
            this.$emit('zoom', {
                lat: lat,
                lng: lng,
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-information-tournees-container {
        // border-top:1px solid #999;
        // border-bottom:1px solid #999;
        // overflow: auto;
        box-sizing: border-box;
        background-color: white;
        display: flex;
        flex-direction: column;
        color:#222;
    }

    .img-pin {
        width:40px;
        margin-right:12px;
        text-align:center;
    }
    .vignette {
        display:flex;
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        align-items:center;
        // border-right:2px solid transparent;
        // border-top:1px solid #999;
        // border-bottom:1px solid #999;
    }
    .vignette:hover {
        cursor: pointer;
        border-color: #0d468c;
    }
    .vignette:hover *{
        color: #0d468c;
    }
    .vignette:hover img{
        transform: scale(1.1);
    }
</style>