<template>
  <div>
    <fx-titre :titre="datas.label" />
    <div style="padding: 10px;display: flex;flex-direction: column;gap: 10px">
      <div style="font-weight: bold">
        Liste des profils
      </div>
      <div
        v-for="(item,index) in datas.profils"
        :key="index"
      >
        <div style="display: flex;align-items:center;gap: 10px">
          <v-chip>{{ item.profil_id }}</v-chip>
          <div>{{ item.profil_libelle }}</div>
          <div
            v-if="item.pp_defaut === 1"
            style="font-weight: bold"
          >
            Defaut
          </div>
        </div>
      </div>
      <div style="display: flex;justify-content: flex-end">
        <v-btn
          color="error"
          @click="f_resilier"
        >
          Résilier
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementPackWeb',
    extends: CxBase,
    data () {
        return {
            urlRessourceEndPoint: '',
            dataPacks: null,
        }
    },
    methods: {
        f_resilier () {
            this.$wikaz.outils.notification.showConfirmation(
                'Résiliation',
                'Voulez-vous réellement résilier ce pack',
                () => {
                    this.$wikaz.request.delete(this.datas.url).then(() => {
                        this.$emit('on-refresh')
                    })
                },
            )
        },
    },
}
</script>

<style scoped>
    .cx-traitement-gestion-pack-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-pack-col1 {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }
</style>