<template>
  <v-text-field
    ref="myInput"
    :clearable="clearable"
    style="margin-top: 2px;margin-bottom: 2px;"
    outlined
    hide-details
    dense
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="!editable"
    :rows="rows"
    :size="size"
    :style="{width: width}"
    :type="type"
    :rules="rules"
    :value="value"
    @input="onChange"
  />
</template>
<script>
export default{
    name: 'FxEmail',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 'text',
            type: String,
        },
        value: { default: null },
        size: { default: 'small' },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        clearable: {
            default: false,
            type: Boolean,
        },
        labelLeft: { default: '' },
        labelRight: { default: '' },
        tooltip: { default: null },
        tooltipPosition: { default: 'bottom-start' },
        labelColor: { default: '#000' },
        rows: { default: 4 },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return { ruleEmail: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) }
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        onChange (valeur) {
            this.$emit('input', valeur)
        },
    },
}
</script>