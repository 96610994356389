<template>
  <fx-list
    title="Facturation"
    show-search
    show-result
    show-refresher
    show-add
  />
</template>
<script>
export default { name: 'CxFacturationResident' }
</script>
<style scoped>
</style>