<template>
  <div
    v-if="datas"
    style="display: flex;flex-direction: column;gap: 5px;overflow: auto"
  >
    <div style="display: flex">
      <fx-titre titre="Informations importantes" />
      <fx-icon
        icon="create"
        color="primary"
        tooltip="Modifier la fiche"
        @click="f_modifier"
      />
    </div>
    <fx-champ
      label-width="120px"
      label="GIR"
      :text="datas.resident_gir"
    />
    <fx-champ
      label-width="120px"
      label="Groupe sanguin"
      :text="datas.resident_groupe_sanguin"
    />
    <fx-champ
      label-width="120px"
      label="Allergies connues"
      :text="datas.resident_allergies"
    />
    <fx-champ
      label-width="120px"
      label="Médicaments pris"
      :text="datas.resident_medicaments"
    />
    <fx-champ
      label-width="120px"
      label="Autres informations"
      :text="datas.resident_autres_infos"
    />
  </div>
  <div
    v-else
    style="display: flex;flex-direction: column;gap: 5px;overflow: auto"
  >
    <div style="display: flex">
      <fx-titre titre="Informations importantes" />
      <fx-icon
        icon="create"
        color="primary"
        tooltip="Modifier la fiche"
        @click="f_modifier"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxFicheMedicale',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/0/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint + '/0', data).then(()=> {
                    this.getData()
                })
            })
        },
    },
}
</script>

<style scoped>

</style>