<template>
  <div
    class="alterStyleRow"
    style="display: flex;padding: 5px;align-items: center;justify-content: space-between"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon
        :size="30"
        color="primary"
      >
        {{ item.affichage_type_icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.affichage_type_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        style="font-weight: bold"
        v-html="item.zone_affichage_libelle"
      />
    </v-list-item-content>
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item @click="f_delete">
          <v-list-item-avatar>
            <v-icon color="error">
              delete
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Supprimer</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxAffichage',
    extends: FxItemTemplate,
    methods: {
        f_delete () {
            this.$wikaz.request.delete('api/edt/modules/systeme-documentaire/documents/' + this.item.zone_affect_document_id + '/affichage/' + this.item.zone_affect_id).then(()=>{
                this.f_emit_actualiser()
            })
        },
    },
}
</script>
<style scoped lang="scss">

    .alterStyleRow:nth-child(even) {
        background-color: #f5f3f3
    }
    .alterStyleRow:nth-child(odd) {
        background-color: #ffffff
    }

</style>