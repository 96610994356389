<template>
  <v-flex xs12>
    <v-container grid-list-xl>
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          v-if="!(['xs','sm','md'].indexOf($screen.breakpoint) > -1)"
          class="md12"
        >
          <v-card
            v-if="!(['xs','sm','md'].indexOf($screen.breakpoint) > -1)"
            flat
            style="display:flex;justify-content: center"
          >
            <div
              style="display: flex;justify-content: center;align-items: center;"
              :style="{height: height}"
            >
              <div
                class="circle-container"
                style="border-radius: 50%"
                :style="{width: dimensionCircle + 'px',height:dimensionCircle + 'px'}"
              >
                <div
                  v-for="index in dataElements.length"
                  :key="index"
                  class="point"
                  :style="{
                    width: dimensionPoint + 'px',
                    height: dimensionPoint + 'px',
                    left: getX(( 6.28 / dataElements.length )* (index-1) ) + 'px',
                    top: getY(( 6.28 / dataElements.length )* (index-1) ) + 'px'}"
                />
                <div
                  v-for="(item2,index2) in dataElements"
                  :key="'_'+index2"
                  class="texte"
                  :style="{
                    left: getXtext(( 6.28 / dataElements.length )* (index2-1) ) + 'px',
                    top: getYtext(( 6.28 / dataElements.length )* (index2-1) ) + 'px',
                    width: largeurText + 'px'}"
                >
                  {{ item2 }}
                </div>
              </div>
            </div>
          </v-card>
        </v-flex>
        <v-flex
          v-else
          class="md8"
        >
          <v-card flat>
            <v-timeline>
              <v-timeline-item
                v-for="(item,index) in dataElements"
                :key="index"
                small
                color="#8BBC3C"
              >
                <v-card elevation="10">
                  <v-card-text
                    style="font-weight: bold"
                    v-html="item"
                  />
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
export default {
    name: 'FxSectionCercle',
    data () {
        return {
            height: '500px',
            dimensionCircle: 350,
            dimensionPoint: 20,
            largeurText: 260,
            dataElements: [
                'Protéger l’environnement, la nature et tous ses habitants',
                'Promouvoir les valeurs de la solidarité',
                'Assurer la défense du patrimoine et des traditions françaises et régionales',
                'Promouvoir le droit à vivre dans un environnement sain et préservé',
                'S’engager pour le développement de la culture',
                'Soutenir les valeurs de la citoyenneté',
                'Favoriser les responsabilités sociale et sociétale',
                'Placer le développement durable au cœur de notre croissance',
                'Penser nos enjeux économiques en adéquation avec notre environnement',
            ],
        }
    },
    methods: {
        getX (angle) {
            let rayon = this.dimensionCircle / 2
            let coordX = this.dimensionCircle / 2
            let X = coordX + rayon * Math.cos(angle)
            return X - (this.dimensionPoint / 2)
        },
        getY (angle) {
            let rayon = this.dimensionCircle / 2
            let coordY = this.dimensionCircle / 2
            let Y = coordY + rayon * Math.sin(angle)
            return Y - (this.dimensionPoint / 2)
        },
        getXtext (angle) {
            let rayon = this.dimensionCircle / 2
            let coordX = this.dimensionCircle / 2
            let X = coordX + rayon * Math.cos(angle)
            let offset = this.largeurText + this.dimensionPoint
            if (angle < 1.57 || angle > 4.71) {
                offset = - 30
            }
            if (angle > 1.37 && angle < 1.77) {
                offset = (this.largeurText / 2)
            }
            if (angle > 4.51 && angle < 4.91) {
                offset = (this.largeurText / 2)
            }
            return X - offset
        },
        getYtext (angle) {
            let rayon = this.dimensionCircle / 2
            let coordY = this.dimensionCircle / 2
            let Y = coordY + rayon * Math.sin(angle)
            let offset = 30
            if (angle > 1.37 && angle < 1.77) {
                offset = - 30
            }
            if (angle > 4.51 && angle < 4.91) {
                offset = 80
            }
            return Y - offset
        },
    },
}
</script>

<style scoped>
    .circle-container {
        position:relative;
        border: 2px solid #8BBC3C;
    }

    .point {
        background-color: white;
        border-radius: 50%;
        position: absolute;
        border: 3px solid #8BBC3C;
    }

    .texte {
        position: absolute;
        text-align: center;
    }
</style>