<template>
  <fx-list
    ref="obj_liste"
    class="cx-intervention-container"
    :item-renderer="itemRendererIntervention"
    title="Interventions"
    :url="urlRessourceEndPoint"
    :show-add="showAdd"
    show-search
    show-refresher
    :data-provider="datas"
    @actualiser="f_refresh"
    @add="f_ajouter"
  />
</template>
<script>
import IxItemInterventions from '@/wikaz-konnect/gdt/planning/planning-elements/agents/IxItemInterventions'

export default {
    name: 'CxInterventions',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        showAdd: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            datas: null,
            itemRendererIntervention: IxItemInterventions,
        }
    },
    methods: {
        f_refresh () {
            this.$refs.obj_liste.refresh()
        },
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                this.f_result_ajouter,
            )
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.f_refresh()
            })
        },
    },
}
</script>

<style scoped>
    .cx-intervention-container {
        height: 330px
    }
</style>