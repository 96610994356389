<template>
  <div
    v-if="datas"
    style="display:flex;align-items:center;justify-content:center;height: auto;width: 100%;height: 30px"
  >
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="subject"
      color="#878787"
      tooltip="Afficher les observations de la demande"
      @click="f_afficher_observation()"
    />
    <!--        <fx-icon style="margin-left: 10px;margin-right: 10px" :size="26" icon="print" color="#878787" tooltip="Imprimer la fiche demande" @click="f_imprimer_fiche()"/>-->
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="folder"
      color="#F6D159"
      tooltip="Fichiers de la demande"
      @click="f_afficher_archive()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="update"
      color="primary"
      tooltip="Reporter la date souhaité"
      @click="f_report()"
    />
  </div>
  <cx-waiting
    v-else
    :size="18"
  />
</template>
<script>

import CxObservations from '@/wikaz-konnect/gdi/gestion-clients/observations/CxObservations'
import CxPopupArchivesIntervention from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupArchivesIntervention'

export default {
    name: 'CxFicheDemandeOutils',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.f_charger_data()
    },
    methods: {
        f_charger_data () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/outils').then((data) => {
                this.datas = data
            })
        },
        f_afficher_observation () {
            this.$wikaz.popups.show(
                CxObservations,
                {
                    datas: {
                        urlEndPoint: this.urlRessourceEndPoint + '/observations',
                        titre: 'Observations intervention',
                        width: '500px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_result_popup)
        },
        f_afficher_archive () {
            this.$wikaz.popups.show(
                CxPopupArchivesIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/fichiers',
                        titre: 'Fichiers de la demande',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_result_popup,
            )
        },
        f_report () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/report/0/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint + '/report/0', data).then(() => {
                    this.f_charger_data()
                    this.$emit('refresh')
                })
            })
        },
    },
}
</script>