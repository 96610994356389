<template>
  <div style="display: flex;flex-direction: column;padding: 10px;gap: 10px">
    <fx-titre titre="Document Légaux" />
    <div
      v-for="(item,index) in datas"
      :key="index"
      style="display: flex;align-items: center;gap: 5px"
    >
      <v-icon color="primary">
        picture_as_pdf
      </v-icon>
      <div
        style="width: 100%;display: flex;justify-content: space-between"
        :style="{color: (item.date_expire === 1) ? 'red' : 'black'}"
      >
        <div>{{ item.fichiers_legaux_libelle }}</div>
      </div>
      <div style="width: 30px">
        <fx-icon
          v-if="item.exist"
          icon="visibility"
          color="primary"
          @click="f_afficher(item.fichiers_legaux_id)"
        />
      </div>
      <div style="width: 30px">
        <fx-icon
          icon="backup"
          color="primary"
          @click="f_ajouter(item.fichiers_legaux_id)"
        />
      </div>
      <div style="width: 30px">
        <fx-icon
          v-if="item.exist"
          icon="delete"
          color="error"
          @click="f_delete(item.fichiers_legaux_id)"
        />
      </div>
      <div style="width: 200px">
        <div
          v-if="item.fichier_affect_date_expiration"
          style="display: flex;align-items: center"
        >
          <fx-icon
            :size="20"
            icon="schedule"
            :color="(item.date_expire === 1) ? 'error' : 'info'"
            tooltip="Date d'expiration"
          />
          <div :style="{color: (item.date_expire === 1) ? 'red' : 'black'}">
            {{ $wikaz.outils.dates.getDate(item.fichier_affect_date_expiration) }}
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      width="440"
    >
      <v-card
        :key="indexKey"
        style="display:flex; flex-direction: column;padding:10px;gap: 10px"
      >
        <v-card-title>Ajouter un fichier</v-card-title>
        <div style="display: flex;align-items: center">
          <fx-label text="Fichier" />
          <v-file-input
            v-model="uploadFile"
            outlined
            dense
            hide-details
            accept="application/pdf"
            show-size
          />
        </div>
        <div style="display: flex">
          <fx-label text="Date expiration" />
          <fx-date-field-check v-model="selectedDate" />
        </div>
        <div style="display: flex;justify-content: flex-end;gap: 5px">
          <v-btn
            color="success"
            :disabled="!uploadFile"
            @click="f_valider()"
          >
            Valider
          </v-btn>
          <v-btn
            color="error"
            @click="f_reset"
          >
            Annuler
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: 'CxDocumentsResidentsLegaux',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datas: null,
            showDialog: false,
            selectedFileId: 0,
            uploadFile: null,
            selectedDate: null,
            indexKey: 0,
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_afficher (fichierId) {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/' + fichierId)
        },
        f_delete (fichierId) {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer le fichier ?', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + fichierId).then(() => {
                    this.getData()
                })
            })
        },
        f_ajouter (fichierId) {
            this.selectedFileId = fichierId
            this.showDialog = true
        },
        f_valider () {
            if(this.selectedFileId > 0) {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '?id=' + this.selectedFileId + '&expire=' + this.selectedDate, this.uploadFile).then(() => {
                // this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + this.selectedFileId + '?expire=' + this.selectedDate, this.uploadFile).then(() => {
                    this.f_reset()
                    this.getData()
                })
            }
        },
        f_reset () {
            this.selectedFileId = 0
            this.showDialog = false
            this.uploadFile = null
            this.selectedDate = null
            this.indexKey ++
        },
    },
}
</script>

<style scoped>

</style>