<template>
  <div
    v-if="datas"
    class="cx-traitement-gestion-resident-container"
  >
    <div
      v-if="index === 0"
      class="cx-traitement-gestion-resident-col1"
    >
      <cx-fiche-document
        ref="objet_fiche"
        :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id"
      />
    </div>
    <div
      v-if="index === 1"
      class="cx-traitement-gestion-resident-col2"
    >
      <cx-zone-affichage :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id + '/affichage'" />
    </div>
    <div
      v-if="index === 2"
      class="cx-traitement-gestion-resident-col3"
    >
      <cx-historique-version
        :url-ressource-end-point="'api/edt/modules/systeme-documentaire/documents/' + datas.document_id + '/fichier'"
        @refresh="f_refresh"
      />
    </div>
    <v-bottom-navigation
      v-model="index"
      absolute
      grow
      hide-on-scroll
      horizontal
    >
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Détail</span>
        <v-icon>person</v-icon>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Classement</span>
        <v-icon>today</v-icon>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Versions</span>
        <v-icon>folder</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxFicheDocument from '@/wikaz-konnect/gdt/systeme-documentaire/elements/fiche/CxFicheDocument'
import CxZoneAffichage from '@/wikaz-konnect/gdt/systeme-documentaire/elements/affichage/CxZoneAffichage'
import CxHistoriqueVersion from '@/wikaz-konnect/gdt/systeme-documentaire/elements/historique-version/CxHistoriqueVersion'

export default {
    name: 'CxTraitementSystemeDocumentairePwa',
    components: {
        CxHistoriqueVersion,
        CxZoneAffichage,
        CxFicheDocument,
    },
    extends: CxBase,
    data () {
        return { index: 0 }
    },
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Document'
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        f_retour () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-systeme-documentaire',
                module_libelle: 'docuthèque',
            })
        },
        f_refresh () {
            this.$refs.objet_fiche.getData()
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-traitement-gestion-resident-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col1 {
        box-sizing: border-box;
        display: grid;
        padding: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col2 {
        box-sizing: border-box;
        display: grid;
        padding: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col3 {
        box-sizing: border-box;
        display: grid;
        padding: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

</style>