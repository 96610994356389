<template>
  <div
    v-if="urlRessourceEndPoint"
    class="cx-traitement-gestion-pack-container"
  >
    <div class="cx-traitement-gestion-pack-col1">
      <cx-fiche-compte :url-ressource-end-point="urlRessourceEndPoint" />
      <cx-moyen-paiement-compte
        style="overflow: auto"
        :data-in="datas"
        :url-ressource-end-point="urlRessourceEndPoint + '/moyen-de-paiements'"
      />
      <cx-liste-utilisateurs :url-ressource-end-point="urlRessourceEndPoint + '/utilisateurs'" />
    </div>
    <fx-traitement-template
      :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
      :item-renderer="itemRenderer"
      padding="0"
      :set-visible-ajout="true"
      :url-ressource-end-point="urlRessourceEndPoint + '/packs'"
      titre-liste="Liste des comptes Wikaz Konnect"
      width="100%"
      width-list="450px"
    />
  </div>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxFicheCompte from '@/wikaz-core/ui/modules/gestion-des-packs/CxFicheCompte'
import CxMoyenPaiementCompte from '@/wikaz-core/ui/modules/gestion-des-packs/moyen-paiement-compte/CxMoyenPaiementCompte'
import IxItemPack from '@/wikaz-core/ui/modules/gestion-des-packs/packs-compte/IxItemPack'
import CxTraitementPackWeb from '@/wikaz-core/ui/modules/gestion-des-packs/packs-compte/CxTraitementPackWeb'
import CxTraitementPackPwa from '@/wikaz-core/ui/modules/gestion-des-packs/packs-compte/CxTraitementPackPwa'
import CxListeUtilisateurs from '@/wikaz-core/ui/modules/gestion-des-packs/CxListeUtilisateurs'

export default {
    name: 'CxTraitementGestionPackWeb',
    components: {
        CxListeUtilisateurs,
        CxMoyenPaiementCompte,
        CxFicheCompte,
    },
    extends: CxBase,
    data () {
        return {
            urlRessourceEndPoint: '',
            itemRenderer: IxItemPack,
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementPackWeb),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementPackPwa),
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-des-packs-wk/comptes/' + this.datas.compte_id
            break
        }
    },
}
</script>

<style scoped lang="scss">
    .cx-traitement-gestion-pack-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-pack-col1 {
        width: 100%;
        height: 100%;
        // box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr 2fr;
        overflow: auto;
    }
</style>