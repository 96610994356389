<template>
  <!--<cx-suivi-demandes url-ressource-end-point="api/entreprises/modules/reception-demandes-externes/demandes"></cx-suivi-demandes>-->
  <fx-datagrid-vuetify
    ref="obj_liste"
    url-ressource-end-point="api/entreprises/modules/reception-demandes-externes/demandes"
    @accepter="f_accepter"
    @commentaire="f_commentaire"
  />
</template>
<script>
export default {
    name: 'VxReceptionDemandesExternes',
    methods: {
        f_refresh () {
            this.$refs.obj_liste.chargerData()
        },
        f_accepter (item) {
            this.$wikaz.request.post('api/entreprises/modules/reception-demandes-externes/demandes/' + item.demande_id + '/acceptation').then(() => {
                this.f_refresh()
            })
        },
        f_commentaire (item) {
            this.$wikaz.outils.notification.showConfirmationCommentaire(
                'Ajouter une observation ou préciser une indisponibilité',
                'Veuillez saisir votre observation, ou préciser votre indisponibilité afin de ne pas être contacté.',
                (observation) => {
                    this.$wikaz.request.post('api/entreprises/modules/reception-demandes-externes/demandes/' + item.demande_id + '/commentaire', { observation: observation })
                })
        },
    },
}
</script>
<style scoped lang="scss">
</style>