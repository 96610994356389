<template>
  <div style="background-color: white">
    <v-list-item three-line>
      <v-list-item-avatar
        tile
        size="40"
      >
        <v-icon color="primary">
          check_circle
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="'Intervention terminée'" />
        <v-list-item-subtitle
          v-if="datas.cloture_libelle"
          v-text="datas.cloture_libelle"
        />
        <v-list-item-subtitle
          v-for="(itemEtape,index) in datas.etapes"
          :key="index"
          v-text="itemEtape.label"
        />
      </v-list-item-content>
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <fx-icon
            :size="30"
            color="primary"
            icon="menu"
            tooltip="Menu des actions"
            v-on="on"
          />
        </template>
        <v-list>
          <v-list-item @click="f_afficher">
            <v-list-item-avatar>
              <v-icon color="primary">
                visibility
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Voir le CRI</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </div>
</template>

<script>
export default {
    name: 'CxInterventionTerminee',
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_afficher () {
            this.$wikaz.request.get('api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/showcri')
        },
    },
}
</script>

<style scoped>

</style>