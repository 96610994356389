<template>
  <div />
</template>
<script>
export default {
    name: 'FxFiltreTemplate',
    props: {
        preSearch: {
            type: String,
            default: '',
        },
        dataIn: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { recherche: this.preSearch }
    },
    methods: {
        filterChanged () {
            this.$emit('on-changed')
        },
        getFiltreData () {
            return ''
        },
        f_format_date (valeur, format = 'DD/MM/YYYY') {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, format)
        },
    },
}
</script>