<template>
  <div
    v-if="datas"
    style="display: flex;flex-direction: column;gap: 5px;overflow: auto"
  >
    <div style="display: flex;justify-content: space-between">
      <fx-titre titre="Fiche document" />
      <fx-icon
        color="primary"
        icon="create"
        @click="f_modifier"
      />
    </div>
    <fx-champ
      label="Id"
      :text="datas.document_id"
    />
    <fx-champ
      label="Titre"
      :text="datas.document_titre"
    />
    <fx-champ
      label="Type"
      :text="datas.documents_type_libelle"
    />
    <fx-champ
      label="Code"
      :text="datas.documents_type_code"
    />
    <fx-champ
      label="Chrono"
      :text="datas.document_chrono"
    />
    <fx-champ
      label="Version"
      :text="datas.document_version"
    />
    <div
      v-if="datas.file_existe"
      style="height: 100px;display: flex;flex-direction: column;justify-content: center;align-items: center"
    >
      <fx-icon
        icon="visibility"
        color="primary"
        :size="40"
        @click="f_afficher"
      />
      <div
        v-if="datas.expire === 1"
        class="error--text"
        style="font-size: 20px;font-weight: bold"
      >
        Veuillez mettre à jour le document
      </div>
    </div>
    <div
      v-else
      style="height: 100px;display: flex;justify-content: center;align-items: center"
    >
      <div
        class="warning--text"
        style="font-size: 20px;font-weight: bold"
      >
        Pas de document à visualiser
      </div>
    </div>
  </div>
  <div v-else>
    <fx-titre titre="Fiche document" />
  </div>
</template>

<script>
export default {
    name: 'CxFicheDocument',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint, data).then(()=> {
                    this.getData()
                })
            })
        },
        f_afficher () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/fichier/0')
        },
    },
}
</script>

<style scoped>

</style>