<template>
  <div class="container-liste-intervention">
    <cx-fiche-inter :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/interventions/' + datas.intervention_id" />
    <cx-fiche-intervention-outils
      :url-ressource-end-point="'api/entreprises/modules/gestion-clients-entreprises/interventions/' + datas.intervention_id"
      :intervention-id="datas.intervention_id"
    />
  </div>
</template>
<script>
import CxFicheInter from '@/wikaz-konnect/gdi/gestion-clients/fiche-inter/CxFicheInter'
import CxFicheInterventionOutils from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/CxFicheInterventionOutils'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
export default {
    name: 'CxPageIntervention',
    components: {
        CxFicheInterventionOutils,
        CxFicheInter,
    },
    extends: CxBase,
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Intervention : ' + this.datas.intervention_id
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        f_retour () {
            let dataParams = this.$store.getters.getViewPwa().config.datas
            // dataParams.client_id = this.$store.getters.getViewPwa().config.datas.client_id
            this.$store.commit('SET_VIEW_PWA', {
                component: 'cx-page-interventions',
                module_libelle: 'Historique interventions',
                config: {
                    iconLeft: 'arrow_back',
                    datas: dataParams,
                },
            })
        },
    },
}
</script>
<style scoped>
    .container-liste-intervention {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 25px 1fr;
        grid-row-gap: 5px;
        padding: 10px;
    }
</style>