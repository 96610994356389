<template>
  <v-list-item
    dense
    style="overflow: visible;height: 50px"
    :style="{marginLeft: ( item.module_parent_id > 0 ) ? '20px' : '0'}"
    @click="f_emit_selection"
  >
    <v-list-item-avatar tile>
      <v-img
        height="20px"
        contain
        :src="$wikaz.outils.urls.getUrlImageByUrl('apps/icones/' + item.module_application_id + '.png')"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="item.module_libelle" />
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon v-if="item.module_plateforme === 1">
        desktop_windows
      </v-icon>
      <v-icon v-else-if="item.module_plateforme === 2">
        smartphone
      </v-icon>
      <v-icon v-else-if="item.module_plateforme === 3">
        devices
      </v-icon>
      <v-icon v-else>
        error
      </v-icon>
    </v-list-item-avatar>
    <div
      v-if="item.filtre > 0"
      style="display: flex;justify-content: space-around;width: 30px"
    >
      <fx-icon
        v-if="item.down_visible === 1"
        icon="keyboard_arrow_down"
        @click="f_down(item.module_id)"
      />
    </div>
    <div
      v-if="item.filtre > 0"
      style="display: flex;justify-content: space-around;width: 30px"
    >
      <fx-icon
        v-if="item.up_visible === 1"
        icon="keyboard_arrow_up"
        @click="f_up(item.module_id)"
      />
    </div>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxMenus',
    extends: FxItemTemplate,
    methods: {
        f_up (module_id) {
            this.$wikaz.request.post('api/wikaz/modules/gestion-menu/menus/' + module_id + '/up').then(() => {
                this.f_emit_actualiser()
            })
        },
        f_down (module_id) {
            this.$wikaz.request.post('api/wikaz/modules/gestion-menu/menus/' + module_id + '/down').then(() => {
                this.f_emit_actualiser()
            })
        },
    },
}
</script>