import Vue from 'vue'
import WikazDialog from '@/wikaz-core/popups/WikazDialog'
import FxFormulaireGenerique from '@/wikaz-core/ui/composants/template/formulaire/FxFormulaireGenerique'
import WikazDialogTransfertList from '@/wikaz-core/popups/WikazDialogTransfertList'

let popups = function () {
    this.componentClass = null,
    this.show = function (component, params, validateFunction = null, closeFunction = null) {
        this.componentClass = Vue.extend(WikazDialog)
        let instance = new this.componentClass
        instance.$vuetify = window.Vue.$vuetify
        instance.visible = true
        instance.composant = component
        instance.params = params
        instance.$mount()
        instance.$on(['validate', 'on-submit'], typeof validateFunction === 'function' ? validateFunction : () => {
            return false
        })
        instance.$on('close', typeof closeFunction === 'function' ? closeFunction : () => {
            return false
        })
        instance.$on(['close', 'validate', 'on-submit'], () => {
            if(instance && instance.$el) {
                instance.$destroy()
            }
        })
        document.body.appendChild(instance.$el)
    }
    this.showFormulaire = function (urlRessourceEndPoint, validateFunction = null, closeFunction = null) {
        this.show(FxFormulaireGenerique, {
            datas: {
                urlRessourceEndPoint: urlRessourceEndPoint,
                isEditable: true,
                isPopup: true,
            },
        }, validateFunction, closeFunction)
    }
    this.showTransfertList = function (titre, urlIndex, urlUpdate,
        validateFunction = null,
        closeFunction = null,
        width = '900px', height = '800px') {
        this.show(
            WikazDialogTransfertList,
            {
                datas: {
                    urlRessourceEndPointIndex: urlIndex,
                    urlRessourceEndPointUpdate: urlUpdate,
                    titre: titre,
                    width: width,
                    height: height,
                },
            },
            () => {
                if(validateFunction) {
                    validateFunction()
                }
            },
            () => {
                if(closeFunction) {
                    closeFunction()
                }
            },
        )
    }
}
export default popups