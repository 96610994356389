<template>
  <div
    :style="'width:100%;height:100%'"
    :class="isMobile ? 'fx-traitement-template-container-mobile' : 'fx-traitement-template-container'"
  >
    <div
      class="fx-traitement-template-liste"
      :style="{width: isMobile ? '100%' : widthList,paddingBottom: '10px',padding: padding}"
    >
      <!--<fx-titre :titre="titreListe"/>-->
      <!--<v-card-title v-if="titreListe" >{{titreListe}}</v-card-title>-->
      <div
        v-if="titreListe"
        style="font-weight: bold"
      >
        {{ titreListe }}
      </div>
      <component
        :is="composantFiltreElement"
        v-if="composantFiltreElement"
        ref="objFiltre"
        style="padding: 5px"
        @on-changed="f_charger_liste"
      />
      <div v-else />
      <fx-list
        ref="objListe"
        :data-provider="dataProvider"
        :export-data="dataProvider"
        :pre-select-attribute="preSelectAttribute"
        :export-ressource="exportRessource"
        :export-type="exportType"
        :filename-export="filenameExport"
        :item-renderer="itemRenderer"
        :pre-search="searchField"
        :pre-select-value="preSelectValue"
        :show-warning="setVisibleWarning"
        :show-add="setVisibleAjout"
        :show-export="showExport"
        :show-refresher="showRefresh"
        :show-result="true"
        :show-search="true"
        :tooltip-export="tooltipExport"
        style="overflow: auto;"
        @add="f_ajouter_element()"
        @itemEmitting="f_selected_element($event)"
        @on-dblclick="f_selected_element($event,true)"
        @refresh="f_charger_liste()"
        @warning="f_warning_element()"
      />
      <div>
        <div
          v-if="isPagination && dataPagination.wikazNbPages > 1"
          style="display: flex;justify-content: center;padding: 5px;gap: 1px"
        >
          <div
            v-for="(item,index) in dataPagination.wikazNbPages"
            :key="index"
            :style="{backgroundColor: (item == dataPagination.wikazPage) ? '#0D6AAD' : '',
                     color: (item == dataPagination.wikazPage) ? 'white' : ''}"
            style="width: 30px;text-align:center;cursor: pointer;border-radius: 5px;font-weight: bold"
            @click="changePagination(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <component
      :is="composantTraitementElement"
      v-if="afficheTraitement"
      :key="cacheKey"
      :style="{padding: padding}"
      :data-in="dataInTraitement"
      :url-ressource-end-point="urlRessourceEndPoint"
      @on-refresh="f_charger_liste"
      @on-close="f_fermer"
      @on-close-update="f_fermer_update"
    />
  </div>
</template>
<style lang="scss" scoped>
    .fx-traitement-template-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .fx-traitement-template-container-mobile {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .fx-traitement-template-liste {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        overflow: auto;
    }

    .fx-traitement-template-traitement {
        box-sizing: border-box;
    }
</style>
<script>
export default {
    name: 'FxTraitementTemplate',
    props: {
        urlRessourceEndPoint: String,
        titreListe: {
            type: String,
            default: null,
        },
        composantAjoutElement: {
            default: null,
            type: Object,
        },
        composantWarningElement: {
            default: null,
            type: Object,
        },
        composantTraitementElement: {
            default: null,
            type: String,
        },
        composantFiltreElement: {
            default: null,
            type: String,
        },
        dataPropsFiltre: {
            default: null,
            type: Object,
        },
        dataPropsTraitement: {
            default: null,
            type: Object,
        },
        itemRenderer: Object,
        setVisibleAjout: {
            type: Boolean,
            default: true,
        },
        setVisibleWarning: {
            type: Boolean,
            default: false,
        },
        widthList: {
            type: String,
            default: '300px',
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        preSearch: {
            type: String,
            default: '',
        },
        showRefresh: {
            type: Boolean,
            default: true,
        },
        widthTraitement: {
            type: String,
            default: '100%',
        },
        showExport: {
            type: Boolean,
            default: false,
        },
        tooltipExport: {
            type: String,
            default: '',
        },
        filenameExport: {
            type: String,
            default: 'export',
        },
        exportRessource: {
            type: String,
            default: 'exporter',
        },
        exportType: {
            type: String,
            default: 'csv',
        },
        selectItemMode: { default: 'on-click' },
        preSelectAttribute: { default: 'id' },
        preSelectValue: { default: null },
        padding: {
            type: String,
            default: '5px',
        },
    },
    data () {
        return {
            dataProvider: [],
            target_id: null,
            types: [],
            searchField: this.preSearch,
            cancelRequest: null,
            afficheTraitement: false,
            dataInTraitement: null,
            isPagination: false,
            dataPagination: null,
            filtrePagination: 'page=1',
            cacheKey: 0,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    watch: {
        urlRessourceEndPoint (newVal) {
            if (newVal) {
                // this.f_actualiser()
                this.f_charger_liste()
            }
        },
        preSearch () {
            this.searchField = this.preSearch
            // this.f_actualiser()
            this.f_charger_liste()
        },
    },
    mounted () {
        this.f_charger_liste()
    },
    methods: {
        setPagination (dataPagination) {
            if(dataPagination) {
                this.isPagination = true
                this.dataPagination = dataPagination
            } else {
                this.isPagination = false
                this.dataPagination = null
            }
        },
        f_charger_liste (selection = null) {
            this.dataProvider = []
            this.$refs.objListe.loading = true
            this.affichageTraitement(false)

            if (this.cancelRequest !== null) {
                this.cancelRequest.abort()
            }
            this.cancelRequest = new AbortController()
            if(this.urlRessourceEndPoint) {
                if (! this.composantFiltreElement) {
                    this.$wikaz.request.request('GET', this.urlRessourceEndPoint + '?' + this.filtrePagination, null, null, this.cancelRequest).then((data) => {
                        if(data && data.pagination) {
                            this.setPagination(data.pagination)
                        }
                        this.f_result_liste(data)
                        if (selection &&
                                Object.prototype.hasOwnProperty.call(selection, 'key') &&
                                Object.prototype.hasOwnProperty.call(selection, 'val')
                        ) {
                            this.$refs.objListe.selectById(selection.key, selection.val)
                        }
                    })
                } else {
                    this.$wikaz.request.request('GET', this.urlRessourceEndPoint + this.$refs.objFiltre.getFiltreData() + '&' + this.filtrePagination, null, null, this.cancelRequest).then(data => {
                        if(data && data.pagination) {
                            this.setPagination(data.pagination)
                        }
                        this.f_result_liste(data)
                        if (selection &&
                                Object.prototype.hasOwnProperty.call(selection, 'key') &&
                                Object.prototype.hasOwnProperty.call(selection, 'val')
                        ) {
                            this.$refs.objListe.selectById(selection.key, selection.val)
                        }
                    })
                }
            }

        },
        f_result_liste (data) {
            this.dataProvider = data
            if (this.$refs.objListe) {
                this.$refs.objListe.loading = false
            }
        },
        changePagination (page) {
            this.filtrePagination = 'page=' + page
            this.f_charger_liste()
        },
        f_selected_element (item, isDoubleClickEvent = false) {
            if (this.isMobile) {
                this.$store.commit('SET_VIEW_PWA', {
                    component: this.composantTraitementElement,
                    config: { datas: item },
                })
            } else {
                if ((this.selectItemMode === 'on-click' && ! isDoubleClickEvent) || (this.selectItemMode === 'on-dblclick' && isDoubleClickEvent)) {
                    this.affichageTraitement(true, item)
                }
            }
        },
        affichageTraitement (etat, item = null) {
            this.afficheTraitement = etat
            if(etat) {
                this.dataInTraitement = null
                // let item = (this.$refs.objListe.selected) ? this.$refs.objListe.selected : {};
                this.dataInTraitement = (this.dataPropsTraitement) ? Object.assign(item, this.dataPropsTraitement) : item
                this.cacheKey ++
            }
        },
        f_fermer () {
            this.$emit('fermer')
        },
        f_fermer_update () {
            this.$emit('fermer-update')
        },
        f_ajouter_element () {
            if (this.composantAjoutElement) {
                this.$wikaz.getInstance().popups.show(
                    this.composantAjoutElement,
                    {
                        datas: {
                            urlRessourceEndPoint: this.urlRessourceEndPoint,
                            isEditable: true,
                            isPopup: true,
                        },
                    },
                    this.f_result_ajout_element,
                )
            } else {
                this.$wikaz.getInstance().popups.showFormulaire(
                    this.urlRessourceEndPoint + '/create',
                    this.f_result_ajout_element,
                )
            }
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.f_charger_liste()
            })
        },
        f_warning_element () {
            if (this.composantWarningElement) {
                this.$wikaz.getInstance().popups.show(
                    this.composantWarningElement,
                    { datas: { isPopup: true } },
                    this.f_result_warning_element,
                )
            }
        },
        f_result_warning_element () {
            this.f_charger_liste()
        },
        f_actualiser () {
            this.affichageTraitement(false)
            this.$refs.objListe.loading = true
            if (! this.composantFiltreElement) {
                this.$wikaz.request.get(this.urlRessourceEndPoint + '?' + this.filtrePagination).then(this.f_result_liste)
            } else {
                this.$wikaz.request.get(this.urlRessourceEndPoint + this.$refs.objFiltre.getFiltreData() + '&' + this.filtrePagination).then(this.f_result_liste)
            }
        },
    },
}
</script>