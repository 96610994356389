<template>
  <div style="padding: 10px">
    <template v-for="(item,index) in datas">
      <div :key="index">
        <div style="padding: 10px;font-weight: bold;display: flex;justify-content: space-between">
          <div>{{ $wikaz.getInstance().outils.dates.getDate(item.dadate,'dddd DD MMM YYYY') }} {{ (($wikaz.getInstance().outils.dates.getTodayBdd() === item.dadate) ? ' ( Aujourd\'hui )' : ' ( Hier )') }}</div>
          <fx-icon
            :size="30"
            :icon="item.show_intervention ? 'expand_less' : 'expand_more'"
            @click="item.show_intervention = !item.show_intervention"
          />
        </div>
        <v-divider />
        <template v-for="(astreinte,index2) in item.astreintes">
          <div
            v-show="item.show_intervention"
            :key="index2"
            style="width: 100%;padding: 10px"
          >
            <div style="margin-bottom: 10px">
              {{ astreinte.utilisateur }}
            </div>
            <cx-interventions
              style="height: 250px;margin-bottom: 15px"
              :url-ressource-end-point="'api/edt/modules/gestion-astreintes-pwa-gdt/astreintes/' + astreinte.astreinte_id + '/interventions'"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import CxInterventions from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxInterventions'
export default {
    name: 'VxGestionAstreintesPwaGdt',
    components: { CxInterventions },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = null
            this.$wikaz.request.get('api/edt/modules/gestion-astreintes-pwa-gdt/astreintes').then((data) => {
                this.datas = data
            })
        },
    },
}
</script>

<style scoped>

</style>