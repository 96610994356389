<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        v-if="item.proprietaire"
        color="green"
        icon="account_circle"
        tooltip="Propriétaire"
      />
      <v-icon
        v-else
        :color="item.actif === 1 ? 'green' : 'grey'"
      >
        person
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.nom + ' ' + item.prenom"
      />
      <v-list-item-subtitle
        class="caption"
        v-html="item.email"
      />
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'
export default {
    name: 'IxUtilisateurs',
    extends: FxItemTemplate,
}
</script>
<style scoped>
</style>