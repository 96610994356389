<template>
  <v-list-item
    dense
    @click="f_emit_selection"
  >
    <v-list-item-avatar v-if="item.id">
      <v-icon>{{ item.id }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        align="center"
        v-html="item.categorie"
      />
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title v-html="item.id" />
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import FxItemTemplate from '../base/FxItemTemplate'
export default {
    name: 'IxItemIcon',
    extends: FxItemTemplate,
}
</script>