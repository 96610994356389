<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        v-if="item.affect_id"
        :size="30"
        icon="business"
        color="success"
        tooltip="Résident"
      />
      <fx-icon
        v-else
        :size="30"
        icon="person"
        color="grey"
        tooltip="Non résident"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.resident_nom + ' ' + item.resident_prenom"
      />
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxResident',
    extends: FxItemTemplate,
    methods: {
        f_telephone (valeur) {
            window.location = 'tel:' + valeur
        },
    },
}
</script>