<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-filtre-element="composantFiltreElemen"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    titre-liste="Résidents"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>

<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementGestionResident from '@/wikaz-konnect/gdt/gestion-residents/CxTraitementGestionResident'
import CxTraitementGestionResidentPwa from '@/wikaz-konnect/gdt/gestion-residents/CxTraitementGestionResidentPwa'
import IxResident from '@/wikaz-konnect/gdt/gestion-residents/IxResident'
import CxFiltreResidents from '@/wikaz-konnect/gdt/gestion-residents/CxFiltreResidents'

export default {
    name: 'VxGestionResidents',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/edt/modules/gestion-residents/residents',
            composantFiltreElemen: this.$wikaz.outils.importation.elementByComponent(CxFiltreResidents),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionResident),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionResidentPwa),
            itemRenderer: IxResident,
        }
    },
}
</script>
<style scoped>

</style>