<template>
  <v-app
    style="width:100%;height: 100%"
    class="v-app-container"
    :style="'background: ' + $vuetify.theme.currentTheme.background"
  >
    <v-main style="width:100%;height: 100%">
      <keep-alive>
        <router-view id="myApp" />
      </keep-alive>
    </v-main>
  </v-app>
</template>
<script>
export default { name: 'VxApplication' }
</script>
<style lang="scss">
    html,body {
        height: 100%;
        width: 100%;
    }

    .v-dialog {
        overflow: visible !important;
    }

    #myApp {
        width: 100%;
        height: 100%;
        padding: 0;
        min-height: inherit;
        max-height: inherit;
        box-sizing: border-box;
        // overflow:auto;
    }
</style>