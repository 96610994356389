<template>
  <div>
    <fx-titre titre="Gestion des blocs" />
    <div style="display: flex;justify-content: flex-end;margin-right: 10px">
      <fx-icon
        icon="add"
        color="green"
        @click="f_ajouter_card"
      />
    </div>
    <fx-list-simple
      :data-provider="value.cards"
      :item-renderer="itemRendererCards"
      @itemEmitting="selectedItem = $event"
      @actualiser="$emit('refresh')"
    />
  </div>
</template>

<script>
import IxItemCards from '../IxItemCards'

export default {
    name: 'CxCardsEditeur',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            itemRendererCards: IxItemCards,
            selectedItem: null,
        }
    },
    methods: {
        f_ajouter_card () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id + '/blocs/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id + '/blocs', data).then((resultat) => {
                data.cards_id = resultat
                data.cards_composant = 'fx-card'
                this.value.cards.push(data)
            })
        },
    },
}
</script>

<style scoped>

</style>