<template>
  <v-text-field
    ref="myInput"
    style="margin-top: 2px;margin-bottom: 2px;"
    outlined
    hide-details
    dense
    :disabled="disabled"
    :placeholder="placeholder"
    :readonly="!editable"
    :style="{width: width}"
    type="number"
    step="0.1"
    :rules="rules"
    :value="value"
    @input="$emit('input',$event)"
  />
</template>
<style lang="scss" scoped>

    .fx-input {
        padding-left: 10px ;
        margin-top: 1px;
        margin-bottom: 1px;
        // background-color: white;
        border: 1px solid grey;
        border-radius: 5px;
        // width: 100%;
    }

    .fx-input:focus {
        outline: none !important;
        border: 2px solid #0d6aad;
        // box-shadow: 0 0 10px #719ECE;
    }

    .fx-input-normal {
        height: 40px;
    }

    .fx-input-small {
        height: 30px;
    }
</style>
<script>
export default{
    name: 'FxNumberInput',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return { errorString: '' }
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        onChange (event) {
            this.$emit('input', event.target.value)
        },
    },
}
</script>