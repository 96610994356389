<template>
  <div class="fiche-container">
    <v-img
      contain
      width="150px"
      :src="'data:image/jpeg;base64,' + this.$wikaz.getInstance().getPhotoUtilisateur(item.wcu_utilisateur_id)"
    />
    <div class="champ-container">
      <div class="champ-libelle">
        Nom Prénom
      </div>
      <div class="champ-content">
        {{ item.label }}
      </div>
    </div>
    <div class="champ-container">
      <div class="champ-libelle">
        Email
      </div>
      <div class="champ-content">
        {{ item.email }}
      </div>
    </div>
    <div class="champ-container">
      <div class="champ-libelle">
        Tel 1
      </div>
      <div class="champ-content">
        {{ item.user_telephone1 }}
      </div>
    </div>
    <div class="champ-container">
      <div class="champ-libelle">
        Tel 2
      </div>
      <div class="champ-content">
        {{ item.user_telephone2 }}
      </div>
    </div>
    <div class="champ-container">
      <div class="champ-libelle">
        Nb Hrs/An
      </div>
      <div class="champ-content">
        {{ item.propriete_nb_heures_annuelle }}
      </div>
    </div>
    <div class="champ-container">
      <div class="champ-libelle">
        Embauche
      </div>
      <div class="champ-content">
        {{ item.propriete_date_embauche }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'CxMiniFicheTechnicien',
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
}
</script>
<style scoped lang="scss">
    .fiche-container {
        width: 400px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #DDDDDD;
    }

    .champ-container {
        width:100%;
        height: 48px;
        display: flex;
        color: black;
        align-items: center;
        padding: 7px
    }

    .champ-libelle {
        width: 120px;
        text-align: right;
        margin-right: 10px
    }

    .champ-content {
        height: 100%;
        border: 1px solid grey;
        border-radius: 7px;
        background-color: white;
        width: 100%;padding: 5px
    }
</style>