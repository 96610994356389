<template>
  <v-img
    v-if="dataProvider && dataProvider.imageSrc"
    :src="$icsilab.rootApi + 'fichiers?file=' + dataProvider.imageSrc"
    :height="dataProvider.height"
  />
  <fx-anomalie-composant
    v-else
    composant="fx-bandeau-image"
    propriete="imageSrc"
  />
</template>
<script>
export default {
    name: 'FxBandeauImage',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_click () {
        },
    },
}
</script>