<template>
  <div style="display: flex;align-items: center">
    <div style="width: 80%">
      Paramétrage de l'en tête
    </div>
    <fx-icon
      style="margin-right: 10px"
      icon="settings"
      :size="24"
      color="blue"
      @click="f_editer"
    />
  </div>
</template>
<script>
export default {
    name: 'CxPanelHeader',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_editer () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/header/0/edit', this.f_result_update)
        },
        f_result_update (data) {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/header/0', data).then(() => {
                this.$emit('refresh')
            })
        },
    },
}
</script>

<style scoped>

</style>