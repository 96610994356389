<template>
  <div
    style="display: flex;align-items: center"
    :disabled="editable===false"
  >
    <fx-checkbox
      v-model="checked"
      :value="checked"
      :label="label"
      :true-value="true"
      :false-value="false"
      @on-change="updateValeurDate"
    />
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          style="margin: 0;padding: 0;"
          outlined
          hide-details
          dense
          :disabled="checked===false"
          prepend-icon="event"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        first-day-of-week="1"
        locale="fr-fr"
        @input="menu1 = false"
        v-on="listeners"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
    name: 'FxDateFieldCheck',
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        format: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        formatOut: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        editable: {
            default: false,
            type: Boolean,
        },
        type: { default: 'date' },
        width: { default: '120px' },
    },
    data () {
        return {
            valeur: (this.value !== '' && this.value !== null) ? new Date(this.value) : new Date(),
            date: (this.value !== '' && this.value !== null) ? new Date(this.value).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10), // new Date().toISOString().substr(0, 10),
            dateFormatted: this.formatDate((this.value !== '' && this.value !== null) ? new Date(this.value).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)), // this.formatDate(new Date().toISOString().substr(0, 10)),
            menu1: false,
            checked: (this.value !== '' && this.value !== null),
        }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => {
                    if (item === 'input') {
                        this.updateValeurDate()
                    } else {
                        self.$emit(item, event)
                    }
                }
            })
            return { ...self.$listeners }
        },
        style () {
            return { width: this.width }
        },
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    mounted () {
        this.updateValeurDate()
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        updateValeurDate () {
            let valRetour
            if (this.type === 'datetime') {
                if (this.checked) {
                    valRetour = {
                        date: this.$wikaz.getInstance().outils.dates.getDate(this.valeur, 'YYYY-MM-DD'),
                        time: this.$wikaz.getInstance().outils.dates.getDate(this.valeur, 'HH:mm'),
                        datetime: this.$wikaz.getInstance().outils.dates.getDate(this.valeur, 'YYYY-MM-DD HH:mm'),
                    }
                } else {
                    valRetour = null
                }
            } else {
                if (this.checked) {
                    valRetour = this.$wikaz.getInstance().outils.dates.getDate(this.date, this.formatOut)
                } else {
                    valRetour = null
                }
            }
            this.$emit('input', valRetour)
        },
        formatDate (date) {
            if (! date) {
                return null
            }

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (! date) {
                return null
            }

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
}
</script>