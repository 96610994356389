<template>
  <div class="vx-suivi-recurrence-container">
    <div class="cx-suivi-recurrence-header">
      <div style="display: flex;align-items: center;width: 100%">
        Suivi Reccurence
      </div>
      <v-card
        width="200px"
        @click="selectedPage=1"
      >
        <div
          style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 10px"
          :style="{backgroundColor: (selectedPage === 1) ? 'green' : '',
                   color: (selectedPage === 1) ? 'white' : '' }"
        >
          <div>En cours</div>
          <div style="font-size: 20px;font-weight: bold">
            {{ datas ? datas.total_en_cours : '' }} €
          </div>
        </div>
      </v-card>
      <v-btn
        color="primary"
        :disabled="(datas.total_en_cours < 100)"
      >
        Encaisser
      </v-btn>
      <v-card
        width="200px"
        @click="selectedPage=2"
      >
        <div
          style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 10px"
          :style="{backgroundColor: (selectedPage === 2) ? 'green' : '',
                   color: (selectedPage === 2) ? 'white' : '' }"
        >
          <div>Facturé</div>
          <div style="font-size: 20px;font-weight: bold">
            {{ datas ? datas.total_bordereau_en_cours : '' }} €
          </div>
        </div>
      </v-card>
      <v-card
        width="200px"
        @click="selectedPage=3"
      >
        <div
          style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 10px"
          :style="{backgroundColor: (selectedPage === 3) ? 'green' : '',
                   color: (selectedPage === 3) ? 'white' : '' }"
        >
          <div>Payé</div>
          <div style="font-size: 20px;font-weight: bold">
            {{ datas ? datas.total_bordereau_payes : '' }} €
          </div>
        </div>
      </v-card>
    </div>
    <div
      v-if="selectedPage===1"
      class="cx-suivi-recurrence-content"
    >
      <cx-suivi-en-cours
        url-ressource-end-point="api/entreprises/modules/suivi-recurrence"
        @actualiser="getData"
      />
    </div>
    <div
      v-if="selectedPage===2"
      class="cx-suivi-recurrence-content"
    >
      <cx-suivi-facture
        url-ressource-end-point="api/entreprises/modules/suivi-recurrence"
        @actualiser="getData"
      />
    </div>
    <div
      v-if="selectedPage===3"
      class="cx-suivi-recurrence-content"
    >
      <cx-suivi-paye
        url-ressource-end-point="api/entreprises/modules/suivi-recurrence"
        @actualiser="getData"
      />
    </div>
  </div>
</template>

<script>
import CxSuiviEnCours from '@/wikaz-konnect/pdc/suivi-recurrence/CxSuiviEnCours'
import CxSuiviFacture from '@/wikaz-konnect/pdc/suivi-recurrence/CxSuiviFacture'
import CxSuiviPaye from '@/wikaz-konnect/pdc/suivi-recurrence/CxSuiviPaye'
export default {
    name: 'VxSuiviRecurrence',
    components: {
        CxSuiviPaye,
        CxSuiviFacture,
        CxSuiviEnCours,
    },
    data () {
        return {
            datas: null,
            selectedPage: 1,
            periode: null,
            showBusy: false,
            urlRessourceEndPoint: 'api/entreprises/modules/suivi-recurrence/',
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.showBusy = true
            this.$wikaz.request.get(this.urlRessourceEndPoint + 'suivi').then((data) => {
                this.datas = data
                this.showBusy = false
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .vx-suivi-recurrence-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        // grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-suivi-recurrence-header {
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        overflow: auto;
    }

    .cx-suivi-recurrence-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>