<template>
  <div style="display: flex;flex-direction: column;overflow: auto">
    <fx-titre titre="Résidents" />
    <fx-list
      ref="obj_liste"
      style="overflow: auto"
      :url="urlRessourceEndPoint"
      :data-provider.sync="datas"
      :item-renderer="itemRendererResident"
      show-search
      show-refresher
      show-result
      show-add
      @add="f_ajouter"
      @arret="f_arret"
    />
  </div>
</template>
<script>

import IxResident from '@/wikaz-konnect/gdt/gestion-logements/elements/residents/IxResident'

export default {
    name: 'CxListeResidents',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datas: null,
            itemRendererResident: IxResident,
        }
    },
    methods: {
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(()=> {
                    this.$refs.obj_liste.refresh()
                })
            })
        },
        f_arret (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/' + item.affect_id + '/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.affect_id, data).then(()=> {
                    this.$refs.obj_liste.refresh()
                })
            })
        },
    },
}
</script>

<style scoped>

</style>