import Vue from 'vue'
import CxMultiModules from '@/wikaz-core/ui/composants/divers/CxMultiModules'
import CxPhotoProfil from '@/wikaz-core/ui/composants/divers/CxPhotoProfil'
import CxWaiting from '@/wikaz-core/ui/composants/divers/CxWaiting'
import FxItemRendererMenu from '@/wikaz-core/ui/composants/divers/FxItemRendererMenu'
import FxSousTitre from '@/wikaz-core/ui/composants/divers/FxSousTitre'
import FxTitre from '@/wikaz-core/ui/composants/divers/FxTitre'
import FxListTransfert from '@/wikaz-core/ui/composants/divers/FxListTransfert'
import FxTabNavigator from '@/wikaz-core/ui/composants/divers/FxTabNavigator'
import FxBlocInfo from '@/wikaz-core/ui/composants/divers/FxBlocInfo'
import FxCalendrier from '@/wikaz-core/ui/composants/divers/FxCalendrier'
import FxChamp from '@/wikaz-core/ui/composants/divers/FxChamp'
import FxLabel from '@/wikaz-core/ui/composants/divers/FxLabel'
import FxEditeurHtml from '@/wikaz-core/ui/composants/divers/FxEditeurHtml'
Vue.component('fx-label', FxLabel)
Vue.component('cx-multi-modules', CxMultiModules)
Vue.component('cx-photo-profil', CxPhotoProfil)
Vue.component('cx-waiting', CxWaiting)
Vue.component('fx-item-renderer-menu', FxItemRendererMenu)
Vue.component('fx-sous-titre', FxSousTitre)
Vue.component('fx-titre', FxTitre)
Vue.component('fx-list-transfert', FxListTransfert)
Vue.component('fx-tab-navigator', FxTabNavigator)
Vue.component('fx-bloc-info', FxBlocInfo)
Vue.component('fx-calendrier', FxCalendrier)
Vue.component('fx-champ', FxChamp)
Vue.component('fx-editeur-html', FxEditeurHtml)