<template>
  <div style="width:auto;display: flex;">
    <fx-icon
      :size="38"
      icon="keyboard_arrow_left"
      @click="f_retrait_annee"
    />
    <div
      style="display:flex;width: 120px;text-align: center;border: 1px solid grey;border-radius: 5px;align-items: center;justify-content: center"
      @click="showEdit=true"
    >
      {{ value }}
    </div>
    <fx-icon
      :size="38"
      icon="keyboard_arrow_right"
      @click="f_ajout_annee"
    />
    <v-dialog
      v-model="showEdit"
      width="200px"
    >
      <v-list max-height="80vh" style="overflow-y: auto">
        <v-list-item
          v-for="(item, index) in moisAnnee"
          :key="index"
          @click="f_select_annee(item)"
        >
          <v-list-item-content style="margin-left: 10px">
            <v-list-item-title v-text="item" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: 'FxSelectionAnnee',
    props: {
        value: {
            type: Number,
            default: (new Date()).getFullYear(),
        },
    },
    data () {
        return {
            nbAnneesAnterieure: 40,
            limiteAnneeAnterieure: 2021,
            moisAnnee: [(new Date()).getFullYear() - 1, (new Date()).getFullYear(), (new Date()).getFullYear() + 1],
            showEdit: false,
        }
    },
    mounted () {
        let anneeCourante = (new Date()).getFullYear()

        if ((anneeCourante - this.nbAnneesAnterieure) < this.limiteAnneeAnterieure) {
            this.nbAnneesAnterieure = anneeCourante - this.limiteAnneeAnterieure
        }

        let tabAnnee = []

        for (let i = 1; i <= this.nbAnneesAnterieure ; i ++) {
            tabAnnee.push(anneeCourante - i)
        }

        tabAnnee.push(anneeCourante)
        tabAnnee.push(anneeCourante + 1)

        this.moisAnnee = tabAnnee.sort((a, b) => a - b)
    },
    methods: {
        f_retrait_annee () {
            if (this.value > ((new Date()).getFullYear() - this.nbAnneesAnterieure)) {
                this.$emit('input', this.value - 1)
            }
        },
        f_ajout_annee () {
            if (this.value < ((new Date()).getFullYear() + 1)) {
                this.$emit('input', this.value + 1)
            }
        },
        f_select_annee (item) {
            this.$emit('input', item)
            this.showEdit = false
        },
    },
}
</script>
<style scoped>
</style>