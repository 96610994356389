<template>
  <v-list-item
    v-if="item.affichage === 1"
    dense
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <fx-icon
        v-if="item.is_proprietaire > 0"
        color="green"
        icon="vpn_key"
        tooltip="Mon prospect"
      />
      <fx-icon
        v-else-if="item.is_proprietaire === 0 && item.contact_user_proprietaire > 0"
        color="red"
        icon="vpn_key"
        tooltip="Prospect affecté à quelqu'un d'autre"
      />
      <fx-icon
        v-else
        color="blue"
        icon="remove"
        tooltip="Prospect disponible"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        style="font-size: 18px"
        :style="{color: getColorNom(item.action)}"
      >
        {{ item.contact_nom }}
      </v-list-item-title>
      <div style="display: flex;justify-content: space-between;margin-top: 5px">
        <div style="display: flex;align-items: center;font-size: 12px;color: #6c757d">
          <div>{{ item.nom_proprietaire }}</div>
        </div>
        <div style="display: flex;align-items: center;font-size: 12px;color: #6c757d">
          <div>{{ item.etat_libelle }}</div>
        </div>
      </div>
    </v-list-item-content>
    <v-list-item-avatar>
      <fx-icon
        :color="item.etat_color"
        :icon="item.etat_icon"
        :tooltip="item.etat_libelle"
      />
    </v-list-item-avatar>
  </v-list-item>
  <v-list-item
    v-else
    dense
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <fx-icon
        v-if="item.is_proprietaire > 0"
        color="green"
        icon="vpn_key"
        tooltip="Mon prospect"
      />
      <fx-icon
        v-else-if="item.is_proprietaire === 0 && item.contact_user_proprietaire > 0"
        color="red"
        icon="vpn_key"
        tooltip="Prospect affecté à quelqu'un d'autre"
      />
      <fx-icon
        v-else
        color="blue"
        icon="remove"
        tooltip="Prospect disponible"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title style="font-size: 18px">
        {{ item.interlocuteur_nom }} {{ item.interlocuteur_prenom }}
      </v-list-item-title>
      <v-list-item-title
        style="font-size: 13px"
        :style="{color: getColorNom(item.action)}"
      >
        {{ item.contact_nom }}
      </v-list-item-title>
      <div style="display: flex;justify-content: space-between;margin-top: 5px">
        <div style="display: flex;align-items: center;font-size: 12px;color: #6c757d">
          <div>{{ item.nom_proprietaire }}</div>
        </div>
        <div style="display: flex;align-items: center;font-size: 12px;color: #6c757d">
          <div>{{ item.etat_libelle }}</div>
        </div>
      </div>
    </v-list-item-content>
    <v-list-item-avatar>
      <fx-icon
        :color="item.etat_color"
        :icon="item.etat_icon"
        :tooltip="item.etat_libelle"
      />
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemGestionCrm',
    extends: FxItemTemplate,
    methods: {
        getColorNom (date) {
            if (date !== null) {
                if (date === this.$wikaz.outils.dates.getTodayBdd()) {
                    return 'green'
                }
                return 'red'

            }
            return 'black'
            // (item.actions > 0) ? 'red' : 'black'
        },
    },
}
</script>
<style scoped>
</style>