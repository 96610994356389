<template>
  <v-list-item
    dense
    style="overflow: visible;"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon>event</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>Date</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>{{ f_format_date(item.indisponibilite_date) }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon
        color="red"
        @click="f_action_custom('delete',item.indisponibilite_id)"
      >
        delete
      </v-icon>
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxIndisponibilites',
    extends: FxItemTemplate,
}
</script>