<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-content>
      <v-list-item-title>{{ item.profil_libelle }}</v-list-item-title>
    </v-list-item-content>
    <fx-icon
      style="margin-left: 5px"
      icon="desktop_windows"
      :badge="item.modules_count"
      tooltip="Nombre de modules"
    />
    <fx-icon
      style="margin-left: 15px"
      icon="person"
      :badge="item.users_count"
      tooltip="Nombre d'utilisateurs"
    />
  </v-list-item>
</template>
<script>
import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxProfils',
    extends: FxItemTemplate,
}
</script>