<template>
  <v-flex class="md4">
    <v-card
      flat
      tile
      style="height: 100%"
    >
      <v-card-text
        v-if="dataCoordonnees === null"
        style="height: 400px"
      >
        <fx-titre titre="Mes coordonnées" />
        <cx-waiting style="margin-top: 20px" />
      </v-card-text>
      <v-card-text
        v-else
        style="height: auto"
      >
        <fx-titre titre="Ma société" />
        <div style="display: flex;align-items: center;margin-top: 10px;margin-bottom: 10px">
          <div style="font-weight: bold">
            Identifiant Wikaz Konnect :
          </div>
          <div style="margin-left: 10px">
            {{ dataCoordonnees.compte_id.toString().padStart(5, '0') }}
          </div>
        </div>
        <div
          v-if="dataCoordonnees.compte_raison_sociale"
          style="font-weight: bold"
          v-html="dataCoordonnees.compte_raison_sociale"
        />
        <div
          v-if="dataCoordonnees.compte_siren"
          v-html="'Siren : '+dataCoordonnees.compte_siren"
        />
        <div
          v-if="dataCoordonnees.compte_adresse"
          v-html="dataCoordonnees.compte_adresse"
        />
        <div
          v-if="dataCoordonnees.compte_cp && dataCoordonnees.compte_ville"
          v-html="dataCoordonnees.compte_cp + ' ' + dataCoordonnees.compte_ville"
        />
        <div
          v-if="dataCoordonnees.compte_telephone1"
          v-html="dataCoordonnees.compte_telephone1"
        />
        <div
          v-if="dataCoordonnees.compte_telephone2"
          v-html="dataCoordonnees.compte_telephone2"
        />
        <div style="display: flex;justify-content: flex-end">
          <v-btn
            small
            color="success"
            @click="f_modifier_coordonnees()"
          >
            Modifier mes coordonnees
          </v-btn>
        </div>
        <cx-photo-profil
          titre="Logo"
          :url-ressource="urlEndPoint + 'logo'"
          field=""
          style="margin-top: 10px"
        />
        <!--                <v-btn color="primary" @click="f_gerer_categories">Gestion des métiers</v-btn>-->
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>

export default {
    name: 'CxParametragesGeneraux',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { dataCoordonnees: null }
    },
    mounted () {
        this.chargerDatas()
    },
    methods: {
        chargerDatas () {
            this.$wikaz.request.get(this.urlEndPoint + 'coordonnees').then((data) => {
                this.dataCoordonnees = data
                if (this.selectedJour) {
                    this.selectedJour = this.dataJours[this.selectedIndex]
                }
            })
        },
        f_modifier_coordonnees () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlEndPoint + 'coordonnees' + '/0/edit',
                this.f_result_update_element,
            )
        },
        f_result_update_element (data) {
            this.$wikaz.request.put(this.urlEndPoint + 'coordonnees' + '/0', data).then(() => {
                this.chargerDatas()
            })
        },
    },
}
</script>

<style scoped>

</style>