<template>
  <div style="overflow: auto">
    <fx-list
      ref="objListe"
      title="Interlocuteurs"
      show-search
      show-result
      show-refresher
      show-add
      :url="urlRessourceEndPoint + 'interlocuteurs'"
      :data-provider.sync="datas"
      :item-renderer="itemRendererInterlocuteur"
      @add="f_ajouter"
    />
    <v-dialog
      v-model="showDialog"
      width="800px"
    >
      <cx-selection-interlocuteur
        v-if="showDialog"
        :url-ressource-end-point="urlRessourceEndPoint + 'interlocuteurs'"
        :url-ressource-end-point-affectation="urlRessourceEndPoint + 'affectation-interlocuteur'"
        @close="f_close_dialog"
        @actualiser="f_close_dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxSelectionInterlocuteur from '@/wikaz-konnect/gdi/gestion-clients/interlocuteur/CxSelectionInterlocuteur'

export default {
    name: 'CxInterlocuteurGdi',
    components: { CxSelectionInterlocuteur },
    extends: CxBase,
    props: {
        urlRessourceEndPoint: {
            required: true,
            type: String,
        },
        itemRendererInterlocuteur: {
            required: true,
            type: Object,
        },
    },
    data () {
        return { showDialog: false }
    },
    methods: {
        f_ajouter () {
            this.showDialog = true
        },
        f_close_dialog () {
            this.showDialog = false
            this.$refs.objListe.refresh()
        },
    },
}
</script>

<style scoped>

</style>