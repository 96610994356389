<template>
  <fx-traitement-template
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :item-renderer="itemRenderer"
    :set-visible-ajout="true"
    :url-ressource-end-point="urlRessourceEndPoint"
    titre-liste="Liste des comptes Wikaz Konnect"
    width="100%"
    width-list="450px"
  />
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import IxItemCompte from '@/wikaz-core/ui/modules/gestion-des-packs/IxItemCompte'
import CxTraitementGestionPackPwa from '@/wikaz-core/ui/modules/gestion-des-packs/CxTraitementGestionPackPwa'
import CxTraitementGestionPackWeb from '@/wikaz-core/ui/modules/gestion-des-packs/CxTraitementGestionPackWeb'

export default {
    name: 'VxGestionDesPacks',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: '',
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionPackWeb),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionPackPwa),
            itemRenderer: IxItemCompte,
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-des-packs-wk/comptes'
            break
        }
    },
}
</script>
<style scoped>
</style>