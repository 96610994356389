class Google {
    etat = null
    key = null
    constructor () {
        this.etat = false
        this.key = null
        window.Vue.$wikaz.request.get('api/wikaz/structure/google_key').then((data) => {
            this.etat = !! data.compte_google_api_key
            this.key = data.compte_google_api_key ? data.compte_google_api_key : null
        })
        // this.etat = process.env.VUE_APP_GOOGLE_API_KEY ? true : false
        // this.key = process.env.VUE_APP_GOOGLE_API_KEY ? process.env.VUE_APP_GOOGLE_API_KEY : null
        if (this.etat) {
            this.chargerGoogleApi()
        }
    }
    chargerGoogleApi () {
        let balise = document.createElement('script')
        balise.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.key + '&libraries=visualization,places'
        balise.async = true
        balise.defer = true
        document.head.appendChild(balise)
    }
}
export default Google