<template>
  <v-list-item @click="f_emit_selection">
    <!--        <div style="padding: 10px;margin-right: 10px">
            <v-img height="50"
                   width="50"
                   :aspect-ratio="16/9"
                   contain
                   :src="'data:image/jpeg;base64,' + item.logo"
            ></v-img>
        </div>-->
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.label"
      />
      <v-list-item-subtitle>Propriétaire : {{ item.proprietaire_nom }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ item.proprietaire_email }}</v-list-item-subtitle>
      <v-list-item-subtitle>{{ item.nb_souscription }} Fonctionnalités</v-list-item-subtitle>
    </v-list-item-content>
    <div style="width: 40px">
      <fx-icon
        v-if="item.nb_moyen_paiement > 0"
        icon="payment"
        :badge="item.nb_moyen_paiement"
        tooltip="Nombre de moyen de paiements"
      />
    </div>
    <div style="width: 40px">
      <fx-icon
        v-if="item.nb_souscription > 0"
        icon="dashboard"
        :badge="item.nb_souscription"
        tooltip="Nombre de fonctionnalités payantes"
      />
    </div>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemCompte',
    extends: FxItemTemplate,
    methods: {
        f_ajouter () {

        },
    },
}
</script>