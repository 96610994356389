<template>
  <div class="fx-list-transfert-container">
    <div class="fx-list-transfert-header">
      <fx-titre
        v-if="titre"
        :titre="titre"
      />
    </div>
    <div class="fx-list-transfert-content">
      <v-card style="overflow: auto;height: 100%">
        <fx-list
          style="overflow: auto;height: 100%"
          title="Non sélectionné"
          show-search
          :data-provider.sync="datas_false"
          :item-renderer="item_renderer"
        />
      </v-card>
      <div style="display: flex;flex-direction: column;justify-content: center">
        <fx-icon
          icon="keyboard_arrow_right"
          tooltip="Ajouter"
          :size="32"
          @click="f_ajouter"
        />
        <fx-icon
          icon="keyboard_arrow_left"
          tooltip="Retirer"
          :size="32"
          @click="f_retirer"
        />
      </div>
      <v-card style="overflow: auto;height: 100%">
        <fx-list
          style="overflow: auto;height: 100%"
          title="Sélectionné"
          show-search
          :data-provider.sync="datas_true"
          :item-renderer="item_renderer"
        />
      </v-card>
    </div>
  </div>
</template>
<script>
import IxListTransfert from '@/wikaz-core/ui/composants/divers/IxListTransfert'

export default {
    name: 'FxListTransfert',
    props: {
        urlRessouceIndex: {
            type: String,
            default: null,
        },
        urlRessouceUpdate: {
            type: String,
            default: null,
        },
        itemRenderer: {
            default: () => {
                return IxListTransfert
            },
        },
        titre: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datas: null,
            datas_true: null,
            datas_false: null,
            item_renderer: this.itemRenderer,
        }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.datas = []
            this.datas_true = []
            this.datas_false = []
            this.$wikaz.request.get(this.urlRessouceIndex).then((data) => {
                this.datas = data
                for (let i = 0; i < data.length; i ++) {
                    data[i].selected = 0
                    if(data[i].etat) {
                        this.datas_true.push(data[i])
                    } else {
                        this.datas_false.push(data[i])
                    }
                }
            })
        },
        f_ajouter () {
            let tabAjout = this.datas_false.filter((item) => {
                return (item.selected === 1)
            }).map((item) => {
                return item.id
            })
            let tabAllready = this.datas_true.map((item) => {
                return item.id
            })
            let data = tabAjout.concat(tabAllready).join(',')
            this.$wikaz.request.put(this.urlRessouceUpdate, { data: data }).then(this.getData)
        },
        f_retirer () {
            let tabAjout = this.datas_true.filter((item) => {
                return (item.selected === 0)
            }).map((item) => {
                return item.id
            })
            let data = tabAjout.join(',')
            this.$wikaz.request.put(this.urlRessouceUpdate, { data: data }).then(this.getData)
        },
    },
}
</script>
<style scoped>
    .fx-list-transfert-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .fx-list-transfert-header {
    }

    .fx-list-transfert-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }
</style>