<template>
  <div class="cx-filtre-gestion-crm-container">
    <fx-checkbox
      v-model="showInactif"
      label="Afficher les inactifs"
      @on-change="filterChanged"
    />
  </div>
</template>
<script>

import FxFiltreTemplate from '@/wikaz-core/ui/composants/base/FxFiltreTemplate'

export default {
    name: 'CxFiltreUtilisateur',
    extends: FxFiltreTemplate,
    data () {
        return { showInactif: 0 }
    },
    methods: {
        getFiltreData () {
            return '?inactif=' + this.showInactif
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-filtre-gestion-crm-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        overflow: auto;
    }

    .cx-filtre-gestion-crm-ligne {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 80px 1fr;
        grid-template-rows: 1fr;
    }
</style>