<template>
  <v-card style="display:flex;padding: 10px; height: 500px">
    <fx-list
      title="Congés & Indisponibilités"
      style="height: 100%"
      :item-renderer="itemRenderer"
      :data-provider="dataConges"
      show-search
      show-refresher
      show-add
      @refresh="chargerDatas"
      @delete="f_delete"
      @add="f_ajout"
    />
  </v-card>
</template>

<script>
import IxIndisponibilites from './IxIndisponibilites'

export default {
    name: 'CxCongesIndisponibilite',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            dataConges: null,
            itemRenderer: IxIndisponibilites,
        }
    },
    mounted () {
        this.chargerDatas()
    },
    methods: {
        chargerDatas () {
            this.$wikaz.request.get(this.urlEndPoint).then((data) => {
                this.dataConges = data
            })
        },
        f_ajout () {
            this.$wikaz.popups.showFormulaire(this.urlEndPoint + '/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post(this.urlEndPoint, data).then((data) => {
                if(data.code === 'success') {
                    this.chargerDatas()
                }
            })
        },
        f_delete (indisponibiliteId) {
            this.$wikaz.request.delete(this.urlEndPoint + '/' + indisponibiliteId).then(() => {
                this.chargerDatas()
            })
        },
    },
}
</script>

<style scoped>

</style>