import store from '@/wikaz-core/store'
import notification from '@/wikaz-core/outils/notification'

class Request {
    url = null
    constructor () {
        this.url = (process.env.NODE_ENV === 'development') ? '/baseURL' : process.env.VUE_APP_URL_API_PROD
    }
    request (method, path, headers, datas, controller) {
        let signal = controller ? controller.signal : null
        let myHeaders = {}
        if (headers) {
            myHeaders = {
                // "Content-Type": "application/json",
                Accept: 'application/json',
                Authorization: 'Bearer ' + store.state.auth.accessToken,
                ...headers,
            }
        } else {
            myHeaders = {
                // "Content-Type": "application/json",
                Accept: 'application/json',
                Authorization: 'Bearer ' + store.state.auth.accessToken,
            }
        }

        let datasSend = null
        if (datas instanceof File) {
            datasSend = new FormData()
            datasSend.append('file', datas)
        } else {
            myHeaders['Content-Type'] = 'application/json'
            datasSend = datas ? JSON.stringify(datas) : null
        }

        const myConfig = {
            method: method,
            headers: myHeaders,
            mode: 'cors',
            cache: 'no-cache',
            body: datasSend,
            signal: signal,
        }
        return window.fetch(this.url + '/' + path, myConfig)
            .then(async response => {
                const data = await response
                if (response.ok) {
                    switch (response.headers.get('Content-Type')) {
                    case 'application/json':
                    case 'application/json; charset=UTF-8':
                        return Promise.resolve(response.json().then((data) => {
                            (new notification()).showAuto(data)
                            if (response.headers.get('wikaz-pagination')) {
                                data.pagination = JSON.parse(response.headers.get('wikaz-pagination'))
                            }
                            return data
                        }))
                    case 'text/html':
                    case 'text/html; charset=UTF-8':
                    case 'text/plain;charset=UTF-8':
                    case 'text/plain; charset=UTF-8':
                        return data.text()
                    case 'application/pdf':
                    case 'image/jpg':
                    case 'image/jpeg':
                    case 'image/webp':
                    case 'image/png':
                        data.blob().then((data) => {
                            window.open(window.URL.createObjectURL(data))
                        })
                        return Promise.reject()
                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                        data.blob().then((data) => {
                            window.open(window.URL.createObjectURL(data))
                        })
                        return Promise.reject()
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        data.blob().then((data) => {
                            window.open(window.URL.createObjectURL(data))
                        })
                        return Promise.reject()
                    default:
                        return data.text()
                    }
                } else {
                    switch (response.status.toString()) {
                    case '400':
                        break
                    default:
                        break

                    }
                    return Promise.reject()
                }
            }, 'test')
            .catch(() => {
                // (new notification()).showError('une erreur est survenue')
                // return Promise.reject(e)
                return null
            })
    }
    get (path, headers = null) {
        return this.request('GET', path, headers)
    }
    post (path, datas = {}, headers = null) {
        return this.request('POST', path, headers, datas)
    }
    put (path, datas = {}, headers = null) {
        return this.request('PUT', path, headers, datas)
    }
    delete (path, headers = null) {
        return this.request('DELETE', path, headers)
    }
    afficherFichier (path) {
        let config = {
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'application/json' },
        }
        return new Promise((resolve, reject) => {
            this.getFull(path, config)
                .then((response) => {
                    let blob = new Blob([response.data], { type: response.headers['content-type'] })
                    let url = window.URL.createObjectURL(blob)
                    window.open(url)
                    resolve()
                })
                .catch(err => reject(err))
        })
    }
    openJpgBase64 (data) {
        let base64ImageData = 'data:image/jpeg;base64,' + data
        let contentType = 'image/jpeg'

        const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length))
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i ++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: contentType })
        const blobUrl = URL.createObjectURL(blob)

        window.open(blobUrl, '_blank')
    }
}
export default Request