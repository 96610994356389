import { render, staticRenderFns } from "./VxGestionCodesPrestationsEntreprises.vue?vue&type=template&id=61e98f81&scoped=true&"
import script from "./VxGestionCodesPrestationsEntreprises.vue?vue&type=script&lang=js&"
export * from "./VxGestionCodesPrestationsEntreprises.vue?vue&type=script&lang=js&"
import style0 from "./VxGestionCodesPrestationsEntreprises.vue?vue&type=style&index=0&id=61e98f81&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e98f81",
  null
  
)

export default component.exports