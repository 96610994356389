import Vue from 'vue'
import FxTraitementTemplate from '@/wikaz-core/ui/composants/template/FxTraitementTemplate'
import FxFormulaireGenerique from '@/wikaz-core/ui/composants/template/formulaire/FxFormulaireGenerique'
import FxTooltipIconError from '@/wikaz-core/ui/composants/template/formulaire/FxTooltipIconError'
import FxFormFooter from '@/wikaz-core/ui/composants/template/formulaire/FxFormFooter'
import FxFormItem from '@/wikaz-core/ui/composants/template/formulaire/FxFormItem'

Vue.component('fx-traitement-template', FxTraitementTemplate)

Vue.component('fx-formulaire-generique', FxFormulaireGenerique)
Vue.component('fx-tooltip-icon-error', FxTooltipIconError)
Vue.component('fx-form-footer', FxFormFooter)
Vue.component('fx-form-item', FxFormItem)