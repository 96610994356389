<template>
  <fx-datagrid-vuetify
    ref="obj_liste"
    :url-ressource-end-point="urlRessourceEndPoint + 'actions'"
    @planifier_demande="planifier_demande"
    @liberer_demande="liberer_demande"
    @propager_demande="propager_demande"
    @reporter_intervention="reporter_intervention"

    @accepter_demande_conge="accepter_demande_conge"
    @refuser_demande_conge="refuser_demande_conge"
    @accepter_demande_bde="accepter_demande_bde"
    @refuser_demande_bde="refuser_demande_bde"
  />
</template>
<script>
import CxPropagerDemande from '@/wikaz-konnect/commun/gestion-actions/CxPropagerDemande'
import CxPopupReporterIntervention
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupReporterIntervention'

export default {
    name: 'VxGestionActionsGdi',
    data () {
        return { urlRessourceEndPoint: 'api/entreprises/modules/gestion-actions-gdi/' }
    },
    methods: {
        f_refresh () {
            this.$refs.obj_liste.chargerData()
        },
        planifier_demande (item) {
            this.$wikaz.popups.show(
                CxPopupReporterIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/planification',
                        titre: 'Planifier l\'intervention',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_refresh,
            )
        },
        liberer_demande (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/liberer').then(() => {
                this.f_refresh()
            })
        },
        propager_demande (item) {
            this.$wikaz.popups.show(
                CxPropagerDemande,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/propager',
                        titre: 'Propager la demande',
                        width: '800px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_refresh)
        },
        reporter_intervention (item) {
            this.$wikaz.popups.show(
                CxPopupReporterIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + 'interventions/' + item.cible_id + '/report',
                        titre: 'Reporter l\'intervention',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_refresh,
            )
        },

        accepter_demande_conge (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/formulaire_accepter_demande_conge',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + 'demandes/' + data.demande_conge_id + '/accepter_demande_conge', data).then(() => {
                        this.f_refresh()
                    })
                },
            )
        },
        refuser_demande_conge (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/formulaire_refuser_demande_conge',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + 'demandes/' + data.demande_conge_id + '/refuser_demande_conge', data).then(() => {
                        this.f_refresh()
                    })
                },
            )
        },
        accepter_demande_bde (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/formulaire_accepter_demande_bde',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + 'demandes/' + data.bde_id + '/accepter_demande_bde', data).then(() => {
                        this.f_refresh()
                    })
                },
            )
        },
        refuser_demande_bde (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + 'demandes/' + item.cible_id + '/formulaire_refuser_demande_bde',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint + 'demandes/' + data.bde_id + '/refuser_demande_bde', data).then(() => {
                        this.f_refresh()
                    })
                },
            )
        },
    },
}
</script>