<template>
  <div style="display: flex;align-items: center">
    <span style="width:70px;text-align:center;font-size: 16px">Début:</span>
    <fx-date-field
      ref="debut"
      v-model="dateDebut"
      @input="changePeriode"
    />
    <span style="width:50px;text-align:center;font-size: 16px">Fin:</span>
    <fx-date-field
      ref="fin"
      v-model="dateFin"
      @input="changePeriode"
    />
    <div style="width: 10px" />
    <v-btn @click="selectJour">
      Jour
    </v-btn>
    <v-btn @click="selectSemaine">
      Semaine
    </v-btn>
    <v-btn @click="selectMois">
      Mois
    </v-btn>
  </div>
</template>
<script>

export default{
    name: 'FxPeriodeDate',
    data () {
        return {
            dateDebut: null,
            dateFin: null,
        }
    },
    mounted () {
        this.$emit('input', this.getPeriode())
    },
    methods: {
        changePeriode () {
            this.$emit('input', this.getPeriode())
        },
        selectJour () {
            this.changePeriode()
        },
        selectSemaine () {
            this.changePeriode()
        },
        selectMois () {
            this.changePeriode()
        },
        getPeriode () {
            return {
                debut: this.dateDebut,
                fin: this.dateFin,
            }
        },
    },
}
</script>