<template>
  <div
    class="agenda-rdv"
    :style="'height: ' + (dataProvider.duree/15)*heightQuarter +'px;top: '+ f_get_top(dataProvider.th_libelle) + 'px;background-color: ' + dataProvider.couleur"
  >
    <template
      v-if="!itemRendererCellule"
      @click="f_afficher_detail"
    >
      <div>{{ dataProvider.nom_client | limitationLength }}</div>
      <div v-if="dataProvider.intervention_duree>0.5">
        {{ dataProvider.adresse_cp }} {{ dataProvider.adresse_ville }}
      </div>
      <div v-if="dataProvider.intervention_duree>0.75">
        {{ dataProvider.prestation_libelle }}
      </div>
    </template>
    <component
      :is="itemRendererCellule"
      v-else
      :data-provider="dataProvider"
      @refresh="f_refresh"
    />
  </div>
</template>
<script>

// import CxDetailRdv from "../../../../components/desktop/plannings/CxDetailRdv";

export default {
    name: 'FxAgendaRdv',
    filters: {
        limitationLength: function (value) {
            if (value.length >= 17) {
                return value.substring(0, 14) + '...'
            }
            return value

        },
    },
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
        plage_debut: {
            type: Number,
            default: 1,
        },
        plage_fin: {
            type: Number,
            default: 24,
        },
        heightQuarter: {
            type: Number,
            default: 20,
        },
        itemRendererCellule: { default: null },
    },
    data () {
        return { afficher: false }
    },
    methods: {
        afficherDialog (etat) {
            this.afficher = etat
        },
        f_get_top (horaire) {
            let heure = parseInt(horaire.split(':')[0])
            let minute = parseInt(horaire.split(':')[1])
            return (heure - this.plage_debut) * (this.heightQuarter * 4) + (minute / 15) * this.heightQuarter
        },
        f_refresh () {
            this.$emit('refresh')
        },
    },
}
</script>

<style scoped lang="scss">
    .agenda-rdv {
        cursor: pointer;
        // border-top-right-radius: 15px;
        // border-bottom-right-radius: 15px;
        // border-bottom-left-radius: 15px;
        border: 1px solid #b3adad;
        position:absolute;
        display: flex;
        flex-direction: column;
        padding: 10px;
        font-size: 13px;
        width: 100%;
    }
</style>