<template>
  <div
    v-if="datas"
    :key="keyChange"
    class="cx-traitement-menu-container"
  >
    <fx-formulaire-generique
      v-if="index===0"
      :data-in="datas"
      :url-ressource="'api/wikaz/modules/gestion-menu/menus/'+datas.module_id+'/edit'"
      @on-submit="updateData"
    />
    <fx-formulaire-generique
      v-else-if="index===1"
      :url-ressource="'api/wikaz/modules/gestion-menu/menus/'+datas.module_id+'/configuration/0/edit'"
      @on-submit="updateConfiguration"
    />
    <div
      v-else-if="index===2"
      style="overflow: auto;padding: 10px"
      title="Profils"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-profils'"
        :url-ressouce-update="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-profils/' + datas.module_id"
      />
    </div>
    <div
      v-else-if="index===3"
      style="overflow: auto;padding: 10px"
      title="Utilisateur"
    >
      <fx-list-transfert
        :url-ressouce-index="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-utilisateurs'"
        :url-ressouce-update="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-utilisateurs/' + datas.module_id"
      />
    </div>
    <v-bottom-navigation
      v-model="index"
      height="36"
      grow
      hide-on-scroll
      horizontal
      @change="onChange"
    >
      <v-btn
        color="primary"
        text
      >
        <v-icon>article</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        text
      >
        <v-icon>settings</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        text
      >
        <v-icon>portrait</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        text
      >
        <v-icon>person</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementMenuPwa',
    extends: CxBase,
    data () {
        return {
            index: 0,
            keyChange: 0,
        }
    },
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = this.$store.getters.getViewPwa().config.datas.module_libelle
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
    },
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-menu/menus/' + data.module_id, data).then(this.f_result_updateData)
        },
        updateConfiguration (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-menu/menus/' + data.module_id + '/configuration/0', data).then(this.f_result_updateData)
        },
        f_result_updateData () {
            this.$emit('on-refresh')
        },
        f_retour () {
            this.f_precedent('vx-gestion-menu', 'Gestion du menu')
        },
        onChange () {
            this.keyChange ++
        },
    },
}
</script>
<style scoped>
    .cx-traitement-menu-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-traitement-menu-col1 {
        box-sizing: border-box;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-menu-col2 {
        height: 100%;
        box-sizing: border-box;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
    }
</style>