<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn
        style="margin-top: 2px;margin-bottom: 5px;border: 1px solid grey"
        :color="(value && value.length >= 7) ? value : '#FFFFFF'"
        v-on="on"
      >
        {{ value }}
      </v-btn>
    </template>
    <v-color-picker
      :value="(value && value.length >= 7) ? value : '#FFFFFF'"
      mode="hexa"
      hide-mode-switch
      @input="onChange"
    />
  </v-menu>
</template>
<script>
export default{
    name: 'FxColorPicker',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: '' },
        size: { default: 'large' },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        onChange (ev) {
            this.$emit('input', ev)
        },
    },
}
</script>
<style lang="scss">
</style>