<template>
  <div
    class="fx-label-container"
    :style="{minWidth: width,width: width,bold: error,color: error ? 'red' : null}"
  >
    {{ text }}
  </div>
</template>
<script>
export default{
    name: 'FxLabel',
    props: {
        text: { default: '' },
        width: {
            type: String,
            default: '130px',
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="scss" scoped>
    .fx-label-container{
        display:flex;
        align-items: center;
        font-size: 12px;
        justify-content: flex-end;
        padding-right: 10px;
    }
</style>