<template>
  <div style="display:flex;width: 100%;align-items: center">
    <div style="width: 110px">
      <v-text-field
        ref="myInput"
        style="margin-top: 2px;margin-bottom: 2px;width: 110px"
        outlined
        hide-details
        dense
        maxlength="9"
        :placeholder="placeholder"
        :readonly="!editable"
        :style="{width: width}"
        type="text"
        :rules="rules"
        :value="value"
        label=""
        @input="onChange"
      />
    </div>
    <div style="width:calc(100% - 130px);display: flex;flex-direction: column;align-items: center;justify-content: center">
      <div
        style="font-weight: bold;font-size: 12px;"
        v-html="denomination"
      />
    </div>
  </div>
</template>
<script>
export default{
    name: 'FxSirenInput',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            errorString: '',
            denomination: '',
            dataSiren: null,
        }
    },
    methods: {
        validate () {
            if(this.value && this.value.length === 9) {
                if(this.dataSiren && ! this.dataSiren.error) {
                    return true
                }
            }
            return false
        },
        getError () {
            if(this.value && this.value.length === 9) {
                if(this.dataSiren && this.dataSiren.error) {
                    return this.dataSiren.message
                }
                return ''
            }
            return 'Siren invalide'
        },
        onChange (event) {
            if(event.length === 9) {
                this.$wikaz.request.get('api/wikaz/sirene?client_id=' + this.$wikaz.app.client_id + '&type=SIREN&search=' + event.toString()).then((data) => {
                    this.dataSiren = data
                    this.denomination = data.denomination ? data.denomination : ''
                    this.$emit('input', event)
                })
                this.$emit('input', event)
            } else {
                this.denomination = ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
</style>