import Vue from 'vue'
import VxGestionParametragesEntreprises from '@/wikaz-konnect/gdi/parametrages/VxGestionParametragesEntreprises'
import VxGestionConfigurationEntreprises
from '@/wikaz-konnect/gdi/parametrages/gestion-configuration/VxGestionConfigurationEntreprises'
import VxGestionCodesPrestationsEntreprises
from '@/wikaz-konnect/gdi/parametrages/gestion-codes-prestations/VxGestionCodesPrestationsEntreprises'
import VxGestionClientsEntreprises from '@/wikaz-konnect/gdi/gestion-clients/VxGestionClientsEntreprises'
import VxPlanningsEntreprises from '@/wikaz-konnect/gdi/plannings/VxPlanningsEntreprises'
import VxGestionDemandes from '@/wikaz-konnect/gdi/gestion-demandes/VxGestionDemandes'
import VxReceptionDemandesExternes from '@/wikaz-konnect/gdi/reception-demandes/VxReceptionDemandesExternes'
import VxReleveActivitePwa from '@/wikaz-konnect/gdi/releve-activite/VxReleveActivitePwa'
import VxMonActivite from '@/wikaz-konnect/gdi/mon-activite/VxMonActivite'
import VxSupport from '@/wikaz-core/ui/modules/support/VxSupport'
import VxMaintenance from '@/wikaz-core/ui/modules/maintenance/VxMaintenance'
import VxGestionFactures from '@/wikaz-core/ui/modules/factures/VxGestionFactures'
import CxPageInterventions from '@/wikaz-konnect/gdi/gestion-clients/traitement-pwa/CxPageInterventions'
import CxPageIntervention from '@/wikaz-konnect/gdi/gestion-clients/traitement-pwa/CxPageIntervention'
import VxGestionInterlocuteurs from '@/wikaz-konnect/gdi/gestion-interlocuteurs/VxGestionInterlocuteurs'
import VxGestionRecepteurs from '@/wikaz-konnect/gdi/gestion-recepteurs/VxGestionRecepteurs'
import VxGestionEmetteurs from '@/wikaz-konnect/gdi/gestion-emetteurs/VxGestionEmetteurs'
import VxGestionZones from '@/wikaz-konnect/gdi/parametrages/gestion-zones/VxGestionZones'
import VxGestionDesPacks from '@/wikaz-core/ui/modules/gestion-des-packs/VxGestionDesPacks'
import CxTraitementActivite from '@/wikaz-konnect/gdi/mon-activite/CxTraitementActivite'
import CxTraitementGestionClients
from '@/wikaz-konnect/gdi/gestion-clients/traitement-desktop/CxTraitementGestionClients'

const Imports = []

Imports.push(
    {
        name: 'vx-gestion-parametrages-entreprises',
        component: VxGestionParametragesEntreprises,
    },
    {
        name: 'vx-gestion-configuration-entreprises',
        component: VxGestionConfigurationEntreprises,
    },
    {
        name: 'vx-gestion-codes-prestations-entreprises',
        component: VxGestionCodesPrestationsEntreprises,
    },
    {
        name: 'vx-gestion-clients-entreprises',
        component: VxGestionClientsEntreprises,
    },
    {
        name: 'vx-plannings-entreprises',
        component: VxPlanningsEntreprises,
    },
    {
        name: 'vx-gestion-demandes',
        component: VxGestionDemandes,
    },
    {
        name: 'vx-reception-demandes-externes',
        component: VxReceptionDemandesExternes,
    },
    {
        name: 'vx-releve-activite-pwa',
        component: VxReleveActivitePwa,
    },
    {
        name: 'vx-mon-activite',
        component: VxMonActivite,
    },
    {
        name: 'vx-support-gdi',
        component: VxSupport,
    },
    {
        name: 'vx-maintenance-gdi',
        component: VxMaintenance,
    },
    {
        name: 'vx-gestion-factures-gdi',
        component: VxGestionFactures,
    },
    {
        name: 'cx-page-interventions',
        component: CxPageInterventions,
    },
    {
        name: 'cx-page-intervention',
        component: CxPageIntervention,
    },
    {
        name: 'vx-gestion-interlocuteurs',
        component: VxGestionInterlocuteurs,
    },
    {
        name: 'vx-gestion-emetteurs',
        component: VxGestionEmetteurs,
    },
    {
        name: 'vx-gestion-recepteurs',
        component: VxGestionRecepteurs,
    },
    {
        name: 'vx-gestion-zones',
        component: VxGestionZones,
    },
    {
        name: 'vx-gestion-des-packs-wk',
        component: VxGestionDesPacks,
    },
    {
        name: 'cx-traitement-activite',
        component: CxTraitementActivite,
    },
    {
        name: 'cx-traitement-gestion-clients',
        component: CxTraitementGestionClients,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports