<template>
  <v-card class="cx-edition-facture-container">
    <v-card-title>Edition Facture</v-card-title>
    <div class="cx-edition-facture-edition">
      <div
        v-if="datas"
        style="display: flex;justify-content: space-between"
      >
        <div style="width: 40%">
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="Date"
            />
            <fx-date-field v-model="datas.facture_date" />
          </div>
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="Libellé"
            />
            <fx-text-input v-model="datas.facture_observation" />
          </div>
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="Type Paiement"
            />
            <fx-dropdown
              v-model="datas.facture_type_paiement"
              :data-provider="[{id: 1,label: 'Virement'},{id: 2,label: 'Prélèvement'}]"
            />
          </div>
        </div>
        <div style="width: 55%">
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="Raison sociale"
            />
            <fx-text-input v-model="datas.facture_raison_sociale" />
          </div>
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="Adresse"
            />
            <fx-text-input v-model="datas.facture_adresse" />
          </div>
          <div style="display: flex;align-items: center">
            <fx-label
              width="100px"
              text="CP"
            />
            <fx-text-input
              v-model="datas.facture_cp"
              width="170px"
            />
            <fx-label
              width="50px"
              text="Ville"
            />
            <fx-text-input v-model="datas.facture_ville" />
          </div>
        </div>
      </div>
      <fx-datagrid-vuetify
        ref="obj_liste"
        titre="Lignes de facturation"
        :url-ressource-end-point="urlRessourceEndPoint + '/edition'"
        @ajouter="ajouter_ligne"
        @supprimer="supprimer"
        @modifier="modifier_ligne"
      />
    </div>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="success"
        @click="enregistrer"
      >
        Valider
      </v-btn>
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: 'CxEditionFacture',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        enregistrer () {
            this.$wikaz.request.put(this.urlRessourceEndPoint, this.datas).then(() => {
                this.$emit('on-submit')
            })
        },
        ajouter_ligne () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/edition/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/edition', data).then(() => {
                    this.$refs.obj_liste.chargerData()
                })
            })
        },
        supprimer (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/edition/' + item.detail_id).then(() => {
                this.$refs.obj_liste.chargerData()
            })
        },
        modifier_ligne (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/edition/' + item.detail_id + '/edit', (data) => {
                this.$wikaz.request.put(this.urlRessourceEndPoint + '/edition/' + data.detail_id, data).then(() => {
                    this.$refs.obj_liste.chargerData()
                })
            })
        },
    },
}
</script>
<style scoped>
    .cx-edition-facture-container {
        width: 1000px;
        height: 800px;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-edition-facture-edition {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>