<template>
  <!--<div style="width: 150px;height: 35px;display: flex;align-items: center">
        <v-icon>{{item.event_icon}}</v-icon>
        <div style="margin-left: 10px">{{item.event_description}}</div>
    </div>-->
  <v-list-item
    dense
    style="overflow: visible;border-bottom: 1px solid grey"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon color="red">
        warning
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="item.detail_anomalie" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import FxItemTemplate from '../../../../../wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemAnomalie',
    extends: FxItemTemplate,
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>