import Vue from 'vue'
import VxGestionActionsGdi from '@/wikaz-konnect/commun/gestion-actions/VxGestionActionsGdi'
import VxGestionUtilisateursWikaz from '@/wikaz-konnect/commun/gestion-utilisateurs/VxGestionUtilisateursWikaz'

const Imports = []

Imports.push(
    {
        name: 'vx-gestion-actions-gdi',
        component: VxGestionActionsGdi,
    },
    {
        name: 'vx-gestion-utilisateurs-wikaz',
        component: VxGestionUtilisateursWikaz,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports