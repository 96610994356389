<template>
  <div>Traitement Pwa {{ datas }}</div>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementGestionPackPwa',
    extends: CxBase,
    data () {
        return { urlRessourceEndPoint: '' }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-des-packs-wk/comptes/'
            break
        }
    },
}
</script>

<style scoped>

</style>