<template>
  <div class="cx-suivi-demandes-container">
    <fx-tab-navigator>
      <div
        style="overflow: auto;padding: 10px"
        title="En cours"
      >
        <fx-datagrid-vuetify
          v-if="urlRessourceEndPoint"
          :key="selectedTypeListe"
          ref="objDatagrid1"
          :url-ressource-end-point="urlRessourceEndPoint + '?type=0'"
          @consulter="f_consulter"
          @affecter="f_affecter_demande"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Historique"
      >
        <div class="cx-suivi-demandes-container-historique">
          <fx-periode-date v-model="selectedPeriode" />
          <fx-datagrid-vuetify
            v-if="urlRessourceEndPoint && selectedPeriode"
            :key="selectedTypeListe"
            ref="objDatagrid2"
            :url-ressource-end-point="urlRessourceEndPoint + '?type=1&debut=' + this.selectedPeriode.debut + '&fin=' + this.selectedPeriode.fin"
            @consulter="f_consulter"
            @affecter="f_affecter_demande"
          />
        </div>
      </div>
    </fx-tab-navigator>
  </div>
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxAffecterDemande from '@/wikaz-konnect/gdi/gestion-demandes/CxAffecterDemande'

export default {
    name: 'VxGestionDemandes',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-demandes/demandes',
            datas: null,
            search: '',
            loading: false,
            selectedTypeListe: 0,
            selectedPeriode: null,
        }
    },
    methods: {
        f_refresh () {
            if (this.$refs.objDatagrid1) {
                this.$refs.objDatagrid1.chargerData()
            }
            if (this.$refs.objDatagrid2) {
                this.$refs.objDatagrid2.chargerData()
            }
        },
        f_affecter_demande (item) {
            this.$wikaz.popups.show(
                CxAffecterDemande,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.demande_id + '/affecter',
                        titre: 'Propager la demande',
                        width: '800px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_refresh)
        },
        f_consulter (item) {
            if (this.isMobile) {
                let dataParams = {}
                dataParams.client_id = item.demande_client_id
                dataParams.intervention_id = item.intervention_id
                dataParams.demande_id = item.demande_id
                this.$store.commit('SET_VIEW_PWA', {
                    component: 'cx-page-intervention',
                    module_libelle: 'Demande : ' + item.demande_id,
                    config: {
                        iconLeft: 'arrow_back',
                        datas: dataParams,
                        clickLeft: this.f_retour_detail,
                    },
                })
            } else {
                this.$store.commit('ADD_TAB', {
                    action: '1',
                    color: '#F45345',
                    icon: 'perm_contact_calendar',
                    module_application_id: 2,
                    module_etat: 1,
                    module_id: 209,
                    module_libelle: 'Intervention : ',
                    module_parent_id: 0,
                    props: [],
                    route: 'cx-traitement-gestion-clients',
                    url_image: 'clients.jpg',
                    dataIn: {
                        inter: item.demande_id.toString().padStart(7, '0'),
                        client_id: item.demande_client_id,
                    },
                })
            }
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-suivi-demandes-container {
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-suivi-demandes-container-historique {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>