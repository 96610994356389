<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :composant-filtre-element="composantFiltreElement"
    titre-liste="Clients"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import IxGestionClients from '@/wikaz-konnect/gdi/gestion-clients/IxGestionClients'
import CxTraitementGestionClients from '@/wikaz-konnect/gdi/gestion-clients/traitement-desktop/CxTraitementGestionClients'
import CxTraitementGestionClientsPwa from '@/wikaz-konnect/gdi/gestion-clients/traitement-pwa/CxTraitementGestionClientsPwa'
import CxFiltreClients from '@/wikaz-konnect/gdi/gestion-clients/CxFiltreClients'

export default {
    name: 'VxGestionClientsEntreprises',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-clients-entreprises/clients',
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreClients),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionClients),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementGestionClientsPwa),
            itemRenderer: IxGestionClients,
        }
    },
}
</script>