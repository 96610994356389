<template>
  <v-card
    class="fx-formulaire-generique-container"
    :width="isMobile ? 'auto' : width"
    :style="{maxHeight: isPopup ? '90vh' : '100vh'}"
  >
    <v-card-title style="padding-bottom: 10px">
      {{ title }}
    </v-card-title>
    <cx-waiting v-if="showSpinner" />
    <div v-else>
      <div
        v-if="!multiColonnes"
        class="cx-column-1"
      >
        <div style="padding: 10px;overflow: auto">
          <template v-for="(item, index) in dataStructure">
            <template v-if="Array.isArray(item)">
              <div
                :key="index"
                style="display: flex"
              >
                <template v-for="(sousItem, index2) in item">
                  <fx-form-item
                    v-if="isDisplayedField(sousItem)"
                    :ref="sousItem.field"
                    :key="index2"
                    :item="sousItem"
                    :small="small"
                    :label-width="(index2 === 0) ? labelWidth : 'auto'"
                    :data-provider="dataProvider"
                    :rules-brut="ruleValidate[sousItem.field]"
                    @input="isDisplayedField(sousItem)"
                  />
                </template>
              </div>
            </template>
            <template v-else>
              <fx-form-item
                v-if="isDisplayedField(item)"
                :ref="item.field"
                :key="index"
                :item="item"
                :small="small"
                :label-width="labelWidth"
                :data-provider="dataProvider"
                :rules-brut="ruleValidate[item.field]"
                @input="isDisplayedField(item)"
              />
            </template>
          </template>
        </div>
      </div>
      <div
        v-else
        :class="'cx-column-' + nbCols"
      >
        <div
          v-for="(dataStructureElement,indexElement) in dataStructure"
          :key="indexElement"
          style="padding: 10px;overflow: auto"
        >
          <template v-for="(item, index) in dataStructureElement">
            <template v-if="Array.isArray(item)">
              <div
                :key="index"
                style="display: flex"
              >
                <template v-for="(sousItem, index2) in item">
                  <fx-form-item
                    v-if="isDisplayedField(sousItem)"
                    :ref="sousItem.field"
                    :key="index2"
                    :item="sousItem"
                    :small="small"
                    :label-width="(index2 === 0) ? labelWidth : 'auto'"
                    :data-provider="dataProvider"
                    :rules-brut="ruleValidate[sousItem.field]"
                    @input="isDisplayedField(sousItem)"
                  />
                </template>
              </div>
            </template>
            <template v-else>
              <fx-form-item
                v-if="isDisplayedField(item)"
                :ref="item.field"
                :key="index"
                :item="item"
                :small="small"
                :label-width="labelWidth"
                :data-provider="dataProvider"
                :rules-brut="ruleValidate[item.field]"
                @input="isDisplayedField(item)"
              />
            </template>
          </template>
        </div>
      </div>
    </div>
    <fx-form-footer
      :show-validate="isEditable"
      :show-cancel="isPopup"
      @close="close"
      @submit="submit"
    />
  </v-card>
</template>
<script>
import FxFormItem from '@/wikaz-core/ui/composants/template/formulaire/FxFormItem'
export default {
    name: 'FxFormulaireGenerique',
    components: { FxFormItem },
    props: {
        setEditable: {
            type: Boolean,
            default: true,
        },
        footerVisible: {
            type: Boolean,
            default: true,
        },
        dataIn: {
            type: Object,
            default: null,
        },
        urlRessource: {
            type: String,
            default: '',
        },
        setPopup: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            title: 'Chargement',
            width: '450px',
            labelWidth: '100px',
            dataProvider: null,
            ruleValidate: {},
            isEditable: this.setEditable,
            isPopup: this.setPopup,
            urlRessourceEndPoint: '',
            dataStructure: [],
            multiColonnes: false,
            showSpinner: true,
            isValid: false,
            terms: [],
            term_actions: [],
            hasPopupDataProvider: false,
            isMobile: window.Vue.$store.getters.isMobile(),
            nbCols: 1,
        }
    },
    watch: {
        urlRessourceEndPoint: function () {
            this.f_charger_formulaire()
        },
    },
    mounted () {
        if (this.dataIn && ! this.hasPopupDataProvider) {
            this.dataProvider = this.dataIn
        }
        if (this.urlRessource) {
            this.urlRessourceEndPoint = this.urlRessource
        }
    },
    methods: {
        submit () {
            let isValid = true
            if (this.multiColonnes) {
                for (const colonne in this.dataStructure) {
                    let itemsCol = this.dataStructure[colonne]
                    let nbElem = itemsCol.length
                    for (let i = 0; i < nbElem; i ++) {
                        let item = itemsCol[i]
                        if (this.isDisplayedField(item)) {
                            if (Array.isArray(item)) {
                                for (let j = 0; j < item.length; j ++) {
                                    if (this.$refs[item[j].field]) {
                                        let formElement = this.$refs[item[j].field][0]
                                        if(! formElement.validate()) {
                                            isValid = false
                                        }
                                    }
                                }
                            } else {
                                if (this.$refs[item.field]) {
                                    let formElement = this.$refs[item.field][0]
                                    if(! formElement.validate()) {
                                        isValid = false
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                let nbElem = this.dataStructure.length
                for (let i = 0; i < nbElem; i ++) {
                    let item = this.dataStructure[i]
                    if (this.isDisplayedField(item)) {
                        if (Array.isArray(item)) {
                            for (let j = 0; j < item.length; j ++) {
                                if (this.$refs[item[j].field]) {
                                    let formElement = this.$refs[item[j].field][0]
                                    if(! formElement.validate()) {
                                        isValid = false
                                    }
                                }
                            }
                        } else {
                            if (this.$refs[item.field]) {
                                let formElement = this.$refs[item.field][0]
                                if(! formElement.validate()) {
                                    isValid = false
                                }
                            }
                        }
                    }
                }
            }

            if (isValid) {
                this.$emit('on-submit', this.dataProvider)
            } else {
                this.$wikaz.getInstance().outils.notification.showError('Veuillez vérifier votre saisie')
            }
        },
        close () {
            this.$emit('close')
        },
        f_charger_formulaire () {
            if (this.urlRessourceEndPoint !== '') {
                this.$wikaz.request.get(this.urlRessourceEndPoint).then(this.f_result_formulaire)
            }
        },
        f_result_formulaire (data) {
            this.title = data.titre
            this.width = data.width
            this.labelWidth = data.labelWidth
            if (this.dataIn === null && ! this.hasPopupDataProvider) {
                this.dataProvider = data.dataProvider
            }
            this.multiColonnes = data.multiColonnes
            this.isEditable = data.editable
            this.dataStructure = data.dataStructure
            if(this.multiColonnes && ! this.isMobile) {
                this.nbCols = Object.entries(this.dataStructure).length
            }
            this.ruleValidate = data.ruleValidate
            this.showSpinner = false
            this.terms = data.terms
            this.term_actions = data.term_actions
        },
        isDisplayedField (component) {
            let display = true
            let term = this.term_actions.find(i => i.field === component.field)
            if (term !== undefined) {
                display = (term.action === 'hide')
                let targetTerms = this.terms.filter(i => term.terms.indexOf(i.id) > - 1)
                let nbTrueTerms = 0
                targetTerms.forEach(function (e) {
                    let comparedValue = (e.fieldToCompare !== null) ? this.dataProvider[e.fieldToCompare] : e.value
                    switch (e.term) {
                    case 'equal':
                        if (this.dataProvider[e.field] === comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'different':
                        if (this.dataProvider[e.field] !== comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'more':
                        if (this.dataProvider[e.field] < comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'more_or_equal':
                        if (this.dataProvider[e.field] <= comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'less':
                        if (this.dataProvider[e.field] > comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'less_or_equal':
                        if (this.dataProvider[e.field] >= comparedValue) {
                            nbTrueTerms ++
                        }
                        break
                    case 'in':
                        if (comparedValue.indexOf(this.dataProvider[e.field]) > - 1) {
                            nbTrueTerms ++
                        }
                        break
                    case 'not_in':
                        if (comparedValue.indexOf(this.dataProvider[e.field]) === - 1) {
                            nbTrueTerms ++
                        }
                        break
                    }
                }, this)
                display = (nbTrueTerms === targetTerms.length)

                /*if (!display) {
                        this.dataProvider[component.field] = ''
                    } else {
                        if (this.multiColonnes) {
                            for (const colonne in this.dataStructure) {
                                let itemsCol = this.dataStructure[colonne]
                                for (const index in itemsCol) {
                                    if (itemsCol[index].field === component.field) {
                                        this.dataProvider[component.field]  = (itemsCol[index].default || itemsCol[index].default === 0) ? itemsCol[index].default : ''
                                    }
                                }
                            }

                        } else {
                            for (const index in this.dataStructure) {
                                if (this.dataStructure[index].field === component.field) {
                                    this.dataProvider[component.field] = (this.dataStructure[index].default || this.dataStructure[index].default === 0) ? this.dataStructure[index].default : ''
                                }
                            }
                        }
                    }*/
            }
            return display
        },
    },
}
</script>
<style scoped lang="scss">
    .fx-formulaire-generique-container {
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: auto 1fr auto;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-column-1 {
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-column-2 {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    .cx-column-3 {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }

    /*.fx-formulaire-generique-conttainer::-webkit-scrollbar {
        width: 0 !important;
    }*/
</style>