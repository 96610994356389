<template>
  <fx-traitement-template
    :composant-filtre-element="composantFiltreElement"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :item-renderer="itemRenderer"
    :url-ressource-end-point="urlRessourceEndPoint"
    titre-liste="Liste des menus"
    width-list="400px"
  />
</template>
<script>
import IxTraitement from './IxMenus.vue'
import CxTraitementMenu from './CxTraitementMenu'
import CxFiltreModule from './CxFiltreModule'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementMenuPwa from '@/wikaz-konnect/adm/gestion-menu/CxTraitementMenuPwa'

export default {
    name: 'VxGestionMenu',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/wikaz/modules/gestion-menu/menus',
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementMenu),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementMenuPwa),
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreModule),
            itemRenderer: IxTraitement,
        }
    },
}
</script>