<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :titre-liste="'Liste des utilisateurs ' + isMobile"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :composant-filtre-element="composantFiltreElement"
    :item-renderer="itemRenderer"
    width-list="350px"
  />
</template>
<script>
import IxUtilisateur from './IxUtilisateur.vue'
import CxFiltreUtilisateur from './CxFiltreUtilisateur'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementUtilisateur from '@/wikaz-konnect/adm/gestion-utilisateurs/CxTraitementUtilisateur'
import CxTraitementUtilisateurPwa from '@/wikaz-konnect/adm/gestion-utilisateurs/CxTraitementUtilisateurPwa'

export default {
    name: 'VxGestionUtilisateurs',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/wikaz/modules/gestion-utilisateurs/utilisateurs',
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreUtilisateur),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementUtilisateur),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementUtilisateurPwa),
            itemRenderer: IxUtilisateur,
        }
    },
}
</script>