<template>
  <v-list-item @click="f_emit_selection">
    <div style="padding: 10px;margin-right: 10px">
      <v-img
        height="50"
        width="50"
        :aspect-ratio="16/9"
        contain
        :src="'data:image/jpeg;base64,' + item.logo"
      />
    </div>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.label"
      />
    </v-list-item-content>
    <v-list-item-action>
      <v-btn
        color="success"
        @click="f_action_custom('inviter',item)"
      >
        Inviter
      </v-btn>
    </v-list-item-action>
    <!--        <v-menu bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on }">
                <fx-icon v-on="on" :size="30" color="primary" icon="menu" tooltip="Menu des actions"></fx-icon>
            </template>
            <v-list>
                <v-list-item @click="f_ajouter">
                    <v-list-item-avatar><v-icon color="primary">email</v-icon></v-list-item-avatar>
                    <v-list-item-title>Envoyer une invitation</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>-->
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemCompte',
    extends: FxItemTemplate,
    methods: {
        f_ajouter () {

        },
    },
}
</script>