<template>
  <div class="cx-explorateur-fichier-container">
    <fx-titre :titre="titre" />
    <div style="width:100%;display: flex;justify-content: flex-end;align-items: center">
      <fx-button-upload
        v-if="showImport"
        :filetype="getFileType(extensionArray)"
        :url="urlRessourceEndPoint"
        tooltip="Ajouter un nouveau document"
        @uploaded="getRepo"
      />
      <v-btn
        style="margin-left: 10px"
        :loading="showWait"
        x-small
        color="primary"
        @click="getRepo"
      >
        Actualiser
      </v-btn>
    </div>
    <fx-tree-file
      :datas="files"
      style="width: 100%;height:100%;border: 1px solid #dddddd"
      @show="showFile"
      @delete="deleteFile"
    />
  </div>
</template>
<style lang="scss" scoped>
    .cx-explorateur-fichier-container {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 25px 25px 1fr;
        grid-row-gap: 5px;
        overflow: auto;
    }
</style>
<script>
import FxButtonUpload from '@/wikaz-core/ui/composants/fichiers/FxButtonUpload'
import FxTreeFile from '@/wikaz-core/ui/composants/fichiers/FxTreeFile'
export default{
    name: 'CxExplorateurFichier',
    components: {
        FxTreeFile,
        FxButtonUpload,
    },
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        titre: {
            type: String,
            default: 'Liste des documents disponibles',
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        border: {
            type: Boolean,
            default: true,
        },
        showImport: {
            type: Boolean,
            default: false,
        },
        showRefresher: {
            type: Boolean,
            default: false,
        },
        extensionArray: {
            type: Array,
            default: function () {
                return ['pdf']
            },
        },
    },
    data () {
        return {
            files: {},
            attachments: [],
            formData: new FormData(),
            showWait: false,
        }
    },
    computed: {
        styleObject () {
            return { border: this.border ? '1px solid' : 'none' }
        },
    },
    watch: {
        urlRessourceEndPoint (url) {
            if (url) {
                this.getRepo()
            }
        },
    },
    mounted () {
        this.getRepo()
    },
    methods: {
        getRepo () {
            this.showWait = true
            this.files = []
            if (this.urlRessourceEndPoint) {
                this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                    this.files = data
                    this.showWait = false
                })
            }
        },
        showFile (file) {
            let urlSplit = (this.urlRessourceEndPoint).split('?')
            let urlBase = urlSplit[0]
            let params = ''
            if (urlSplit.length > 1) {
                params = urlSplit[1]
            }
            if (params !== '') {
                params = '?' + params + '&url=' + file.url
            } else {
                params = '?url=' + file.url
            }
            this.$wikaz.request.get(urlBase + '/' + file.filename + params)
        },
        deleteFile (file) {
            let urlSplit = (this.urlRessourceEndPoint).split('?')
            let urlBase = urlSplit[0]
            let params = ''
            if (urlSplit.length > 1) {
                params = urlSplit[1]
            }
            if (params !== '') {
                params = '?' + params + '&url=' + file.url
            } else {
                params = '?url=' + file.url
            }
            this.$wikaz.outils.notification.showConfirmation('Suppression du Fichier', 'Souhaitez vous réellement supprimer le fichier : ' + file.filename, () => {
                this.$wikaz.request.delete(urlBase + '/' + file.filename + params).then(() => {
                    this.getRepo()
                })
            }, '400px', '180px')
        },
        getFileType (extarray) {
            let extValueArr = []
            for (let i = extarray.length - 1; i >= 0; i --) {
                extValueArr.push('.' + extarray[i])
            }
            return extValueArr.join(',')
        },
        close () {
            this.$emit('close')
        },
    },
}
</script>