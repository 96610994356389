<template>
  <div class="cx-ligne-agents-container">
    <div
      class="cx-ligne-agents-cartouche"
      :style="{width: largeurGauche + 'px'}"
    >
      <v-avatar
        style="margin-left: 5px;margin-right: 5px"
        size="48"
      >
        <v-img :src="'data:image/jpeg;base64,' + $wikaz.getInstance().getPhotoUtilisateur(item.wcu_utilisateur_id)" />
      </v-avatar>
      <div style="width: 100%">
        {{ item.label }}
      </div>
    </div>
    <div style="width: 30px;border-right: 1px solid black;color: black;display: flex;align-items: center;justify-content: center;background-color: #e0eef9">
      <!--{{item.is_maladie === 1 ? 'X' : ''}}-->
      <fx-icon
        v-if="item.is_maladie"
        icon="done"
        color="red"
        tooltip="Maladie"
      />
    </div>
    <div style="width: 30px;border-right: 1px solid black;color: black;display: flex;align-items: center;justify-content: center;background-color: #e0eef9">
      <!--{{item.is_astreinte === 1 ? 'X' : ''}}-->
      <fx-icon
        v-if="item.is_astreinte"
        icon="done"
        color="#4d7ae0"
        tooltip="Astreinte"
      />
    </div>
    <div
      v-if="item.is_conge === 1"
      class="cx-ligne-agents-case"
      :style="{ width: 'calc((100% - '+(largeurGauche+60)+'px)',
                backgroundColor: 'brown'
      }"
    >
      Congé
    </div>
    <div
      v-if="item.is_tlc === 1"
      class="cx-ligne-agents-case"
      :style="{ width: 'calc((100% - '+(largeurGauche+60)+'px)',
                backgroundColor: 'grey'
      }"
    >
      TLC
    </div>
    <template v-for="(element,indexJour) in item.detail">
      <!--<div :key="indexJour" class="cx-ligne-agents-case" :style="{width: 'calc((100% - '+largeurGauche+'px)/'+ item.detail.length+')',backgroundColor: element.color}">
            </div>-->
      <div
        v-if="indexJour === 0"
        :key="indexJour"
        class="cx-ligne-agents-case"
        :style="{ marginLeft: 'calc((100% - '+(largeurGauche+60)+'px)/24*'+ element.debut+')',
                  width: 'calc((100% - '+(largeurGauche+60)+'px)/24*'+ element.duree+')',
                  backgroundColor: element.poste_color
        }"
      >
        {{ element.poste_libelle }} {{ ( element.pointage_astreinte === 1 ? ' + Astreinte' : '') }}  {{ ( element.pointage_maladie === 1 ? ' ( Maladie )' : '') }} <br> ( {{ parseInt(element.debut) }}h - {{ parseInt(element.fin) }}h )
      </div>
      <div
        v-else
        :key="indexJour"
        class="cx-ligne-agents-case"
        :style="{ marginLeft: 'calc((100% - '+(largeurGauche+60)+'px)/24*'+ (element.debut-(item.detail[indexJour-1].duree)) +')',
                  width: 'calc((100% - '+(largeurGauche+60)+'px)/24*'+ element.duree+')',
                  backgroundColor: element.poste_color
        }"
      >
        {{ element.poste_libelle }} {{ ( element.pointage_astreinte === 1 ? ' + Astreinte' : '') }}  {{ ( element.pointage_maladie === 1 ? ' ( Maladie )' : '') }} <br> ( {{ parseInt(element.debut) }}h - {{ parseInt(element.fin) }}h )
      </div>
    </template>
  </div>
</template>
<script>
export default {
    name: 'CxLignePlanning',
    props: {
        datas: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-agents-container {
        width: 100%;
        height: 60px;
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid black;
        //border-left: 1px solid black;
        //border-right: 1px solid black;
        background-color: #ffffff;
        overflow: auto;
    }

    .cx-ligne-agents-cartouche {
        display: flex;
        align-items: center;
        border-right: 1px solid black;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-agents-case {
        cursor: pointer;
        // border-left: 1px solid black;
        // border-top: 1px solid black;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .fiche-container {
        width: 400px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #DDDDDD;
    }

    .champ-container {
        width:100%;
        height: 48px;
        display: flex;
        color: black;
        align-items: center;
        padding: 7px
    }

    .champ-libelle {
        width: 120px;
        text-align: right;
        margin-right: 10px
    }

    .champ-content {
        height: 100%;
        border: 1px solid grey;
        border-radius: 7px;
        background-color: white;
        width: 100%;padding: 5px
    }
</style>