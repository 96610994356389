<template>
  <div style="background-color: white">
    <v-list-item two-line>
      <v-list-item-avatar
        tile
        size="40"
      >
        <v-icon
          v-if="datas.intervention_aquisition_demande_id === 0"
          color="primary"
        >
          home
        </v-icon>
        <v-icon
          v-else
          color="primary"
        >
          device_hub
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle v-text="datas.prestation_libelle" />
        <v-list-item-subtitle v-text="'Horaire : ' + datas.th_libelle" />
      </v-list-item-content>
      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <fx-icon
            :size="30"
            color="primary"
            icon="menu"
            tooltip="Menu des actions"
            v-on="on"
          />
        </template>
        <v-list>
          <v-list-item @click="f_afficher_observations()">
            <v-list-item-avatar>
              <v-icon color="primary">
                subject
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Observations</v-list-item-title>
          </v-list-item>
          <v-list-item @click="f_afficher_fichiers()">
            <v-list-item-avatar>
              <v-icon color="#F6D159">
                folder
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>Fichiers</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item>
  </div>
</template>

<script>

import CxObservations from '@/wikaz-konnect/gdi/gestion-clients/observations/CxObservations'
import CxPopupArchivesIntervention
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupArchivesIntervention'

export default {
    name: 'CxInterventionInfosPrestation',
    props: {
        datas: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_afficher_observations () {
            this.$wikaz.popups.show(
                CxObservations,
                {
                    datas: {
                        urlEndPoint: 'api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/observations',
                        titre: 'Observations intervention',
                        width: '500px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_result_popup,
            )
        },
        f_afficher_fichiers () {
            this.$wikaz.popups.show(
                CxPopupArchivesIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: 'api/entreprises/modules/mon-activite/planning/' + this.datas.intervention_id + '/fichiers',
                        titre: 'Fichiers de l\'intervention',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_result_popup,
            )
        },
    },
}
</script>

<style scoped>

</style>