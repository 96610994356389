<template>
  <v-container
    v-if="dataProvider && dataProvider.formulaire"
    grid-list-xl
  >
    <v-layout
      row
      wrap
      justify-center
    >
      <v-flex
        class="fx-card"
        xs12
        :class="(dataProvider.size ? dataProvider.size : 'md4')"
      >
        <v-card
          flat
          class="transparent"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <template>
              <component
                :is="formulaire[index].composant"
                v-for="(item,index) in formulaire"
                :key="index"
                v-model="datas[item.form_props_name]"
                :counter="item.max"
                :rules="getRules(index)"
                :label="item.label"
                :prepend-icon="item.icon"
              />
            </template>
            <div style="display: flex;justify-content: flex-end;margin-top: 15px">
              <v-btn
                :disabled="!valid"
                color="success"
                @click="validate"
              >
                Envoyer
              </v-btn>
            </div>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'FxFormulaire',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        valid: true,
        datas: {},
        formulaire: null,

    }),
    mounted () {
        this.formulaire = this.dataProvider.formulaire
    },
    methods: {
        validate () {
            if (this.$refs.form.validate()) {
                this.datas.destinataire = this.dataProvider.destinataire
                Vue.axios.post(this.$icsilab.rootApi + 'formulaire', this.datas).then(() => {
                })
            }
        },
        getRules () {
            /*switch (this.formulaire[value].rules) {
                    case 'email':
                        return [
                            v => !!v || this.formulaire[value].label +' est requis',
                            v => /.+@.+\..+/.test(v) || this.formulaire[value].label + ' n\'est pas valide' ,
                        ]
                    default:
                        return [
                            v => !!v || this.formulaire[value].label + ' est requis',
                            v => (v && v.length <= this.formulaire[value].max) || this.formulaire[value].label + ' doit être composé de ' + this.formulaire[value].max + ' caractères maximum',
                        ]
                }*/
        },
    },
}
</script>

<style scoped>

</style>