<template>
  <div
    v-if="datas"
    style="display: flex;flex-direction: column;width: 100%;gap: 2px;font-size: 13px"
  >
    <fx-titre titre="Fiche Compte Wikaz" />
    <div style="padding: 10px;margin-right: 10px;display: flex;justify-content: center">
      <v-img
        height="80"
        width="80"
        :aspect-ratio="16/9"
        contain
        :src="'data:image/jpeg;base64,' + datas.logo"
      />
    </div>
    <div
      v-if="datas.compte_id"
      style="padding-left: 10px;"
    >
      <b>Compte Id :</b> {{ datas.compte_id }}
    </div>
    <div
      v-if="datas.compte_raison_sociale"
      style="padding-left: 10px;"
    >
      <b>Société :</b> {{ datas.compte_raison_sociale }}
    </div>
    <div
      v-if="datas.compte_adresse || datas.compte_cp || datas.compte_ville"
      style="display:flex;padding-left: 10px;"
    >
      <div style="font-weight: bold">
        Adresse :
      </div>
      <div style="margin-left: 3px">
        <div>{{ datas.compte_adresse }}</div>
        <div>{{ datas.compte_cp }} {{ datas.compte_ville }}</div>
      </div>
    </div>
    <div
      v-if="datas.compte_telephone1"
      style="display:flex;align-items: center;padding-left: 10px"
    >
      <div><b>Téléphone :</b></div>
      <div style="margin-left: 3px;margin-right: 3px">
        {{ datas.compte_telephone1 }}
      </div>
      <fx-icon
        icon="phone"
        @click="$wikaz.api.navigateur.tel(datas.compte_telephone1)"
      />
    </div>
    <div
      v-if="datas.compte_telephone2"
      style="display:flex;align-items: center;padding-left: 10px"
    >
      <div><b>Téléphone :</b></div>
      <div style="margin-left: 3px;margin-right: 3px">
        {{ datas.compte_telephone2 }}
      </div>
      <fx-icon
        icon="phone"
        @click="$wikaz.api.navigateur.tel(datas.compte_telephone2)"
      />
    </div>
    <!--        <fx-champ v-if="datas.compte_latitude" label-width="150px" label="Geoposition" :text="datas.compte_latitude+','+datas.compte_longitude"></fx-champ>-->
    <div
      v-if="datas.proprietaire_nom"
      style="display:flex;padding-left: 10px;"
    >
      <div style="font-weight: bold">
        Propriétaire :
      </div>
      <div style="margin-left: 3px">
        <div>{{ datas.proprietaire_nom }}</div>
        <div>{{ datas.proprietaire_email }}</div>
      </div>
    </div>
    <div style="display: flex;justify-content: flex-end">
      <fx-icon
        color="error"
        icon="delete"
        tooltip="supprimer le compte"
        @click="suppression(datas.compte_id)"
      />
    </div>
  </div>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxFicheCompte',
    extends: CxBase,
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    mounted () {
        this.getDatas()
    },
    methods: {
        getDatas () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datas = data
            })
        },
        suppression () {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer ce compte', () => {
                this.$wikaz.outils.notification.showConfirmation('Suppression', 'Je sais c\'est lourd, mais êtes vous vraiment certain de vouloir supprimer ce compte.', () => {
                    this.$wikaz.request.delete(this.urlRessourceEndPoint).then(() => {
                        this.getDatas()
                    })
                })
            })
        },
    },
}
</script>

<style scoped>

</style>