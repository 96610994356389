<template>
  <v-menu
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <fx-icon
        :size="30"
        color="white"
        icon="menu"
        v-on="on"
      />
    </template>
    <v-list ref="objLst">
      <v-list-item
        v-if="item.email"
        @click="f_mail(item.email)"
      >
        <v-list-item-avatar>
          <v-icon color="primary">
            mail
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Envoyer un mail</v-list-item-title>
          <v-list-item-subtitle v-html="item.email" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="item.user_telephone1"
        @click="f_appeler(item.user_telephone1)"
      >
        <v-list-item-avatar>
          <v-icon color="primary">
            phone
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Appeler Tel 1</v-list-item-title>
          <v-list-item-subtitle v-html="item.user_telephone1" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="item.user_telephone2"
        @click="f_appeler(item.user_telephone2)"
      >
        <v-list-item-avatar>
          <v-icon color="primary">
            phone
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Appeler Tel 2</v-list-item-title>
          <v-list-item-subtitle v-html="item.user_telephone2" />
        </v-list-item-content>
      </v-list-item>
      <!--<v-list-item>
          <v-list-item-avatar><v-icon color="primary">date_range</v-icon></v-list-item-avatar>
          <v-list-item-title>Gerer les congés</v-list-item-title>
        </v-list-item>-->
      <v-list-item @click="f_imprimer_planning">
        <v-list-item-avatar>
          <v-icon color="primary">
            print
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Imprimer le planning</v-list-item-title>
      </v-list-item>
      <v-list-item @click="f_remise_a_zero">
        <v-list-item-avatar>
          <v-icon color="error">
            cancel
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Remise à zéro du mois</v-list-item-title>
      </v-list-item>
      <v-list-item @click="f_historique">
        <v-list-item-avatar>
          <v-icon color="primary">
            description
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Afficher historique des demandes</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CxHistoriqueDemandes from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxHistoriqueDemandes'

export default {
    name: 'CxMenuAgent',
    props: {
        datas: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_imprimer_planning () {
            this.$wikaz.request.get('api/edt/modules/planning-edt/rapport/' + this.item.wcu_utilisateur_id + '?annee=' + this.datas.annee + '&mois=' + this.datas.mois)
        },
        f_appeler (numero_telephone) {
            window.location = 'tel:' + numero_telephone
        },
        f_mail (email) {
            window.location = 'mailto:' + email
        },
        f_remise_a_zero () {
            this.$wikaz.outils.notification.showConfirmation(
                'Remise à zéro',
                'Etes-vous sûr de vouloir remettre à zéro ce planning ?',
                this.f_send_remise_a_zero,
            )
        },
        f_send_remise_a_zero () {
            this.$wikaz.request.post('api/edt/modules/planning-edt/reset?user=' + this.item.wcu_utilisateur_id + '&annee=' + this.datas.annee + '&mois=' + this.datas.mois).then(() => {
                this.$emit('refresh')
            })
        },
        f_historique () {
            this.$wikaz.getInstance().popups.show(
                CxHistoriqueDemandes,
                { datas: { urlRessourceEndPoint: 'api/edt/modules/planning-edt/planning/' + this.item.wcu_utilisateur_id + '/historique' } },
            )
        },
    },
}
</script>

<style scoped>

</style>