<template>
  <div style="width: 150px">
    <v-menu
      ref="menu1"
      v-model="menu1"
      :disabled="editable===false"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          style="margin-top: 2px;margin-bottom: 2px"
          outlined
          hide-details
          dense
          :disabled="editable===false"
          prepend-icon="event"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        first-day-of-week="1"
        locale="fr-fr"
        @input="menu1 = false"
        v-on="listeners"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
    name: 'FxDateField',
    props: {
        value: {
            type: String,
            default: null,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        format: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        formatOut: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        editable: {
            default: true,
            type: Boolean,
        },
    },
    data () {
        return {
            valeur: (this.value !== '' && this.value !== null) ? new Date(this.value) : new Date(),
            date: (this.value !== '' && this.value !== null) ? new Date(this.value).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10), // new Date().toISOString().substr(0, 10),
            dateFormatted: this.formatDate((this.value !== '' && this.value !== null) ? new Date(this.value).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)), // this.formatDate(new Date().toISOString().substr(0, 10)),
            menu1: false,
            menu2: false,
        }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => {
                    if (item === 'input') {
                        self.$emit(item, this.$wikaz.getInstance().outils.dates.getDate(event, this.formatOut))
                    } else {
                        self.$emit(item, event)
                    }
                }
            })
            return { ...self.$listeners }
        },
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    mounted () {
        this.$emit('input', this.$wikaz.getInstance().outils.dates.getDate(this.date, this.formatOut))
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        formatDate (date) {
            if (! date) {
                return null
            }

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (! date) {
                return null
            }

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
}
</script>