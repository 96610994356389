<template>
  <v-flex
    v-if="dataProvider && dataProvider.height"
    xs12
    :style="{height: dataProvider.height ? dataProvider.height : '50px'}"
  />
  <fx-anomalie-composant
    v-else
    composant="fx-space"
    propriete="height"
  />
</template>
<script>
export default {
    name: 'FxSpace',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
}
</script>