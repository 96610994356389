<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-content>
      <v-list-item-title v-html="item.prestation_libelle" />
      <v-list-item-subtitle v-html="'durée : '+item.duree_libelle" />
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxCreationIntervention',
    extends: FxItemTemplate,
}
</script>