/* eslint-disable no-console */

import { register } from 'register-service-worker'
import notification from '@/wikaz-core/outils/notification'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready () {
            console.log('Vous utilisez actuellement la Version : ' + require('../package.json').version)
            setInterval(() => {
                if (window.Vue.$router.app.$options.store.state.auth.isLoggedIn) {
                    Notification.requestPermission(function (result) {
                        if (result === 'granted') {
                            window.Vue.$wikaz.getInstance().request.get('api/wikaz/structure/notifications').then((data) => {

                                navigator.serviceWorker.ready.then(function (registration) {
                                    for (let i = 0; i < data.length; i ++) {
                                        registration.showNotification(data[i].notification_titre, {
                                            body: data[i].notification_body,
                                            icon: './images/logo_wikaz.png',
                                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                                            tag: 'notification-' + data[i].notification_id,
                                        })
                                    }
                                })
                            })
                        }
                    })
                } else {
                    console.log('Non authentifié')
                }
            }, 60000)
        },
        registered () {
            console.log('Service worker has been registered.')
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updatefound () {
            console.log('New content is downloading.')
        },
        updated () {
            (new notification()).showWarning('Une nouvelle version est disponible.')
            console.log('New content is available; please refresh.')
        },
        offline () {
            (new notification()).showWarning('pas de connexion internet')
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        },
    })
}