<template>
  <v-switch
    v-model="selectedValue"
    style="margin:0;padding-top: 6px;padding-bottom: 6px"
    hide-details
    :disabled="disabled"
    :true-value="trueValue"
    :false-value="falseValue"
    @change="onChange"
  />
</template>
<script>
export default{
    name: 'FxSwitch',
    props: {
        disabled: { default: false },
        value: { default: 1 },
        trueValue: { default: 1 },
        falseValue: { default: 0 },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return { selectedValue: this.value }
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        onChange (ev) {
            this.$emit('input', ev)
            this.$emit('on-change', ev)
        },
    },
}
</script>