<template>
  <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column;gap: 10px">
    <cx-parametrages-generaux
      style="width: 500px"
      url-end-point="api/entreprises/modules/gestion-configuration-entreprises/"
    />
  </div>
</template>

<script>

import CxParametragesGeneraux
from '@/wikaz-konnect/gdi/parametrages/gestion-configuration/CxParametragesGeneraux'
export default {
    name: 'VxGestionConfigurationEntreprises',
    components: { CxParametragesGeneraux },
}
</script>

<style scoped>

</style>