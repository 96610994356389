import { STORAGE_KEY } from './state'

const localStoragePlugin = store => {
    store.subscribe((mutation, state) => {
        if (mutation.type === 'CLEAR_ALL_DATA') {
            localStorage.removeItem(STORAGE_KEY)
        } else {
            const syncedData = {
                isMobile: state.isMobile,
                theme: state.theme,
                auth: state.auth,
                user: state.user,
                menu: state.menu,
                sidebar: state.sidebar,
                mobileView: state.mobileView,
                tabs: [],
                selectedTab: null,
                selectedTabIndex: null,
            }
            localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData))
        }
    })
}

export default [localStoragePlugin]