<template>
  <v-list-item
    dense
    style="overflow: visible;"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon>event</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>Plage</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>{{ item.debut_libelle }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>{{ item.fin_libelle }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon
        color="red"
        @click="f_delete(item.plage_id)"
      >
        delete
      </v-icon>
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default{
    name: 'IxPlages',
    extends: FxItemTemplate,
    methods: {
        date (date) {
            return date ? this.$wikaz.outils.dates.getDate(date, 'DD/MM/YYYY HH:mm') : ''
        },
        f_delete (plageId) {
            this.$wikaz.request.delete('api/entreprises/modules/gestion-utilisateurs-wikaz/utilisateurs/0/disponibilites/' + plageId).then(() => {
                this.f_emit_actualiser()
            })
        },

    },
}
</script>