<template>
  <v-card
    v-if="urlRessourceEndPoint"
    class="cx-creation-demande"
    :style="{width: width,height: 'auto'}"
  >
    <fx-titre titre="Créer une nouvelle demande" />
    <div style="height: 50px;display: flex;align-items: center">
      <fx-label
        width="160px"
        text="Origine"
      />
      <fx-dropdown
        v-model="selectedOrigine"
        pre-holder="Sélectionner une origine"
        :data-provider.sync="dataOrigine"
        :url="urlRessourceEndPoint + '/origine-demandes'"
        @loadComplete="chargerData"
        @input="f_selection_origine_change"
      />
    </div>
    <div style="height: 50px;display: flex;align-items: center">
      <fx-label
        width="160px"
        text="Prestation"
      />
      <fx-dropdown
        ref="obj_prestation"
        v-model="selectedPrestation"
        pre-holder="Sélectionner une prestation"
        :data-provider.sync="dataPrestation"
        :url="urlRessourceEndPoint + '/prestations-demandes/' + selectedOrigine"
        @input="f_selection_prestation_change"
      />
    </div>
    <div style="height: auto;display: flex;align-items: center;margin-bottom: 10px">
      <fx-label
        width="160px"
        text="Description"
      />
      <fx-text-area v-model="description" />
    </div>
    <div
      v-if="dataTarifs"
      style="height: 35px;display: flex;align-items: center"
    >
      <fx-label
        width="160px"
        text="Rémunération"
      />
      <fx-number-input v-model="dataTarifs.remuneration" />
      <div>€</div>
    </div>
    <div style="height: 35px;display: flex;align-items: center">
      <fx-label
        width="160px"
        text="Affectation Manuelle"
      />
      <fx-switch
        v-model="etat_affectation"
        label-on="Oui"
        label-off="Non"
        :true-value="true"
        :false-value="false"
      />
    </div>
    <div
      v-if="etat_affectation"
      style="height: auto;display: flex;align-items: center"
    >
      <div style="width: 220px;text-align: right;margin-right: 10px" />
      <fx-list
        v-if="selectedPrestation"
        ref="obj_liste"
        :item-renderer="itemRendererCompte"
        show-refresher
        show-search
        style="height: 300px"
        :url="urlRessourceEndPoint + '/creation-demandes/' + selectedPrestation + '/affectation'"
        @itemEmitting="f_select_receveur"
      />
      <div v-else>
        Veuillez sélectionner une prestation
      </div>
    </div>
    <div style="height: 35px;display: flex;align-items: center">
      <fx-label
        width="160px"
        text="Rdv Souhaité"
      />
      <fx-switch
        v-model="etat_souhaite"
        label-on="Oui"
        label-off="Non"
        :true-value="true"
        :false-value="false"
      />
    </div>
    <div
      v-if="etat_souhaite"
      style="height: auto;display: flex;align-items: center"
    >
      <fx-label
        width="160px"
        text="Date"
      />
      <fx-date-field v-model="dateSouhaite" />
    </div>
    <div
      v-if="etat_souhaite"
      style="height: auto;display: flex;align-items: center"
    >
      <fx-label
        width="160px"
        text="Heure"
      />
      <fx-heure-field v-model="heureSouhaite" />
    </div>
    <div
      v-if="etat_souhaite"
      style="height: auto;display: flex;align-items: center"
    >
      <div style="width: 160px" />
      <fx-checkbox
        v-model="dateImpose"
        :true-value="1"
        false-value="0"
      />
      <div>Cette date est imposée par le client</div>
    </div>
    <div style="height: 50px;display: flex;align-items: center">
      <fx-label
        width="160px"
        text="Référence"
      />
      <fx-text-input v-model="demandeReferenceDo" />
    </div>
    <div style="margin-top:10px;display: flex;justify-content: flex-end">
      <v-btn
        color="success"
        @click="f_valider"
      >
        Valider
      </v-btn>
      <v-btn
        style="margin-left: 10px"
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import IxCreationDemande from './IxCreationDemande'
import FxNumberInput from '@/wikaz-core/ui/composants/inputs/FxNumberInput'

export default {
    name: 'CxCreationDemande',
    components: { FxNumberInput },
    data () {
        return {
            titre: 'haha',
            urlRessourceEndPoint: null,
            width: '100%',
            height: '600px',
            date: null,
            tranche: null,
            dataPrestation: null,
            isMobile: window.Vue.$store.getters.isMobile(),
            datas: null,
            tab: null,
            loading: false,
            etat_affectation: false,
            etat_souhaite: false,
            selectedPrestation: null,
            selectedReceveur: 0,
            itemRendererCompte: IxCreationDemande,
            dateSouhaite: null,
            heureSouhaite: null,
            dataProvider: { demande_prestation_id: null },
            selectedOrigine: 0,
            dataOrigine: null,
            dataTarifs: null,
            demandeReferenceDo: null,
            description: null,
            dateImpose: 0,
        }
    },
    methods: {
        chargerData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/origine-client').then((data) => {
                this.selectedOrigine = data.client_aquisition_compte_id
            })
        },
        f_selection_origine_change () {
            if(this.$refs.obj_prestation) {
                this.$refs.obj_prestation.url = this.urlRessourceEndPoint + '/prestations-demandes/' + this.selectedOrigine
                // this.$refs.obj_prestation.getDataProvider()
            }
        },
        f_selection_prestation_change () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/tarification-demandes/' + this.selectedPrestation).then((data) => {
                this.dataTarifs = data
            })
            if(this.$refs.obj_liste) {
                this.$refs.obj_liste.chargerData()
            }
        },
        f_select_receveur (item) {
            this.selectedReceveur = item.compte_id
        },
        f_valider () {
            if (this.selectedPrestation) {
                if(this.etat_affectation && this.selectedReceveur === null) {
                    this.$wikaz.outils.notification.showError('Veuillez sélectionner un receveur')
                } else {
                    let dataParams = {
                        demande_prestation_id: this.selectedPrestation,
                        demande_description: this.description,
                        affectation: this.etat_affectation,
                        demande_receveur_compte_id: 0,
                        planification: this.etat_souhaite,
                        demande_date_souhaite: null,
                        demande_th_souhaite: null,
                        demande_date_th_impose: 0,
                        demande_remuneration: this.dataTarifs.remuneration,
                        demande_reference_do: this.demandeReferenceDo,
                    }

                    if(this.etat_affectation) {
                        dataParams.demande_receveur_compte_id = this.selectedReceveur
                    }

                    if(this.etat_souhaite) {
                        dataParams.demande_date_souhaite = this.dateSouhaite
                        dataParams.demande_th_souhaite = this.heureSouhaite
                        dataParams.demande_date_th_impose = this.dateImpose
                    }
                    this.$wikaz.request.post(this.urlRessourceEndPoint + '/creation-demandes', dataParams).then(() => {
                        this.$emit('on-submit')
                    })
                }
            } else {
                this.$wikaz.outils.notification.showError('Veuillez sélectionner une prestation')
            }
        },
    },
}
</script>
<style scoped>
    .cx-creation-demande{
        padding: 10px;
    }
</style>