const icsilab = {
    site: null,
    currentRoute: 'home',
    baseUrl: null,
    urlApi: null,
    rootUrl: null,
    rootApi: null,
    siteId: null,
    dataSite: null,
    getFooter: function () {
        if (this.dataSite) {
            return this.dataSite.footer
        }
        return null
    },
    getHeader: function () {
        if (this.dataSite) {
            return this.dataSite.header
        }
        return null
    },
    getDatas: function () {
        return this.dataSite
    },
    getPages: function () {
        return this.dataSite.pages
    },
    goToPage: function (route) {
        this.currentRoute = route
    },
}

export default icsilab