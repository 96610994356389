<template>
  <div style="padding: 0;margin-bottom: 2px">
    <v-btn
      x-small
      color="success"
      @click="selectionFichier"
    >
      Ajouter un fichier
    </v-btn>
    <input
      :id="url"
      ref="file"
      :accept="filetype"
      type="file"
      :name="url"
      class="hidden"
      style="display:none;"
      @change="upload"
    >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Nom du fichier
        </v-card-title>
        <div style="display: flex;align-items: center;padding: 10px">
          <fx-text-input
            v-model="fileName"
            outlined
            dense
          />
          <div style="margin-left: 5px;margin-right: 5px">
            .{{ extension }}
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            color="success"
            @click="sendFile"
          >
            Valider
          </v-btn>
          <v-btn
            small
            color="error"
            @click="dialog = false"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
    name: 'FxButtonUpload',
    props: {
        url: { default: null },
        filetype: { default: 'pdf' },
        showUpload: { default: true },
        tooltip: { default: '' },
    },
    data () {
        return {
            exportFile: null,
            dialog: false,
            fileUpload: null,
            fileName: null,
            extension: null,
        }
    },
    methods: {
        upload (e) {
            let files = e.target.files || e.dataTransfer.files
            if (! files.length) {
                return
            }
            this.uploaded = null
            for (let i = files.length - 1; i >= 0; i --) {
                this.uploaded = files[i]
            }
            if (this.uploaded) {
                this.fileName = this.uploaded.name.split('.').slice(0, - 1).join('.')
                this.extension = this.uploaded.name.split('.').pop()
                this.exportFile = this.uploaded
                this.dialog = true
            }
            this.$refs.file.value = null
        },
        sendFile () {
            if (this.fileName.length > 0) {
                this.dialog = false
                this.$wikaz.request.request('POST', this.url + '?filename=' + this.fileName + '.' + this.extension, null, this.exportFile).then((response) => {
                    if (response) {
                        this.$wikaz.outils.notification.showSuccess('Le document a bien été enregistré.')
                        this.$emit('uploaded', this.exportFile)
                    }
                }).catch((err) => {
                    this.$wikaz.outils.notification.showWarning('Une erreur est survenue, merci de reessayer.' + err.message)
                    this.file = null
                })
            } else {
                this.$wikaz.outils.notification.showError('Veuillez saisir un nom de fichier')
            }
        },
        selectionFichier () {
            this.$refs.file.click()
        },
    },
}
</script>