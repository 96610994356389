<template>
  <div class="vx-mon-tableau-de-bord-container-principal">
    <div>
      <div
        v-if="datas"
        class="ligne-info"
      >
        <div style="width: 100%">
          Heures à réaliser dans l’année :
        </div>
        <div
          class="bloc-info"
          style="font-weight: bold"
        >
          {{ datas.data.heure_total }} h
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="datas"
        class="ligne-info"
      >
        <div style="width: 100%">
          Heures réalisées :
        </div>
        <div
          class="bloc-info"
          style="font-weight: bold"
        >
          {{ datas.data.heure_fait }} h
        </div>
      </div>
    </div>
    <div>
      <div
        v-if="datas"
        class="ligne-info"
      >
        <div style="width: 100%">
          Heures restantes :
        </div>
        <div
          class="bloc-info"
          style="font-weight: bold"
        >
          {{ datas.data.heure_restant }} h
        </div>
      </div>
    </div>
    <fx-chart
      v-if="datas"
      :data-provider="datas.chart"
    />
    <!--        <div>
            <div v-if="datas" class="ligne-info">
                <div style="width: 100%">Congés planifiés : </div>
                <div class="bloc-info" style="font-weight: bold">{{datas.data.conge_total}}</div>
            </div>
        </div>
        <div>
            <div v-if="datas" class="ligne-info">
                <div style="width: 100%">Congés passés : </div>
                <div class="bloc-info" style="font-weight: bold">{{datas.data.conge_fait}}</div>
            </div>
        </div>
        <div>
            <div v-if="datas" class="ligne-info">
                <div style="width: 100%">Congés futur : </div>
                <div class="bloc-info" style="font-weight: bold">{{datas.data.conge_restant}}</div>
            </div>
        </div>-->
  </div>
</template>

<script>
import FxChart from '@/wikaz-core/ui/composants/charts/FxChart'
export default {
    name: 'VxMonTableauDeBordGdt',
    components: { FxChart },
    data () {
        return {
            datas: null,
            chart: null,
        }
    },
    mounted () {
        this.getDatas()
    },
    methods: {
        getDatas () {
            this.$wikaz.request.get('api/edt/modules/mon-tableau-de-bord-gdt/statistiques').then((data) => {
                this.datas = data
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .vx-mon-tableau-de-bord-container-principal {
        font-size: 13px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .ligne-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 15px;
    }

    .bloc-info {
        border-radius: 15px;
        background-color: white;
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 15px;
    }
</style>