<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <v-icon
        v-if="item.proprietaire === 1"
        color="green"
      >
        account_circle
      </v-icon>
      <v-icon v-else>
        person
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-text="item.label" />
      <v-list-item-subtitle v-text="item.email" />
      <v-list-item-subtitle v-text="'Utilisateur id : ' + item.id" />
    </v-list-item-content>
    <v-list-item-action>
      <fx-icon
        :size="28"
        color="primary"
        icon="login"
        tooltip="usurpation"
        @click="usurpation(item)"
      />
    </v-list-item-action>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemUtilisateur',
    extends: FxItemTemplate,
    methods: {
        usurpation () {
            this.$wikaz.request.get('api/wikaz/structure/maintenance/' + this.item.id).then((data) => {
                this.$store.commit('UPDATE_TOKEN', data.token)
                if (this.isMobile) {
                    this.$store.commit('SET_VIEW_PWA', null)
                    this.$root.$emit('refreshMenu')
                } else {
                    this.$store.commit('REMOVE_TAB', this.$store.getters.getSelectedTab())
                }
            })
        },
    },
}
</script>