<template>
  <v-card
    class="cx-propager-demande-container-principal"
    :style="{width: width,height: height }"
  >
    <fx-titre titre="Affecter la demande" />
    <fx-datagrid-vuetify
      v-if="urlRessourceEndPoint"
      :url-ressource-end-point="urlRessourceEndPoint"
      @affecter="f_affecter"
    />
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>
<script>
export default {
    name: 'CxAffecterDemande',
    data () {
        return {
            titre: 'haha',
            urlRessourceEndPoint: null,
            width: '100%',
            height: '600px',
            date: null,
            tranche: null,
            dataPlanification: null,
            isMobile: window.Vue.$store.getters.isMobile(),
            datas: null,
            tab: null,
            loading: false,
        }
    },
    methods: {
        f_affecter (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, { receveur: item.compte_id }).then((data) => {
                if (data.code !== 'error') {
                    this.$emit('on-submit')
                }
            })
        },
    },

}
</script>
<style scoped>
    .cx-propager-demande-container-principal{
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>