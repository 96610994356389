<template>
  <div class="cx-fiche-intervention-container">
    <div style="height: 25px;width: 100%">
      <fx-titre
        v-if="afficherTitre"
        titre="Demande"
      />
    </div>
    <div
      v-if="datas"
      class="cx-formulaire-container"
    >
      <div class="ligne-formulaire">
        <fx-champ
          label="Numéro"
          :text="datas.demande_id.toString()"
        />
        <fx-champ
          label="Créé le"
          :text="$wikaz.outils.dates.getDate(datas.demande_date)"
        />
      </div>
      <fx-champ
        label="Origine"
        :text="datas.demande_origine"
      />
      <fx-champ
        label="Prestation"
        :text="datas.prestation"
      />
      <fx-champ
        label="Durée"
        :text="datas.prestation_duree"
      />
      <fx-champ
        label="Date Rdv"
        :text="datas.rdv_souhaite"
      >
        <fx-icon
          v-if="datas.demande_date_th_impose===1"
          icon="lock"
          color="error"
          tooltip="Cette date est imposée"
        />
      </fx-champ>
      <fx-champ
        label="Etat"
        :text="datas.etat_libelle"
      />
      <fx-champ
        label="Ref DO"
        :text="datas.demande_reference_do"
      />
      <fx-champ
        v-if="datas.etat_id === 3"
        label="Date"
        :text="$wikaz.outils.dates.getDate(datas.intervention_date_planifie)"
      />
      <fx-champ
        label="Description"
        :text="datas.demande_description"
      />
    </div>
    <!--        <fx-titre v-if="datas" titre="Observations"/>
        <div v-if="datas" style="overflow: auto">
            <div v-for="(item,index) in datas.observations" :key="index">
                    <div style="display: flex;height: 25px;align-items: center;margin-left: 10px;font-weight: bold">
                        {{date(item.obs_date,'DD/MM/YYYY HH:mm')}} ({{item.utilisateur}})
                    </div>
                    <div style="margin-left: 20px">
                        {{item.obs_contenu}}
                    </div>
            </div>
        </div>-->
    <cx-waiting
      v-if="datas === null && url"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>

export default {
    name: 'CxFicheDemande',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
        afficherTitre: { default: true },
    },
    data () {
        return {
            url: this.urlEndPoint,
            datas: null,
        }
    },
    mounted () {
        this.getDatas()
    },
    methods: {
        getDatas () {
            if (this.url) {
                this.datas = null
                this.$wikaz.request.get(this.url).then((data) => {
                    this.datas = data
                })
            }
        },
        setUrl (url) {
            this.url = url
            this.getDatas()
        },
        date (date, format = 'DD/MM/YYYY') {
            if (! date) {
                return ''
            }
            return this.$wikaz.outils.dates.getDate(date, format)
        },
        getRef () {
            let ref = this.datas.intervention_reference
            if (ref && ref > 0) {
                return 'Suite de l\'intervention : ' + ref
            }
            return 'Intervention Primeur'

        },
        getDuree (duree) {
            return this.timeConvert(duree * 60)
        },
        timeConvert (n) {
            let num = n
            let hours = (num / 60)
            let rhours = Math.floor(hours)
            let minutes = (hours - rhours) * 60
            let rminutes = Math.round(minutes)
            // return num + " minutes = " + rhours + " heure(s) et " + rminutes + " minute(s).";
            return rhours.toString().padStart(2, '0') + ' h ' + rminutes.toString().padStart(2, '0')
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-fiche-intervention-container {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: auto;
        font-size: 12px;
        // background-color: #EEEEEE;
    }

    .cx-formulaire-container {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 6px;
        overflow: auto;
    }

    .ligne-formulaire {
        display: flex;
        align-items: center;
    }
</style>