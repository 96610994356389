<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        :size="30"
        :icon="item.contacts_type_icon"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.contact_nom + ' ' + item.contact_prenom"
      />
      <v-list-item-subtitle
        class="caption"
        style="font-weight: bold;white-space: pre-line;"
        v-html="get_type(item)"
      />
    </v-list-item-content>
    <div style="display: flex">
      <fx-icon
        :color="(item.contact_is_referent === 1) ? 'green' : '#cccccc'"
        :size="20"
        tooltip="Référent"
        icon="grade"
      />
      <fx-icon
        :color="(item.contact_is_confiance === 1) ? 'green' : '#cccccc'"
        :size="20"
        tooltip="Confiance"
        icon="recommend"
      />
      <fx-icon
        :color="(item.contact_is_protection === 1) ? 'green' : '#cccccc'"
        :size="20"
        tooltip="Protection"
        icon="shield"
      />
    </div>
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item @click="f_edit">
          <v-list-item-avatar>
            <v-icon color="primary">
              create
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Modifier</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="item.contact_telephone"
          @click="$wikaz.api.navigateur.tel(item.contact_telephone)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.contact_telephone }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="item.contact_adresse"
          @click="$wikaz.api.navigateur.geo('//?q=' + item.contact_adresse + ', ' + item.contact_cp + ' ' + item.contact_ville)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              map
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title v-html="item.contact_adresse + ' </BR> ' + item.contact_cp + ' ' + item.contact_ville" />
        </v-list-item>
        <v-list-item
          v-if="item.contact_email"
          @click="$wikaz.api.navigateur.mailto(item.contact_email )"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              mail
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title v-html="item.contact_email" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxResident',
    extends: FxItemTemplate,
    methods: {
        f_edit () {
            this.$wikaz.popups.showFormulaire('api/edt/modules/gestion-residents/residents/' + this.item.contact_resident_id + '/contacts/' + this.item.contact_id + '/edit', (data) => {
                this.$wikaz.request.put('api/edt/modules/gestion-residents/residents/' + data.contact_resident_id + '/contacts/' + data.contact_id, data).then(()=> {
                    this.f_emit_actualiser()
                })
            })
        },
        get_type (item) {
            if(item.contact_detail) {
                return item.contacts_type_libelle + ' ( ' + item.contact_detail + ' )'
            }
            return item.contacts_type_libelle

        },
    },
}
</script>