let filters = function () {
    this.filterBy = function (filter, target) {
        let newArray = []
        filter = this.removeAccents(filter)
        if (target && Array.isArray(target) && target.length > 0) {
            let cols = Object.keys(target[0])
            for (let i = 0; i < target.length; i ++) {
                let check = 0
                for (let j = 0; j < cols.length; j ++) {
                    let val = target[i][cols[j]]
                    if ((val && Array.isArray(val)) || (typeof val === 'object')) {
                        if (this.filterBy(filter, val).length > 0) {
                            check ++
                            break
                        }
                    } else if (! (typeof val === 'object') && val) {
                        if (! Array.isArray(val)) {
                            val = this.removeAccents(val)
                            if (val.toString().toLowerCase().indexOf(filter.toLowerCase()) !== - 1) {
                                check ++
                                break
                            }
                        } else {
                            if (this.filterBy(filter, val)) {
                                check ++
                                break
                            }
                        }
                    }
                }
                if (check > 0) {
                    newArray.push(target[i])
                }
            }
        } else if (target && typeof target === 'object') {
            let cols = Object.keys(target)
            let check = 0
            for (let i = 0; i < cols.length; i ++) {
                let val = target[cols[i]]
                if ((val && Array.isArray(val)) || (typeof val === 'object')) {
                    if (this.filterBy(filter, val).length > 0) {
                        check ++
                        break
                    }
                } else if (! (typeof val === 'object') && val) {
                    if (! Array.isArray(val)) {
                        val = this.removeAccents(val)
                        if (val.toString().toLowerCase().indexOf(filter.toLowerCase()) !== - 1) {
                            check ++
                            break
                        }
                    } else {
                        if (this.filterBy(filter, val)) {
                            check ++
                            break
                        }
                    }
                }
            }
            if (check > 0) {
                newArray.push(target)
            }
        }
        return newArray
    }
    this.filterList = function (filter, target) {
        let newArray = []
        let colonnes = Object.keys(target[0])
        for (let i = 0; i < target.length; i ++) {
            let compteur = 0
            for (let j = 0; j < colonnes.length; j ++) {
                let chaine = target[i][colonnes[j]]
                if (chaine !== null) {
                    if (chaine.toString().toUpperCase().search(filter.toUpperCase()) > - 1) {
                        compteur ++
                    }
                }
            }
            if (compteur > 0) {
                newArray.push(target[i])
            }
        }
        return newArray
    }
    this.removeAccents = function (value) {
        let r = value.toString().toLowerCase()
        r = r.replace(new RegExp(/\s/g), '')
        r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
        r = r.replace(new RegExp(/æ/g), 'ae')
        r = r.replace(new RegExp(/ç/g), 'c')
        r = r.replace(new RegExp(/[èéêë]/g), 'e')
        r = r.replace(new RegExp(/[ìíîï]/g), 'i')
        r = r.replace(new RegExp(/ñ/g), 'n')
        r = r.replace(new RegExp(/[òóôõö]/g), 'o')
        r = r.replace(new RegExp(/œ/g), 'oe')
        r = r.replace(new RegExp(/[ùúûü]/g), 'u')
        r = r.replace(new RegExp(/[ýÿ]/g), 'y')
        r = r.replace(new RegExp(/\W/g), '')
        return r
    }
}
export default filters