<template>
  <fx-icon
    icon="cloud_download"
    color="#319ace"
    :tooltip="tooltip"
    @click="openFile"
  />
</template>
<style lang="scss" scoped>
    .exporter{
        img {
            transition: all ease .3s;
            width: 22px;
        }
        &:hover{
            img{
                transform: scale(1.12);
            }
        }
    }
</style>
<script>
export default {
    name: 'FxExportButton',
    props: {
        json: String,
        filename: String,
        type: String,
        uriRessource: {
            type: String,
            default: 'exporter',
        },
        tooltip: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            data: new FormData(),
            test: 1,
            base: this.$wikaz.request.url,
        }
    },
    methods: {
        openFile () {
            this.$wikaz.request.request('POST', this.uriRessource, null, {
                data: this.json,
                type: this.type,
            })
            this.resetData()
        },
        resetData () {
            this.data = new FormData()
        },
    },
}
</script>