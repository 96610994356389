<template>
  <div style="width:100%;display: flex;align-items: center;padding: 0;margin: 0">
    <v-text-field
      ref="myInput"
      :clearable="clearable"
      style="margin-top: 2px;margin-bottom: 2px;"
      outlined
      hide-details
      dense
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="!editable"
      :size="size"
      :style="{width: width}"
      :type="type"
      :value="value"
      :rules="rules"
      v-on="listeners"
    />
    <fx-icon
      v-if="value !== null && value.length === 10"
      style="margin-left: 10px"
      icon="phone"
      color="green"
      :size="22"
      @click="f_appeler()"
    />
  </div>
</template>
<style lang="scss" scoped>
    .fx-textinput{
        width:100%;
        &.ivu-input-group{
            top: 0 !important;
        }
    }
</style>
<script>
export default{
    name: 'FxTelephone',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 'text',
            type: String,
        },
        value: { default: null },
        size: { default: 'small' },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        clearable: {
            default: false,
            type: Boolean,
        },
        labelLeft: { default: null },
        labelRight: { default: null },
        tooltip: { default: null },
        tooltipPosition: { default: 'bottom-start' },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return { formatted: this.value }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => {
                    if (item === 'input') {
                        let regex = / /gi
                        self.$emit(item, event.replace(regex, ''))
                    } else {
                        self.$emit(item, event)
                    }
                }
            })
            return { ...self.$listeners }
        },
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        f_appeler () {
            window.location = 'tel:' + this.value
        },
    },
}
</script>
<style scoped>
    input::placeholder {
        color: #BBB;
    }
</style>