<template>
  <div style="width: 100%;height: 90px;display: flex;justify-content: center">
    <v-avatar
      v-if="value"
      size="90"
    >
      <v-img
        height="100%"
        contain
        :src="'data:image/jpeg;base64,' + value"
      />
    </v-avatar>
  </div>
</template>
<script>
export default{
    name: 'FxTextInput',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        type: {
            default: 'text',
            type: String,
        },
        value: { default: null },
        size: { default: 'small' },
        width: { default: '100%' },
        placeholder: { default: '' },
        editable: {
            default: true,
            type: Boolean,
        },
        clearable: {
            default: false,
            type: Boolean,
        },
        labelLeft: { default: '' },
        labelRight: { default: '' },
        tooltip: { default: null },
        tooltipPosition: { default: 'bottom-start' },
        labelColor: { default: '#000' },
        rows: { default: 4 },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => self.$emit(item, event)
            })
            return { ...self.$listeners }
        },
    },
    watch: {
        editable: function () {
        },
    },
    methods: {
        validate () {
            return true // this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
    },
}
</script>
<style lang="scss" scoped>
</style>