import store from '@/wikaz-core/store'
import router from '@/wikaz-core/router/router'
import WikazApp from '@/wikaz-core/WikazApp'
import Request from '@/wikaz-core/request/Request'
import notification from '@/wikaz-core/outils/notification'

class Auth {
    constructor () {
    }
    login (username, password, redirect) {
        const params = {
            grant_type: 'password',
            username: username,
            password: password,
            client_secret: WikazApp.app.client_secret,
            client_id: WikazApp.app.client_id,
        }
        this.activation = true

        return (new Request()).request('POST', 'oauth/token', { 'Content-Type': 'application/json' }, params)
            .then((response) => {
                this._storeToken(response)
                if (redirect) {
                    router.push({ name: redirect })
                }
                return response
            })
            .catch((errorResponse) => {
                (new notification()).showError('Erreur d\'identification!')
                return errorResponse
            })
    }
    logout (showMessage = false) {
        store.commit('CLEAR_ALL_DATA')
        if (this.activation) {
            this.activation = false
            if (showMessage) {
                (new notification()).showError('Session expirée. </br>Merci de vous identifier à nouveau.')
            }
        }
        router.push({ name: 'login' })
        return false
    }
    _storeToken (response) {
        const auth = store.state.auth
        auth.isLoggedIn = true
        auth.accessToken = response.access_token
        auth.refreshToken = response.refresh_token
        auth.expires_in = response.expires_in
        let dateHeureExpire = new Date()
        dateHeureExpire.setSeconds(dateHeureExpire.getSeconds() + response.expires_in)
        auth.expires_date_heure = dateHeureExpire
        store.commit('UPDATE_AUTH', auth)
    }
}
export default Auth