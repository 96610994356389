<template>
  <div class="fx-chart-container">
    <div>
      <apexchart
        v-if="dataProvider"
        height="100%"
        :options="dataProvider.options"
        :series="dataProvider.series"
      />
    </div>
    <apexchart
      v-if="dataProvider && dataProvider.brush"
      height="130"
      :options="dataProvider.brush"
      :series="dataProvider.series"
    />
  </div>
</template>

<script>
export default {
    name: 'FxChart',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
}
</script>
<style scoped>
    .fx-chart-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>