<template>
  <v-select
    v-if="dataProvider"
    ref="myInput"
    style="height: 45px;margin-top: 0px;margin-bottom: 0px"
    :style="{width: width}"
    :value="value"
    hide-details
    outlined
    :multiple="multiple"
    :items="dataProvider"
    :item-text="labelField"
    :item-value="idField"
    :label="preHolder"
    solo
    :rules="rules"
    dense
    @change="onChange"
  >
    <template v-slot:item="{ item }">
      <div v-html="item[labelField]" />
    </template>
    <template v-slot:selection="{ item }">
      <div v-html="item[labelField]" />
    </template>
  </v-select>
  <v-select
    v-else
    hide-details
    outlined
    solo
    dense
    style="height: 45px;margin-top: 2px;margin-bottom: 2px"
    :style="{width: width}"
  />
</template>
<script>
export default{
    name: 'FxDropdown',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        width: { default: '100%' },
        labelField: { default: 'label' },
        idField: { default: 'id' },
        dataProvider: {
            default () {
                return []
            },
        },
        multiple: {
            default: false,
            type: Boolean,
        },
        url: {
            default: null,
            type: String,
        },
        preHolder: {
            default: '',
            type: String,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    watch: {
        url: function () {
            this.getDataProvider()
        },
    },
    mounted () {
        this.getDataProvider()
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        onChange (ev) {
            this.$emit('input', ev)
            this.$emit('on-change', ev)
        },
        isDefined (item) {
            return [undefined, null].indexOf(item) < 0
        },
        getDataProvider () {
            if (this.url) {
                this.$wikaz.request.get(this.url).then((data) => {
                    this.$emit('update:dataProvider', data)
                    this.$emit('loadComplete')
                })
            }
        },
    },
}
</script>