<template>
  <fx-datagrid-vuetify
    ref="objDatagrid"
    :url-ressource-end-point="urlRessourceEndPoint"
    @ajouter="f_ajouter"
    @edition="f_editer"
    @suppression="f_suppression"
  />
</template>
<script>

export default {
    name: 'VxGestionPostesEdt',
    data () {
        return { urlRessourceEndPoint: 'api/edt/modules/gestion-postes-edt/postes' }
    },
    methods: {
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                this.f_result_ajouter,
            )
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_editer (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.poste_id + '/edit',
                this.f_result_editer,
            )
        },
        f_result_editer (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + data.poste_id, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_suppression (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.poste_id).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
    },
}
</script>
<style scoped>
</style>