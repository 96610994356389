<template>
  <fx-traitement-template
    :composant-filtre-element="composantFiltreElement"
    :url-ressource-end-point="urlRessourceEndPoint"
    titre-liste="Liste des profils"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    :item-renderer="itemRenderer"
    width-list="400px"
  />
</template>
<script>
import IxTraitement from './IxProfils.vue'
import CxTraitementProfil from './CxTraitementProfil'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxTraitementProfilPwa from '@/wikaz-konnect/adm/gestion-profils/CxTraitementProfilPwa'
import CxFiltreProfil from '@/wikaz-konnect/adm/gestion-profils/CxFiltreProfil'

export default {
    name: 'VxGestionProfils',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/wikaz/modules/gestion-profils/profils',
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementProfil),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementProfilPwa),
            composantFiltreElement: this.$wikaz.outils.importation.elementByComponent(CxFiltreProfil),
            itemRenderer: IxTraitement,
        }
    },
}
</script>