<template>
  <v-card
    class="cx-selection-interlocuteur-container"
    height="600px"
  >
    <fx-titre titre="Interlocuteurs" />
    <fx-datagrid-vuetify
      ref="objListe"
      :url-ressource-end-point="urlRessourceEndPointAffectation"
      @dbClick="f_affectation_db_click"
      @affectation="f_affectation"
      @ajouter="f_ajouter"
    />
    <div style="display: flex;justify-content: flex-end;padding: 10px">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxSelectionInterlocuteur',
    extends: CxBase,
    props: {
        urlRessourceEndPoint: {
            required: true,
            type: String,
        },
        urlRessourceEndPointAffectation: {
            required: true,
            type: String,
        },
    },
    methods: {
        f_affectation_db_click (datagridItem) {
            this.post_affectation(datagridItem.item.interlocuteur_id)
        },
        f_affectation (item) {
            this.post_affectation(item.interlocuteur_id)
        },
        post_affectation (interlocuteurId) {
            this.$wikaz.request.put(this.urlRessourceEndPointAffectation + '/' + interlocuteurId).then(() => {
                this.$emit('actualiser')
            })
        },
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                    this.$emit('actualiser')
                })
            })
        },
    },
}
</script>

<style  lang="scss">
    .cx-selection-interlocuteur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-column-gap: 10px;
        overflow: auto;
    }

</style>