<template>
  <div
    v-if="$vuetify.theme.dark"
    style="display: flex;align-items: center;justify-content: center"
  >
    <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
      <fx-icon
        icon="warning"
        color="red"
        style="margin-right: 10px"
        :size="50"
      />
      <div style="font-size: 25px">
        Ce module ne fonctionne pas avec le Mode Sombre. Pour utiliser ce module, vous devez passer en mode clair
      </div>
    </div>
  </div>
  <div
    v-else
    class="vx-editeur-de-site-container"
  >
    <div style="width:100%;display: flex;align-items: center;justify-content: space-between">
      <div style="display: flex;align-items: center;width: 400px;padding-left: 5px">
        <div style="width: 200px">
          Editeur de site
        </div>
        <fx-dropdown
          v-model="selectedSiteId"
          :data-provider.sync="dataSites"
          :selected-index="0"
          url="api/wikaz/modules/editeur-de-site/sites"
        />
      </div>
      <!--            <fx-text-input v-model="newSiteName" outlined dense></fx-text-input>
            <v-btn v-if="selectedSiteId" color="success" small @click="f_dupliquer">Dupliquer</v-btn>-->
      <v-btn
        v-if="selectedSiteId"
        color="success"
        small
        @click="f_publier"
      >
        Publier
      </v-btn>
    </div>
    <cx-editeur-site
      :key="selectedSiteId"
      :site-id="selectedSiteId"
    />
  </div>
</template>
<script>
import CxEditeurSite from './CxEditeurSite'
export default {
    name: 'VxEditeurDeSite',
    components: { CxEditeurSite },
    data () {
        return {
            dataSites: null,
            selectedSiteId: null,
            newSiteName: null,
        }
    },
    methods: {
        f_publier () {
            this.$wikaz.request.post('api/wikaz/modules/editeur-de-site/sites/' + this.selectedSiteId + '/publication')
        },
        f_dupliquer () {
            if (this.newSiteName) {
                this.$wikaz.request.post('api/wikaz/modules/editeur-de-site/sites/' + this.selectedSiteId + '/duplication?name=' + this.newSiteName)
            }
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-editeur-de-site-container {
        // border: 1px solid red;
        width: 100%;
        height: 100%;
        padding: 5px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-column-gap: 10px;
        overflow: auto;
    }
</style>