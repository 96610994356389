<template>
  <fx-list
    ref="listeAdresses"
    style="width:100%;height:100%;overflow: auto;"
    :title="titre"
    :show-add="showAdd"
    show-search
    show-refresher
    :item-renderer="item_renderer"
    :data-provider="adresses"
    @add="f_ajouter_adresse"
    @refresh="refresh()"
    @itemEmitting="selectionChange($event)"
  />
</template>
<script>
import IxListeAdresses from './IxListeAdresses'

export default{
    name: 'CxListeAdresses',
    props: {
        urlEndPoint: {
            required: true,
            type: String,
        },
        titre: { default: 'Adresses Client' },
        showAdd: { default: true },
    },
    data () {
        return {
            adresses: [],
            item_renderer: IxListeAdresses,
        }
    },
    mounted () {
        this.getListe()
    },
    methods: {
        getListe () {
            this.$refs.listeAdresses.loading = true
            this.$wikaz.request.get(this.urlEndPoint).then((data) => {
                this.adresses = data
                this.$refs.listeAdresses.loading = false
            })
        },
        selectionChange (item) {
            if (item) {
                this.$emit('selectedFiltreAdresse', 'Adresse#' + item.id + '#')
                this.$emit('selectedAdresseId', item.id)
                this.$emit('selectionChange', item)
            }
        },
        getSelectedAdresse () {
            return this.$refs.listeAdresses.getSelectedItem()
        },
        refresh () {
            this.$emit('on-submit')
            this.getListe()
        },
        f_ajouter_adresse () {
            this.$wikaz.popups.showFormulaire(this.urlEndPoint + '/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post(this.urlEndPoint, data).then(() => {
                this.getListe()
            })
        },
    },
}
</script>