<template>
  <div
    style="display: flex;gap: 3px;"
    :style="{width: width}"
  >
    <fx-label
      v-if="label"
      :width="labelWidth"
      :text="label"
    />
    <div
      class="fx-champ-container"
      :style="{backgroundColor: $vuetify.theme.isDark ? '#1e1e1e' : 'white'}"
    >
      <div
        style="width: 100%;white-space: pre-line"
        v-html="text"
      />
      <fx-icon
        icon="content_copy"
        color="grey"
        tooltip="copier"
        :size="14"
        @click="$wikaz.outils.clipboard.copyToClipboard(text)"
      />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
    name: 'FxChamp',
    props: {
        text: { default: null },
        label: {
            type: String,
            default: null,
        },
        labelWidth: {
            type: String,
            default: '70px',
        },
        width: {
            type: String,
            default: '100%',
        },
    },
}
</script>
<style scoped lang="scss">
    .fx-champ-container {
        width: 100%;
        padding: 2px 5px 2px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        border: 1px solid lightgrey;
    }
</style>