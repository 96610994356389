<template>
  <v-checkbox
    v-model="selectedValue"
    style="padding: 0;margin-top: 0;margin-bottom: 0"
    hide-details
    :disabled="disabled"
    :true-value="trueValue"
    :false-value="falseValue"
    :label="label"
    v-on="listeners"
    @change="onChange"
  />
</template>
<script>
export default{
    name: 'FxCheckbox',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        label: {
            default: null,
            type: String,
        },
        value: { default: 0 },
        size: { default: 'small' },
        width: { default: '100%' },
        trueValue: { default: 1 },
        falseValue: { default: 0 },
    },
    data () {
        return { selectedValue: this.value }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => self.$emit(item, event)
            })
            return { ...self.$listeners }
        },
    },
    methods: {
        onChange (ev) {
            this.$emit('input', ev)
            this.$emit('on-change', ev)
        },
    },
}
</script>