<template>
  <div class="cx-traitement-menu-container">
    <div
      v-if="datas"
      class="cx-traitement-menu-col1"
    >
      <fx-formulaire-generique
        :data-in="datas"
        :url-ressource="'api/wikaz/modules/gestion-menu/menus/'+datas.module_id+'/edit'"
        @on-submit="updateData"
      />
      <fx-formulaire-generique
        :url-ressource="'api/wikaz/modules/gestion-menu/menus/'+datas.module_id+'/configuration/0/edit'"
        @on-submit="updateConfiguration"
      />
    </div>
    <fx-tab-navigator>
      <div
        style="overflow: auto;padding: 10px"
        title="Profils"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-profils'"
          :url-ressouce-update="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-profils/' + datas.module_id"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Utilisateur"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-utilisateurs'"
          :url-ressouce-update="'api/wikaz/modules/gestion-menu/menus/' + datas.module_id + '/affectation-utilisateurs/' + datas.module_id"
        />
      </div>
    </fx-tab-navigator>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'CxTraitementMenu',
    extends: CxBase,
    data () {
        return { tabIndex: 0 }
    },
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-menu/menus/' + data.module_id, data).then(this.f_result_updateData)
        },
        updateConfiguration (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-menu/menus/' + data.module_id + '/configuration/0', data).then(this.f_result_updateData)
        },
        f_result_updateData () {
            this.$emit('on-refresh')
        },
        f_retour () {
            this.f_precedent('vx-gestion-menu', 'Gestion du menu')
        },
    },
}
</script>
<style scoped>
    .cx-traitement-menu-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 450px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-menu-col1 {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-traitement-menu-col2 {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
    }
</style>