<template>
  <div
    v-if="!isMobile"
    style="padding: 100px"
  >
    <div style="min-width: 800px">
      <fx-calendrier :item-renderer="itemRenderer" />
    </div>
  </div>
  <fx-calendrier
    v-else
    style="padding: 5px"
    :item-renderer="itemRenderer"
  />
</template>

<script>
import CxItemMoisPwa from './CxItemMoisPwa'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'

export default {
    name: 'VxReleveActivitePwa',
    extends: VxBase,
    data () {
        return { itemRenderer: CxItemMoisPwa }
    },
}
</script>