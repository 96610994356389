<template>
  <div
    v-if="datas"
    style="display:flex;align-items:center;justify-content:center;height: auto;width: 100%"
  >
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="subject"
      color="#878787"
      tooltip="Afficher les observations de l'intervention"
      @click="f_afficher_observation()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="print"
      color="#878787"
      tooltip="Imprimer la fiche intervention"
      @click="f_imprimer_fiche()"
    />
    <fx-icon
      v-if="datas.intervention_cloture"
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="description"
      color="#878787"
      tooltip="CRI"
      @click="f_imprimer_facture()"
    />
    <fx-icon
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="folder"
      color="#F6D159"
      tooltip="Fichiers de l'intervention"
      @click="f_afficher_archive()"
    />
    <!--        <fx-icon v-if="datas.intervention_aquisition_demande_id > 0" style="margin-left: 10px;margin-right: 10px" :size="26" icon="folder" color="#F6D159" tooltip="Base de connaissance" @click="f_afficher_archive()"/>-->
    <!--        <fx-icon v-if="datas.intervention_cloture === null" style="margin-left: 10px;margin-right: 10px" :size="26" icon="check_circle" color="primary" tooltip="Cloturer l'intervention" @click="f_cloturer()"/>-->
    <fx-icon
      v-if="datas.intervention_cloture === null"
      style="margin-left: 10px;margin-right: 10px"
      :size="26"
      icon="update"
      color="primary"
      tooltip="Reporter l'intervention"
      @click="f_report()"
    />
    <!--        <fx-icon style="margin-left: 10px;margin-right: 10px" :size="26" icon="delete" color="error" tooltip="Supprimer l'intervention" @click="f_supprimer_intervention()"/>-->
  </div>
  <cx-waiting
    v-else
    :size="18"
  />
</template>
<script>

import CxObservations from '@/wikaz-konnect/gdi/gestion-clients/observations/CxObservations'
import CxPopupArchivesIntervention
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupArchivesIntervention'
import CxPopupReporterIntervention
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/elements-outils/CxPopupReporterIntervention'

export default {
    name: 'CxFicheInterventionOutils',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.f_charger_data()
    },
    methods: {
        f_charger_data () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/outils').then((data) => {
                this.datas = data
            })
        },
        f_result_formx (data) {
            this.$wikaz.request.put(data.urlUpdate, data).then(() => {
            })
        },
        f_result_formPost (data) {
            this.$wikaz.request.post(data.urlUpdate, data).then(() => {
            })
        },
        f_afficher_observation () {
            this.$wikaz.popups.show(
                CxObservations,
                {
                    datas: {
                        urlEndPoint: this.urlRessourceEndPoint + '/observations',
                        titre: 'Observations intervention',
                        width: '500px',
                        height: '600px',
                        isPopup: true,
                    },
                },
                this.f_result_popup)
        },
        f_imprimer_fiche () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/impression')
        },
        f_imprimer_facture () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + '/facture')
        },
        f_afficher_archive () {
            this.$wikaz.popups.show(
                CxPopupArchivesIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/fichiers',
                        titre: 'Fichiers de l\'intervention',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_result_popup,
            )
        },
        f_result_popup () {

        },
        f_cloturer () {
        },
        f_report () {
            this.$wikaz.popups.show(
                CxPopupReporterIntervention,
                {
                    datas: {
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/report',
                        titre: 'Reporter l\'intervention',
                        width: '800px',
                        height: '800px',
                        isPopup: true,
                    },
                },
                this.f_result_popup,
            )
        },
    },
}
</script>