<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        v-if="item.pack_id === 15"
        color="grey"
        icon="extension"
      />
      <fx-icon
        v-else
        color="success"
        icon="extension"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.pack_libelle"
      />
      <v-list-item-subtitle v-if="item.souscription_date_debut">
        Depuis le : {{ $wikaz.outils.dates.getDate(item.souscription_date_debut,'DD/MM/YYYY HH:mm') }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemPack',
    extends: FxItemTemplate,
    methods: {
        f_ajouter () {

        },
    },
}
</script>