<template>
  <fx-datagrid-vuetify
    ref="obj_liste"
    :url-ressource-end-point="urlRessourceEndPoint"
    @ajouter="f_ajouter"
    @editer_etat="f_editer"
    @delete="f_delete"
  />
</template>
<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
export default {
    name: 'VxGestionEtatsProspects',
    extends: VxBase,
    data () {
        return { urlRessourceEndPoint: 'api/entreprises/modules/gestion-etats-prospects-wk-crm/etats' }
    },
    methods: {
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', this.f_result_ajouter)
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.$refs.obj_liste.chargerData()
            })
        },
        f_editer (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/' + item.etat_id + '/edit', this.f_result_editer)
        },
        f_result_editer (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + data.etat_id, data).then(() => {
                this.$refs.obj_liste.chargerData()
            })
        },
        f_delete (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.etat_id).then(() => {
                this.$refs.obj_liste.chargerData()
            })
        },
    },
}
</script>
<style scoped>
</style>