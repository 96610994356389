<template>
  <v-dialog
    v-model="visible"
    class="fx-popup-container"
    width="auto "
    persistent
    eager
  >
    <component
      :is="composant"
      ref="instanceComposant"
      v-bind="params.props"
      @close="close"
      @validate="validate"
      @on-submit="validate"
    />
  </v-dialog>
</template>

<script>
export default {
    name: 'WikazDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        composant: {
            type: Object,
            default: null,
        },
        params: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            isMobile: window.Vue.$store.getters.isMobile(),
            width: '200px',
        }
    },
    watch: {
        visible: function (newEtat) {
            if(! newEtat) {
                this.close()
            }
        },
    },
    mounted () {
        document.addEventListener('keydown', this.escKeyClose)
        if(this.params && this.params.datas) {
            for (const property in this.params.datas) {
                this.$refs.instanceComposant[property] = this.params.datas[property]
            }
        }
    },
    beforeDestroy () {
        document.removeEventListener('keydown', this.escKeyClose)
    },
    methods: {
        close () {
            this.$emit('close')
        },
        validate (data) {
            this.$emit('validate', data)
        },
        escKeyClose (e) {
            if (e.keyCode === 27) {
                this.close()
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .fx-popup-container /deep/ v-dialog {
        overflow: visible;
    }
    .modal-container {
        box-sizing: border-box;
        border: 1px solid grey;
        overflow: visible !important;
        padding: 10px;
    }
</style>