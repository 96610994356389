<template>
  <cx-liste-interventions
    ref="obj_liste_interventions"
    titre=""
    style="padding: 5px"
    width="100%"
    :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/interventions'"
    @selectedItem="f_select_intervention"
  />
</template>
<script>
import CxListeInterventions from '@/wikaz-konnect/gdi/gestion-clients/liste-interventions/CxListeInterventions'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
export default {
    name: 'CxPageInterventions',
    components: { CxListeInterventions },
    extends: CxBase,
    mounted () {
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Liste des interventions'
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
        this.$refs.obj_liste_interventions.setFiltre(this.datas.id)
    },
    methods: {
        f_select_intervention (event) {
            let dataParams = this.datas
            dataParams.intervention_id = event.intervention_id
            this.$store.commit('SET_VIEW_PWA', {
                component: 'cx-page-intervention',
                config: {
                    iconLeft: 'arrow_back',
                    datas: dataParams,
                },
            })
        },
        f_retour () {
            let dataParams = this.datas
            this.$store.commit('SET_VIEW_PWA', {
                component: 'cx-traitement-gestion-clients-pwa',
                // module_libelle: 'Client : ' + dataParams.client_id,
                config: {
                    iconLeft: 'arrow_back',
                    datas: dataParams,
                },
            })
        },
    },
}
</script>
<style scoped>
</style>