<template>
  <v-flex
    v-if="dataProvider"
    style="height: auto;background-color: #e7e4e4;margin-left: 20px;margin-right: 20px;border-radius: 25px;padding-bottom: 15px"
    xs12
  >
    <div
      class="headline"
      style="display: flex;justify-content: center;align-items: center;height: 80px"
    >
      Documents utiles
    </div>
    <v-container grid-list-xl>
      <v-layout
        row
        wrap
        justify-center
      >
        <template v-for="index in 10">
          <v-flex
            v-if="dataProvider['fileSrc' + index]"
            :key="index"
            class="md3"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  flat
                  :elevation="hover ? 12 : 4"
                  @click="open(dataProvider['fileSrc' + index])"
                >
                  <v-card-text class="text-center">
                    <v-icon
                      x-large
                      :color="(dataProvider && dataProvider.iconColor) ? dataProvider.iconColor : '#09203F'"
                    >
                      picture_as_pdf
                    </v-icon>
                  </v-card-text>
                  <v-card-title
                    class="title"
                    style="justify-content: center"
                    v-html="dataProvider['fileTitre' + index]"
                  >
                    +
                  </v-card-title>
                </v-card>
              </template>
            </v-hover>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
export default {
    name: 'FxFiles',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        open () {

        },
    },
}
</script>

<style scoped>

</style>