<template>
  <div>
    <v-flex xs12>
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center
        >
          <v-flex
            xs12
            class="md6"
          >
            <template>
              <v-card
                flat
                class="transparent"
              >
                <v-card-title :style="{justifyContent: 'center'}">
                  <v-text-field
                    label="Recherche"
                    prepend-icon="search"
                    @keyup.enter="f_recherche"
                  />
                </v-card-title>
                <!--<v-card-subtitle class="title" v-html="dataProvider.sousTitre">
                                </v-card-subtitle>-->
                <!--<v-card-text v-if="dataProvider.content" :style="{textAlign: dataProvider.contentAlign}">
                                    <span v-html="dataProvider.content"></span>
                                </v-card-text>-->
              </v-card>
            </template>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
    <v-flex
      v-for="(item,index) in dataProvider"
      :key="index"
      xs12
    >
      <v-container grid-list-xl>
        <v-layout
          row
          wrap
          justify-center
        >
          <v-flex
            xs12
            class="md8"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  flat
                  class="transparent"
                  :elevation="hover ? 12 : 6"
                  @click="f_route(item.route)"
                >
                  <v-card-subtitle
                    class="title"
                    style="font-weight: bold"
                    v-html="item.titre"
                  />
                  <v-card-text>
                    <div
                      style="margin-left: 20px"
                      v-html="item.detail"
                    />
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'FxRecherche',
    data () {
        return { dataProvider: null }
    },
    methods: {
        f_recherche (valeur) {
            this.dataProvider = null
            Vue.axios.get(this.$icsilab.rootApi + 'recherches?search=' + valeur.currentTarget.value).then((data) => {
                this.dataProvider = data.data
            })
        },
        f_route (route) {
            this.$icsilab.goToPage(route)
        },
    },
}
</script>

<style scoped>

</style>