class Navigateur {
    etat = null
    key = null
    mailto (valeur) {
        window.location = 'mailto:' + valeur
    }
    tel (valeur) {
        window.location = 'tel:' + valeur
    }
    geo (valeur) {
        window.location = 'geo:' + valeur
    }
}
export default Navigateur