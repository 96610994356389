<template>
  <div
    class="fx-datagrid-vuetify-container"
    title=""
  >
    <div
      style="display: flex;width: 100%;height: auto;align-items: center"
      :style="{flexDirection: (isMobile ? 'column' : 'row' ) }"
    >
      <div
        v-if="titre"
        style="display: flex;align-items: center;margin-left: 10px;margin-right: 10px;font-weight: bold"
      >
        {{ titre }}
      </div>
      <!--            <v-text-field dense
                          :class="dense ? 'small' : ''"
                          v-model="search"
                          append-icon="search"
                          label="Filtre"
                          hide-details
                          outlined
            ></v-text-field>-->
      <fx-filter-tag v-model="search" />
      <div :style="{marginTop: (isMobile ? '10px' : '0')}">
        <v-btn
          :small="dense"
          color="primary"
          style="margin-left: 10px"
          :loading="loading"
          @click="chargerData"
        >
          Actualiser
        </v-btn>
        <v-btn
          v-if="datas && datas.visible_ajout"
          :small="dense"
          color="success"
          style="margin-left: 10px"
          @click="f_actions('ajouter')"
        >
          Ajouter
        </v-btn>
        <v-btn
          v-if="datas && datas.export"
          :small="dense"
          color="success"
          style="margin-left: 10px"
          @click="f_exporter"
        >
          Exporter
        </v-btn>
      </div>
    </div>
    <v-data-table
      v-if="datas"
      :dense="dense"
      fixed-header
      height="calc(100% - 60px)"
      :headers="datas.headers"
      :items="datas.datas"
      :search="search"
      :custom-filter="customFilter"
      style="overflow: auto;border: 1px solid #dddddd"
      :items-per-page="(datas.items_par_page) ? datas.items_par_page : 15"
      :show-select="(datas.selection) ? true : false"
      :single-select="( datas.selection ) ? datas.selection.singleSelect : false"
      :item-key="datas.item_key"
      show-group-by
      :single-expand="true"
      :expanded.sync="expanded"
      :show-expand="datas.expand"
      :hide-default-footer="(datas.footer) ? false : true"
      @dblclick:row="f_double_click_item"
    >
      <template
        v-for="(element,index) in datas.itemsRenderers"
        :slot="element.slot"
        slot-scope="{headers,item}"
      >
        <div
          v-if="element.type === 'basic'"
          :key="index"
        >
          <v-tooltip
            v-if="f_compare(item,element)"
            bottom
            :disabled="!(element.tooltip || element.tooltip_field) ? true : false"
          >
            <template v-slot:activator="{ on }">
              <fx-icon
                v-if="element.icon"
                :color="element.color_field ? item[element.color_field] : (element.color ? element.color : 'grey')"
                :icon="element.icon_field ? item[element.icon_field] : (element.icon_text ? element.icon_text : '')"
                :tooltip="element.icon_tooltip ? item[element.icon_tooltip] : null"
                v-on="on"
              />
              <v-chip
                v-else-if="element.chip"
                :text-color="element.font_color?element.font_color:'black'"
                :color="element.color_field ? item[element.color_field] : (element.color ? element.color : 'grey')"
                v-on="on"
              >
                {{ (element.format_date ? f_format_date_heure(item[element.field],element.format_date) : item[element.field] ) + ( element.symbol ? (' ' + element.symbol) : '' ) }}
              </v-chip>
              <v-img
                v-else-if="element.image"
                :src="'data:image/jpeg;base64,' + item[element.field]"
                height="100"
                width="100"
                :aspect-ratio="16/9"
                contain
                v-on="on"
              />
              <div
                v-else
                v-on="on"
                v-html="((element.format_date ? f_format_date_heure(item[element.field],element.format_date) : item[element.field])+ ( element.symbol ? (' ' + element.symbol) : '' )) !== 'null' ? ((element.format_date ? f_format_date_heure(item[element.field],element.format_date) : item[element.field])+ ( element.symbol ? (' ' + element.symbol) : '' )) : ''"
              />
            </template>
            <span>{{ element.tooltip_field ? item[element.tooltip_field] : (element.tooltip ? element.tooltip : '') }}</span>
          </v-tooltip>
          <div v-else>
            {{ element.format_date ? f_format_date_heure(item[element.field],element.format_date) : item[element.field] }}
          </div>
        </div>
        <template v-else-if="element.type === 'expands'">
          <td
            :key="'A' + index"
            :colspan="1"
            style="background-color: #EEEEEE"
          />
          <td
            :key="'B' + index"
            :colspan="headers.length - 1"
            style="padding: 0;"
          >
            <component
              :is="itemRendererDetail"
              v-if="itemRendererDetail"
              :key="expanded[0] ? expanded[0][datas.item_key] : 0"
              :data-in="item"
            />
          </td>
        </template>
        <v-menu
          v-else-if="element.type === 'actions' && getNbElementMenu(element.menus,item)"
          :key="index"
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <fx-icon
              :size="30"
              color="primary"
              icon="menu"
              v-on="on"
            />
          </template>
          <v-list ref="objLst">
            <template v-for="(menuItem,menuIndex) in element.menus">
              <v-list-item
                v-if="f_compare(item,menuItem)"
                :key="menuIndex"
                @click="f_actions(menuItem.clickNameEvent,item)"
              >
                <v-list-item-avatar>
                  <v-icon :color="menuItem.color">
                    {{ menuItem.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
    name: 'FxDatagridVuetify',
    props: {
        urlRessourceEndPoint: {
            required: true,
            type: String,
        },
        itemRendererDetail: {
            default: null,
            type: Object,
        },
        dense: {
            default: false,
            type: Boolean,
        },
        titre: {
            default: null,
            type: String,
        },
    },
    data () {
        return {
            datas: null,
            search: '',
            loading: false,
            selectedTypeListe: 0,
            selectedPeriode: null,
            isMobile: window.Vue.$store.getters.isMobile(),
            expanded: [],
            cancelRequest: null,
        }
    },
    mounted () {
        this.chargerData()
    },
    methods: {
        chargerData () {
            this.$emit('actualiser')
            this.loading = true
            this.datas = null
            if (this.cancelRequest !== null) {
                this.cancelRequest.abort()
            }
            this.cancelRequest = new AbortController()
            this.$wikaz.request.request('GET', this.urlRessourceEndPoint, null, null, this.cancelRequest).then((data) => {
                this.datas = data
                this.loading = false
            })
        },
        f_actions (emitName, item = null) {
            if (item && ! item.id) {
                if (this.datas.item_key) {
                    item.id = item[this.datas.item_key]
                }
            }
            this.$emit(emitName, item)
        },
        f_format_date_heure (valeur, format = 'DD/MM/YYYY HH:mm') {
            let dateFormat = this.$wikaz.getInstance().outils.dates.getDate(valeur, format)
            if (dateFormat !== 'Invalid Date') {
                return dateFormat // Invalid date
            }
            return ''
        },
        f_compare (item, dataMenu) {
            if (dataMenu.condition) {
                let comparedValue = dataMenu.condition.valeur
                let valeur = item[dataMenu.condition.field]
                switch (dataMenu.condition.operator) {
                case 'equal':
                    if (valeur === comparedValue) {
                        return true
                    }
                    return false
                case 'different':
                    if (valeur !== comparedValue) {
                        return true
                    }
                    return false
                case 'more':
                    if (valeur > comparedValue) {
                        return true
                    }
                    return false
                case 'more_or_equal':
                    if (valeur >= comparedValue) {
                        return true
                    }
                    return false
                case 'less':
                    if (valeur < comparedValue) {
                        return true
                    }
                    return false
                case 'less_or_equal':
                    if (valeur <= comparedValue) {
                        return true
                    }
                    return false
                case 'in':
                    if (valeur.indexOf(comparedValue) > - 1) {
                        return true
                    }
                    return false
                case 'not_in':
                    if (valeur.indexOf(comparedValue) === - 1) {
                        return true
                    }
                    return false
                }
            }
            return true
        },
        f_traitement_data_export (data) {
            if (this.datas.export.columns) {
                let tmpData = []
                for (let i = 0; i < data.length; i ++) {
                    let elements = {}
                    for (let key in data[i]) {
                        if (this.datas.export.columns.includes(key)) {
                            elements[key] = data[i][key]
                        }
                    }
                    tmpData.push(elements)
                }
                return JSON.stringify(tmpData)
            }
            return JSON.stringify(data)

        },
        f_exporter () {
            this.$wikaz.request.request('POST', 'api/wikaz/exporter', null, {
                data: this.f_traitement_data_export(this.datas.datas),
                type: this.datas.export.extension ? this.datas.export.extension : 'xlsx',
            })
        },
        getNbElementMenu (menu, item) {
            let nbMenu = 0
            for (let i = 0; i < menu.length; i ++) {
                if(this.f_compare(item, menu[i])) {
                    nbMenu ++
                }
            }
            return nbMenu
        },
        f_double_click_item (event, item) {
            this.$emit('dbClick', item)
        },
        customFilter (value, search, item) {
            let searchValeur = search.toLowerCase()
            let searchElements = searchValeur.split('#')
            searchElements = searchElements.filter(function (val) {
                return val !== ''
            })
            let testFiltres = []
            for (let j = 0; j < searchElements.length; j ++) {
                testFiltres[j] = false
            }

            for (const property in item) {
                let value = item[property]
                if (value && value.length > 0) {
                    if (searchElements.length === 1) {
                        searchValeur = searchValeur.replaceAll('#', '')
                        if (value.toLowerCase().includes(searchValeur)) {
                            return true
                        }
                    } else {
                        for (let i = 0; i < searchElements.length; i ++) {
                            if (value.toLowerCase().includes(searchElements[i])) {
                                testFiltres[i] = true
                            }
                        }
                        if (! testFiltres.includes(false)) {
                            return true
                        }
                    }

                }
            }
            return false
        },
    },
}
</script>
<style lang="scss" scoped>

    .v-data-table::v-deep th {
      font-size: 14px !important;
    }

    .fx-datagrid-vuetify-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

    .v-text-field.small .v-input__control .v-input__slot {
        height: 30px;
        min-height: auto !important;
        display: flex !important;
        align-items: center !important;
    }

</style>