<template>
  <fx-datagrid-vuetify
    ref="objDatagrid"
    :url-ressource-end-point="urlRessourceEndPoint"
    @ajouter="f_ajouter"
  />
</template>
<script>

export default {
    name: 'VxFichesTransmission',
    data () {
        return { urlRessourceEndPoint: 'api/edt/modules/fiches-transmission/messages' }
    },
    methods: {
        f_refresh () {
            this.$refs.objDatagrid.chargerData()
        },
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                this.f_result_ajouter,
            )
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
    },
}
</script>

<style scoped>

</style>