<template>
  <v-card
    class="cx-fichier-prestations-container"
    style="width: 500px;height: 500px"
  >
    <cx-explorateur-fichier
      :url-ressource-end-point="urlRessourceEndPoint"
      :titre="titre"
      :show-refresher="showRefresher"
      :show-import="showImport"
    />
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
    name: 'CxFichiersPrestations',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        titre: {
            type: String,
            default: 'Liste des documents disponibles',
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        border: {
            type: Boolean,
            default: true,
        },
        showImport: {
            type: Boolean,
            default: false,
        },
        showRefresher: {
            type: Boolean,
            default: false,
        },
        extensionArray: {
            type: Array,
            default: function () {
                return ['pdf']
            },
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-fichier-prestations-container{
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        gap: 10px;
        overflow: auto;
    }
</style>