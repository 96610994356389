<template>
  <v-list-item
    dense
    style="overflow: visible;height: 30px"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon>ballot</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ item.cards_id }}</v-list-item-title>
    </v-list-item-content>
    <v-list-item-content>
      <v-list-item-title>{{ item.cards_composant }}</v-list-item-title>
    </v-list-item-content>
    <fx-icon
      icon="create"
      :size="22"
      @click="f_editer"
    />
    <fx-icon
      icon="delete"
      color="red"
      :size="22"
      @click="f_delete"
    />
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemPages',
    extends: FxItemTemplate,
    methods: {
        f_editer () {
            this.$wikaz.popups.showFormulaire('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/0/composants/0/blocs/' + this.item.cards_id + '/edit', this.f_result_update)
        },
        f_result_update (data) {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/0/composants/0/blocs/' + this.item.cards_id, data).then(() => {
                this.f_emit_actualiser()
            })
        },
        f_delete () {
            this.$wikaz.request.delete('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/0/composants/0/blocs/' + this.item.cards_id).then(() => {
                this.f_emit_actualiser()
            })
        },
    },
}
</script>
<style scoped>
</style>