<template>
  <v-combobox
    ref="myInput"
    v-model="model"
    :items="items"
    item-text="label"
    :filter="filterObject"
    :loading="isLoading"
    :search-input.sync="search"
    hide-details
    outlined
    dense
    @input="$emit('input',$event)"
  />
</template>
<script>
export default{
    name: 'FxAdresseInputObject',
    props: {
        disabled: {
            default: false,
            type: Boolean,
        },
        value: { default: null },
        editable: {
            default: true,
            type: Boolean,
        },
    },
    data: () => ({
        descriptionLimit: 60,
        items: [],
        isLoading: false,
        model: null,
        search: null,
    }),
    watch: {
        search (val) {
            if (val && val.length >= 4 && val.length % 3 === 0) {
                this.isLoading = true
                this.$wikaz.request.get('api/wikaz/structure/adresse/' + val.replace(',', ''))
                    .then((data) => {
                        this.items = data
                        this.isLoading = false
                    })
            }
        },
    },
    mounted () {
        this.model = this.value
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        filterObject () {
            // item, queryText, itemText
            // console.log(item, queryText, itemText)
            return true
        },
    },
}
</script>
<style scoped>
</style>

<!--<template>
    <v-autocomplete
        ref="myInput"
        v-model="select"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        cache-items
        @input="$emit('input',$event)"
        solo
        outlined
        dense
        hide-details
    ></v-autocomplete>
</template>
<script>
    export default{
        name: 'fx-adresse-input',
        props: {
            disabled: {
                default: false,
                type: Boolean
            },
            value: {
                default: null
            },
            editable: {
                default: true,
                type: Boolean
            }
        },
        data () {
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
                states: [],
            }
        },
        watch: {
            search (val) {
                val && val !== this.select && this.querySelections(val)
            },
        },
        methods: {
            validate() {
                return this.$refs.myInput.validate()
            },
            getError() {
                return this.$refs.myInput.errorBucket[0]
            },
            querySelections (valeur) {
                console.log(this.items)
                if (valeur && valeur.length >= 4 && valeur.length%3 === 0) {
                    this.loading = true
                    this.$wikaz.request.get('api/wikaz/structure/adresse/' + valeur).then((data) => {
                        this.items = []
                        for (let i = 0; i < data.length; i++) {
                            this.items.push(data[i].label)
                        }
                        this.loading = false
                    })
                }
            },
        },
    }
</script>
<style scoped>
    input::placeholder {
        color: #BBB;
    }
</style>-->