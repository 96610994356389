<template>
  <div class="cx-panel-proprietes-container">
    <div style="font-weight: bold">
      Paramètres
    </div>
    <fx-formulaire-generique
      small
      :data-in="value"
      :url-ressource="'api/wikaz/modules/editeur-de-site/sites/' + $icsilab.siteId + '/pages/' + value.composant_pages_id + '/composants/'+ value.composant_id + '/edit'"
      @on-submit="f_update"
    />
    <template v-for="(propriete,name,index) in value">
      <v-card
        v-if="['ordre','cards','formulaire'].indexOf(name) > -1"
        :key="index"
        style="padding: 5px"
      >
        <cx-ordre-editeur
          v-if="name === 'ordre'"
          v-model="value"
          @refresh="f_refresh"
        />
        <cx-cards-editeur
          v-else-if="name === 'cards'"
          v-model="value"
          @refresh="f_refresh"
        />
        <cx-formulaires-editeur
          v-else-if="name === 'formulaire'"
          v-model="value[name]"
        />
      </v-card>
    </template>
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        small
        @click="f_delete"
      >
        Supprimer
      </v-btn>
    </div>
  </div>
</template>
<script>
import CxFormulairesEditeur from './elements-editeur/panel-proprietes/CxFormulaireEditeur'
import CxCardsEditeur from './elements-editeur/panel-proprietes/CxCardsEditeur'
import CxOrdreEditeur from './elements-editeur/panel-proprietes/CxOrdreEditeur'

export default {
    name: 'CxPanelProprietes',
    components: {
        CxOrdreEditeur,
        CxCardsEditeur,
        CxFormulairesEditeur,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { dataComposantsSite: null }
    },
    methods: {
        f_refresh () {
            this.$emit('refresh')
        },
        f_update (data) {
            this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id, data).then(() => {
                this.f_refresh()
            })
        },
        f_delete () {
            this.$wikaz.request.delete('api/wikaz/modules/editeur-de-site/sites/' + this.$icsilab.siteId + '/pages/' + this.value.composant_pages_id + '/composants/' + this.value.composant_id).then(() => {
                this.f_refresh()
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-panel-proprietes-container {
        width: 100%;
        height: 100%;
        // border: 1px solid grey;
        box-sizing: border-box;
        // padding: 5px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 5px;
    }
</style>