<template>
  <div />
</template>
<script>
export default {
    name: 'FxItemTemplate',
    props: {
        item: {
            type: Object,
            default: null,
        },
        index: {
            type: Number,
            default: - 1,
        },
        lastIndex: {
            type: Number,
            default: - 1,
        },
    },
    data () {
        return { color: '#c1c1c1' }
    },
    methods: {
        f_emit_actualiser () {
            this.$emit('actualiser')
        },
        f_emit_selection () {
            this.$emit('itemEmitting', this.item)
        },
        f_format_date (valeur, format = 'DD/MM/YYYY') {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, format)
        },
        f_format_date_heure (valeur, format = 'DD/MM/YYYY HH:mm') {
            return this.$wikaz.getInstance().outils.dates.getDate(valeur, format)
        },
        f_action_custom (name, item) {
            this.$emit(name, item)
        },
    },
}
</script>