import Vue from 'vue'
import VxConfigurationsGdt from '@/wikaz-konnect/gdt/parametrages/configurations/VxConfigurationsGdt'
import VxGestionMetiersEdt from '@/wikaz-konnect/gdt/parametrages/gestion-metiers/VxGestionMetiersEdt'
import VxGestionPostesEdt from '@/wikaz-konnect/gdt/parametrages/gestion-postes/VxGestionPostesEdt'
import VxGestionHorairesEdt from '@/wikaz-konnect/gdt/parametrages/gestion-horaires/VxGestionHorairesEdt'
import VxConfigurationDocutheque from '@/wikaz-konnect/gdt/parametrages/configuration-docutheque/VxConfigurationDocutheque'
import VxConfigurationLogements from '@/wikaz-konnect/gdt/parametrages/configuration-logements/VxConfigurationLogements'
import VxSynthesesGdtWk from '@/wikaz-konnect/gdt/syntheses/VxSynthesesGdtWk'
import VxStatistiqueJoursEdt from '@/wikaz-konnect/gdt/syntheses/statistique-jours/VxStatistiqueJoursEdt'
import VxStatistiquePLanificationEdt from '@/wikaz-konnect/gdt/syntheses/statistique-planification/VxStatistiquePLanificationEdt'
import VxStatistiqueHebdoEdt from '@/wikaz-konnect/gdt/syntheses/statistique-hebdo/VxStatistiqueHebdoEdt'

import VxPlanningEdt from '@/wikaz-konnect/gdt/planning/VxPlanningEdt'
import VxCongesGdt from '@/wikaz-konnect/gdt/conges/VxCongesGdt'
import VxMonTableauDeBordGdt from '@/wikaz-konnect/gdt/tableau-de-bord/VxMonTableauDeBordGdt'
import VxMonPlanningEdt from '@/wikaz-konnect/gdt/mon-planning/VxMonPlanningEdt'
import VxGestionCongesEdt from '@/wikaz-konnect/gdt/gestion-conges-edt/VxGestionCongesEdt'
import VxBourseDesEchangesEdt from '@/wikaz-konnect/gdt/bourse-des-echanges/VxBourseDesEchangesEdt'
import VxGestionAstreintesPwaGdt from '@/wikaz-konnect/gdt/gestion-astreintes/VxGestionAstreintesPwaGdt'
import VxGestionResidents from '@/wikaz-konnect/gdt/gestion-residents/VxGestionResidents'
import VxGestionLogements from '@/wikaz-konnect/gdt/gestion-logements/VxGestionLogements'
import VxSystemeDocumentaire from '@/wikaz-konnect/gdt/systeme-documentaire/VxSystemeDocumentaire'
import CxAllPlanningJournee from '@/wikaz-konnect/gdt/mon-planning/CxAllPlanningJournee'
import VxStatistiqueMensuelles from '@/wikaz-konnect/gdt/syntheses/statistique-mensuelles/VxStatistiqueMensuelles.vue'
import VxFichesTransmission from '@/wikaz-konnect/gdt/fiches-transmission/VxFichesTransmission.vue'
import VxGestionPrestations from '@/wikaz-konnect/gdt/parametrages/gestion-prestations/VxGestionPrestations.vue'
import VxGestionClientsSap from '@/wikaz-konnect/gdt/gestion-clients-sap/VxGestionClientsSap.vue'
import VxPlanningsSap from '@/wikaz-konnect/gdt/plannings-sap/VxPlanningsSap.vue'

const Imports = []

Imports.push(
    {
        name: 'vx-configurations-gdt',
        component: VxConfigurationsGdt,
    },
    {
        name: 'vx-gestion-metiers-edt',
        component: VxGestionMetiersEdt,
    },
    {
        name: 'vx-gestion-postes-edt',
        component: VxGestionPostesEdt,
    },
    {
        name: 'vx-gestion-horaires-edt',
        component: VxGestionHorairesEdt,
    },
    {
        name: 'vx-configuration-docutheque',
        component: VxConfigurationDocutheque,
    },
    {
        name: 'vx-configuration-logements',
        component: VxConfigurationLogements,
    },
    {
        name: 'vx-syntheses-gdt',
        component: VxSynthesesGdtWk,
    },
    {
        name: 'vx-statistique-jours-edt',
        component: VxStatistiqueJoursEdt,
    },
    {
        name: 'vx-statistique-planification-edt',
        component: VxStatistiquePLanificationEdt,
    },
    {
        name: 'vx-statistique-hebdo-edt',
        component: VxStatistiqueHebdoEdt,
    },
    {
        name: 'vx-statistique-mensuelles',
        component: VxStatistiqueMensuelles,
    },
    {
        name: 'vx-planning-edt',
        component: VxPlanningEdt,
    },
    {
        name: 'vx-conges-gdt',
        component: VxCongesGdt,
    },
    {
        name: 'vx-mon-tableau-de-bord-gdt',
        component: VxMonTableauDeBordGdt,
    },
    {
        name: 'vx-mon-planning-edt',
        component: VxMonPlanningEdt,
    },
    {
        name: 'vx-gestion-conges-edt',
        component: VxGestionCongesEdt,
    },
    {
        name: 'vx-bourse-des-echanges-edt',
        component: VxBourseDesEchangesEdt,
    },
    {
        name: 'vx-gestion-astreintes-pwa-gdt',
        component: VxGestionAstreintesPwaGdt,
    },
    {
        name: 'vx-gestion-residents',
        component: VxGestionResidents,
    },
    {
        name: 'vx-gestion-logements',
        component: VxGestionLogements,
    },
    {
        name: 'vx-systeme-documentaire',
        component: VxSystemeDocumentaire,
    },

    {
        name: 'cx-all-planning-journee',
        component: CxAllPlanningJournee,
    },
    {
        name: 'vx-fiches-transmission',
        component: VxFichesTransmission,
    },
    {
        name: 'vx-gestion-prestations',
        component: VxGestionPrestations,
    },
    {
        name: 'vx-gestion-clients-sap',
        component: VxGestionClientsSap,
    },
    {
        name: 'vx-plannings-sap',
        component: VxPlanningsSap,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports