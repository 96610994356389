<template>
  <div style="width: 100%;display: flex;justify-content: center">
    <div>
      <template v-for="(item,index) in datasPeriodes">
        <v-tooltip
          :key="item.index_pages"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn
              :key="item.index_pages"
              :x-small="xSmall"
              :color="( indexCalendrier === index ) ? 'primary' : ''"
              style="font-size: 11px"
              v-on="on"
              @click="f_change_periode(item.index_page)"
            >
              <span>S<span v-if="index>0">+{{ index }}</span></span>
            </v-btn>
          </template>
          <span> Semaine du Lundi {{ getDateFormatted(item.date_debut) }} au Dimanche {{ getDateFormatted(item.date_fin) }}</span>
        </v-tooltip>
      </template>
    </div>
  </div>
</template>

<script>
export default {
    name: 'FxPeriodeSemaineBloc',
    props: {
        xSmall: {
            type: Boolean,
            default: false,
        },
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datasPeriodes: null,
            indexCalendrier: 0,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    mounted () {
        this.chargerPeriodes()
    },
    methods: {
        chargerPeriodes () {
            this.busy = true
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datasPeriodes = data
                this.busy = false
                this.$emit('selection', this.indexCalendrier)
            })
        },
        actualiser () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.datasPeriodes = data
            })
        },
        getDateFormatted (valeur) {
            return this.$wikaz.outils.dates.getDate(valeur, 'DD/MM/YYYY')
        },
        f_change_periode (index_page) {
            this.indexCalendrier = index_page
            this.$emit('selection', this.indexCalendrier)
        },
        getSelectedValue () {
            return this.indexCalendrier
        },
    },
}
</script>

<style scoped>

</style>