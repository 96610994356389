<template>
  <v-card
    class="cx-carte-tournees-technicien-container"
    :style="{width: width,height: height,color: 'black'}"
  >
    <div
      v-if="urlRessourceEndPoint"
      class="cx-carte-tournees-technicien-maps-container"
    >
      <cx-carte-tournees
        :id="'map'"
        ref="realTour"
        title="Parcours réel"
        :techniciens="techniciens"
        :not-assigned-points="notAssignedPoints"
        @set-steps="setSteps"
      />
      <div class="cx-carte-tournees-technicien-maps-col2">
        <fx-titre
          titre="Informations"
          height="22px"
        />
        <div
          id="informations"
          style="overflow: auto;height: 350px"
        >
          <div
            v-for="(technicien, indexTechnicien) in techniciens"
            :key="indexTechnicien"
            style="width: 100%;overflow: auto"
          >
            <cx-information-tournees
              v-for="(key, index) in Object.keys(technicien.informations)"
              :key="index"
              :title="technicien.informations[key].title"
              :items="technicien.informations[key].items"
              :computed-total-distance="technicien.informations[key].computedTotalDistance"
              :color="colors[indexTechnicien].rgba"
              @zoom="zoom"
            />
          </div>
          <cx-information-tournees
            :title="notAssignedInformations.title"
            :items="notAssignedInformations.items"
            :computed-total-distance="notAssignedInformations.computedTotalDistance"
            @zoom="zoom"
          />
        </div>
        <cx-carte-tournees
          v-if="displayOptimizedTour"
          :id="'mapopti'"
          ref="optimizedTour"
          :optimized="true"
          title="Parcour Optimisé"
          :techniciens="techniciens"
          @set-steps="setSteps"
        />
      </div>
    </div>
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        small
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import CxCarteTournees from './CxCarteTournees.vue'
import CxInformationTournees from './CxInformationTournees.vue'

export default {
    name: 'VxCarteTournees',
    components: {
        CxCarteTournees,
        CxInformationTournees,
    },
    props: { url: { default: null } },
    data () {
        return {
            urlRessourceEndPoint: this.url,
            techniciens: [],
            colors: [
                {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                },
            ],
            ready: false,
            notAssignedPoints: [],
            notAssignedInformations: {
                items: [],
                title: 'Interventions non attribuées',
                computedTotalDistance: null,
            },
            displayOptimizedTour: true,
            width: '1250px',
            height: '800px',
        }
    },
    mounted () {
        let urlReady = setInterval(() => {
            if (this.urlRessourceEndPoint) {
                clearInterval(urlReady)
                this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                    data.techniciens.forEach((technicien) => {
                        this.techniciens.push({
                            waypoints: technicien.waypoints,
                            startingPoint: technicien.startingPoint,
                            informations: {
                                real: {
                                    items: [],
                                    title: (data.techniciens.length === 1) ? 'Parcours réel' : '',
                                    computedTotalDistance: null,
                                },
                                optimized: {
                                    items: [],
                                    title: 'Parcours optimisé',
                                    computedTotalDistance: null,
                                },
                            },
                        })
                    })
                    this.displayOptimizedTour = (data.techniciens.length === 1)
                    this.notAssignedPoints = data.notAssignedPoints
                })
            }
        })
    },
    methods: {
        setSteps (event) {
            let res = event.res
            let indexTechnicien = event.indexTechnicien
            let optimized = event.optimized
            let routes = res.routes[0]
            let items = []
            let waypoints = this.techniciens[indexTechnicien].waypoints
            for (let i = 1; i < routes.legs.length; i ++) {
                let icon = ''
                let contenu = ''
                if (this.optimized) {
                    if (this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].etat_inter === '2') {
                        icon = {
                            url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_' + this.colors[indexTechnicien].libelle + (i - 1) + '.png',
                            scaledSize: {
                                height: 40,
                                width: 24,
                            },
                        }
                        contenu = this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].contenu
                    } else {
                        icon = {
                            url: this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].icon,
                            scaledSize: {
                                height: 40,
                                width: 40,
                            },
                        }
                        contenu = this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].contenu
                    }
                } else {
                    if (waypoints[routes.waypoint_order[i - 1]].etat_inter === '2' || (this.techniciens.length > 1 && i > 1)) {
                        icon = {
                            url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_' + this.colors[indexTechnicien].libelle + (i - 1) + '.png',
                            scaledSize: {
                                height: 40,
                                width: 24,
                            },
                        }
                        contenu = waypoints[routes.waypoint_order[i - 1]].contenu
                    } else {
                        icon = {
                            url: waypoints[routes.waypoint_order[i - 1]].icon,
                            scaledSize: {
                                height: 40,
                                width: 40,
                            },
                        }
                        contenu = waypoints[routes.waypoint_order[i - 1]].contenu
                    }
                }
                let km = i > 0 ? routes.legs[i - 1].distance.value / 1000 + ' kms - ' : ''
                let duree = i > 0 ? this.secondsToHms(routes.legs[i - 1].duration.value) : ''
                contenu += '<span>' + km + ' - ' + duree

                items.push({
                    content: contenu,
                    icon: icon.url,
                    lat: routes.legs[i].start_location.lat(),
                    lng: routes.legs[i].start_location.lng(),
                })
            }
            items.push({
                content: waypoints[0].contenu,
                icon: waypoints[0].icon,
                lat: routes.legs[0].start_location.lat(),
                lng: routes.legs[0].start_location.lng(),
            })
            if (optimized) {
                this.techniciens[indexTechnicien].informations.optimized.items = items
                this.techniciens[indexTechnicien].informations.optimized.computedTotalDistance = this.computeTotalDistance(res)
            } else {
                this.techniciens[indexTechnicien].informations.real.items = items
                this.techniciens[indexTechnicien].informations.real.computedTotalDistance = this.computeTotalDistance(res)
            }
            if ((indexTechnicien + 1) === this.techniciens.length) {
                this.setNotAssignedSteps()
            }
            this.ready = true
        },
        setNotAssignedSteps () {
            if (this.notAssignedPoints.length > 0) {
                let notAssignedItems = []
                for (let i = 0; i < this.notAssignedPoints.length; i ++) {
                    notAssignedItems.push({
                        lat: parseFloat(this.notAssignedPoints[i].latitude),
                        lng: parseFloat(this.notAssignedPoints[i].longitude),
                        icon: this.notAssignedPoints[i].icon,
                        content: this.notAssignedPoints[i].contenu,
                    })
                }
                this.notAssignedInformations.items = notAssignedItems
            }
        },
        secondsToHms (d) {
            d = Number(d)
            let h = Math.floor(d / 3600)
            let m = Math.floor(d % 3600 / 60)
            let s = Math.floor(d % 3600 % 60)
            return ((h > 0 ? h + 'h ' + (m < 10 ? '0' : '') : '') + m + 'm ' + (s < 10 ? '0' : '') + s + 's ')
        },
        zoom (event) {
            if (this.$refs.realTour) {
                this.$refs.realTour.map.panTo(event)
                this.$refs.realTour.map.setZoom(17)
            }
        },
        computeTotalDistance (result) {
            let total = 0
            let time = 0
            let myroute = result.routes[0]
            for (let i = 0; i < myroute.legs.length; i ++) {
                total += myroute.legs[i].distance.value
                time += myroute.legs[i].duration.value
            }
            total = total / 1000
            return '( ' + total + ' kms ) ( ' + this.secondsToHms(time) + ' )'
        },
    },
}
</script>
<style scoped>

    .cx-carte-tournees-technicien-container {
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 25px;
        grid-row-gap: 10px;
    }

    .cx-carte-tournees-technicien-maps-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
    }

    .cx-carte-tournees-technicien-maps-col1 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-row-gap: 10px;
    }

    .cx-carte-tournees-technicien-maps-col2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 25px 1fr 1fr;
        overflow: auto;
    }

    #right-side {
        box-shadow:2px 2px 6px 2px #555;
        height: 100%;
        overflow-y: auto;
    }
</style>