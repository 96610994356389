<template>
  <v-card
    v-if="urlRessourceEndPointIndex"
    class="cx-affectation-interlocuteur-container"
    style="padding: 10px"
    :height="height"
    :width="width"
  >
    <fx-list-transfert
      :titre="titre"
      :url-ressouce-index="urlRessourceEndPointIndex"
      :url-ressouce-update="urlRessourceEndPointUpdate"
    />
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'WikazDialogTransfertList',
    extends: CxBase,
    data () {
        return {
            urlRessourceEndPointIndex: '',
            urlRessourceEndPointUpdate: '',
            titre: '',
            height: '500',
            width: '600',
        }
    },
}
</script>

<style scoped>
    .cx-affectation-interlocuteur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 5px;
        overflow: auto;
    }
</style>