<template>
  <div style="display: flex">
    <v-menu
      ref="menu1"
      v-model="menu1"
      :disabled="editable===false"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          style="margin: 0;padding: 0;height: 32px"
          :disabled="editable===false"
          prepend-icon="event"
          @blur="date = parseDate(dateFormatted)"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        first-day-of-week="1"
        locale="fr-fr"
        @input="menu1 = false"
        v-on="listeners"
      />
    </v-menu>
    <v-menu
      ref="menu"
      v-model="menu3"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="time"
          style="margin: 0;padding: 0;height: 32px"
          :disabled="editable===false"
          prepend-icon="access_time"
          readonly
          v-on="on"
        />
      </template>
      <v-time-picker
        v-if="menu3"
        v-model="time"
        no-title
        format="24hr"
        @click:minute="$refs.menu.save(time)"
        v-on="listeners"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
    name: 'FxDateHeureField',
    props: {
        value: {
            type: String,
            default: null,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        format: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        formatOut: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        editable: {
            default: true,
            type: Boolean,
        },
    },
    data () {
        return {
            valeur: (this.value !== '' && this.value !== null) ? new Date(this.value.split(' ')[0]) : new Date(),
            date: (this.value !== '' && this.value !== null) ? new Date(this.value.split(' ')[0]).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10), // new Date().toISOString().substr(0, 10),
            dateFormatted: this.formatDate((this.value !== '' && this.value !== null) ? new Date(this.value.split(' ')[0]).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)), // this.formatDate(new Date().toISOString().substr(0, 10)),
            menu1: false,
            menu2: false,
            time: (this.value !== '' && this.value !== null && this.value.split(' ')[1]) ? this.value.split(' ')[1] : this.$wikaz.getInstance().outils.dates.getTime(),
            menu3: false,
            modal2: false,
        }
    },
    computed: {
        listeners () {
            let self = this
            Object.keys(this.$listeners).forEach((item) => {
                self.$listeners[item] = (event) => {
                    if (item === 'input') {
                        self.$emit(item, this.$wikaz.getInstance().outils.dates.getDate(this.date, this.formatOut) + ' ' + this.time)
                    } else {
                        self.$emit(item, event)
                    }
                }
            })
            return { ...self.$listeners }
        },
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    },
    mounted () {
        this.$emit('input', this.$wikaz.getInstance().outils.dates.getDate(this.date, this.formatOut) + ' ' + this.time)
    },
    methods: {
        validate () {
            return true
        },
        getError () {
            return null
        },
        formatDate (date) {
            if (! date) {
                return null
            }

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (! date) {
                return null
            }

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
}
</script>