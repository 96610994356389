<template>
  <!--    <div class="cx-gestion-recepteur-container">
        <div class="cx-gestion-recepteur-container-header">
            <v-btn :color="(selectedFiltre===1) ? 'primary' : ''" @click="f_change(1)">Mes Donneurs d'Ordres</v-btn>
            <v-badge v-if="nbDemandeAttenteMonAccord>0" :content="nbDemandeAttenteMonAccord" overlap color="success" >
                <v-btn :color="(selectedFiltre===2) ? 'primary' : ''" @click="f_change(2)">Invitations en attente de mon accord</v-btn>
            </v-badge>
            <v-btn v-else :color="(selectedFiltre===2) ? 'primary' : ''" @click="f_change(2)">Invitations en attente de mon accord</v-btn>
            <v-btn :color="(selectedFiltre===3) ? 'primary' : ''" @click="f_change(3)">Invitations en attente de son accord</v-btn>
        </div>
        <cx-liste-emetteur :key="indexKey" ref="objDatagrid" :url-ressource-end-point="urlRessourceEndPoint + '?filtre=' + selectedFiltre"
                           @ajouter="f_ajouter"
                           @add_accord="f_add_accord"
                           @del_accord="f_del_accord"
                           @affectation_prestation="f_affectation_prestation"
                           @supprimer="f_supprimer"></cx-liste-emetteur>
        <v-dialog v-model="showDialog" width="600px">
            <cx-recherche-compte-wikaz v-if="showDialog" :url-ressource-end-point="urlRessourceEndPoint"
                                       titre="Rechercher un donneur d'ordre"
                                       @close="f_close_dialog"
                                       @submit="f_close_dialog"
            ></cx-recherche-compte-wikaz>
        </v-dialog>
    </div>-->
  <div class="cx-gestion-recepteur-container">
    <fx-datagrid-vuetify
      ref="obj_liste"
      :url-ressource-end-point="urlRessourceEndPoint"
      @ajouter="f_ajouter"
      @supprimer_lien="f_supprimer"
      @donner_accord="f_add_accord"
      @retirer_accord="f_del_accord"
      @invite_responsable="f_invitation"
    />
    <v-dialog
      v-model="showDialog"
      width="600px"
    >
      <cx-recherche-compte-wikaz
        v-if="showDialog"
        :url-ressource-end-point="urlRessourceEndPoint"
        :sens="1"
        @close="f_close_dialog"
        @submit="f_close_dialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showDialogInvitationMail"
      width="500px"
    >
      <v-card style="padding: 10px">
        <v-card-title>Envoyer une invitation</v-card-title>
        <v-card-text>
          Email
          <fx-text-input v-model="adresse_email" />
        </v-card-text>
        <v-card-text style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="success"
            @click="f_send_mail_invitation"
          >
            Envoyer
          </v-btn>
          <v-btn
            color="error"
            @click="adresse_email= null;showDialogInvitationMail=false"
          >
            Annuler
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CxAffectationTechniciensPrestations
from '@/wikaz-konnect/gdi/parametrages/gestion-codes-prestations/CxAffectationTechniciensPrestations'
import CxRechercheCompteWikaz from '@/wikaz-konnect/gdi/gestion-recepteurs/CxRechercheCompteWikaz'

export default {
    name: 'VxGestionEmetteurs',
    components: { CxRechercheCompteWikaz },
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-emetteurs/emetteurs',
            selectedFiltre: 1,
            indexKey: 0,
            nbDemandeAttenteMonAccord: 0,
            showDialog: false,
            showDialogInvitationMail: false,
            adresse_email: '',
            ruleEmail: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),

        }
    },
    methods: {
        f_actualiser () {
            this.$refs.obj_liste.chargerData()
        },
        f_change (index) {
            this.selectedFiltre = index
            this.indexKey ++
        },
        f_ajouter () {
            this.showDialog = true
        },
        f_add_accord (item) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.link_id + '/on', item).then(() => {
                this.f_actualiser()
            })
        },
        f_del_accord (item) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.link_id + '/off', item).then(() => {
                this.f_actualiser()
            })
        },
        f_close_dialog () {
            this.showDialog = false
            this.f_actualiser()
        },
        f_supprimer (item) {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer ce lien', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.link_id).then(() => {
                    this.f_actualiser()
                })
            })
        },
        f_affectation_prestation (item) {
            this.$wikaz.popups.show(
                CxAffectationTechniciensPrestations,
                {
                    datas: {
                        title: 'Affectation des prestations',
                        urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.link_id + '/souscription-prestations',
                        isEditable: true,
                        isPopup: true,
                    },
                },
                () => {
                    this.f_actualiser()
                },
                () => {
                    this.f_actualiser()
                },
            )
        },
        f_invitation () {
            this.showDialogInvitationMail = true
        },
        f_send_mail_invitation () {
            if (this.ruleEmail.test(this.adresse_email)) {
                this.$wikaz.request.post(this.urlRessourceEndPoint + '/0/email', { email: this.adresse_email }).then(() => {
                    this.$emit('submit')
                })
                this.showDialogInvitationMail = false
            } else {
                this.$wikaz.outils.notification.showError('Veuillez saisir un email valide.')
            }
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-gestion-recepteur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-gestion-recepteur-container-header {
        display: flex;
        gap: 10px;
        padding-left: 10px;
        flex-flow: row wrap;
    }
</style>