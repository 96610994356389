<template>
  <div style="display: flex;align-items: center">
    <div style="width: 80%">
      Gestion des fichiers
    </div>
    <fx-icon
      style="margin-right: 10px"
      icon="settings"
      :size="24"
      color="blue"
      @click="f_editer"
    />
  </div>
</template>
<script>

import CxExplorateurFichiersSite from './CxExplorateurFichiersSite'

export default {
    name: 'CxPanelFichiers',
    props: {
        value: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_editer () {
            this.$wikaz.popups.show(
                CxExplorateurFichiersSite,
                {
                    datas: {
                        titre: 'Bibliothèque Fichiers',
                        urlRessourceEndPoint: 'api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/fichiers',
                        isEditable: true,
                        isPopup: true,
                    },
                },
                this.f_result_update,
            )
        },
        f_result_update () {
            /*this.$wikaz.request.put('api/wikaz/modules/editeur-de-site/sites/' + this.value.id + '/header/0',data).then(() => {
                    this.$emit('refresh')
                })*/
        },
    },
}
</script>

<style scoped>

</style>