<template>
  <div />
</template>
<script>
export default {
    name: 'CxBase',
    props: {
        dataIn: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            datas: this.dataIn,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    mounted () {
        if(this.isMobile) {
            this.datas = this.$store.getters.getViewPwa().config.datas
        }
    },
    methods: {
        f_precedent (composant, label) {
            this.$store.commit('SET_VIEW_PWA', {
                component: composant,
                module_libelle: label,
            })
        },
    },
}
</script>