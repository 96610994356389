<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-avatar>
      <fx-icon
        :size="30"
        icon="person"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.client_nom + ' ' + (item.client_prenom ? item.client_prenom : '') "
      />
    </v-list-item-content>
    <v-menu
      v-if="this.$attrs.selected && ( item.client_telephone )"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          tooltip="Menu des actions"
          v-on="on"
        />
      </template>
      <v-list>
        <v-list-item
          v-if="this.$attrs.selected && item.client_telephone"
          @click="f_telephone(item.client_telephone)"
        >
          <v-list-item-avatar>
            <v-icon color="primary">
              phone
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.client_telephone }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemGestionClientsPrestataires',
    extends: FxItemTemplate,
    methods: {
        f_telephone (valeur) {
            window.location = 'tel:' + valeur
        },
    },
}
</script>