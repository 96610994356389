<template>
  <fx-traitement-template
    :url-ressource-end-point="urlRessourceEndPoint"
    :composant-filtre-element="composantFiltreElemen"
    :composant-traitement-element="(isMobile) ? composantTraitementElementMobile : composantTraitementElementWeb"
    titre-liste="Documents"
    :item-renderer="itemRenderer"
    width="100%"
    width-list="400px"
  />
</template>

<script>
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import IxDocuments from '@/wikaz-konnect/gdt/systeme-documentaire/IxDocuments'
import CxTraitementSystemeDocumentaire
from '@/wikaz-konnect/gdt/systeme-documentaire/CxTraitementSystemeDocumentaire'
import CxTraitementSystemeDocumentairePwa
from '@/wikaz-konnect/gdt/systeme-documentaire/CxTraitementSystemeDocumentairePwa'
import CxFiltreDocuments from '@/wikaz-konnect/gdt/systeme-documentaire/CxFiltreDocuments'

export default {
    name: 'VxSystemeDocumentaire',
    extends: VxBase,
    data () {
        return {
            urlRessourceEndPoint: 'api/edt/modules/systeme-documentaire/documents',
            composantFiltreElemen: this.$wikaz.outils.importation.elementByComponent(CxFiltreDocuments),
            composantTraitementElementWeb: this.$wikaz.outils.importation.elementByComponent(CxTraitementSystemeDocumentaire),
            composantTraitementElementMobile: this.$wikaz.outils.importation.elementByComponent(CxTraitementSystemeDocumentairePwa),
            itemRenderer: IxDocuments,
        }
    },
}
</script>

<style scoped>

</style>