<template>
  <div
    v-if="datas"
    class="cx-infos-site-container"
  >
    <div style="font-weight: bold">
      Paramétrage du site
    </div>
    <fx-champ
      label-width="40px"
      label="Id"
      :text="datas.id"
    />
    <fx-champ
      label-width="40px"
      label="Nom"
      :text="datas.name"
    />
    <fx-champ
      label-width="40px"
      label="Url"
      :text="datas.url_aws.substr(0,20)+'...'"
    >
      <fx-icon
        icon="visibility"
        :tooltip="datas.url_aws"
        @click="open_site(datas.url_aws)"
      />
    </fx-champ>
    <div style="display: flex;justify-content: space-between">
      <div>Favicon</div>
      <fx-button-upload
        :url="$icsilab.urlApi + 'fichiers'"
        filetype=".ico"
      />
    </div>
    <cx-panel-images v-model="datas" />
    <cx-panel-fichiers v-model="datas" />
    <cx-panel-site
      v-model="datas"
      @refresh="chargerData"
    />
    <cx-panel-header
      v-model="datas"
      @refresh="chargerData"
    />
    <cx-panel-footer
      v-model="datas"
      @refresh="chargerData"
    />
    <cx-panel-pages
      v-model="datas"
      @refresh="chargerData"
      @onSelect="f_select_page"
    />
  </div>
  <cx-waiting v-else />
</template>

<script>
import CxPanelHeader from './elements-editeur/infos-site/CxPanelHeader'
import CxPanelFooter from './elements-editeur/infos-site/CxPanelFooter'
import CxPanelPages from './elements-editeur/infos-site/CxPanelPages'
import CxPanelSite from './elements-editeur/infos-site/CxPanelSite'
import CxPanelImages from './elements-editeur/infos-site/CxPanelImages'
import CxPanelFichiers from './elements-editeur/infos-site/CxPanelFichiers'
import FxButtonUpload from '@/wikaz-core/ui/composants/fichiers/FxButtonUpload'
export default {
    name: 'CxInfosSite',
    components: {
        FxButtonUpload,
        CxPanelFichiers,
        CxPanelImages,
        CxPanelSite,
        CxPanelPages,
        CxPanelFooter,
        CxPanelHeader,
    },
    props: {
        siteId: {
            type: Number,
            default: 0,
        },
    },
    data () {
        return { datas: null }
    },
    mounted () {
        this.chargerData()
    },
    methods: {
        chargerData () {
            this.datas = null
            this.$icsilab.dataSite = null
            if (this.siteId && this.siteId > 0) {
                this.$wikaz.request.get('api/wikaz/modules/editeur-de-site/sites/' + this.siteId).then((data) => {
                    this.datas = data
                    this.$icsilab.site = data.name
                    this.$icsilab.siteId = data.id
                    this.$icsilab.dataSite = data
                    this.$icsilab.baseUrl = this.$wikaz.request.url
                    this.$icsilab.rootUrl = this.$icsilab.baseUrl + '/sites/' + this.$icsilab.site + '/'
                    this.$icsilab.urlApi = '/api/icsilab/sites/' + this.$icsilab.site + '/'
                    this.$icsilab.rootApi = this.$icsilab.baseUrl + this.$icsilab.urlApi
                })
            }
        },
        f_select_page (item) {
            this.$emit('selectedPage', item.pages_id)
        },
        open_site (url) {
            window.open(url, '_blank')
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-infos-site-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto 1fr;
        grid-row-gap: 5px;
        overflow: auto;
    }
</style>