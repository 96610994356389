<template>
  <div
    v-if="dataProvider.etat < 1"
    class="case"
    :class="{current: dataProvider.etat === 0}"
  >
    <div class="case-top" />
    <div class="case-content">
      <span>{{ dataProvider.mois_libelle }}</span>
    </div>
    <div class="case-bottom">
      <span v-if="dataProvider.etat < 0">Terminé</span>
      <span v-else>En Cours</span>
      <div class="btns">
        <v-btn
          class="mx-1"
          fab
          x-small
          color="primary"
        >
          <v-icon @click="show()">
            search
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          x-small
          color="primary"
        >
          <v-icon @click="download()">
            get_app
          </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
  <div
    v-else
    class="case disabled"
  >
    <div class="case-top" />
    <div class="case-content">
      <span>{{ dataProvider.mois_libelle }}</span>
    </div>
    <div class="case-bottom" />
  </div>
</template>
<script>
export default {
    name: 'CxItemMois',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        show () {
            this.$wikaz.request.get('api/entreprises/modules/releve-activite-pwa/releve?annee=' + this.dataProvider.annee + '&mois=' + this.dataProvider.mois)
        },
        download () {
            /*this.$wikaz.request.get('api/entreprises/modules/releve-activite-pwa/releve?annee=' + this.dataProvider.annee + '&mois=' + this.dataProvider.mois,
                    'releve_' + this.dataProvider.mois_libelle + '_' + this.dataProvider.annee + '.pdf')*/
        },
    },
}
</script>
<style scoped lang="scss">
    .case{
        color: #fff;
        display:flex;
        margin: 6px 2px;
        border-radius: 4px;
        min-width:162px;
        background-color: rgb(103, 184, 91);
        border: 1px solid rgba(233, 248, 255, 0.28);
        box-shadow: 1px 2px 4px 0 rgba(132, 152, 163, 0.39);
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        text-align: center;
        min-height:150px;
        &.disabled{
             background-color: rgb(127, 125, 132);
        }
        &.current{
             background-color: rgba(15, 123, 199, 0.73);
        }
        &:hover{
         // transform: scale(1.02);
        }
        >*{
            display:flex;
            width:100%;
        }
        .case-top{
            height:25px;
            text-align: left;
        }
        .case-content{
            height:30%;
            text-align: center;
            align-items: center;
            justify-content: center;
            >span{
                font-size:1.8rem;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
        .case-bottom{
            height:45px;
            padding: 8px;
            display:flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            .ivu-btn{
                margin:6px;
            }
        }
        &:not(.disabled){
            .case-bottom{
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }
</style>