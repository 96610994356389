import notification from '@/wikaz-core/outils/notification'

let clipboard = function () {
    this.copyToClipboard = function (valeur) {
        let element = document.createElement('textarea')
        element.value = valeur
        document.body.appendChild(element)
        element.select()
        document.execCommand('copy')
        element.remove();
        (new notification()).showInfo('Contenu copié dans le presse-papier')
    }
}
export default clipboard