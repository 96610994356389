<template>
  <div
    id="cxmodule"
    style="width: 100%;height: 100%;display: flex;flex-direction: row"
  >
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      width="370px"
    >
      <v-list
        dense
        height="60px"
      >
        <fx-item-renderer-menu
          :icon="mini ? 'keyboard_arrow_right' : 'keyboard_arrow_left'"
          :mini="mini"
          libelle="Sous Menu"
          @click="mini = !mini"
        />
      </v-list>
      <v-divider />
      <v-list
        v-if="modules.length > 0"
        dense
        height="auto"
      >
        <template v-for="(item, index) in modules">
          <fx-item-renderer-menu
            :key="index"
            :icon="item.icon"
            :libelle="item.module_libelle"
            :mini="mini"
            :class="page === item.route ? 'active' : ''"
            @click="onChangePage(item.route)"
          />
        </template>
      </v-list>
      <cx-waiting
        v-else
        :size="50"
      />
    </v-navigation-drawer>
    <keep-alive>
      <component
        :is="page"
        v-if="ready"
        :key="keyChange"
        class="contentDroit"
      />
    </keep-alive>
  </div>
</template>
<script>

export default {
    name: 'CxMultiModules',
    props: {
        module_id: {
            default: null,
            required: true,
        },
    },
    data () {
        return {
            page: null,
            modules: [],
            ready: false,
            drawer: true,
            mini: true,
            keyChange: 0,
        }
    },
    watch: {
        modules: function (newVal) {
            if (Array.isArray(newVal)) {
                if (newVal.length > 0) {
                    for (let i = 0; i < newVal.length; i ++) {
                        // if (this.$store.getters.isModuleAllowed(newVal[i].module_id)) {
                        this.page = newVal[i].route
                        this.ready = true
                        break
                        // }
                    }
                }
            }
        },
    },
    mounted () {
        this.getModules()
    },
    methods: {
        getModules () {
            if (this.module_id) {
                this.$wikaz.request.get('api/wikaz/structure/menu/' + this.module_id).then((data) => {
                    this.modules = JSON.parse(JSON.stringify(data))
                })
            }
        },
        onChangePage (route) {
            this.page = route
            this.keyChange ++
        },
    },
}
</script>
<style lang="scss" scoped>
    $border: 0px;
    .contentPrincipal{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        border: $border solid
    }
    .contentGauche{
        width: 112px;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        border: $border solid
    }
    .contentGauche{
        right: 0px;
        border: $border solid
    }
    .myBtn{
        height:auto;
        width:97px;
        font-size:0.8rem;
        span{
            white-space:normal;
        }
    }

    .active {
        background-color: #78bd92;
        border-bottom-left-radius: 15px 15px;
        border-top-left-radius: 15px 15px;
        // opacity: 0.7;
    }
</style>