<template>
  <v-list-item @click="f_emit_selection">
    <v-chip
      style="width: 50px;margin-right: 10px;display: flex;justify-content: center"
      :color="item.nb_residents > 0 ? 'green' : 'red'"
    >
      {{ item.logement_numero }}
    </v-chip>
    <v-list-item-content>
      <v-list-item-title
        v-if="item.logement_libelle"
        class="caption"
        style="font-weight: bold"
        v-html="item.logement_libelle"
      />
      <v-list-item-subtitle
        class="caption"
        style="font-weight: bold"
        v-html="item.logements_type_libelle"
      />
    </v-list-item-content>
    <div style="display: flex;width: 100px">
      <fx-icon
        :size="30"
        icon="stairs"
        tooltip="Etage"
      />
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="item.libelle_etage"
      />
    </div>
    <fx-icon
      :size="30"
      icon="person"
      tooltip="Résidents"
      :badge="item.nb_residents"
    />
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxLogement',
    extends: FxItemTemplate,
    methods: {
        f_telephone (valeur) {
            window.location = 'tel:' + valeur
        },
        getEtage (etage) {
            if (etage === 0) {
                return 'RDC'
            }
            return 'Etage ' + etage

        },
    },
}
</script>