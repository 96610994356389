<template>
  <v-list-item
    dense
    style="overflow: visible"
    @click="f_emit_selection"
  >
    <v-list-item-content>
      <v-list-item-title style="font-weight: bold">
        {{ item.interlocuteur_nom }} {{ item.interlocuteur_prenom }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="item.interlocuteur_fonction">
        {{ item.interlocuteur_fonction }}
      </v-list-item-subtitle>
      <v-list-item-content
        v-if="item.interlocuteur_observations"
        style="font-size: 13px;padding: 0"
      >
        {{ item.interlocuteur_observations }}
      </v-list-item-content>
      <v-list-item-subtitle v-if="item.interlocuteur_email">
        {{ item.interlocuteur_email }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.interlocuteur_telephone1">
        {{ item.interlocuteur_telephone1 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.interlocuteur_telephone2">
        {{ item.interlocuteur_telephone2 }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.interlocuteur_fax">
        {{ item.interlocuteur_fax }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.interlocuteur_date_naissance">
        {{ item.interlocuteur_date_naissance }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-menu
      :key="index"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          v-on="on"
        />
      </template>
      <v-list ref="objLst">
        <v-list-item @click="f_modifier_interlocuteur()">
          <v-list-item-avatar>
            <v-icon color="primary">
              create
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Modifier</v-list-item-title>
        </v-list-item>
        <v-list-item @click="f_retirer_interlocuteur(item.interlocuteur_id)">
          <v-list-item-avatar>
            <v-icon color="red">
              cancel
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Retirer</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="item.interlocuteur_telephone1"
          @click="$wikaz.api.navigateur.tel(item.interlocuteur_telephone1)"
        >
          <v-list-item-avatar><v-icon>phone</v-icon></v-list-item-avatar>
          <v-list-item-title>{{ item.interlocuteur_telephone1 }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="item.interlocuteur_telephone2"
          @click="$wikaz.api.navigateur.tel(item.interlocuteur_telephone2)"
        >
          <v-list-item-avatar><v-icon>phone</v-icon></v-list-item-avatar>
          <v-list-item-title>{{ item.interlocuteur_telephone2 }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="item.interlocuteur_email"
          @click="$wikaz.api.navigateur.mailto(item.interlocuteur_email)"
        >
          <v-list-item-avatar><v-icon>email</v-icon></v-list-item-avatar>
          <v-list-item-title>{{ item.interlocuteur_email }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemInterlocuteurContact',
    extends: FxItemTemplate,
    data () {
        return {
            isMobile: window.Vue.$store.getters.isMobile(),
            urlRessourceEndPoint: null,
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '4':
            this.urlRessourceEndPoint = 'api/crm/modules/gestion-prospects-crm/contacts/' + this.item.contact_affect_interloc_contact_id
            break
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-prospects-wk-crm/contacts/' + this.item.contact_affect_interloc_contact_id
            break
        }
    },
    methods: {
        f_modifier_interlocuteur () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + this.item.interlocuteur_contact_id + '/interlocuteurs/' + this.item.interlocuteur_id + '/edit',
                (data) => {
                    this.$wikaz.request.put(this.urlRessourceEndPoint + this.item.interlocuteur_contact_id + '/interlocuteurs/' + data.interlocuteur_id, data).then(() => {
                        this.f_emit_actualiser()
                    })
                },
            )
        },
        f_retirer_interlocuteur (interlocuteurId) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/affectation-interlocuteur/' + interlocuteurId).then(() => {
                this.f_emit_actualiser()
            })
        },
    },
}
</script>
<style scoped>
</style>