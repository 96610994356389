import Vue from 'vue'
import VxAdministrationWikazKonnect
from '@/wikaz-konnect/adm/VxAdministrationWikazKonnect'
import VxGestionFacturesAdministration
from '@/wikaz-konnect/adm/gestion-factures/VxGestionFacturesAdministration'
import VxGestionMenu from '@/wikaz-konnect/adm/gestion-menu/VxGestionMenu'
import VxGestionUtilisateurs
from '@/wikaz-konnect/adm/gestion-utilisateurs/VxGestionUtilisateurs'
import VxGestionProfils
from '@/wikaz-konnect/adm/gestion-profils/VxGestionProfils'
import VxStatistiqueFacturation
from '@/wikaz-konnect/adm/statistique-facturation/VxStatistiqueFacturation'

const Imports = []

Imports.push(
    {
        name: 'vx-administration-wikaz-konnect',
        component: VxAdministrationWikazKonnect,
    },
    {
        name: 'vx-gestion-factures-administration',
        component: VxGestionFacturesAdministration,
    },
    {
        name: 'vx-gestion-menu',
        component: VxGestionMenu,
    },
    {
        name: 'vx-gestion-utilisateurs',
        component: VxGestionUtilisateurs,
    },
    {
        name: 'vx-statistique-facturation-adm',
        component: VxStatistiqueFacturation,
    },
    {
        name: 'vx-gestion-profils',
        component: VxGestionProfils,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports