<template>
  <div />
</template>
<script>
export default {
    name: 'VxBase',
    props: {
        moduleInfos: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { isMobile: window.Vue.$store.getters.isMobile() }
    },
    methods: {
        remove_tab () {
            this.$store.commit('REMOVE_TAB', this.$store.getters.getSelectedTab())
        },
    },
}
</script>