import { render, staticRenderFns } from "./FxDatagridCrud.vue?vue&type=template&id=870d7892&scoped=true&"
import script from "./FxDatagridCrud.vue?vue&type=script&lang=js&"
export * from "./FxDatagridCrud.vue?vue&type=script&lang=js&"
import style0 from "./FxDatagridCrud.vue?vue&type=style&index=0&id=870d7892&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "870d7892",
  null
  
)

export default component.exports