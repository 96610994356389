<template>
  <fx-datagrid-vuetify
    ref="objDatagrid"
    :url-ressource-end-point="urlRessourceEndPoint"
    @edition="f_editer"
    @suppression="f_suppression"
  />
</template>
<script>

export default {
    name: 'VxGestionHorairesEdt',
    data () {
        return { urlRessourceEndPoint: 'api/edt/modules/gestion-horaires-edt/horaires' }
    },
    methods: {
        f_editer (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.horaire_debut + '/edit',
                this.f_result_editer,
            )
        },
        f_result_editer (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + data.heure, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_suppression (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.horaire_debut).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
    },
}
</script>
<style scoped>
</style>