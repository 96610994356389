<template>
  <fx-datagrid-vuetify
    ref="obj_liste"
    :url-ressource-end-point="urlRessourceEndPoint"
    :item-renderer-detail="itemDetail"
    @ajouter="f_ajouter"
    @modifier="f_modifier"
    @suppression="f_suppression"
    @dbClick="f_db_click"
    @affectation_clients="f_affectations_clients"
    @affectation_adresses="f_affectation_adresses"
  />
</template>
<script>
import CxItemDetailInterlocuteur from '@/wikaz-konnect/gdi/gestion-interlocuteurs/CxItemDetailInterlocuteur'

export default {
    name: 'VxGestionInterlocuteurs',
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-interlocuteurs/interlocuteurs',
            itemDetail: CxItemDetailInterlocuteur,
        }
    },
    methods: {
        f_actualiser () {
            this.$refs.obj_liste.chargerData()
        },
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                    this.f_actualiser()
                })
            })
        },
        f_modifier (item) {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.interlocuteur_id + '/edit',
                (data) => {
                    this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.interlocuteur_id, data).then(() => {
                        this.f_actualiser()
                    })
                },
            )
        },
        f_suppression (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.interlocuteur_id).then(() => {
                this.f_actualiser()
            })
        },
        f_db_click (item) {
            this.f_modifier(item.item)
        },
        f_affectations_clients (item) {
            this.$wikaz.popups.showTransfertList(
                'Affectations clients',
                this.urlRessourceEndPoint + '/' + item.interlocuteur_id + '/affectation-clients',
                this.urlRessourceEndPoint + '/' + item.interlocuteur_id + '/affectation-clients/0',
                () => {
                    this.f_actualiser()
                },
                () => {
                    this.f_actualiser()
                },
            )
        },
        f_affectation_adresses (item) {
            this.$wikaz.popups.showTransfertList(
                'Affectations adresses',
                this.urlRessourceEndPoint + '/' + item.interlocuteur_id + '/affectation-adresses',
                this.urlRessourceEndPoint + '/' + item.interlocuteur_id + '/affectation-adresses/0',
                () => {
                    this.f_actualiser()
                },
                () => {
                    this.f_actualiser()
                },
            )
        },
    },
}
</script>
<style scoped>
</style>