<template>
  <v-combobox
    v-model="select"
    multiple
    prepend-icon="search"
    label="Filtre"
    outlined
    dense
    chips
    small-chips
    deletable-chips
    hide-details
    class="tag-input"
    :search-input.sync="search"
    @keyup.tab="updateTags"
    @paste="updateTags"
    @input="updateTags"
  />
</template>

<script>
export default {
    name: 'FxFilterTag',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            select: (this.value && this.value !== '') ? this.value.split('#') : '',
            items: [],
            search: '', //sync search
        }
    },
    methods: {
        updateTags () {
            this.$nextTick(() => {
                if (this.search) {
                    this.select.push(...this.search.split(','))
                }
                this.$nextTick(() => {
                    this.search = ''
                })
            })
            this.$emit('input', this.select.join('#'))
        },
    },
}
</script>