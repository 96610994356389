<template>
  <!--<div style="width: 150px;height: 35px;display: flex;align-items: center">
        <v-icon>{{item.event_icon}}</v-icon>
        <div style="margin-left: 10px">{{item.event_description}}</div>
    </div>-->
  <v-list-item
    dense
    style="overflow: visible;border-bottom: 1px solid grey"
    @click="f_emit_selection"
  >
    <v-list-item-avatar>
      <v-icon color="primary">
        {{ item.event_icon }}
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title v-html="item.event_titre" />
      <v-list-item-subtitle
        v-if="item.event_time"
        v-html="item.event_time"
      />
      <v-list-item-content
        v-if="item.event_description"
        v-html="item.event_description"
      />
    </v-list-item-content>
    <v-list-item-avatar>
      <v-icon
        v-if="item.event_etat === 1"
        color="grey"
      >
        hourglass_empty
      </v-icon>
      <v-icon
        v-if="item.event_etat === 2"
        color="green"
      >
        check_circle
      </v-icon>
      <v-icon
        v-if="item.event_etat === 3"
        color="red"
      >
        close
      </v-icon>
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import FxItemTemplate from '../../../../../wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxItemEvenement',
    extends: FxItemTemplate,
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>