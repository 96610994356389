<template>
  <div class="cx-traitement-gestion-resident-container">
    <div class="cx-traitement-gestion-resident-col1">
      <cx-fiche-resident :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id" />
      <cx-appartement
        style="overflow: auto"
        :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id + '/appartements'"
      />
      <cx-contacts-resident
        style="overflow: auto"
        :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id + '/contacts'"
      />
    </div>
    <div class="cx-traitement-gestion-resident-col2">
      <cx-fiche-medicale :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id + '/informations'" />
      <cx-documents-residents-legaux :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id + '/fichiers-legaux'" />
      <cx-documents-residents-divers :url-ressource-end-point="'api/edt/modules/gestion-residents/residents/' + datas.resident_id + '/fichiers'" />
    </div>
    <div class="cx-traitement-gestion-resident-col3">
      <cx-projets-accompagnements />
      <cx-facturation-resident />
    </div>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxFicheMedicale from '@/wikaz-konnect/gdt/gestion-residents/elements/medical/CxFicheMedicale'
import CxProjetsAccompagnements
from '@/wikaz-konnect/gdt/gestion-residents/elements/accompagnements/CxProjetsAccompagnements'
import CxContactsResident from '@/wikaz-konnect/gdt/gestion-residents/elements/contacts/CxContactsResident'
import CxAppartement from '@/wikaz-konnect/gdt/gestion-residents/elements/appartements/CxAppartement'
import CxFicheResident from '@/wikaz-konnect/gdt/gestion-residents/elements/fiche/CxFicheResident'
import CxDocumentsResidentsDivers
from '@/wikaz-konnect/gdt/gestion-residents/elements/documents/CxDocumentsResidentsDivers'
import CxDocumentsResidentsLegaux
from '@/wikaz-konnect/gdt/gestion-residents/elements/documents/CxDocumentsResidentsLegaux'
import CxFacturationResident
from '@/wikaz-konnect/gdt/gestion-residents/elements/facturation/CxFacturationResident'

export default {
    name: 'CxTraitementGestionResident',
    components: {
        CxFacturationResident,
        CxDocumentsResidentsLegaux,
        CxDocumentsResidentsDivers,
        CxFicheResident,
        CxAppartement,
        CxContactsResident,
        CxProjetsAccompagnements,
        CxFicheMedicale,
    },
    extends: CxBase,
}
</script>
<style scoped lang="scss">
    .cx-traitement-gestion-resident-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 150px 1fr 2fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col2 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 200px 1fr;
        overflow: auto;
    }

    .cx-traitement-gestion-resident-col3 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }

</style>