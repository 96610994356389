<template>
  <div class="vx-plannings-container-principal">
    <fx-list
      style="height: 100%;overflow: auto"
      pre-selected-attribute="dadate"
      :pre-selected-value="selectedDate"
      :show-loading="calendarLoading"
      show-search
      show-refresher
      :item-renderer="itemRendererDate"
      :data-provider="dataCalendar"
      @refresh="chargerCalendrier"
      @itemEmitting="selectionChange($event)"
    />
    <div
      class="vx-planning-container-col2"
      style="overflow: auto"
    >
      <div style="display: flex;align-items: center">
        <cx-selection-type-planning
          style="margin-top: 5px"
          @input="f_change_type"
        />
      </div>
      <fx-agenda
        :item-renderer-cellule="itemRendererCellule"
        :datas="datas"
        :show-today="false"
        :composant-carte="composantCarte"
        @refresh="chargerCalendrier"
      />
    </div>
  </div>
</template>
<script>
import IxCelluleIntervention from './IxCelluleIntervention'
import IxItemDateCalendrier from './IxItemDateCalendrier'
import CxSelectionTypePlanning from './CxSelectionTypePlanning'
import VxCarteTournees from '@/wikaz-konnect/gdi/plannings/VxCarteTournees'

export default {
    name: 'VxPlanningsEntreprises',
    components: { CxSelectionTypePlanning },
    data () {
        return {
            datas: null,
            busy: false,
            dataCalendar: null,
            itemRendererDate: IxItemDateCalendrier,
            selectedType: 1,
            selectedDate: null,
            selectedTechnicienId: 0,
            itemRendererCellule: IxCelluleIntervention,
            selectedSelection: null,
            calendarLoading: false,
            cancelRequestCalendrier: null,
            cancelRequestPlanning: null,
            composantCarte: VxCarteTournees,
        }
    },
    mounted () {
        this.selectedDate = this.$wikaz.outils.dates.getTodayBdd()
    },
    methods: {
        f_change_type (item) {
            let changeType = (this.selectedType !== item.type)
            this.selectedType = item.type
            this.selectedTechnicienId = item.cible
            if (changeType) {
                if (this.selectedType === 1) {
                    this.selectedDate = this.$wikaz.outils.dates.getTodayBdd()
                } else {
                    this.selectedDate = 0
                }
            }
            this.chargerCalendrier()
        },
        chargerCalendrier () {
            this.calendarLoading = true
            if (this.cancelRequestCalendrier !== null) {
                this.cancelRequestCalendrier.abort()
            }
            this.cancelRequestCalendrier = new AbortController()
            this.$wikaz.request.request('GET', 'api/entreprises/modules/plannings-entreprises/plannings?type=' + this.selectedType + '&cible=' + this.selectedTechnicienId, null, null, this.cancelRequestCalendrier).then((data) => {
                this.dataCalendar = data
                this.calendarLoading = false
                this.chargerDatas()
            })
        },
        chargerDatas () {
            this.busy = true
            this.datas = null
            if (this.cancelRequestPlanning !== null) {
                this.cancelRequestPlanning.abort()
            }
            this.cancelRequestPlanning = new AbortController()
            this.$wikaz.request.request('GET', 'api/entreprises/modules/plannings-entreprises/plannings/' + this.selectedDate + '?type=' + this.selectedType + '&cible=' + this.selectedTechnicienId, null, null, this.cancelRequestPlanning).then((data) => {
                this.datas = data
                this.busy = false
            })
        },
        selectionChange (item) {
            if(item) {
                if (item.dadate) {
                    this.selectedDate = item.dadate
                } else {
                    this.selectedDate = 0
                }
            }
            this.chargerDatas()
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-plannings-container-principal {
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 300px 1fr;
        grid-template-rows: 1fr;
        column-gap: 5px;
    }

    .vx-planning-container-col2 {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 46px 1fr;
        grid-column-gap: 15px;
    }

</style>