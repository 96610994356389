<template>
  <v-card
    class="cx-observation-prestataire-container"
    :style="{width: isMobile ? 'auto' : width,height: isMobile ? '400px' : width, padding: isPopup ? '5px' : '0'}"
  >
    <fx-list
      v-if="urlEndPoint"
      ref="objListe"
      style="width:100%;height:100%;overflow: auto"
      show-add
      show-search
      show-refresher
      :title="titre"
      :item-renderer="itemRendererObservation"
      :url="urlEndPoint"
      @add="ajouterObservation"
    />
    <div
      v-if="isPopup"
      style="display: flex;justify-content: flex-end;margin-top: 5px"
    >
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
    <div v-else />
  </v-card>
</template>
<script>
import IxObservations from './IxObservations'

export default {
    name: 'CxObservationsPrestataires',
    props: {
        url: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            width: '400px',
            height: '600px',
            itemRendererObservation: IxObservations,
            urlEndPoint: this.url,
            titre: this.title,
            isMobile: window.Vue.$store.getters.isMobile(),
            isPopup: false,
        }
    },
    methods: {
        ajouterObservation () {
            this.$wikaz.popups.showFormulaire(this.urlEndPoint + '/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post(this.urlEndPoint, data).then(() => {
                this.$refs.objListe.refresh()
            })
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-observation-prestataire-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }
</style>