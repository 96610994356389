<template>
  <fx-formulaire-generique
    :url-ressource="urlRessourceEndPoint + '/create'"
    @on-submit="update"
  />
</template>

<script>
export default {
    name: 'CxConfAnomalieMinPoste',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    methods: {
        update (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data)
        },
    },
}
</script>

<style scoped>

</style>