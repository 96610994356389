<template>
  <v-card
    class="cx-report-intervention-container"
    :style="{height: isMobile ? '500px' : null}"
  >
    <fx-titre :titre="titre" />
    <cx-recherche-disponibilite
      v-if="urlRessourceEndPoint"
      ref="objDisponibilite"
      v-model="dataPlanification"
      :url-end-point="urlRessourceEndPoint"
    />
    <div style="display: flex;flex-direction: row;justify-content: flex-end; padding: 10px;gap: 10px">
      <v-btn
        color="success"
        small
        @click="f_valider"
      >
        Valider
      </v-btn>
      <v-btn
        color="error"
        small
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>
<script>

import CxRechercheDisponibilite
from '@/wikaz-konnect/gdi/gestion-clients/liste-adresses/creation-intervention/disponibilite/CxRechercheDisponibilite'
export default {
    name: 'CxPopupReporterIntervention',
    components: { CxRechercheDisponibilite },
    data () {
        return {
            titre: 'haha',
            urlRessourceEndPoint: null,
            width: '100%',
            height: '800px',
            date: null,
            tranche: null,
            dataPlanification: null,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
    methods: {
        f_valider () {
            if (this.dataPlanification) {
                let dataParams = {
                    date_rdv: this.dataPlanification.infos_planification.presence_date,
                    tranche_horaire_rdv: this.dataPlanification.infos_planification.th_id,
                    cible_id: this.dataPlanification.infos_planification.utilisateur_id,
                    observation_inter: this.dataPlanification.observation,
                    observation_client: this.dataPlanification.observation_client,
                }
                this.$wikaz.request.put(this.urlRessourceEndPoint, dataParams).then((data) => {
                    this.$emit('on-submit', data)
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Veuillez vérifier votre sélection')
            }
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-report-intervention-container {
        height: 800px;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        overflow: auto;
    }
</style>