<template>
  <div style="width: 100%;height: 100%">
    <fx-titre
      v-if="title"
      :titre="title"
    />
    <div
      :id="id"
      style="width: 100%; height: 100%"
    />
  </div>
</template>

<script>
export default {
    name: 'CxCarteTournees',
    props: {
        id: { default: 'map' },
        title: { default: null },
        urlRessourceEndPoint: { default: null },
        techniciens: { default: () => [] },
        notAssignedPoints: { default: () => [] },
        optimized: { default: false },
    },
    data () {
        return {
            map: null,
            stepDisplay: null,
            colors: [
                {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                }, {
                    libelle: 'blue',
                    rgba: 'rgba(2, 90, 165, .3)',
                }, {
                    libelle: 'red',
                    rgba: 'rgba(214, 4, 4, .3)',
                }, {
                    libelle: 'green',
                    rgba: 'rgba(40, 173, 22, .3)',
                }, {
                    libelle: 'purple',
                    rgba: 'rgba(150, 0, 196, .3)',
                }, {
                    libelle: 'orange',
                    rgba: 'rgba(198, 99, 0, .3)',
                }, {
                    libelle: 'white',
                    rgba: 'rgba(244, 237, 237, .3)',
                }, {
                    libelle: 'yellow',
                    rgba: 'rgba(252, 202, 53, .3)',
                }, {
                    libelle: 'black',
                    rgba: 'rgba(24, 24, 25, .3)',
                },
            ],
            directionsService: null,
            bounds: null,
        }
    },
    watch: {
        techniciens: function () {
            if (this.techniciens.length > 0) {
                this.init()
            }
        },
        urlRessourceEndPoint: function () {
            if (this.urlRessourceEndPoint.length > 0) {
                this.loadData()
            }
        },
    },
    methods: {
        init () {
            let elem = document.getElementById(this.id)
            this.map = new window.google.maps.Map(elem, {
                center: {
                    lat: 48.864605,
                    lng: 2.343691,
                },
                zoom: 5,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            })
            this.stepDisplay = new window.google.maps.InfoWindow()
            this.bounds = new window.google.maps.LatLngBounds()
            this.directionsService = new window.google.maps.DirectionsService()
            this.techniciens.forEach((technicien, index) => {
                this.setTcRoute(index)
            })
        },
        loadData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint).then((data) => {
                this.waypoints = data.waypoints
                if ( // data.hasOwnProperty('startingPoint')
                    Object.prototype.hasOwnProperty.call(data, 'startingPoint') && data.startingPoint) {
                    this.startingPoint = data.startingPoint
                } else {
                    this.startingPoint = {
                        lat: parseFloat(this.waypoints[0].latitude),
                        lng: parseFloat(this.waypoints[0].longitude),
                    }
                }
                if ( // data.hasOwnProperty('notAssignedPoints')
                    Object.prototype.hasOwnProperty.call(data, 'notAssignedPoints') && data.notAssignedPoints.length > 0) {
                    this.notAssignedPoints = data.notAssignedPoints
                }
                this.init()
            })
        },
        makeMarker (lat, lng, title, icon) {
            let infoMarker = new window.google.maps.Marker({
                position: {
                    lat: lat,
                    lng: lng,
                },
                map: this.map,
                draggable: false,
                icon: icon,
                title: title,
            })
            return infoMarker
        },
        addEvent (marker, text) {
            window.google.maps.event.addListener(marker, 'click', () => {
                this.stepDisplay.close()
                this.stepDisplay.setContent(text)
                this.stepDisplay.open(this.map, marker)
            })
        },
        setTcRoute (indexTechnicien) {
            this.ready = false
            let googleWaypoints = []
            this.techniciens[indexTechnicien].waypoints.forEach(point => {
                if (point.latitude && point.longitude) {
                    googleWaypoints.push({
                        location: {
                            lat: parseFloat(point.latitude),
                            lng: parseFloat(point.longitude),
                        },
                    })
                }
            })

            if (! this.optimized && this.notAssignedPoints.length > 0) {
                this.notAssignedPoints.forEach(point => {
                    if (point.latitude && point.longitude) {
                        let mark = this.makeMarker(
                            parseFloat(point.latitude),
                            parseFloat(point.longitude),
                            ' ',
                            {
                                url: point.icon,
                                scaledSize: {
                                    height: 32,
                                    width: 28,
                                },
                            },
                        )
                        this.addEvent(mark, point.contenu)
                    }
                })
            }
            let directionsDisplay = new window.google.maps.DirectionsRenderer({
                draggable: false,
                map: this.map,
                preserveViewport: true,
                polylineOptions: {
                    strokeColor: this.colors[indexTechnicien].libelle,
                    strokeWeight: 4,
                },
                suppressMarkers: true,
            })
            let request = {
                origin: this.techniciens[indexTechnicien].startingPoint,
                destination: this.techniciens[indexTechnicien].startingPoint,
                waypoints: googleWaypoints,
                optimizeWaypoints: this.optimized,
                travelMode: window.google.maps.TravelMode.DRIVING,
            }
            this.directionsService.route(request, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(result)
                    this.setSteps(result, indexTechnicien)
                }
            })
        },
        setSteps (res, indexTechnicien) {
            let routes = res.routes[0]
            for (let i = 1; i < routes.legs.length; i ++) {
                let contenu = ''
                let icon = ''
                if (this.optimized) {
                    if (this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].etat_inter === '2') {
                        icon = {
                            url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_' + this.colors[indexTechnicien].libelle + (i - 1) + '.png',
                            scaledSize: {
                                height: 40,
                                width: 24,
                            },
                        }
                        contenu = this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].contenu
                    } else {
                        icon = {
                            url: this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].icon,
                            scaledSize: {
                                height: 40,
                                width: 40,
                            },
                        }
                        contenu = this.techniciens[indexTechnicien].waypoints[routes.waypoint_order[i - 1]].contenu
                    }
                } else {
                    if (this.techniciens[indexTechnicien].waypoints[i - 1].etat_inter === '2' || (this.techniciens.length > 1 && i > 1)) {
                        icon = {
                            url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_' + this.colors[indexTechnicien].libelle + (i - 1) + '.png',
                            scaledSize: {
                                height: 40,
                                width: 24,
                            },
                        }
                        contenu = this.techniciens[indexTechnicien].waypoints[i - 1].contenu
                    } else {
                        icon = {
                            url: i === 0 ? this.techniciens[indexTechnicien].waypoints[0].icon : this.techniciens[indexTechnicien].waypoints[i - 1].icon,
                            scaledSize: {
                                height: 40,
                                width: 40,
                            },
                        }
                        contenu = i === 0 ? this.techniciens[indexTechnicien].waypoints[0].contenu : this.techniciens[indexTechnicien].waypoints[i - 1].contenu
                    }
                }
                this.bounds.extend({
                    lat: routes.legs[i].start_location.lat(),
                    lng: routes.legs[i].start_location.lng(),
                })
                let mark = this.makeMarker(routes.legs[i].start_location.lat(), routes.legs[i].start_location.lng(), ' ', icon)
                this.addEvent(mark, contenu)
            }
            this.$emit('set-steps', {
                res: res,
                optimized: this.optimized,
                indexTechnicien: indexTechnicien,
            })
            this.map.fitBounds(this.bounds)
        },
    },
}
</script>