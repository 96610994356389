<template>
  <div
    v-if="dataProvider"
    class="cx-preview-container"
  >
    <div
      v-for="(item,index) in dataProvider.composants"
      :key="index"
      @click="f_selected(item)"
    >
      <components
        :is="item.name"
        class="element"
        :style="{border: showContours ? '1px solid red' : ''}"
        :class="((selectedItem && item.composant_id === selectedItem.composant_id) ? 'active' : '')"
        :data-provider="item"
      />
    </div>
  </div>
  <cx-waiting v-else />
</template>
<script>
require('./elements-site/composants/imports.js')
export default {
    name: 'CxPreview',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
        showContours: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return { selectedItem: null }
    },
    methods: {
        f_selected (item) {
            this.selectedItem = item
            this.$emit('selectedItem', item)
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-preview-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        // grid-template-columns: 1fr;
        // grid-template-rows: 1fr;
        // grid-column-gap: 5px;
        overflow: auto;
    }

    .element {
        box-sizing: border-box;
        cursor: pointer;
        opacity: 0.3;
        // padding-right: 5px;
        // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        // transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    .element:hover {
        // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        // background-color: #15bda0 !important;
        // background-image: none !important;
        opacity: 1;
        // background-color: #15bda0 !important;
        // border-right: 5px solid grey;
        // border-top: 1px solid grey;
        // border-bottom: 1px solid grey;;
    }

    .active {
        opacity: 1;
        // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        // background-color: #15bda0 !important;
        // background-image: none !important;
        // opacity: 0.5;
        // background-color: #15bda0 !important;
        // background-image: none !important;
        // border-right: 5px solid green !important;
        // border-top: 1px solid green !important;
        // border-bottom: 1px solid green !important;
    }

</style>