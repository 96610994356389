<template>
  <!--    <div class="cx-gestion-recepteur-container">
        <div class="cx-gestion-recepteur-container-header" style="display: flex;gap: 10px;padding-left: 10px">
            <v-btn :color="(selectedFiltre===1) ? 'primary' : ''" @click="f_change(1)">Mes prestataires</v-btn>
            <v-btn :color="(selectedFiltre===2) ? 'primary' : ''" @click="f_change(2)">Invitations en attente de mon accord</v-btn>
             <v-btn :color="(selectedFiltre===3) ? 'primary' : ''" @click="f_change(3)">Invitations en attente de son accord</v-btn>
        </div>
        <cx-liste-emetteur :key="indexKey" ref="objDatagrid" :url-ressource-end-point="urlRessourceEndPoint + '?filtre=' + selectedFiltre"
                           @ajouter="f_ajouter"
                           @add_accord="f_add_accord"
                           @del_accord="f_del_accord"
                           @supprimer="f_supprimer"></cx-liste-emetteur>
        <v-dialog v-model="showDialog" width="600px">
            <cx-recherche-compte-wikaz v-if="showDialog" :url-ressource-end-point="urlRessourceEndPoint"
                                       @close="f_close_dialog"
                                       @submit="f_close_dialog"
            ></cx-recherche-compte-wikaz>
        </v-dialog>
    </div>-->
  <div class="cx-gestion-recepteur-container">
    <fx-datagrid-vuetify
      ref="obj_liste"
      :url-ressource-end-point="urlRessourceEndPoint"
      @ajouter="f_ajouter"
      @supprimer_lien="f_supprimer"
      @donner_accord="f_add_accord"
      @retirer_accord="f_del_accord"
    />
    <v-dialog
      v-model="showDialog"
      width="600px"
    >
      <cx-recherche-compte-wikaz
        v-if="showDialog"
        :url-ressource-end-point="urlRessourceEndPoint"
        :sens="2"
        @close="f_close_dialog"
        @submit="f_close_dialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import CxRechercheCompteWikaz from '@/wikaz-konnect/gdi/gestion-recepteurs/CxRechercheCompteWikaz'

export default {
    name: 'VxGestionRecepteurs',
    components: { CxRechercheCompteWikaz },
    data () {
        return {
            urlRessourceEndPoint: 'api/entreprises/modules/gestion-recepteurs/recepteurs',
            selectedFiltre: 1,
            indexKey: 0,
            showDialog: false,
        }
    },
    methods: {
        f_actualiser () {
            this.$refs.obj_liste.chargerData()
        },
        f_change (index) {
            this.selectedFiltre = index
            this.indexKey ++
        },
        f_ajouter () {
            this.showDialog = true
        },
        f_add_accord (item) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.link_id + '/on', item).then(() => {
                this.f_actualiser()
            })
        },
        f_del_accord (item) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.link_id + '/off', item).then(() => {
                this.f_actualiser()
            })
        },
        f_close_dialog () {
            this.showDialog = false
            this.f_actualiser()
        },
        f_supprimer (item) {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Etes-vous certain de vouloir supprimer ce lien', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.link_id).then(() => {
                    this.f_actualiser()
                })
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .cx-gestion-recepteur-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-gestion-recepteur-container-header {
        display: flex;
        gap: 10px;
        padding-left: 10px;
        flex-flow: row wrap;
    }
</style>