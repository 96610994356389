<template>
  <v-card
    v-if="urlRessourceEndPoint"
    :style="{width: width,height: '600px'}"
    class="cx-affectation-container"
  >
    <fx-list-transfert
      :titre="title"
      :url-ressouce-index="urlRessourceEndPoint"
      :url-ressouce-update="urlRessourceEndPoint + '/0'"
    />
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
  <cx-waiting v-else />
</template>

<script>
export default {
    name: 'CxAffectationTechniciensPrestations',
    data () {
        return {
            title: 'Affectation des techniciens',
            width: '635px',
            labelWidth: '100px',
            dataProvider: {},
            ruleValidate: {},
            isEditable: this.setEditable,
            isPopup: this.setPopup,
            urlRessourceEndPoint: '',
            dataStructure: [],
            showSpinner: true,
            terms: [],
            term_actions: [],
            hasPopupDataProvider: false,
            isMobile: window.Vue.$store.getters.isMobile(),
        }
    },
}
</script>

<style scoped>
    .cx-affectation-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>