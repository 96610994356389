<template>
  <fx-list
    ref="obj_liste"
    overflow
    style="overflow: auto;height: 100%"
    :url="urlRessourceEndPoint"
    :data-provider.sync="datas"
    title="Contacts"
    show-search
    show-result
    show-refresher
    show-add
    :item-renderer="itemRenderer"
    @add="ajouter"
  />
</template>

<script>
import IxContact from '@/wikaz-konnect/gdt/gestion-residents/elements/contacts/IxContact'

export default {
    name: 'CxContactsResident',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datas: null,
            itemRenderer: IxContact,
        }
    },
    methods: {
        ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', (data) => {
                this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(()=> {
                    this.$refs.obj_liste.refresh()
                })
            })
        },
    },
}
</script>

<style scoped>

</style>