<template>
  <v-flex
    v-if="dataProvider"
    :style="{height: 'auto'}"
    style="background-color: #e7e4e4"
    xs12
  >
    <div>Actus Facebook</div>
    <v-container grid-list-xl>
      <v-layout
        row
        wrap
        justify-center
        align-center
      >
        <template v-if="dataProvider.publications">
          <template v-for="(item,index) in dataProvider.publications.split('#')">
            <v-flex
              :key="index"
              class="md4"
            >
              <template>
                <div
                  :key="index"
                  class="fb-post"
                  :data-href="'https://www.facebook.com/'+dataProvider.facebookPageId+'/posts/'+item+'/'"
                  data-show-text="true"
                  data-width="500px"
                >
                  <!--<blockquote :cite="'https://developers.facebook.com/Ecolos.bleus/posts/' + item" class="fb-xfbml-parse-ignore">
                                        <p>Une initiative intéressante à découvrir.</p>Publiée par <a href="https://www.facebook.com/Ecolos.bleus/">Ecologie Bleue Le Lab</a> sur&nbsp;<a href="https://developers.facebook.com/Ecolos.bleus/posts/3135951233110395">Vendredi 29 mai 2020</a>
                                    </blockquote>-->
                </div>
              </template>
            </v-flex>
          </template>
        </template>
      </v-layout>
    </v-container>
  </v-flex>
</template>

<script>
// import Vue from "vue";

export default {
    name: 'FxPublicationsFB',
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        open (file) {
            window.open(this.$icsilab.rootApi + 'fichiers?file=' + file)
        },
    },
}
</script>

<style scoped>

</style>