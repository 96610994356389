import dayjs from 'dayjs'
import 'dayjs/locale/fr'

let dates = function () {
    dayjs.locale('fr')
    this.dayjs = function () {
        return dayjs()
    }
    this.getTodayBdd = function () {
        return dayjs().format('YYYY-MM-DD')
    }
    this.getDate = function (date, format = 'DD/MM/YYYY') {
        return dayjs(date).format(format)
    }
    this.getCurrentDate = function (format = 'DD/MM/YYYY') {
        return dayjs().format(format)
    }
    this.getTime = function () {
        return dayjs().format('HH:mm')
    }
    this.getAnnee = function (date) {
        return dayjs(date).format('YYYY')
    }
    this.getMoisLettre = function (mois) {
        switch (mois.toString()) {
        case '1': return 'Janvier'
        case '2': return 'Février'
        case '3': return 'Mars'
        case '4': return 'Avril'
        case '5': return 'Mai'
        case '6': return 'Juin'
        case '7': return 'Juillet'
        case '8': return 'Août'
        case '9': return 'Septembre'
        case '10': return 'Octobre'
        case '11': return 'Novembre'
        case '12': return 'Décembre'
        }
    }
}
export default dates