<template>
  <div class="cx-traitement-profil-container">
    <fx-formulaire-generique
      :data-in="dataIn"
      :url-ressource="'api/wikaz/modules/gestion-profils/profils/'+dataIn.profil_id+'/edit'"
      @on-submit="updateData"
    />
    <fx-tab-navigator>
      <div
        style="overflow: auto;padding: 10px"
        title="Modules"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-profils/profils/' + dataIn.profil_id + '/affectation-modules'"
          :url-ressouce-update="'api/wikaz/modules/gestion-profils/profils/' + dataIn.profil_id + '/affectation-modules/' + dataIn.profil_id"
          :item-renderer="itemRendererModuleAffectation"
        />
      </div>
      <div
        style="overflow: auto;padding: 10px"
        title="Utilisateur"
      >
        <fx-list-transfert
          :url-ressouce-index="'api/wikaz/modules/gestion-profils/profils/' + dataIn.profil_id + '/affectation-utilisateurs'"
          :url-ressouce-update="'api/wikaz/modules/gestion-profils/profils/' + dataIn.profil_id + '/affectation-utilisateurs/' + dataIn.profil_id"
          :item-renderer="itemRendererUtilisateurAffectation"
        />
      </div>
    </fx-tab-navigator>
  </div>
</template>
<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import IxModulesAffectation from '@/wikaz-konnect/adm/gestion-profils/IxModulesAffectation'
import IxUtilisateursAffectation from '@/wikaz-konnect/adm/gestion-profils/IxUtilisateursAffectation'

export default {
    name: 'CxTraitementProfil',
    extends: CxBase,
    props: { dataIn: Object },
    data () {
        return {
            tab: null,
            itemRendererModuleAffectation: IxModulesAffectation,
            itemRendererUtilisateurAffectation: IxUtilisateursAffectation,
        }
    },
    methods: {
        updateData (data) {
            this.$wikaz.request.put('api/wikaz/modules/gestion-profils/profils/' + data.profil_id, data).then(() => {
                this.$emit('on-refresh')
            })
        },
    },
}
</script>
<style scoped>
    .cx-traitement-profil-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 450px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 5px;
        overflow: auto;
    }

    .cx-traitement-profil-col2 {
        height: 100%;
        box-sizing: border-box;
        padding: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
    }

    /*.v-window >>> .v-window__container {
        overflow: auto;
    }*/
</style>