<template>
  <div class="cx-fiche-intervention-container">
    <div style="height: 25px;width: 100%">
      <fx-titre
        v-if="afficherTitre"
        titre="Intervention"
      />
    </div>
    <div
      v-if="datas"
      class="cx-formulaire-container"
    >
      <div class="ligne-formulaire">
        <fx-champ
          label="Numéro"
          :text="datas.intervention_id.toString()"
        />
        <fx-champ
          label="Créé le"
          :text="$wikaz.outils.dates.getDate(datas.intervention_date_creation)"
        />
      </div>
      <fx-champ
        label="Presta."
        :text="datas.prestation_libelle + ' ' + getDuree(datas.intervention_duree)"
      />
      <fx-champ
        label=" "
        :text="getRef()"
      />
      <fx-champ
        label="Planifiée"
        :text="$wikaz.outils.dates.getDate(datas.intervention_date_planifie) + ' ' + datas.th_libelle"
      />
      <fx-champ
        label="Tech"
        :text="datas.technicien"
      />
      <!--            <fx-champ label="Adr. fact." :text="datas.addresse_facturation"></fx-champ>-->
      <fx-champ
        label="Adr. inter."
        :text="datas.addresse_intervention"
      />
      <div class="ligne-formulaire">
        <div style="min-width: 72px" />
        <fx-icon
          :size="20"
          icon="directions_run"
          tooltip="Mise en ordre"
          color="primary"
          tooltip-position="top"
        />
        <fx-champ :text="datas.heure_ordre" />
        <fx-icon
          :size="20"
          icon="home"
          tooltip="Arrivée"
          color="primary"
          tooltip-position="top"
        />
        <fx-champ :text="datas.heure_arrivee" />
        <fx-icon
          :size="20"
          icon="directions_walk"
          tooltip="Fin des travaux"
          color="primary"
          tooltip-position="top"
        />
        <fx-champ :text="datas.heure_cloture" />
      </div>
      <fx-champ
        label=" "
        :text="(datas.libelle_cloture ? datas.libelle_cloture : 'En attente')"
      />
    </div>
    <cx-waiting
      v-else-if="datas === null && url"
      width="100%"
      height="100%"
    />
  </div>
</template>
<style scoped lang="scss">
    .cx-fiche-intervention-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        overflow: auto;
        font-size: 12px;
        // background-color: #EEEEEE;
    }

    .cx-formulaire-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 6px;
    }

    .ligne-formulaire {
        display: flex;
        align-items: center;
    }
</style>
<script>
export default {
    name: 'CxFicheInter',
    props: {
        urlEndPoint: {
            type: String,
            default: null,
        },
        afficherTitre: { default: true },
    },
    data () {
        return {
            url: this.urlEndPoint,
            datas: null,
        }
    },
    mounted () {
        this.getDatas()
    },
    methods: {
        getDatas () {
            if (this.url) {
                this.datas = null
                this.$wikaz.request.get(this.url).then((data) => {
                    this.datas = data
                })
            }
        },
        setUrl (url) {
            this.url = url
            this.getDatas()
        },
        date (date) {
            if (! date) {
                return ''
            }
            return this.$wikaz.outils.dates.getDate(date, 'DD/MM/YYYY')
        },
        getRef () {
            let ref = this.datas.intervention_reference
            if (ref && ref > 0) {
                return 'Suite de l\'intervention : ' + ref
            }
            return 'Intervention Primeur'

        },
        getDuree (duree) {
            return this.timeConvert(duree * 60)
        },
        timeConvert (n) {
            let num = n
            let hours = (num / 60)
            let rhours = Math.floor(hours)
            let minutes = (hours - rhours) * 60
            let rminutes = Math.round(minutes)
            // return num + " minutes = " + rhours + " heure(s) et " + rminutes + " minute(s).";
            return rhours.toString().padStart(2, '0') + ' h ' + rminutes.toString().padStart(2, '0')
        },
    },
}
</script>