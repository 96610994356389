import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './wikaz-core/router/router'
import store from './wikaz-core/store'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import WikazApp from '@/wikaz-core/WikazApp'

Vue.prototype.$icsilab = require('./icsilab').default
require('./wikaz-konnect/imports.js')
require('./wikaz-konnect/vue-screen.js')

Vue.config.productionTip = false
Vue.prototype.$wikaz = WikazApp
Vue.use(Vuetify)

new Vue({
    created: function () {
        this.$wikaz.getInstance().initialisation(this)
    },
    render: h => h(App),
    store,
    router,
    vuetify: new Vuetify(require('./wikaz-core/vuetify/vuetifyOptions')),
}).$mount('#app')