<template>
  <div class="fx-datagrid-crud-container">
    <fx-titre :titre="titre" />
    <fx-datagrid-vuetify
      ref="objDatagrid"
      :url-ressource-end-point="urlRessourceEndPoint"
      @ajouter="f_ajouter"
      @edition="f_editer"
      @suppression="f_suppression"
    />
  </div>
</template>
<script>
export default {
    name: 'FxDatagridCrud',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
        titre: {
            type: String,
            default: 'titre',
        },
    },
    methods: {
        f_ajouter () {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                        this.$refs.objDatagrid.chargerData()
                    })
                },
            )
        },
        f_editer (item) {
            this.$wikaz.getInstance().popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.id + '/edit',
                (data) => {
                    this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.id, data).then(() => {
                        this.$refs.objDatagrid.chargerData()
                    })
                },
            )
        },
        f_suppression (item) {
            this.$wikaz.request.delete(this.urlRessourceEndPoint + '/' + item.id).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
    },
}
</script>

<style scoped lang="scss">
    .fx-datagrid-crud-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        // padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>