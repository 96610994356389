<template>
  <div
    v-if="datas"
    class="cx-traitement-client-container"
  >
    <div class="cx-traitement-client-col1">
      <cx-fiche-client :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id" />
      <cx-fiche-client-outils
        :client-id="datas.client_id"
        url-ressource-end-point="api/entreprises/modules/gestion-clients-entreprises/clients/"
        @actualiser="f_actualiser"
      />
      <!--            <cx-interlocuteur-gdi :item-renderer-interlocuteur="itemRendererInterlocuteur" :url-ressource-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/'+datas.client_id+'/'"></cx-interlocuteur-gdi>-->
      <cx-liste-adresses
        :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/adresses'"
        style="width: 100%;height: 100%;overflow: auto"
        @on-submit="f_actualiser_historique"
        @selectedAdresseId="f_selection_adresse($event)"
      />
    </div>
    <div class="cx-traitement-client-col2">
      <cx-liste-interventions
        ref="objListeIntervention"
        :pre-search="datas.inter ? datas.inter.toString() : ''"
        width="100%"
        height="100%"
        :url-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/interventions'"
        @selectedItem="f_select_intervention"
      />
    </div>
    <div
      v-show="selectedCibleType === 1"
      class="cx-traitement-client-col3"
    >
      <cx-fiche-inter ref="objFicheInter" />
      <cx-fiche-intervention-outils
        v-if="selectedCibleId"
        :key="selectedCibleId"
        ref="objFicheInterOutils"
        :url-ressource-end-point="'api/entreprises/modules/gestion-clients-entreprises/interventions/' + selectedCibleId"
      />
    </div>
    <div
      v-show="selectedCibleType === 2"
      class="cx-traitement-client-col3-bis"
    >
      <cx-fiche-demande ref="objFicheDemande" />
      <cx-fiche-demande-outils
        v-if="selectedCibleId"
        :key="selectedCibleId"
        ref="objFicheInterOutils"
        :url-ressource-end-point="'api/entreprises/modules/gestion-clients-entreprises/clients/' + datas.client_id + '/demandes/' + selectedCibleId"
        @refresh="f_charger_demande(selectedCibleId)"
      />
    </div>
  </div>
  <cx-waiting v-else />
</template>
<script>

import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxFicheClient from '@/wikaz-konnect/gdi/gestion-clients/fiche-client/CxFicheClient'
import CxFicheClientOutils from '@/wikaz-konnect/gdi/gestion-clients/fiche-client-outils/CxFicheClientOutils'
import CxListeAdresses from '@/wikaz-konnect/gdi/gestion-clients/liste-adresses/CxListeAdresses'
import CxListeInterventions
from '@/wikaz-konnect/gdi/gestion-clients/liste-interventions/CxListeInterventions'
import CxFicheInter from '@/wikaz-konnect/gdi/gestion-clients/fiche-inter/CxFicheInter'
import CxFicheInterventionOutils
from '@/wikaz-konnect/gdi/gestion-clients/fiche-intervention-outils/CxFicheInterventionOutils'
import CxFicheDemande from '@/wikaz-konnect/gdi/gestion-clients/fiche-demande/CxFicheDemande'
import IxItemInterlocuteurClient
from '@/wikaz-konnect/gdi/gestion-clients/interlocuteur/IxItemInterlocuteurClient'
import CxFicheDemandeOutils from '@/wikaz-konnect/gdi/gestion-clients/fiche-demande-outils/CxFicheDemandeOutils'

export default {
    name: 'CxTraitementGestionClients',
    components: {
        CxFicheDemandeOutils,
        CxFicheDemande,
        CxFicheInterventionOutils,
        CxFicheInter,
        CxListeInterventions,
        CxListeAdresses,
        CxFicheClientOutils,
        CxFicheClient,
    },
    extends: VxBase,
    props: {
        dataIn: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            datas: this.dataIn,
            selectedCibleType: null,
            selectedCibleId: null,
            itemRendererInterlocuteur: IxItemInterlocuteurClient,
        }
    },
    mounted () {
        if(this.moduleInfos) {
            this.datas = this.moduleInfos.dataIn
        }
        this.chargerData()
    },
    methods: {
        chargerData () {
            if (this.datas.inter !== '') {
                this.f_charger_intervention(this.datas.inter)
            }
        },
        f_selection_adresse  (event) {
            this.$refs.objListeIntervention.setFiltre(event)
        },
        f_select_intervention (event) {
            this.selectedCibleType = event.type
            if (event.type === 1) {
                this.selectedCibleId = event.intervention_id
                this.f_charger_intervention(event.intervention_id)
            } else {
                this.selectedCibleId = event.demande_id
                this.f_charger_demande(event.demande_id)
            }

        },
        f_actualiser_historique () {
            this.$refs.objListeIntervention.getListe()
        },
        f_charger_intervention (intervention) {
            if (intervention && this.$refs.objFicheInter) {
                this.$refs.objFicheInter.setUrl('api/entreprises/modules/gestion-clients-entreprises/clients/' + this.datas.client + '/interventions/' + intervention)
            }
        },
        f_charger_demande (demande) {
            if (demande && this.$refs.objFicheDemande) {
                this.$refs.objFicheDemande.setUrl('api/entreprises/modules/gestion-clients-entreprises/clients/' + this.datas.client + '/demandes/' + demande)
            }
        },
        f_actualiser () {
            this.$emit('on-refresh')
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-traitement-client-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 380px 380px 380px;
        grid-template-rows: 100%;
        grid-column-gap: 5px;
        padding-left: 5px;
        overflow: auto;
    }

    .cx-traitement-client-col1 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 240px auto 1fr;
        overflow: auto;
    }

    .cx-traitement-client-col2 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        overflow: auto;
    }

    .cx-traitement-client-col3 {
        box-sizing: border-box;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 3fr 52px 4fr;
        overflow: auto;
    }

    .cx-traitement-client-col3-bis {
        box-sizing: border-box;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
    }
</style>