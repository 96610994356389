<template>
  <v-flex
    v-if="dataProvider && dataProvider.cards"
    xs12
  >
    <v-container grid-list-xl>
      <v-layout
        row
        wrap
        justify-center
      >
        <fx-card
          v-for="(item,index) in getCards()"
          :key="index"
          :data-provider="item"
        />
      </v-layout>
    </v-container>
  </v-flex>
  <fx-anomalie-composant
    v-else
    composant="fx-section"
    propriete="cards"
  />
</template>
<script>
import FxCard from './FxCard'
import FxAnomalieComposant from '@/wikaz-konnect/icsilab/editeur-de-site/elements-site/composants/FxAnomalieComposant'

export default {
    name: 'FxSection',
    components: {
        FxAnomalieComposant,
        FxCard,
    },
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    data () {
        return { isMobile: (['lg', 'xl'].indexOf(this.$screen.breakpoint) === - 1) }
    },
    methods: {
        getCards () {
            if ((['lg', 'xl'].indexOf(this.$screen.breakpoint) === - 1) && (this.dataProvider.reverseMobile ? (this.dataProvider.reverseMobile !== '0') : false)) {
                return this.dataProvider.cards.slice().reverse()
            }
            return this.dataProvider.cards
        },
    },
}
</script>