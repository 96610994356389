import Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import swal from 'sweetalert'

Vue.use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 20,
    newestOnTop: true,
})

let notification = function () {
    this.showAuto = function (data) {
        if (data.code !== undefined && data.message !== undefined && data.duration !== undefined) {
            this.show(data.code, data.message, data.duration)
        }
    }
    this.show = function (type, message, duration = 4.5, showIcon = true) {
        Vue.$toast[type](message, {
            position: 'top-right',
            timeout: duration * 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: showIcon,
            rtl: false,
        })
    }
    this.showError = function (message, duration = 4.5) {
        this.show('error', message, duration)
    }
    this.showWarning = function (message, duration = 4.5) {
        this.show('warning', message, duration)
    }
    this.showSuccess = function (message, duration = 4.5) {
        this.show('success', message, duration)
    }
    this.showInfo = function (message, duration = 4.5) {
        this.show('info', message, duration)
    }
    this.showOpen = function (message, duration = 4.5) {
        this.show('info', message, duration, false)
    }
    this.showConfirmation = function (titre, message, callbackOK) {
        swal({
            title: titre,
            text: message,
            icon: 'warning',
            buttons: {
                cancel: {
                    text: 'Annuler',
                    value: false,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'Oui',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            dangerMode: true,
            closeOnClickOutside: false,
        }).then((reponse) => {
            if(reponse) {
                callbackOK()
            }
        })
    },
    this.showConfirmationCommentaire = function (titre, message, callbackOK) {
        swal({
            title: titre,
            text: message,
            content: {
                element: 'input',
                attributes: {
                    // placeholder: "Type your password",
                    type: 'textarea',
                },
            },
            icon: 'warning',
            buttons: {
                cancel: {
                    text: 'Annuler',
                    value: false,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
                confirm: {
                    text: 'Oui',
                    value: true,
                    visible: true,
                    className: '',
                    closeModal: true,
                },
            },
            dangerMode: true,
            closeOnClickOutside: false,
        }).then((reponse) => {
            if(reponse) {
                callbackOK(reponse)
            }
        })
    }
}
export default notification