<template>
  <fx-datagrid-vuetify
    v-if="urlRessourceEndPoint"
    ref="obj_liste"
    :url-ressource-end-point="urlRessourceEndPoint + '/suivi-paye'"
    @actualiser="actualiser"
  />
</template>

<script>
export default {
    name: 'CxSuiviPaye',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    methods: {
        actualiser () {
            this.$emit('actualiser')
        },
    },
}
</script>

<style scoped>

</style>