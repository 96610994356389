<template>
  <v-list-item
    v-if="item.action_type_traitement === -1"
    dense
    style="overflow: visible"
    @click="f_emit_selection"
    @dblclick="f_edition(item)"
  >
    <v-list-item-avatar>
      <fx-icon
        color="grey"
        :tooltip="item.action_type_libelle"
        :icon="item.action_type_icon"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-subtitle style="white-space: pre-line;">
        {{ f_format_date_heure(item.action_date_traitement) }} - {{ item.utilisateur_in }}
      </v-list-item-subtitle>
      <v-list-item-title style="white-space: pre-line;font-size: 12px;margin-left: 20px">
        {{ item.action_description_traitement }}
      </v-list-item-title>
    </v-list-item-content>
    <v-menu
      :key="index"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          v-on="on"
        />
      </template>
      <v-list ref="objLst">
        <v-list-item @click="f_edition(item)">
          <v-list-item-avatar>
            <v-icon color="primary">
              visibility
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Ouvrir</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
  <v-list-item
    v-else
    dense
    style="overflow: visible"
    @click="f_emit_selection"
    @dblclick="f_edition(item)"
  >
    <v-list-item-avatar>
      <fx-icon
        color="primary"
        :tooltip="item.action_type_libelle"
        :icon="item.action_type_icon"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-subtitle
        v-if="item.action_etat === 0"
        style="white-space: pre-line;"
      >
        Objectif : {{ item.utilisateur_in }} - {{ f_format_date_heure(item.action_date_prevue) }} {{ ((item.interlocuteur) ? (' avec ' + item.interlocuteur) : '' ) }}
      </v-list-item-subtitle>
      <v-list-item-title
        v-if="item.action_etat === 0"
        style="white-space: pre-line;font-size: 13px;padding: 0;margin-left: 20px"
        v-html="item.action_description_traitement"
      />
      <v-list-item-subtitle
        v-if="item.action_etat === 1"
        style="white-space: pre-line;"
      >
        Compte Rendu : {{ item.utilisateur_out }} - {{ f_format_date_heure(item.action_date_traitement) }} {{ ((item.interlocuteur) ? (' avec ' + item.interlocuteur) : '' ) }}
      </v-list-item-subtitle>
      <v-list-item-title
        v-if="item.action_etat === 1"
        style="white-space: pre-line;font-size: 13px;padding: 0;margin-left: 20px"
        v-html="item.action_compte_rendu_traitement"
      />
    </v-list-item-content>
    <v-list-item-avatar>
      <fx-icon
        v-if="item.action_etat === 0"
        :color="getColorIcon(item.action_date_prevue)"
        icon="hourglass_empty"
        tooltip="En attente de traitement"
      />
      <fx-icon
        v-else-if="item.action_efficace === 0"
        color="green"
        icon="done"
        tooltip="Action non efficace terminée"
      />
      <fx-icon
        v-else
        color="green"
        icon="check_circle_outline"
        tooltip="Action efficace terminée"
      />
    </v-list-item-avatar>
    <v-menu
      :key="index"
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <fx-icon
          :size="30"
          color="primary"
          icon="menu"
          v-on="on"
        />
      </template>
      <v-list ref="objLst">
        <v-list-item @click="f_edition(item)">
          <v-list-item-avatar>
            <v-icon color="primary">
              visibility
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-title>Ouvrir</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-list-item>
</template>
<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxGestionCrmAction',
    extends: FxItemTemplate,
    data () {
        return {
            isMobile: window.Vue.$store.getters.isMobile(),
            urlRessourceEndPoint: null,
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '4':
            this.urlRessourceEndPoint = 'api/crm/modules/gestion-prospects-crm/contacts/'
            break
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-prospects-wk-crm/contacts/'
            break
        }
    },
    methods: {
        getColorIcon (date) {
            if (date !== null) {
                if (date === this.$wikaz.outils.dates.getTodayBdd()) {
                    return 'green'
                }
                if (date > this.$wikaz.outils.dates.getTodayBdd()) {
                    return 'black'
                }
                return 'red'

            }
            return 'black'
        },
        f_edition (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + item.action_contact_id + '/actions/' + item.action_id + '/edit', this.f_result_edition_action)
        },
        f_result_edition_action (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + data.action_contact_id + '/actions/' + data.action_id, data).then(() => {
                this.f_emit_actualiser()
            })
        },
    },
}
</script>
<style scoped>
</style>