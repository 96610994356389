import Vue from 'vue'
import VxGestionFactures from '@/wikaz-core/ui/modules/factures/VxGestionFactures'
import VxEditeurDeSite from '@/wikaz-konnect/icsilab/editeur-de-site/VxEditeurDeSite'

const Imports = []

Imports.push(
    {
        name: 'vx-gestion-factures-icsi',
        component: VxGestionFactures,
    },
    {
        name: 'vx-editeur-de-site',
        component: VxEditeurDeSite,
    },
)

Imports.forEach((Import) => {
    Vue.component(Import.name, Import.component)
})

export default Imports