<template>
  <v-card
    :style="{width: width,height: height}"
    class="cx-historique-demandes-container"
  >
    <fx-datagrid-vuetify
      v-if="urlRessourceEndPoint"
      ref="obj_liste"
      :url-ressource-end-point="urlRessourceEndPoint"
    />
    <cx-waiting v-else />
    <div style="display: flex;justify-content: flex-end">
      <v-btn
        color="error"
        @click="$emit('close')"
      >
        Fermer
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
    name: 'CxHistoriqueDemandes',
    data () {
        return {
            urlRessourceEndPoint: '',
            width: '1400px',
            height: '600px',
        }
    },
}
</script>
<style scoped lang="scss">
    .cx-historique-demandes-container {
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-row-gap: 10px;
        overflow: auto;
    }
</style>