<template>
  <div style="width: 150px">
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          ref="myInput"
          v-model="time"
          style="margin-top: 2px;margin-bottom: 2px"
          outlined
          dense
          hide-details
          prepend-icon="access_time"
          readonly
          :rules="rules"
          v-on="on"
        />
      </template>
      <v-time-picker
        v-if="menu2"
        v-model="time"
        full-width
        no-title
        format="24hr"
        @click:minute="$refs.menu.save(time)"
        @input="onChange"
      />
    </v-menu>
  </div>
</template>
<script>
export default {
    name: 'FxHeurefield',
    props: {
        value: {
            type: String,
            default: null,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        format: {
            type: String,
            default: 'dd/MM/yyyy',
        },
        formatOut: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        editable: {
            default: true,
            type: Boolean,
        },
        isDateTime: {
            default: false,
            type: Boolean,
        },
        isTimeNullDefault: {
            default: true,
            type: Boolean,
        },
        rules: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return {
            time: (this.value !== '' && this.value !== null) ? this.value : (this.isTimeNullDefault ? null : this.$wikaz.getInstance().outils.dates.getTime()),
            menu2: false,
        }
    },
    methods: {
        validate () {
            return this.$refs.myInput.validate()
        },
        getError () {
            return this.$refs.myInput.errorBucket[0]
        },
        onChange (event) {
            this.$emit('input', event)
        },
    },
}
</script>