<template>
  <div class="cx-ligne-agents-container">
    <div
      v-if="item.label !== 'Total'"
      class="cx-ligne-agents-cartouche"
      :style="{width: largeurGauche-1 + 'px'}"
    >
      <v-avatar
        style="margin-left: 5px;margin-right: 5px"
        size="48"
      >
        <v-tooltip
          right
          class="v-tooltip-css"
          :open-delay="300"
        >
          <template v-slot:activator="{ on }">
            <v-img
              :src="'data:image/jpeg;base64,' + $wikaz.getInstance().getPhotoUtilisateur(item.wcu_utilisateur_id)"
              v-on="on"
            />
          </template>
          <cx-mini-fiche-technicien :item="item" />
        </v-tooltip>
      </v-avatar>
      <div style="width: 100%">
        {{ item.label }}
      </div>
    </div>
    <div
      v-else
      class="cx-ligne-agents-cartouche"
      :style="{width: largeurGauche-1 + 'px'}"
      style="background-color: #3e3d3d"
    >
      <div style="width: 100%;display: flex;justify-content: center;font-weight: bold;font-size: 18px">
        {{ item.label }}
      </div>
    </div>
    <template v-for="(element,indexStats) in item.stats">
      <div
        :key="indexStats"
        class="cx-ligne-agents-case"
        :style="{width: 'calc((100% - '+(largeurGauche-1)+'px)/'+ item.stats.length+')',
                 backgroundColor: (element.stats_couleur) ? element.stats_couleur : '',
                 color: (element.stats_font_color) ? element.stats_font_color : ''}"
        v-html="element.valeur"
      />
    </template>
  </div>
</template>
<script>
import CxMiniFicheTechnicien from '@/wikaz-konnect/gdt/planning/planning-elements/agents/CxMiniFicheTechnicien'
export default {
    name: 'CxLigneAgents',
    components: { CxMiniFicheTechnicien },
    props: {
        datas: {
            type: Object,
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        largeurGauche: {
            type: Number,
            default: 200,
        },
    },
    data () {
        return {
            showEdit: false,
            selectedEvenement: null,
            indexKey: 123456789,
        }
    },
}
</script>
<style scoped lang="scss">
    .cx-ligne-agents-container {
        width: 100%;
        height: 60px;
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        border-right: 1px solid black;
        background-color: #ffffff;
        color: #1e1e1e;
    }

    .cx-ligne-agents-cartouche {
        display: flex;
        align-items: center;
        background-color: #0d6aad;
        color: white;
    }

    .cx-ligne-agents-case {
        // cursor: pointer;
        border-left: 1px solid black;
        // border-top: 1px solid black;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
    }

    .fiche-container {
        width: 400px;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #DDDDDD;
    }

    .champ-container {
        width:100%;
        height: 48px;
        display: flex;
        color: black;
        align-items: center;
        padding: 7px
    }

    .champ-libelle {
        width: 120px;
        text-align: right;
        margin-right: 10px
    }

    .champ-content {
        height: 100%;
        border: 1px solid grey;
        border-radius: 7px;
        background-color: white;
        width: 100%;padding: 5px
    }
</style>