<template>
  <v-list-item @click="f_emit_selection">
    <v-list-item-content>
      <v-list-item-title
        class="caption"
        style="font-weight: bold"
        v-html="f_format_date_heure(item.commentaire_date_heure) + ' ( ' + item.utilisateur + ' )'"
      />
      <v-list-item-content
        class="caption"
        v-html="item.commentaire_texte"
      />
    </v-list-item-content>
  </v-list-item>
</template>

<script>

import FxItemTemplate from '@/wikaz-core/ui/composants/base/FxItemTemplate'

export default {
    name: 'IxObservations',
    extends: FxItemTemplate,
}
</script>

<style scoped>

</style>