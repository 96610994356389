<template>
  <div style="display: flex;flex-direction: column;overflow: auto">
    <fx-titre titre="Historique des versions" />
    <fx-list
      ref="obj_liste"
      style="overflow: auto"
      :url="urlRessourceEndPoint"
      :data-provider.sync="datas"
      :item-renderer="itemRendererAppartement"
      show-search
      show-refresher
      show-result
      show-add
      @add="f_ajouter"
    />
    <v-dialog
      v-model="showDialog"
      width="440"
    >
      <v-card
        :key="indexKey"
        style="display:flex; flex-direction: column;padding:10px;gap: 10px"
      >
        <v-card-title>Ajouter un fichier</v-card-title>
        <div style="display: flex;align-items: center">
          <fx-label text="Fichier ( PDF )" />
          <v-file-input
            v-model="uploadFile"
            outlined
            dense
            hide-details
            accept="application/pdf"
            show-size
          />
        </div>
        <div style="display: flex">
          <fx-label text="Date expiration" />
          <fx-date-field-check v-model="selectedDate" />
        </div>
        <div style="display: flex;justify-content: flex-end;gap: 5px">
          <v-btn
            color="success"
            :disabled="!uploadFile"
            @click="f_valider"
          >
            Valider
          </v-btn>
          <v-btn
            color="error"
            @click="f_reset"
          >
            Annuler
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IxHistorique from '@/wikaz-konnect/gdt/systeme-documentaire/elements/historique-version/IxHistorique'

export default {
    name: 'CxHistoriqueVersion',
    props: {
        urlRessourceEndPoint: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            datas: null,
            itemRendererAppartement: IxHistorique,
            showDialog: false,
            uploadFile: null,
            selectedDate: null,
            indexKey: 0,
        }
    },
    methods: {
        f_ajouter () {
            this.showDialog = true
        },
        f_valider () {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '?expire=' + this.selectedDate, this.uploadFile).then(() => {
                this.f_reset()
                this.$refs.obj_liste.refresh()
                this.$emit('refresh')
            })
        },
        f_reset () {
            this.showDialog = false
            this.uploadFile = null
            this.selectedDate = null
            this.indexKey ++
        },
    },
}
</script>

<style scoped>

</style>