<template>
  <fx-datagrid-vuetify
    ref="objDatagrid"
    :url-ressource-end-point="urlRessourceEndPoint"
    @ajouter="f_ajouter"
    @modifier="f_modifier"
    @affectation_utilisateurs="f_affectations_utilisateur"
    @affectation_adresses="f_affectation_adresses"
  />
</template>

<script>
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'

export default {
    name: 'VxGestionZones',
    extends: CxBase,
    data () {
        return { urlRessourceEndPoint: 'api/entreprises/modules/gestion-zones/zones' }
    },
    methods: {
        f_actualiser () {
            this.$refs.objDatagrid.chargerData()
        },
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + '/create',
                (data) => {
                    this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                        this.f_actualiser()
                    })
                },
            )
        },
        f_modifier (item) {
            this.$wikaz.popups.showFormulaire(
                this.urlRessourceEndPoint + '/' + item.zones_id + '/edit',
                (data) => {
                    this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + item.zones_id, data).then(() => {
                        this.f_actualiser()
                    })
                },
            )
        },
        f_affectations_utilisateur (item) {
            this.$wikaz.popups.showTransfertList(
                'Affectations utilisateurs',
                this.urlRessourceEndPoint + '/' + item.zones_id + '/affectation-utilisateurs',
                this.urlRessourceEndPoint + '/' + item.zones_id + '/affectation-utilisateurs/0',
                () => {
                    this.f_actualiser()
                },
                () => {
                    this.f_actualiser()
                },
            )
        },
        f_affectation_adresses (item) {
            this.$wikaz.popups.showTransfertList(
                'Affectations adresses',
                this.urlRessourceEndPoint + '/' + item.zones_id + '/affectation-adresses',
                this.urlRessourceEndPoint + '/' + item.zones_id + '/affectation-adresses/0',
                () => {
                    this.f_actualiser()
                },
                () => {
                    this.f_actualiser()
                },
            )
        },
    },
}
</script>

<style scoped>

</style>