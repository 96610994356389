<template>
  <div class="cx-traitement-gestion-crm-pwa-container">
    <div
      v-if="index === 0 && datas"
      class="cx-traitement-gestion-crm-pwa-col1"
    >
      <cx-fiche-prospect
        v-if="datas"
        :datas="datas"
      />
      <div v-else />
      <div
        v-if="datas"
        style="display: flex;justify-content: center;align-items: center;margin-bottom: 10px"
      >
        <v-btn
          v-if="(datas.is_proprietaire > 0 || datas.is_admin === 1) && datas.contact_user_proprietaire > 0"
          x-small
          color="error"
          @click="f_liberer"
        >
          Libérer
        </v-btn>
        <v-btn
          v-if="datas.is_admin === 1"
          style="margin-left: 10px"
          x-small
          color="error"
          @click="f_supprimer"
        >
          Supprimer
        </v-btn>
        <v-btn
          v-if="datas.is_proprietaire === 0 && datas.contact_user_proprietaire === 0"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="f_recuperer"
        >
          Récupérer le prospect
        </v-btn>
        <v-btn
          v-if="datas.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="f_modifier"
        >
          Modifier
        </v-btn>
      </div>
      <cx-interlocuteur-gdi
        :item-renderer-interlocuteur="itemRendererInterlocuteur"
        :url-ressource-end-point="urlRessourceEndPoint + datas.contact_id +'/'"
      />
    </div>
    <div
      v-if="index === 1"
      class="cx-traitement-gestion-crm-pwa-col2"
    >
      <fx-titre titre="Actions commerciales" />
      <div style="display: flex;flex-wrap: wrap;justify-content: flex-end;width: 100%">
        <v-btn
          x-small
          color="success"
          @click="ajouterObservation"
        >
          Saisir une observation
        </v-btn>
        <v-btn
          v-if="datas.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="ajouterAction"
        >
          Action immédiate
        </v-btn>
        <v-btn
          v-if="datas.is_proprietaire === 1"
          style="margin-left: 10px"
          x-small
          color="success"
          @click="programmerAction"
        >
          Programmer une action
        </v-btn>
      </div>
      <fx-list
        ref="objListeActions"
        v-model="selectedAction"
        style="height: 100%;overflow: auto"
        show-search
        show-refresher
        :url="urlRessourceEndPoint + datas.contact_id + '/actions'"
        :item-renderer="itemRenderer"
        @itemEmitting="cacheKeyAction++"
        @refresh="selectedAction = null"
      />
    </div>
    <div
      v-if="index === 2"
      class="cx-traitement-gestion-crm-pwa-col3"
    >
      <cx-explorateur-fichier
        :extension-array="['pdf','jpg','png','jpeg']"
        show-import
        show-refresher
        :url-ressource-end-point="urlRessourceEndPoint + datas.contact_id + '/fichiers'"
      />
    </div>
    <v-bottom-navigation
      v-model="index"
      absolute
      grow
      hide-on-scroll
      horizontal
    >
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Prospect</span>
        <v-icon>person</v-icon>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Actions</span>
        <v-icon>today</v-icon>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        text
      >
        <span>Fichiers</span>
        <v-icon>folder</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>

import IxGestionCrmAction from '@/wikaz-konnect/crm/gestion-prospects-crm/IxGestionCrmAction'
import CxFicheProspect from './CxFicheProspect'
import CxBase from '@/wikaz-core/ui/composants/base/CxBase'
import CxInterlocuteurGdi from '@/wikaz-konnect/gdi/gestion-clients/interlocuteur/CxInterlocuteurGdi'
import IxItemInterlocuteurContact from '@/wikaz-konnect/crm/gestion-prospects-crm/IxItemInterlocuteurContact'

export default {
    name: 'CxTraitementCrmPwa',
    components: {
        CxInterlocuteurGdi,
        CxFicheProspect,
    },
    extends: CxBase,
    data () {
        return {
            index: 0,
            urlRessourceEndPoint: '',
            itemRenderer: IxGestionCrmAction,
            itemRendererInterlocuteur: IxItemInterlocuteurContact,
            selectedAction: null,
        }
    },
    mounted () {
        switch (process.env.VUE_APP_CLIENT_ID) {
        case '4':
            this.urlRessourceEndPoint = 'api/crm/modules/gestion-prospects-crm/contacts/'
            break
        case '5':
            this.urlRessourceEndPoint = 'api/entreprises/modules/gestion-prospects-wk-crm/contacts/'
            break
        }
        if (this.isMobile) {
            this.$store.getters.getViewPwa().module_libelle = 'Prospection'
            this.$store.getters.getViewPwa().config.iconLeft = 'arrow_back'
            this.$store.getters.getViewPwa().config.clickLeft = this.f_retour
        }
        this.getData()
    },
    methods: {
        getData () {
            this.$wikaz.request.get(this.urlRessourceEndPoint + this.datas.contact_id).then((data) => {
                this.datas = data
            })
        },
        f_liberer () {
            this.$wikaz.request.put(this.urlRessourceEndPoint + this.datas.contact_id + '/liberer').then(() => {
                this.f_retour()
            })
        },
        f_supprimer () {
            this.$wikaz.outils.notification.showConfirmation('Suppression', 'Souhaitez vous réellement supprimer ce prospect', () => {
                this.$wikaz.request.delete(this.urlRessourceEndPoint + this.datas.contact_id).then(() => {
                    this.f_retour()
                })
            })
        },
        f_recuperer () {
            this.$wikaz.request.put(this.urlRessourceEndPoint + this.datas.contact_id + '/recuperer').then(() => {
                this.f_retour()
            })
        },
        f_modifier () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + this.datas.contact_id + '/edit', this.f_result_modifier)
        },
        f_result_modifier (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + this.datas.contact_id, data).then(() => {
                this.getData()
            })
        },
        ajouterInterlocuteur () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + this.datas.contact_id + '/interlocuteurs/create', this.f_result_ajout_interlocuteur)
        },
        f_result_ajout_interlocuteur (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + this.datas.contact_id + '/interlocuteurs', data).then(() => {
                this.$refs.objListeInterlocuteurs.refresh()
            })
        },
        f_retour () {
            this.$store.commit('SET_VIEW_PWA', {
                component: 'vx-gestion-prospects-wk-crm',
                module_libelle: 'Prospection',
            })
        },
        ajouterObservation () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + this.datas.contact_id + '/observations/create', this.f_result_ajout_observation)
        },
        f_result_ajout_observation (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + this.datas.contact_id + '/observations', data).then(() => {
                this.$refs.objListeActions.refresh()
            })
        },
        f_refresh_action () {
            this.selectedAction = null
            this.$refs.objListeActions.refresh()
        },
        ajouterAction () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + this.datas.contact_id + '/actions/create', this.f_result_ajout_element)
        },
        f_result_ajout_element (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + this.datas.contact_id + '/actions', data).then(() => {
                this.f_refresh_action()
            })
        },
        programmerAction () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + this.datas.contact_id + '/programmer-actions/create', this.f_result_ajout_programme)
        },
        f_result_ajout_programme (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + this.datas.contact_id + '/programmer-actions', data).then(() => {
                this.f_refresh_action()
            })
        },
    },
}
</script>

<style scoped lang="scss">
        .cx-traitement-gestion-crm-pwa-container {
            // border: 1px solid red;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            padding: 10px;
            display: grid;
            grid-template-columns: 1fr ;
            grid-template-rows: 1fr 50px;
            grid-column-gap: 10px;
            overflow: auto;
        }

        .cx-traitement-gestion-crm-pwa-col1 {
            //border: 1px solid green;
            box-sizing: border-box;
            padding: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto 2fr;
            overflow: auto;
        }

        .cx-traitement-gestion-crm-pwa-col2 {
            //border: 1px solid green;
            box-sizing: border-box;
            padding: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto 1fr;
            grid-row-gap: 10px;
            overflow: auto;
        }

        .cx-traitement-gestion-crm-pwa-col3 {
            // border: 1px solid green;
            box-sizing: border-box;
            padding: 0;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            grid-row-gap: 10px;
            overflow: auto;
        }
</style>