<template>
  <div @click="f_afficher_detail()">
    <div>{{ dataProvider.nom_client | limitationLength }}</div>
    <div v-if="dataProvider.intervention_duree>0.5">
      {{ dataProvider.adresse_cp }} {{ dataProvider.adresse_ville }}
    </div>
    <div v-if="dataProvider.intervention_duree>0.75">
      {{ dataProvider.prestation_libelle }}
    </div>
  </div>
</template>

<script>
import CxDetailRdv from './CxDetailRdv'

export default {
    name: 'IxCelluleIntervention',
    filters: {
        limitationLength: function (value) {
            if (value.length >= 17) {
                return value.substring(0, 14) + '...'
            }
            return value

        },
    },
    props: {
        dataProvider: {
            type: Object,
            default: null,
        },
    },
    methods: {
        f_afficher_detail () {
            this.$wikaz.popups.show(
                CxDetailRdv,
                {
                    datas: {
                        titre: 'Détail Intervention',
                        width: '800px',
                        height: '450px',
                        dataProvider: this.dataProvider,
                    },
                },
                this.f_refresh)
        },
        f_refresh () {
            this.$emit('refresh')
        },
    },
}
</script>

<style scoped>

</style>