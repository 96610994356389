<template>
  <div class="vx-gestion-codes-prestations-principal">
    <div style="display: flex;padding: 10px;gap: 10px">
      <v-btn
        color="primary"
        @click="f_gerer_categories"
      >
        Gestion des métiers
      </v-btn>
      <!--            <v-btn color="primary" @click="f_gerer_codes_postaux">Gestion des codes postaux</v-btn>-->
    </div>
    <fx-datagrid-vuetify
      ref="objDatagrid"
      :url-ressource-end-point="urlRessourceEndPoint"
      @ajouter="f_ajouter"
      @inscription="f_inscription"
      @desinscription="f_desinscription"
      @edition="f_edit"
      @fichiers="f_fichiers"
      @affectation_technicien="f_affectation_technicien"
    />
  </div>
</template>
<script>
import CxAffectationTechniciensPrestations from './CxAffectationTechniciensPrestations'
import VxBase from '@/wikaz-core/ui/composants/base/VxBase'
import CxFichiersPrestations
from '@/wikaz-konnect/gdi/parametrages/gestion-codes-prestations/CxFichiersPrestations'
export default {
    name: 'VxGestionCodesPrestationsEntreprises',
    extends: VxBase,
    data () {
        return { urlRessourceEndPoint: 'api/entreprises/modules/gestion-codes-prestations-entreprises/prestations' }
    },
    methods: {
        f_ajouter () {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/create', this.f_result_ajouter)
        },
        f_result_ajouter (data) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_inscription (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.prestation_id + '/souscriptions?action=inscription', item).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_desinscription (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint + '/' + item.prestation_id + '/souscriptions?action=desinscription', item).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_edit (item) {
            this.$wikaz.popups.showFormulaire(this.urlRessourceEndPoint + '/' + item.prestation_id + '/edit', this.f_result_edit_prestation)
        },
        f_result_edit_prestation (data) {
            this.$wikaz.request.put(this.urlRessourceEndPoint + '/' + data.prestation_id, data).then(() => {
                this.$refs.objDatagrid.chargerData()
            })
        },
        f_affectation_technicien (item) {
            this.$wikaz.popups.show(
                CxAffectationTechniciensPrestations,
                {
                    datas:
                        {
                            urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.prestation_id + '/affectations-techniciens',
                            isEditable: true,
                            isPopup: true,
                        },
                },
            )
        },
        f_fichiers (item) {
            this.$wikaz.popups.show(
                CxFichiersPrestations,
                {
                    props:
                            {
                                urlRessourceEndPoint: this.urlRessourceEndPoint + '/' + item.prestation_id + '/fichiers',
                                titre: 'Liste des documents opératoire',
                                showImport: true,
                                showRefresher: true,
                                width: '400px',
                                height: '500px',
                            },
                },
            )
        },
        f_gerer_categories () {
            this.$wikaz.popups.showTransfertList(
                'Sélections des métiers',
                this.urlRessourceEndPoint + '/0/categories',
                this.urlRessourceEndPoint + '/0/categories/0',
                () => {
                    this.f_refresh()
                },
                () => {
                    this.f_refresh()
                },
            )
        },
        f_gerer_codes_postaux () {
            this.$wikaz.popups.showTransfertList(
                'Sélections des codes postaux',
                this.urlRessourceEndPoint + '/0/cp',
                this.urlRessourceEndPoint + '/0/cp/0',
                () => {
                    this.f_refresh()
                },
                () => {
                    this.f_refresh()
                },
            )
        },
        f_refresh () {
            this.$refs.objDatagrid.chargerData()
        },
    },
}
</script>
<style scoped lang="scss">
    .vx-gestion-codes-prestations-principal {
        width: 100%;
        height: 100%;
        // padding: 10px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        column-gap: 5px;
    }
</style>