<template>
  <div>Résident Pwa</div>
</template>

<script>
export default { name: 'CxTraitementGestionResidentPwa' }
</script>

<style scoped>

</style>