<template>
  <div class="cx-filtre-gestion-crm-container">
    <div class="cx-filtre-gestion-crm-ligne">
      <fx-label
        text="Type"
        width="40px"
      />
      <fx-dropdown
        v-model="selectedType"
        :data-provider.sync="dataType"
        url="api/edt/modules/systeme-documentaire/nomenclatures/documents/types"
        @on-change="filterChanged"
      />
    </div>
    <div>
      <fx-checkbox
        v-model="selectedFiltreAction"
        label="Filtrer les actions"
        @on-change="filterChanged"
      />
    </div>
  </div>
</template>
<script>

import FxFiltreTemplate from '@/wikaz-core/ui/composants/base/FxFiltreTemplate'

export default {
    name: 'CxFiltreDocuments',
    extends: FxFiltreTemplate,
    data () {
        return {
            dataType: null,
            selectedType: - 1,
            selectedFiltreAction: 0,
        }
    },
    methods: {
        getFiltreData () {
            return '?type=' + this.selectedType + '&action=' + this.selectedFiltreAction
        },
    },
}
</script>
<style scoped lang="scss">
    .cx-filtre-gestion-crm-container {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto;
        overflow: auto;
    }

    .cx-filtre-gestion-crm-ligne {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 40px 1fr;
        grid-template-rows: 1fr;
    }
</style>