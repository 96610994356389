<template>
  <v-card
    class="cx-propager-demande-container"
    :style="{width: width,height: height }"
  >
    <v-tabs
      v-model="tab"
      class="cx-propager-demande-container-header"
      background-color="primary"
      dark
    >
      <v-tab>Propager la demande dans les flux</v-tab>
      <v-tab>Affecter la demande</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="cx-propager-demande-container-principal"
      style="height: 100%;display: grid;grid-template-columns: 1fr;grid-template-rows: 1fr;"
    >
      <v-tab-item class="cx-propager-demande-container-page-1">
        <div style="display:flex;justify-content:center;align-items: center;height: 100%">
          La demande sera envoyé dans les flux
        </div>
        <div style="display: flex;justify-content: flex-end;gap: 10px">
          <v-btn
            color="success"
            :loading="loading"
            @click="f_propager_flux"
          >
            Propager
          </v-btn>
          <v-btn
            color="error"
            @click="$emit('close')"
          >
            Fermer
          </v-btn>
        </div>
      </v-tab-item>
      <v-tab-item class="cx-propager-demande-container-page-2">
        <fx-datagrid-vuetify
          v-if="tab===1"
          ref="obj_liste"
          :url-ressource-end-point="urlRessourceEndPoint"
          @affecter="f_affecter"
        />
        <div style="display: flex;justify-content: flex-end">
          <v-btn
            color="error"
            @click="$emit('close')"
          >
            Fermer
          </v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
export default {
    name: 'CxPropagerDemande',
    data () {
        return {
            titre: 'haha',
            urlRessourceEndPoint: null,
            width: '100%',
            height: '600px',
            date: null,
            tranche: null,
            dataPlanification: null,
            isMobile: window.Vue.$store.getters.isMobile(),
            datas: null,
            tab: null,
            loading: false,
        }
    },
    methods: {
        f_propager_flux () {
            this.loading = true
            this.$wikaz.request.post(this.urlRessourceEndPoint, { receveur: 0 }).then((data) => {
                if (data.code !== 'error') {
                    this.$emit('on-submit')
                }
                this.loading = false
            })
        },
        f_affecter (item) {
            this.$wikaz.request.post(this.urlRessourceEndPoint, { receveur: item.compte_id }).then((data) => {
                if (data.code !== 'error') {
                    this.$emit('on-submit')
                }
            })
        },
    },

}
</script>
<style scoped lang="scss">
    .cx-propager-demande-container {
        box-sizing: border-box;
        padding: 10px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        // grid-row-gap: 10px;
        overflow: auto;
    }

    .cx-propager-demande-container-header {
        height: 50px;
    }

    .cx-propager-demande-container-principal {
        height: 50px;
        // border: 1px solid red;
    }

    .cx-propager-demande-container-page-1 {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }

    .cx-propager-demande-container-page-2 {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
    }

</style>